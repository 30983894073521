import { Validators } from "../../../../library/utilities/Validators";
import { API_UPDATE_STATUS } from "../../../../helpers/constants";

export const apiProviderUpdateFormModel = [
  {
    label: "Api Url*",
    value: "",
    type: "text",
    placeholder: "Api Url",
    field: "apiURL",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Parameter Type*",
    value: "",
    type: "dropdown",
    placeholder: "Parameter Type",
    field: "parameterType",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      { value: "QueryString", label: "QueryString" },
      { value: "JSON", label: "JSON" },
    ],
  },
  {
    label: "Parameter Name Value*",
    value: "",
    type: "text",
    placeholder: "Parameter Name Value",
    field: "parameterNameValue",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Parameter Value*",
    value: "",
    type: "text",
    placeholder: "Parameter Value",
    field: "parameterValue",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Parameter Formate*",
    value: "",
    type: "text",
    placeholder: "Parameter Formate",
    field: "parameterFormate",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Level*",
    value: "",
    type: "dropdown",
    placeholder: "Level",
    field: "level",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      { value: 0, label: "0" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 6, label: "6" },
      { value: 7, label: "7" },
      { value: 8, label: "8" },
      { value: 9, label: "9" },
      { value: 10, label: "10" },
      { value: 11, label: "11" },
      { value: 12, label: "12" },
      { value: 13, label: "13" },
      { value: 14, label: "14" },
      { value: 15, label: "15" },
      { value: 16, label: "16" },
      { value: 17, label: "17" },
      { value: 18, label: "18" },
      { value: 19, label: "19" },
      { value: 20, label: "20" },
      { value: 21, label: "21" },
      { value: 22, label: "22" },
      { value: 23, label: "23" },
      { value: 24, label: "24" },
    ],
  },
  {
    label: "Parent Table Id*",
    value: "",
    type: "text",
    placeholder: "Parent Table Id",
    field: "parentTableId",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Insert Number Only" },
    ],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Table name to update*",
    value: "",
    type: "text",
    placeholder: "Table name to update",
    field: "tableNameToUpdate",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Second to update*",
    value: "",
    type: "text",
    placeholder: "Second to update",
    field: "secondToUpdate",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Enter number only" },
    ],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      ...API_UPDATE_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
