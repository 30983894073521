import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";

import { adFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import FileUploader from "../../../library/common/components/FileUploader";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";

export default class CreateAd extends Component {
  formRef = createRef();

  state = {
    files: [],
    currentAd: {
      title: "",
    },
    isEditMode: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
  };

  componentDidMount() {
    if (this.props.match.url.includes("edit")) {
      this.fetchCurrentAd(this.props.match.params.id);
      this.setState({ isEditMode: true });
    }
  }

  fetchCurrentAd = async (id) => {
    const { status, data } = await axiosInstance.get(`/ads/ad/${id}`);
    if (status === 200) {
      this.setState({ currentAd: data });
    }
  };

  handleFileUpload = (files) => {
    this.setState({ files });
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleSave = async () => {
    const { files, isEditMode } = this.state;
    try {
      const { current } = this.formRef;

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `/ads/${this.props.match.params.id}` : "/ads";

      const form = current.getFormData();
      const values = removeErrorFieldsFromValues(form.formData);

      if (form.isFormValid) {
        const formData = new FormData();
        formData.append("image", files[0]);
        formData.append("title", values.title);
        formData.append("startDate", values.startDate);
        formData.append("endDate", values.endDate);
        formData.append("url", values.url);
        formData.append("position", values.position);

        const { status } = await axiosInstance[method](url, formData, {
          header: { "Content-Type": "multipart/form-data" },
        });
        if (status === 200 || status === 201) {
          this.setActionMessage(
            true,
            "Success",
            `Ad ${isEditMode ? "Edited" : "Created"} Successfully`
          );
          if (isEditMode) {
            this.fetchCurrentAd(this.props.match.params.id);
          }
        }
      }
    } catch (err) {
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} ad`
      );
    }
  };

  back = () => {
    this.props.history.push("/admin/ads");
  };

  render() {
    const { currentAd, files, isEditMode, messageBox } = this.state;

    return (
      <Grid container className="page-content adminLogin text-left">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <h3 className="text-left">
              {isEditMode ? "Edit" : "Create New"} Ad
            </h3>

            <Form
              model={adFormModel}
              ref={this.formRef}
              values={currentAd && currentAd}
            />

            <div className="blog-file-upload">
              <FileUploader onDrop={this.handleFileUpload} />
              {files.map((file, index) => (
                <img key={index} src={file.preview} alt="file" />
              ))}
            </div>

            <Grid container>
              <Grid item xs={3}>
                <div style={{ marginTop: "20px" }}>
                  <ButtonComponent
                    className="mt-3 admin-btn-green"
                    onClick={this.handleSave}
                    color="primary"
                    value="Save"
                  />

                  <ButtonComponent
                    onClick={this.back}
                    className="mr-lr-30 admin-btn-orange"
                    value="Back"
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
