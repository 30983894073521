import React, { Component } from "react";
import "./BlogItem/blogItemStyle.scss";
import { config } from "../../helpers/config";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

// import blog1 from "../../images/blog_img/blog1.jpg";
// import blog2 from "../../images/blog_img/blog2.jpg";
// import blog3 from "../../images/blog_img/blog3.jpg";
// import blog4 from "../../images/blog_img/blog4.jpg";
// import blog5 from "../../images/blog_img/blog5.jpg";
// import blog6 from "../../images/blog_img/blog6.jpg";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";

import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Loader } from "../../library/common/components";
import { fetchBlogsAction } from "../../store/common/actions/blogs.actions";
import placeholderimage from "../../images/placeholder400x400.png";
import { EventEmitter } from "../../services/event";
import { PAGEMETASVALUES } from "../../helpers/constants";
class Blog extends Component {
  state = {
    selectedRaceType: 1,
    currentPage: 1,
    blogPerPage: 6,
  };

  componentDidMount() {
    PAGEMETASVALUES?.blogs &&
      EventEmitter.dispatch("setPageMeta", [PAGEMETASVALUES?.blogs]);
    this.fetchBlogs();
  }

  async fetchBlogs() {
    this.setState({ isLoading: true });
    await this.props.fetchBlogsAction();
    this.setState({ isLoading: false });
  }

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, blogPerPage } = this.state;
    let { blogs } = this.props;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < blogs.length / blogPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  renderBlog = (obj, i) => {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={4} key={i}>
          <Card className="blog-card-body">
            <CardMedia
              className="blog-card-img"
              image={
                obj?.thumbnail
                  ? config?.baseUrl + obj?.thumbnail
                  : obj?.url
                  ? config?.baseUrl + obj?.url
                  : placeholderimage
              }
            />
            <CardContent className="blog-inner-spaciing">
              <Typography variant="h6" className="blog-card-heading">
                <Link to={`/blog/${obj.id}`}>{obj.title}</Link>
              </Typography>
              <Typography variant="h6" className="blog-card-description">
                {obj.content}
              </Typography>
            </CardContent>
            <CardActions className="blog-inner-spaciing">
              <Link to={`/blog/${obj.id}`} className="blog-card-button">
                Read More
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </>
    );
  };
  render() {
    const { isLoading, currentPage, blogPerPage } = this.state;
    let { blogs } = this.props;
    blogs.sort((a, b) => b.createdAt - a.createdAt);
    blogs = blogs?.length > 0 && blogs?.sort((a, b) => (a.id < b.id ? 1 : -1));
    const pageNumbers = [];
    let currentPageBlog = blogs;

    if (blogs?.length > 0) {
      const indexOfLastTodo = currentPage * blogPerPage;
      const indexOfFirstTodo = indexOfLastTodo - blogPerPage;
      currentPageBlog = blogs.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(blogs.length / blogPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <Container className="page-banner blogs">
        <Grid container xs={12} className="text-center">
          <Typography className="blog-heading">Blog</Typography>
          <Grid container>
            {isLoading && blogs.length === 0 && (
              <Grid xs={12}>
                <Loader />
              </Grid>
            )}

            {currentPageBlog?.length > 0 &&
              currentPageBlog?.map((obj, i) => this.renderBlog(obj, i))}
          </Grid>
        </Grid>
        <div className="runnerPagination">
          <button
            className="blog-pagination-button"
            onClick={() => this.handlePaginationButtonClick("prev")}
          >
            <ReactSVG src={arrowLeft} />
          </button>
          <Pagination
            hideNextButton
            hidePrevButton
            page={this.state.currentPage}
            onChange={this.handlePaginationClick}
            count={pageNumbers[pageNumbers?.length - 1]}
            siblingCount={2}
            boundaryCount={1}
            size="small"
          />
          <button
            className="blog-pagination-button"
            onClick={() => this.handlePaginationButtonClick("next")}
          >
            <ReactSVG src={arrowRight} />
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ blogs }) => {
  return {
    blogs: blogs.blogs,
  };
};

export default connect(mapStateToProps, { fetchBlogsAction })(Blog);
