import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => (
    <span className="spinner" style={{display:'inline-block',padding:'3px 0'}}>
            <CircularProgress size={24}/>
    </span>
);

export default Loader;
