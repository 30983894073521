/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
import HttpClient from "./http-client";
import CommonService from "./common";
import { authHandler } from "../store/common/actions/common.actions";
import StorageService from "./storage";
import { STORAGE_ENGINES } from "../helpers/constants";
import { config } from "../helpers/config";

const BASE_URL = config.baseUrl;

export default class ApiService {
  constructor() {
    this.httpClient = new HttpClient();
    this.commonService = new CommonService();
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
    this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
    this.store = this.commonService.store;
  }

  getAllCategories = (queryData = {}, config = {}) => {
    return this.httpClient.get(`${BASE_URL}/category/`, config);
  };

  forgotPasword = (queryData = {}, config = {}) => {
    return this.httpClient.get(
      `${BASE_URL}/user/forgot-password/${queryData.email}`,
      config
    );
  };
  register = (queryData = {}, config = {}) => {
    return this.httpClient.post(`${BASE_URL}/user/register`, queryData, config);
  };
  contact = (queryData = {}, config = {}) => {
    return this.httpClient.post(`${BASE_URL}/contact`, queryData, config);
  };
}
