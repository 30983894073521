import React, { PureComponent } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Button, Chip } from "@material-ui/core";
import Carousel, { consts } from "react-elastic-carousel";
import Countdown from "react-countdown";
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import horse from "../../images/sport_icons/horse-head.svg";
import greys from "../../images/sport_icons/Greyhound.svg";
import harnes from "../../images/sport_icons/Harness.svg";

import "./headertopbar.scss";
import { connect } from "react-redux";
import { logoutUser } from "../../library/common/actions";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants/UrlConstants";
import {
  fetchRacings,
  fetchUpcomingRaces,
  removeComplatedUpcommingRace,
} from "../../store/common/actions/common.actions";
import ShowModal from "../common/ShowModal/ShowModal";
import { EventEmitter } from "../../services/event";

// Renderer callback with condition for chip of timer
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return (
      <Chip
        className={(minutes < 5 ? "bg-orange" : "") + " sqare-chip "}
        size='small'
        label={<span>{"0s"}</span>}
      />
    );
  } else {
    // Render a countdown
    return (
      <Chip
        className={(minutes < 5 ? "bg-orange" : "") + " sqare-chip "}
        size='small'
        label={
          <span>
            {hours > 0
              ? `${hours}:${minutes}`
              : minutes > 0
              ? `${minutes}m`
              : seconds > 0
              ? `${seconds}s`
              : ""}
          </span>
        }
      />
    );
  }
};

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 250, itemsToShow: 1, itemsToScroll: 1 },
  { width: 300, itemsToShow: 1, itemsToScroll: 1 },
  { width: 350, itemsToShow: 2, itemsToScroll: 2 },
  { width: 400, itemsToShow: 2, itemsToScroll: 2 },
  { width: 700, itemsToShow: 3, itemsToScroll: 3 },
  { width: 880, itemsToShow: 4, itemsToScroll: 4 },
];

class UpcommingCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      races: [],
      allSports: [],
      isSyncModalOpen: false,
      isLoginModalOpen: false,
    };
    this.carousel = React.createRef();
    EventEmitter.subscribe("getInitialUpcommingRace", () =>
      this.props.fetchUpcomingRaces(this.props.filter, this.props.country)
    );
  }

  componentDidMount() {
    if (
      !window.location.href.includes("admin") &&
      window.location.pathname === "/"
    ) {
      this.props.fetchUpcomingRaces(this.props.filter, this.props.country);
      //this.fetchAllSports();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.filter !== prevProps.filter ||
      this.props.country !== prevProps.country
    ) {
      this.props.fetchUpcomingRaces(this.props.filter, this.props.country);
    }
  }

  // fetchUpcomingRaces(filter, country) {
  //   let raceUrl = `/events/upcoming?`;
  //   // let raceUrl = `/af/upcoming_race?`;
  //   if (typeof filter !== "undefined" && filter !== "") {
  //     let allremoved = filter.filter((item) => item.isChecked);
  //     let sportId = allremoved?.map((item) => item.id);

  //     raceUrl += "sportId=" + sportId.join(",");
  //   } else {
  //     raceUrl += "sportId=";
  //   }
  //   // if (typeof country !== "undefined" && country !== "") {
  //   //   let allcountryremoved = country.filter((item) => item.isChecked);
  //   //   let MeetingState = allcountryremoved?.map((item) => item.value);
  //   //   raceUrl += "&MeetingState=" + MeetingState.join(",");
  //   // } else {
  //   //   raceUrl += "&MeetingState=";
  //   // }

  //   axiosInstance
  //     .get(raceUrl)
  //     .then((res) => {
  //       this.setState({ races: res.data.result });
  //     })
  //     .catch((err) => console.log(err));
  // }

  // async fetchAllSports() {
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.sports);
  //     if (status === 200) {
  //       this.setState({ allSports: data.result });
  //     }
  //   } catch (err) {}
  // }

  // getSportName = (id) => {
  //   let { allSports } = this.state;
  //   let sportName = allSports
  //     .filter((obj) => obj.id === id)
  //     .map((object) => object.sportName);
  //   return sportName;
  // };

  myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <FontAwesomeIcon color='white' icon={faChevronLeft} />
      ) : (
        <FontAwesomeIcon color='white' icon={faChevronRight} />
      );
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  }

  renderSliderItem = (slide, i) => {
    // let sportName = this.getSportName(slide?.sportId);
    return (
      <Button
        key={i}
        variant='outlined'
        className='cursor slide-item topbar-slider'
        onClick={this.handleRaceNavigation(slide)}
        startIcon={
          <ReactSVG src={this.raceIcon(slide?.event?.sport?.sportName)} />
        }
        endIcon={
          <Countdown
            date={moment.utc(slide?.startTimeDate).local()}
            renderer={renderer}
            onComplete={() => this.props.removeComplatedUpcommingRace(slide)}
          />
        }
      >
        <span className='slide-title'>
          <span>{`R${slide?.raceNumber} ${slide?.event?.eventName}`}</span>
        </span>
      </Button>
      // <div
      //   key={i}
      //   className='cursor slide-item'
      //   onClick={this.handleRaceNavigation(slide)}>
      //   <FontAwesomeIcon color='white' icon={faHorseHead} />
      //   <span className='slide-title'>
      //     {slide.MeetingName}, R{slide.RaceNumber}
      //   </span>
      //   <Countdown date={new Date(slide.StartTimeAt)} renderer={renderer} />
      // </div>
    );
  };

  logout = () => {
    this.props.logoutUser();
    this.props.history.push("/");
  };

  handleRaceNavigation = (item) => () => {
    const { racings, isLoggedIn, history } = this.props;

    if (isLoggedIn) {
      history.push(
        `/race/${item?.event?.sportId}/${item?.event?.sport?.sportName}/${item?.event?.eventName}/${item.id}`,
        { item: item }
      );
      // history.push(
      //   `/race/${item?.CategorieId}/${item?.SportName}/${item?.MeetingName}/${item.RaceNumber}`,
      //   { item: item }
      // );

      // if (racings?.length > 0) {
      //   const race = racings?.find(
      //     (raceItem) => raceItem.id === item.meetingId
      //   );

      //   history.push("/sports/" + item?.CategorieId + "/" + item.meetingId, {
      //     all: racings,
      //     item: race,
      //     raceNumber: item.RaceNumber,
      //   });
      // } else {
      //   this.setState({ isSyncModalOpen: true });
      // }
    } else {
      this.setState({ isLoginModalOpen: true });
    }
  };

  // removeComplatedUpcommingRace = (item) => {
  //   this.setState((prevState) => {
  //     return {
  //       races: prevState.races?.filter((raceitem) => raceitem !== item),
  //     };
  //   });
  // };

  toggleSyncModal = () => {
    this.setState((prevState) => {
      return { isSyncModalOpen: !prevState.isSyncModalOpen };
    });
  };

  toggleLoginModal = () => {
    this.setState((prevState) => {
      return { isLoginModalOpen: !prevState.isLoginModalOpen };
    });
  };

  raceIcon = (name) => {
    if (name === "Horse Racing") {
      return horse;
    } else if (name === "Harness Racing") {
      return harnes;
    } else if (name === "Greyhound Racing") {
      return greys;
    }
  };

  render() {
    let { races } = this.state;
    const { search, upcomingRaces } = this.props;
    races = upcomingRaces;
    let upcomingRacesSlides = races;
    search !== "" &&
      (upcomingRacesSlides = races?.filter((obj) =>
        obj?.event?.eventName
          ?.toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase())
      ));
    // let upcomingRacesSlides = upcomingRaces.filter(
    //   (item) => new Date(item.StartTimeAt) > new Date()
    // );
    return (
      <>
        {this.filter}
        <ShowModal
          isModalOpen={this.state.isSyncModalOpen}
          Content={"Please wait data is syncing"}
          onModalClose={this.toggleSyncModal}
          onOkay={this.toggleSyncModal}
        />
        <ShowModal
          isModalOpen={this.state.isLoginModalOpen}
          Content={"Please Login to check Race Details"}
          onModalClose={this.toggleLoginModal}
          onOkay={this.toggleLoginModal}
        />
        {upcomingRacesSlides?.length > 0 ? (
          <>
            <div className='NextoJump-Item NextoJump-RaceSlider'>
              <Carousel
                ref={(ref) => (this.carousel = ref)}
                pagination={false}
                itemPosition={consts.CENTER}
                itemPadding={[0, 0, 0, 0]}
                breakPoints={breakPoints}
                onChange={(currentItemObject, currentPageIndex) => {
                  // console.log(currentPageIndex);
                }}
                showArrows={false}
              >
                {upcomingRacesSlides?.length > 0
                  ? upcomingRacesSlides?.map((slide, i) =>
                      this.renderSliderItem(slide, i)
                    )
                  : ""}
              </Carousel>
            </div>
            <div className='NextoJump-Item NextoJump-Nav'>
              <button onClick={() => this.carousel.slidePrev()}>
                <FontAwesomeIcon color='white' icon={faChevronLeft} />
              </button>
              <button onClick={() => this.carousel.slideNext()}>
                <FontAwesomeIcon color='white' icon={faChevronRight} />
              </button>
            </div>
          </>
        ) : (
          <div className='NextoJump-Item NextoJump-RaceSlider'></div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ authReducer, common, sports }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    racings: common.racings,
    user: authReducer.user,
    upcomingRaces: common.upcomingRaces,
    selected_sport_cat_id: sports.selected_sport_cat_id,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    fetchRacings,
    fetchUpcomingRaces,
    removeComplatedUpcommingRace,
  })(UpcommingCarousel)
);
