import { Validators } from "../../../library/utilities/Validators";

export const vehicleFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Variation",
    value: "",
    type: "text",
    placeholder: "Variation",
    field: "variation",
    validators: [
      { check: Validators.number, message: "Please enter number only" },
    ],
    required: false,
    className: "6",
  },
];
