import { Validators } from "../../../library/utilities/Validators";

export const statesFormModel = [
  {
    label: "States*",
    value: "",
    type: "text",
    placeholder: "States",
    field: "state",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
  {
    label: "Update Required*",
    value: "",
    type: "dropdown",
    placeholder: "Update Required",
    field: "updateRequired",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [
      { id: 1, value: "1", label: "Yes" },
      { id: 2, value: "0", label: "No" },
    ],
  },
];
