import React from "react";
import "./tipspayment.scss";
import {
  Container,
  Grid,
  Typography,
  InputLabel,
  Input,
  Divider,
  Button,
} from "@material-ui/core";
import axiosInstance from "../../helpers/Axios";

class TpisPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        residentalAddress: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    var loginuser = JSON.parse(sessionStorage.getItem("user"));

    if (loginuser?.id && loginuser?.id !== "") {
      this.setState({ isLoading: true });
      axiosInstance
        .get("/user/" + loginuser?.id)
        .then(({ status, data }) => {
          if (status === 200) {
            let userData = {
              ...data,
              firstName: data.firstName ? data.firstName : data.fullname,
              email: data.email ? data.email : data?.username,
              password: "********",
            };

            this.setState({ isLoading: false, userData });
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: true });
        });
    }
  }
  render() {
    let {
      firstName,
      lastName,
      email,
      phone,
      residentalAddress,
      password,
    } = this.state.userData;
    return (
      <>
        <div className="payment-heading-main">
          <Typography variant="h5">Winning Edge Tips Payment</Typography>
        </div>
        <div className="payment-wrapper">
          <Container>
            <Grid container>
              <Grid item xs={9} className="payment-main-left">
                <div className="payment-inner-body payment-inner-body-left">
                  <Grid container>
                    <Grid item md={12} lg={6} className="userdetail-inner-left">
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Typography className="userdetail-heading">
                            Account Details
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <form className="userdetail-form">
                        <Grid container spacing={5}>
                          <Grid item xs={12} sm={6} className="pd-btm">
                            <InputLabel>First Name</InputLabel>
                            <Input
                              //   className={
                              //       typeof error?.firstName !== "undefined" &&
                              //       error?.firstName !== ""
                              //         ? " invalid"
                              //         : ""
                              //   }
                              placeholder={"First Name"}
                              id="firstname"
                              name="firstName"
                              value={firstName}
                              //onChange={this.handleChangeInput}
                            />
                            {/* {typeof error?.firstName !== "undefined" &&
                            error?.firstName !== "" && (
                              <FormHelperText
                                className="userdetail-error"
                                id="component-helper-text-firstname"
                              >
                                <span className="error color-danger text-field-error">
                                  {error?.firstName}
                                </span>
                              </FormHelperText>
                            )} */}
                          </Grid>
                          <Grid item xs={12} sm={6} className="pd-top">
                            <InputLabel>Last Name</InputLabel>
                            <Input
                              //   className={
                              //       typeof error?.lastName !== "undefined" &&
                              //       error?.lastName !== ""
                              //         ? " invalid"
                              //         : ""
                              //   }
                              placeholder={"Last Name"}
                              id="lastname"
                              name="lastName"
                              value={lastName}
                              // onChange={this.handleChangeInput}
                            />
                            {/* {typeof error?.lastName !== "undefined" &&
                            error?.lastName !== "" && (
                              <FormHelperText
                                className="userdetail-error"
                                id="component-helper-text-lastname"
                              >
                                <span className="error color-danger text-field-error">
                                  {error?.lastName}
                                </span>
                              </FormHelperText>
                            )} */}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Email</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.email !== "undefined" &&
                            //   error?.email !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Email"}
                            id="email"
                            name="email"
                            value={email}
                            //   onChange={this.handleChangeInput}
                          />
                          {/* {typeof error?.email !== "undefined" &&
                        error?.email !== "" ? (
                          <FormHelperText
                            className="userdetail-error"
                            id="component-helper-text-email"
                          >
                            <span className="error color-danger text-field-error">
                              {error?.email}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )} */}
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Phone number</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.phone !== "undefined" &&
                            //   error?.phone !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Phone Number"}
                            id="phone"
                            name="phone"
                            value={phone}
                            //   onChange={this.handleChangePhone}
                          />
                          {/* {typeof error?.phone !== "undefined" &&
                        error?.phone !== "" ? (
                          <FormHelperText
                            className="userdetail-error"
                            id="component-helper-text-phone"
                          >
                            <span className="error color-danger text-field-error">
                              {error?.phone}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )} */}
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Residential Address</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.residentalAddress !== "undefined" &&
                            //   error?.residentalAddress !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Residental Address"}
                            id="residentalAddress"
                            name="residentalAddress"
                            value={residentalAddress}
                            //   onChange={this.handleChangeInput}
                          />
                          {/* {typeof error?.residentalAddress !== "undefined" &&
                        error?.residentalAddress !== "" ? (
                          <FormHelperText
                            className="userdetail-error"
                            id="component-helper-text-address"
                          >
                            <span className="error color-danger text-field-error">
                              {error?.residentalAddress}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )} */}
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Password</InputLabel>
                          <Input
                            placeholder={"Password"}
                            id="password"
                            name="password"
                            value={password}
                            //   onChange={this.handleChangeInput}
                          />
                        </Grid>
                      </form>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      lg={6}
                      className="userdetail-inner-right"
                    >
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Typography className="userdetail-heading">
                            Payment information
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <form className="userdetail-form">
                        <Grid item xs={12}>
                          <InputLabel>Name on Card</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.email !== "undefined" &&
                            //   error?.email !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Name on Card"}
                            id="nameOmCard"
                            name="nameOmCard"
                            //   value={userData?.email}
                            //   onChange={this.handleChangeInput}
                          />
                          {/* {typeof error?.email !== "undefined" &&
                        error?.email !== "" ? (
                          <FormHelperText
                            className="userdetail-error"
                            id="component-helper-text-email"
                          >
                            <span className="error color-danger text-field-error">
                              {error?.email}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )} */}
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>Card Number</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.phone !== "undefined" &&
                            //   error?.phone !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Card Number"}
                            id="cardNumber"
                            name="cardNumber"
                            //   value={userData?.phone}
                            //   onChange={this.handleChangePhone}
                          />
                          {/* {typeof error?.phone !== "undefined" &&
                        error?.phone !== "" ? (
                          <FormHelperText
                            className="userdetail-error"
                            id="component-helper-text-phone"
                          >
                            <span className="error color-danger text-field-error">
                              {error?.phone}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )} */}
                        </Grid>
                        <Grid container spacing={5}>
                          <Grid item xs={12} sm={6} className="pd-btm">
                            <InputLabel>Expire Date</InputLabel>
                            <Input
                              //   className={
                              //       typeof error?.firstName !== "undefined" &&
                              //       error?.firstName !== ""
                              //         ? " invalid"
                              //         : ""
                              //   }
                              placeholder={"Expire Date"}
                              id="expireDate"
                              name="expireDate"
                              //value={userData?.firstName}
                              //onChange={this.handleChangeInput}
                            />
                            {/* {typeof error?.firstName !== "undefined" &&
                            error?.firstName !== "" && (
                              <FormHelperText
                                className="userdetail-error"
                                id="component-helper-text-firstname"
                              >
                                <span className="error color-danger text-field-error">
                                  {error?.firstName}
                                </span>
                              </FormHelperText>
                            )} */}
                          </Grid>
                          <Grid item xs={12} sm={6} className="pd-top">
                            <InputLabel>CVN</InputLabel>
                            <Input
                              //   className={
                              //       typeof error?.lastName !== "undefined" &&
                              //       error?.lastName !== ""
                              //         ? " invalid"
                              //         : ""
                              //   }
                              placeholder={"CVN"}
                              id="cvn"
                              name="cvn"
                              // value={userData?.lastName}
                              // onChange={this.handleChangeInput}
                            />
                            {/* {typeof error?.lastName !== "undefined" &&
                            error?.lastName !== "" && (
                              <FormHelperText
                                className="userdetail-error"
                                id="component-helper-text-lastname"
                              >
                                <span className="error color-danger text-field-error">
                                  {error?.lastName}
                                </span>
                              </FormHelperText>
                            )} */}
                          </Grid>
                        </Grid>
                      </form>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Typography className="userdetail-heading promo-heading">
                            Promo Code
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <form className="userdetail-form">
                        <Grid item xs={12}>
                          <InputLabel>Promo Code</InputLabel>
                          <Input
                            // className={
                            //   typeof error?.email !== "undefined" &&
                            //   error?.email !== ""
                            //     ? " invalid"
                            //     : ""
                            // }
                            placeholder={"Promo Code"}
                            id="promoCode"
                            name="promoCode"
                            //   value={userData?.email}
                            //   onChange={this.handleChangeInput}
                          />
                        </Grid>
                      </form>
                      <Button className="payment-button">Continue</Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={3} className="payment-main-right">
                <div className="payment-inner-body payment-inner-body-right">
                  <Grid item xs={12} sm={12}>
                    <Typography className="userdetail-heading">
                      Summary
                    </Typography>
                  </Grid>
                  <Divider />
                  <Typography className={"payment-right-text"}>
                    HONG KONG TIPS & RATINGS - THE SYNDICATE
                  </Typography>
                  <Typography className={"payment-right-name"}>Name</Typography>
                  <div className="payment-bill-price">
                    <Typography>Type</Typography>
                    <Typography>Monthly</Typography>
                  </div>
                  <div className="payment-bill-price">
                    <Typography>Price</Typography>
                    <Typography>$578.98</Typography>
                  </div>
                  <br />
                  <Divider />
                  <div className="payment-bill-price total-price">
                    <Typography>Total</Typography>
                    <Typography>$578.98</Typography>
                  </div>
                  <Divider />
                  <br />
                  <Typography className={"payment-right-name"}>
                    Billing Information
                  </Typography>
                  <Typography className="payment-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam,
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}
export default TpisPayment;
