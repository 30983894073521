import React, { PureComponent } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  AppBar,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  SwipeableDrawer,
  Toolbar,
} from "@material-ui/core";
import BetsSidebarDrawer from "../betssidebardrawer/betssidebardrawer";
import SidebarMenu from "../sidebarmenu/sidebarmenu";
import LoginForm from "../loginform/loginform";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/logo.svg";
import axiosInstance from "../../helpers/Axios";
import "./mainmenubar.scss";
import CloseIcon from "@material-ui/icons/Close";
import { CATEGORY_MENU_STATUS } from "../../helpers/constants";
import { getRoute } from "../../helpers/common";

class MainMenuBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showMenu: false, menu: [] };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState((prevstate) => {
      return { showMenu: !prevstate.showMenu };
    });
  }

  componentDidMount() {
    //this.fetchAllCategories();
  }

  async fetchAllCategories() {
    try {
      const { status, data } = await axiosInstance.get("/category");
      if (status === 200) {
        let menu = data.filter(
          (item) => item?.status !== CATEGORY_MENU_STATUS.DEACTIVE
        );
        menu = menu.map((item) => {
          return {
            ...item,
            title: item.name,
            to: getRoute(item.name, item.id, item.sportType),
            icon: "",
          };
        });

        this.setState({ menu });
      }
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    const { showMenu, menu } = this.state;
    const { isLoggedIn, location } = this.props;
    const flexContainer = {
      display: "flex",
      flexDirection: "row",
      padding: 0,
      flexWrap: "wrap",
      justifyContent: "flex-end",
    };

    var menuItems = [
      { to: "/", title: "Home" },
      { to: "/aboutus", title: "About Us" },
      { to: "/membership", title: "Memberships" },
      { to: "/blog", title: "Blog" },
      { to: "/partners", title: "Bookmakers" },
      { to: "/contactus", title: "Contact Us" },
    ];
    var loginMenuItems = [
      { to: "/allsports", title: "All Sports" },
      { to: "/", title: "Horse Racing" },
      { to: "/race/2/Harness Racing", title: "Harness Racing" },
      { to: "/race/3/Greyhound Racing", title: "Greyhound Racing" },
      { to: "/sports/Cricket/4", title: "Cricket" },
      { to: "/sports/Boxing/6", title: "Boxing" },
    ];
    menuItems = isLoggedIn ? loginMenuItems : menuItems;

    const correctSidebar =
      location.pathname.includes("rugby") ||
      location.pathname.includes("australian")
        ? "rugby"
        : "horse";

    const type = location.pathname.includes("rugby")
      ? "Rugby League"
      : "Australian Rule";

    return (
      <>
        <AppBar
          className="main-menu-wrap appbarpad"
          position="static"
          color="transparent"
          elevation={0}
        >
          <Container>
            <div className="mobileMenu">
              <SwipeableDrawer
                anchor={"left"}
                open={showMenu}
                onClose={this.toggleMenu}
                onOpen={this.toggleMenu}
                className="mobileMenuDrawer"
                swipeAreaWidth={0}
              >
                <div className="mobileMenuDrawerClose">
                  <CloseIcon onClick={this.toggleMenu} />
                </div>
                <List>
                  {menuItems.length > 0
                    ? menuItems.map((item, i) => (
                        <ListItem button key={i}>
                          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                          <NavLink exact to={item.to} activeClassName="active">
                            {item.title}
                          </NavLink>
                          {/* <Link className={i === 0?'active':''}  to={item.to}>{item.title}</Link> */}
                        </ListItem>
                      ))
                    : ""}
                </List>
              </SwipeableDrawer>
            </div>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ justifyContent: "space-between" }}
            >
              <Grid
                item
                md={2}
                sm={2}
                xs={isLoggedIn ? 5 : 6}
                className="text-left logo-container"
                // style={{ maxWidth: "19%", flexBasis: "19%" }}
              >
                {!isLoggedIn && (
                  <Button
                    className="mobileMenu-button"
                    onClick={this.toggleMenu}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Button>
                )}

                <Link to="/">
                  <img
                    src={logo}
                    style={{ maxWidth: 240.56 }}
                    className="App-logo-main"
                    alt="logo"
                  />
                </Link>
              </Grid>
              {}
              <Grid
                item
                xs={!isLoggedIn ? 4 : 3}
                className="hide-xs hide-lg-tab hide-md hide-lg hide-xl"
              ></Grid>
              <Grid
                item
                md={10}
                sm={10}
                className="text-right hide-xs hide-sm-tab"
                style={{ maxWidth: "80.358%" }}
              >
                <Toolbar
                  className="bg-light-green main-menu hide-xs hide-sm-tab menu-scroll"
                  variant="dense"
                  style={{ minHeight: 35 }}
                >
                  <List component="ul" style={flexContainer}>
                    {menuItems.length > 0
                      ? menuItems.map((item, i) => (
                          <ListItem key={i}>
                            <NavLink
                              exact
                              to={item.to}
                              activeClassName="active"
                              isActive={(match, location) => {
                                if (
                                  item.title === "Horse Racing" &&
                                  location.pathname === "/"
                                ) {
                                  return true;
                                } else if (
                                  location.pathname
                                    ?.toLowerCase()
                                    ?.includes(item.title?.toLowerCase())
                                ) {
                                  return true;
                                }
                                if (!match) {
                                  return false;
                                }

                                return true;
                              }}
                            >
                              {item.title}
                            </NavLink>
                            {/* <Link  to={item.to}>{item.title}</Link> */}
                          </ListItem>
                        ))
                      : ""}
                  </List>
                </Toolbar>
                <div className="hide-xl hide-lg hide-md hide-lg-tab">
                  {isLoggedIn && (
                    <BetsSidebarDrawer sidebar={correctSidebar} type={type} />
                  )}
                </div>
              </Grid>
              {!isLoggedIn ? (
                <>
                  <Grid
                    item
                    xs={6}
                    className="hide-xl hide-lg hide-md hide-lg-tab MuiGrid-spacing-xs-0 login-buttons"
                  >
                    <LoginForm />
                  </Grid>
                </>
              ) : (
                <Grid item xs={7} className="header-button-mb">
                  <div className="hide-xl hide-lg hide-md hide-lg-tab">
                    <div className="account account-header">
                      <Button
                        // className="submit-btn bg-green"
                        className="btn"
                        variant="contained"
                      >
                        <Link to="/userdetail">Account</Link>
                      </Button>
                    </div>
                    <BetsSidebarDrawer sidebar={correctSidebar} type={type} />
                  </div>
                </Grid>
              )}
            </Grid>
          </Container>
        </AppBar>
      </>
    );
  }
}

export default MainMenuBar;
