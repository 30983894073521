import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { leagueFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let leagueFormModelArray = leagueFormModel;

class CreateLeague extends React.Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      values: { leagueName: "", variation: "" },
      sportsId: "",
      sportsAll: [],
      sportsTypeId: "",
      error: "",
      isLoading: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentLeague(this.props.id);
    }
  }

  componentWillUnmount() {
    leagueFormModelArray = leagueFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentLeague = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.league + `/${id}`);
    if (status === 200) {
      this.setState({
        values: data.result,
        sportsId: data.result.sportsId,
      });
      this.getSportTypeId(data.result.sportsId);
      let variationArray = JSON.parse("[" + data.result.variation + "]");
      if (variationArray.length > 0) {
        this.setState(() => {
          return {
            values: {
              ...this.state.values,
              ["variation"]: variationArray[0],
            },
          };
        });
      }
    }
  };

  getSportTypeId = (id) => {
    const { allSports } = this.props;
    let obj = allSports.filter((obj) => obj.id == id);
    obj?.length > 0 &&
      this.setState({ sportsTypeId: obj[0].sportTypeId }, () =>
        this.fetchAllSports(this.state.sportsTypeId)
      );
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { leagueName } = this.state.values;
    let { sportsId, error } = this.state;
    let flag = true;

    if (leagueName === "" || sportsId === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    if (sportsId === "") {
      error = "This field id mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error = "";
    }

    this.setState({ error });

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    let { sportsId } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.league}/${this.props.id}` : URLS.league;

      const values = removeErrorFieldsFromValues(form.formData);
      values["sportsId"] = sportsId;
      leagueFormModelArray = leagueFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllLeagues();
          this.setActionMessage(
            true,
            "Success",
            `League ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} League`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    leagueFormModelArray = leagueFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "sportsId") {
      this.setState({
        sportsId: value,
        error: "",
      });
    }
    if (name === "sportsTypeId") {
      this.setState({ sportsTypeId: value, sportsId: "" }, () =>
        this.fetchAllSports(value)
      );
    }
  };

  fetchAllSports = async (id) => {
    let params = [id];
    const { status, data } = await axiosInstance.post(
      `sports/sport/type`,
      params
    );
    if (status === 200) {
      this.setState({ sportsAll: data.result });
    }
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      sportsId,
      error,
      sportsTypeId,
      sportsAll,
    } = this.state;
    var { isEditMode, allSportsType } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={leagueFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div>
                <label>Sports Type*</label>
                <select
                  className="select-box-manual"
                  name="sportsTypeId"
                  onChange={this.handleSelect}
                  value={sportsTypeId}
                >
                  <option>No Sport Type Selected</option>
                  {allSportsType?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.sportType}
                    </option>
                  ))}
                </select>
                <label>Sports*</label>
                <select
                  className="select-box-manual"
                  name="sportsId"
                  onChange={this.handleSelect}
                  value={sportsId}
                >
                  <option value="">
                    {sportsTypeId !== ""
                      ? "No Sport Selected"
                      : "Select Sport Type first"}
                  </option>
                  {sportsAll?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.sportName}
                    </option>
                  ))}
                </select>
                {typeof error !== "undefined" && error !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateLeague;
