import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/racingandsport.png";

class MembershipRaceSports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Racing & Sports Form Guide
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                Get immediate access to detailed sports and racing form guide,
                race cards and race fields for Australian sporting and racing
                events. Our form guide contains all the tab information you need
                to refine your strategy and make an informed decision. motzza
                members can get sports and racing form guides for every
                Australian meeting. These guides display all the information and
                give you all the tools you need to get started.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                Our detailed racing and sports form guides contains all the
                major sporting events across Australia, New Zealand, UK, the
                United States, South Africa, France, Ireland and many more
                countries. Also, you can filter the forms by selecting the date,
                event time and country you would like to view. Each form gives
                you a great amount of information in the form of ratings,
                neutrals, time report tables and worksheets. You can also make
                adjustments to the worksheets based on your own preferences or
                requirements.
              </Typography>
              <ul className="affiliate-ul">
                <li>Detailed race and sports analysis from expert analysts</li>
                <li>Full fields, form and barrier trials</li>
                <li>Speed maps</li>
                <li>All statistics for every runner or player</li>
                <li>Comments on each horse’s previous run</li>
                <li>Sectional times</li>
                <li>Ratings for every run or event</li>
              </ul>
              <Typography variant="body1" className="afiliate-signup-content">
                Whether you are new to sports and racing, or you’re a seasoned
                professional, our racing and sports form guide is for you.{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  Sign up to motzza now
                </span>{" "}
                and get access today.
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipRaceSports;
