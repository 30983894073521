import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { middleware as reduxPackMiddleware } from "redux-pack";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const middleware = [thunk, reduxPackMiddleware];
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // 'settings' to be added after dark theme development  // only whitelist the reducers whose data you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// const composeEnhancers = compose(applyMiddleware(...middleware));
const store = createStore(
  persistedReducer,
  {},
  composeEnhancer(applyMiddleware(...middleware))
);

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

const persistor = persistStore(store);

// const deleteStore = () => persistor.purge();
const deleteStore = () => persistor.purge();

export { persistor, store, deleteStore };
