import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const ButtonComponent = ({className, disabled, value, onClick, loading, icon, color, startIcon,type}) => {
    return (
        <Button startIcon={startIcon} className={className} variant="contained" disabled={disabled} onClick={onClick}
                color={color} type={type? type: 'button'}>
            {value}
        </Button>

    );
};

export default ButtonComponent;

Button.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    startIcon: PropTypes.any,
    onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
    className: 'btn-success',
    color: 'default',
    value: '',
    disabled: false,
    startIcon: null
};
