import React, { Component, createRef } from "react";
import { Grid, Paper, Button, Modal } from "@material-ui/core";
import ActionMessage from "../../library/common/components/ActionMessage";
import axiosInstance from "../../helpers/Axios";
import ListMappings from "./frames/ListMappings";
import Loader from "../../library/common/components/Loader";

export default class GWMeetingMapping extends Component {
  formRef = createRef();

  state = {
    isModalOpen: false,
    isLoading: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
    mappingMeetings: [],
    mappings: [],
  };

  componentDidMount() {
    this.fetchMappings();
  }

  async getLadbrockData() {
    const { status, data } = await axiosInstance.get(
      "/meetingMapping/ledbrokes"
    );

    if (status === 200) {
      let mappingMeetings = data?.data.sort((a, b) => a.localeCompare(b));
      this.setState({
        mappingMeetings: mappingMeetings,
      });
    }
  }

  async fetchMappings() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get("/meetingMapping");

    if (status === 200) {
      this.getLadbrockData();
      this.setState({
        isLoading: false,
        mappings: data?.data?.sort(),
      });
    }
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  onModalClose = () => {
    this.setState({ isModalOpen: false });
  };
  onModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  saveMapping = async () => {
    const { status, data } = await axiosInstance.get("/sync");
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen, messageBox, mappingMeetings, mappings } = this.state;

    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper text-left">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={6}>
                  <h3 className="text-left">GenerationWeb MeetingMapping</h3>
                </Grid>
                <Grid item xs={6} className="text-right">
                  <Button
                    variant="contained"
                    color="primary admin-btn-green"
                    onClick={this.onModalOpen}
                  >
                    Sync Mapping Data
                  </Button>
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={12}>
                  <div style={{ marginTop: "30px" }}>
                    {this.state.isLoading ? (
                      <Loader />
                    ) : (
                      <ListMappings
                        mappings={mappings}
                        mappingMeetings={mappingMeetings}
                        saveMapping={this.handleSave}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Modal
          className="modal"
          open={isModalOpen}
          onClose={this.onModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={"paper"}>
            Sync will run in the background, will take 20 to 30 minutes to
            complete.
            <br /> <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.saveMapping}
            >
              Ok, Start Sync
            </Button>{" "}
            <Button
              variant="contained"
              color="secondary"
              onClick={this.onModalClose}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
