import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/memorabilia.png";

class MembershipMemorabilia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Memorabilia
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                With motzza, you can find official licensed sports memorabilia,
                giftware and merchandise for sale online. Pay tribute to your
                favourite racing and sporting events, teams and players by
                purchasing your very own memorabilia.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                motzza helps you find sports memorabilia from all the major
                sporting and racing events in Australia and worldwide. Our
                affiliates have an extensive range of official sports and
                entertainment memorabilia and collectables.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                As a motzza member, you get access to more stock and more
                athletes than anyone else, so you won’t miss out on that special
                item. Whether you’re passionate about Aussie sporting legends
                like Craig Lowndes, Luke Hodge or Johnathan Thurston, or you’re
                looking to invest in a personally signed item from an
                international icon such as Michael Jordan, Lionel Messi or
                Cristiano Ronaldo, motzza is the place for you.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                Our affiliates only stock authentic products that are produced
                to the highest standard to protect your investment. You can rest
                easy knowing that your purchase is real, authentic and has been
                manufactured under an official license with the express
                endorsement and permission of the athlete and sporting body
                featured. Your investment is always protected when you purchase
                through a motzza affiliated memorabilia and collectable
                retailer.
              </Typography>
              <Typography variant="body1" className="afiliate-signup-content">
                If you’re an avid collector or you’re just looking to pay
                tribute to a particular event in sporting history, we help you
                find exactly what you need. Sign up to{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  become a motzza member today.
                </span>
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipMemorabilia;
