import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";

class CreateAdPlaceHolderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: null,
      adPlaceHolderId: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
      error: {},
    };
  }

  componentDidMount() {
    let { allPage } = this.props;
    if (this.props.isEditMode) {
      this.fetchCurrentPage();
    }
    this.setState({
      adPlaceHolderId: this.props.adPlaceHolderId,
      pageId: allPage?.length > 0 ? allPage[0].id : null,
    });
  }
  componentWillUnmount() {
    this.setState({ error: {} });
  }

  fetchCurrentPage = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.adPlaceHolderPage + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({ pageId: data.result.pageId });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { pageId, error } = this.state;
    let flag = true;

    if (pageId === null) {
      flag = false;
      error.pageId = "This field is mendatory";
      this.setState({ isLoading: false });
    } else {
      flag = true;
      error.pageId = "";
    }
    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    let { pageId, adPlaceHolderId } = this.state;
    this.setState({ isLoading: true });
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.adPlaceHolderPage}/${this.props.id}`
        : URLS.adPlaceHolderPage;

      const values = {};
      values["pageId"] = pageId;
      values["adPlaceHolderId"] = adPlaceHolderId;
      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);

        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllAdPlaceHolderPages();
          this.setActionMessage(
            true,
            "Success",
            `AdPlaceHolder ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } AdPlaceHolder`
      );
    }
  };

  handleSelect = (e) => {
    let value = e.target.value;
    this.setState({ pageId: value, error: {} });
  };

  render() {
    var { messageBox, isLoading, pageId, error } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className='page-content adminLogin text-left'>
          <Grid item xs={12}>
            <Paper className='pageWrapper api-provider'>
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div>
                <label>Page*</label>
                <select
                  className='select-box-manual'
                  name='playerId'
                  onChange={this.handleSelect}
                  value={pageId}
                >
                  <>
                    {this.props.allPage?.map((obj, i) => (
                      <option value={obj.id}>{obj.pageName}</option>
                    ))}
                  </>
                </select>
                {typeof error.pageId !== "undefined" && error.pageId !== "" && (
                  <p style={{ color: "red", margin: "-14px 0 0 0" }}>
                    {error.pageId}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className='mt-3 admin-btn-green'
                        onClick={this.handleSave}
                        color='primary'
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className='mt-3 admin-btn-orange'
                        onClick={this.handleSave}
                        color='secondary'
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className='mr-lr-30'
                      value='Back'
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateAdPlaceHolderPage;
