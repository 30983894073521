import React, { PureComponent } from "react";
import { Button, Popper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Grid, AppBar } from "@material-ui/core";

import "./betssidebardrawer.scss";
import BetsSidebar from "../betssidebar/betssidebar";
import BetsOddsSlip from "../betsoddsslip/betsoddsslip";

import { FaBars, FaTimes } from "react-icons/fa";
import { EventEmitter } from "../../services/event";

class BetsSidebarPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    EventEmitter.subscribe("draweropen", this.showbetsidebar);
  }

  showbetsidebar = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <>
        <div className="BetsSidebarPopup-wrap">
          <Button
            onClick={this.showbetsidebar}
            variant="contained"
            size="small"
            className="BetsSidebar-button btn btn-warning color-white"
          >
            Odds
          </Button>
          <div className={"BetsSidebarPopup " + (open ? "active" : "")}>
            <Button
              className="MenuTopBar-button"
              variant="contained"
              onClick={this.handleClose}
            >
              <FaTimes />
            </Button>
            {/* <BetsSidebar key="sportidebar" /> */}
            <BetsOddsSlip />
          </div>
        </div>
      </>
    );
  }
}

export default BetsSidebarPopup;
