import SportList from "../sportlist/sportlist";
import RaceDetails from "../racelist/raceDetails";
import RaceList from "../racelist/racelist";
import SportRugby from "../sports/rugby/sports.rugby";
import RugbyTeams from "../sports/rugby/rugby.team";
import Help from "../help";
import Feedback from "../feedback";
import Competition from "../competition";
import WhatsNew from "../whatsnew";
import Promotion from "../promotion";
import UserDetail from "../userdetail/userdetail";
import OtherRacing from "../sports/otherracing/otherracing";
import TpisPayment from "../tipsPayment/tipsPayment";
import AllSports from "../allSports/allSports";

export const secureRoutes = [
  {
    path: "/allsports",
    component: AllSports,
    exact: true,
    index: 0,
    name: "All Sports",
    secure: true,
  },
  {
    path: "/",
    component: SportList,
    exact: true,
    index: 0,
    name: "Home",
    secure: true,
  },
  {
    path: "/home",
    component: SportList,
    exact: true,
    index: 0,
    name: "Home",
    secure: true,
  },
  {
    path: "/sports/rugby/:id",
    component: SportRugby,
    index: 0,
    name: "Rugby",
    secure: true,
  },
  {
    path: "/sports/australian/:id",
    component: SportRugby,
    index: 0,
    name: "Rugby",
    secure: true,
  },
  {
    path: "/rugby/markets",
    component: RugbyTeams,
    index: 0,
    name: "Rugby Markets",
    secure: true,
  },
  {
    path: "/markets/:sportName/:marketid",
    component: RugbyTeams,
    index: 0,
    name: "Sports Markets",
    secure: true,
  },
  {
    path: "/sports/:sportName/:id",
    component: SportRugby,
    index: 0,
    name: "Sports",
    secure: true,
  },
  {
    path: "/race/:id/:sportName/:meeting/:raceNumber",
    component: RaceDetails,
    index: 0,
    name: "Race Details Item",
    secure: true,
  },
  {
    path: "/race/:id/:sportName",
    component: SportList,
    index: 0,
    name: "Sport Item",
    secure: true,
  },
  {
    path: "/race/:id/:racename",
    component: RaceList,
    index: 0,
    name: "Race",
    secure: true,
  },
  {
    path: "/race/:sportName",
    component: OtherRacing,
    index: 0,
    name: "Cycling",
    secure: true,
  },
  {
    path: "/help",
    component: Help,
    index: 0,
    name: "Help",
    secure: true,
  },
  {
    path: "/feedback",
    component: Feedback,
    index: 0,
    name: "Feedback",
    secure: true,
  },
  {
    path: "/competition",
    component: Competition,
    index: 0,
    name: "Competition",
    secure: true,
  },
  {
    path: "/whats-new",
    component: WhatsNew,
    index: 0,
    name: "What's New",
    secure: true,
  },
  {
    path: "/promotions",
    component: Promotion,
    index: 0,
    name: "Promotions",
    secure: true,
  },
  {
    path: "/userdetail",
    component: UserDetail,
    index: 0,
    exact: false,
    name: "User Detail",
    secure: true,
    normalLayout: true,
  },
  {
    path: "/tipspayment",
    component: TpisPayment,
    index: 0,
    exact: false,
    name: "Winning Edge Tips Payment",
    secure: true,
    normalLayout: true,
  },
];
