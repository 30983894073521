import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormHelperText,
  InputBase,
} from "@material-ui/core";
import AuthService from "../../services/auth";
import { STORAGE_ENGINES } from "../../helpers/constants";
import StorageService from "../../services/storage";
import { EventEmitter } from "../../services/event";
import { connect } from "react-redux";
import "./loginform.scss";
import {
  getAuth,
  logoutUser,
  setAuthentication,
} from "../../library/common/actions";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logindata: { username: "", password: "" },
      error: {},
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.AuthService = new AuthService();
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
    this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
  }

  componentDidMount() {
    this.props.getAuth();
  }

  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { logindata: { ...prevState.logindata, [name]: value } };
    });
  }

  validate() {
    var { username, password } = this.state.logindata;
    var { error } = this.state;
    var flag = true;
    if (username === "") {
      error.username = "Email is required";
      flag = false;
    } else {
      error.username = "";
    }
    if (password === "") {
      error.password = "Password is required";
      flag = false;
    } else {
      error.password = "";
    }
    this.setState({ error });
    console.log(this.state.error);
    return flag;
  }

  async onSubmit(e) {
    e.preventDefault();
    var { logindata, staySignedIn } = this.state;
    if (this.validate()) {
      this.setState({ isLoading: true });
      // const res = await this.AuthService.login(logindata, staySignedIn);
      let jwt = require("jsonwebtoken");
      const config = {
        auth: {
          username: logindata.username,
          password: logindata.password,
        },
      };

      axiosInstance
        .post(URLS.login, logindata, config)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.props.setAuthentication(data);
            let user_obj = jwt.decode(data.access_token);
            if (staySignedIn) {
              this.localStorage.set("user", user_obj);
            } else {
              this.sessionStorage.set("user", user_obj);
            }
            // this.props.history.redirect("/");
            EventEmitter.dispatch("checkLogin");
          }
        })
        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  username: err.response.data.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  username: "Something went wrong. Please try again",
                },
              };
            });
          }
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { isLoading } = this.state;
    const { auth, isLoggedIn, logoutUser } = this.props;
    return (
      <>
        <form className="login-form" noValidate autoComplete="off">
          {isLoggedIn ? (
            <>
              <Link
                className="MuiButton-root MuiButtonBase-root btn btn-warning"
                to="/userdetail"
              >
                Account
              </Link>
              <Button
                variant="contained"
                className="btn btn-default"
                onClick={logoutUser}
              >
                Sign Out
              </Button>
            </>
          ) : (
            <>
              <div className="login-buttons">
                <div className="signin">
                  <Link
                    className="MuiButtonBase-root MuiButton-root btn btn-success"
                    to="/login"
                  >
                    Log In
                  </Link>
                </div>
                <div className="signup">
                  <Link
                    className="MuiButtonBase-root MuiButton-root btn"
                    to="/register"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </>
            /* <form onSubmit={this.onSubmit}>
              <FormControl>
                <InputBase
                  className={
                    this.props.inputClass +
                    " rounded-input " +
                    (typeof this.state.error.username !== "undefined" &&
                    this.state.error.username !== ""
                      ? "invalid"
                      : "")
                  }
                  placeholder='Email Address'
                  inputProps={{ "aria-label": "description" }}
                  id='username'
                  name='username'
                  value={this.state.logindata.username}
                  onChange={this.handleChange}
                />
                {typeof this.state.error.username !== "undefined" &&
                this.state.error.username !== "" ? (
                  <FormHelperText id='component-helper-text-username'>
                    <span className='error color-danger'>
                      {this.state.error.username}
                    </span>
                  </FormHelperText>
                ) : typeof this.state.error.password !== "undefined" &&
                  this.state.error.password !== "" ? (
                  <span className='error color-danger mr-5'>
                    {this.state.error.password}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl>
                <InputBase
                  type='password'
                  className={
                    this.props.inputClass +
                    " rounded-input " +
                    (typeof this.state.error.password !== "undefined" &&
                    this.state.error.password !== ""
                      ? "invalid"
                      : "")
                  }
                  placeholder='Password'
                  inputProps={{ "aria-label": "description" }}
                  id='password'
                  name='password'
                  value={this.state.logindata.password}
                  onChange={this.handleChange}
                />
                <FormHelperText id='component-helper-text-password'>
                  <Link to='/forgotpassword'>Forgot Password?</Link>
                </FormHelperText>
              </FormControl>
              <FormControl className='buttons-group'>
                <Button
                  disabled={isLoading}
                  variant='contained'
                  className='btn btn-success'
                  onClick={this.onSubmit}
                  type='submit'
                  component='button'>
                  {isLoading ? "Loading" : "Log In"}
                </Button>
                <Link
                  button='true'
                  className='MuiButtonBase-root MuiButton-root MuiButton-contained btn btn-warning'
                  to={"/register"}>
                  Sign Up
                </Link>
              </FormControl>
            </form> */
          )}
        </form>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

LoginForm.propTypes = {
  auth: PropTypes.object,
};

LoginForm.defaultProps = {
  auth: {},
};

export default withRouter(
  connect(mapStateToProps, { getAuth, setAuthentication, logoutUser })(
    LoginForm
  )
);
