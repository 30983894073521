import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Grid,
  List,
  ListItem,
  Button,
  Avatar,
  Typography,
} from "@material-ui/core";
import LogoLists from "../logolists/logolists";
import { connect } from "react-redux";
import { AFURLS } from "../../helpers/constants";
import clockimg from "../../images/clock.png";
import bpay from "../../images/bpay-logo.png";
import paypal from "../../images/paypal-logo-preview.png";
import poli from "../../images/footer_icons/POLi-logo.png";
import visa from "../../images/footer_icons/visa-and-mastercard-logo.png";

import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import linkedin from "../../images/linkedin.svg";

import advt_banner_logohere1 from "../../images/advt_banner_logohere1.jpg";
import advt_banner_logohere2 from "../../images/advt_banner_logohere2.jpg";
import advt_banner_logohere3 from "../../images/advt_banner_logohere3.jpg";

import responsible_wagering from "../../images/responsible_wagering.png";

import "./footerbottom.scss";

import playup from "../../images/footer_icons/playup.png";
import ladbrokes from "../../images/footer_icons/ladbrokes.png";
import neds from "../../images/footer_icons/neds.png";
import VICBet from "../../images/footer_icons/VICBet.png";
import bookmaker from "../../images/footer_icons/bookmaker.png";
import bet365 from "../../images/footer_icons/bet365.png";
import betstar from "../../images/footer_icons/betstar.png";
import palmerBet from "../../images/footer_icons/palmerbet.png";
import winingedge from "../../images/footer_icons/winingedge.png";
import LogoImagePayup from "../../images/bookmarks_logo/playup-transparent.png";
import WatchClock from "./WatchClock";

class FooterBottom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current_affiliates_placeholder: advt_banner_logohere1,
      current_affiliates_logos: [
        {
          type: "url",
          link: AFURLS?.Bet365 ? AFURLS?.Bet365 : "",
          content: bet365,
        },
        {
          type: "url",
          link: AFURLS?.BetStar ? AFURLS?.BetStar : "",
          content: betstar,
        },
        {
          type: "url",
          link: AFURLS?.Bookmaker ? AFURLS?.Bookmaker : "",
          content: bookmaker,
        },
        {
          type: "url",
          link: AFURLS?.Ladbrokes ? AFURLS?.Ladbrokes : "",
          content: ladbrokes,
        },
        { type: "url", link: AFURLS?.Neds ? AFURLS?.Neds : "", content: neds },
        {
          type: "url",
          link: AFURLS?.payups ? AFURLS?.payups : "",
          content: playup,
        },
        {
          type: "url",
          link: AFURLS?.vicbet ? AFURLS?.vicbet : "",
          content: VICBet,
        },
        {
          type: "url",
          link: AFURLS?.PalmerBet ? AFURLS?.PalmerBet : "",
          content: palmerBet,
        },
        {
          type: "url",
          link: AFURLS?.Winingedge ? AFURLS?.Winingedge : "",
          content: winingedge,
        },
      ],
      current_affiliates_logos_temp: [
        { type: "url", content: playup },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
      ],

      pmpc_affiliates_placeholder: advt_banner_logohere2,
      pmpc_affiliates_logos: [{ type: "url", content: advt_banner_logohere2 }],
      pmpc_affiliates_logos_temp: [
        { type: "url", content: LogoImagePayup },

        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
        { type: "string", content: "Logo" },
      ],

      new_affiliates_placeholder: advt_banner_logohere3,
      new_affiliates_logos: [{ type: "url", content: advt_banner_logohere3 }],
      new_affiliates_logos_temp: [{ type: "url", content: LogoImagePayup }],
    };
  }

  render() {
    var menuItems = [
      { to: "/", title: "Home" },
      { to: "/aboutus", title: "About Us" },
      { to: "/membership", title: "Memberships" },
      { to: "/blog", title: "Blog" },
      { to: "/partners", title: "Bookmakers" },
      { to: "/contactus", title: "Contact Us" },
      { to: "/faqs", title: "FAQs" },
      { to: "/terms", title: "Terms & Conditions" },
      { to: "/privacypolicy", title: "Privacy Policy" },
      { to: "/responsiblegamblingpolicy", title: "Responsible Gambling" },
    ];
    var loginMenuItems = [
      { to: "/sports/racingandsports", title: "Racing & Sports Form Guide" },
      {
        to: "/sports/tippingandselection",
        title: "Tipping & Selection Partners ",
      },
      { to: "/partners", title: "Bookmakers" },
      { to: "/memorabilia", title: "Memorabilia" },
      { to: "/terms", title: "Terms & Conditions" },
      { to: "/privacypolicy", title: "Privacy Policy" },
      { to: "/responsiblegamblingpolicy", title: "Responsible Gambling" },
    ];
    menuItems = Boolean(this.props.login) === true ? loginMenuItems : menuItems;
    const flexContainer = {
      display: "flex",
      flexDirection: "row",
    };
    return (
      <>
        <div className="footerbottom-wrapper">
          <Container className="foooterbottom">
            <Grid
              container
              spacing={0}
              justify="flex-start"
              alignItems="center"
              className=""
            >
              <Grid
                item
                xl={9}
                lg={9}
                md={12}
                sm={12}
                xs={12}
                className="hide-xs"
              >
                <List
                  component="ul"
                  className="footer-menu with-seperator"
                  style={flexContainer}
                >
                  {!this.props.user
                    ? menuItems.length > 0
                      ? menuItems.map((item, i) => (
                          <ListItem button key={i}>
                            <NavLink
                              exact
                              to={item.to}
                              activeClassName="active"
                            >
                              {item.title}
                            </NavLink>
                            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                            {/* <Link className={i === 0?'active':''} to={item.to}>{item.title}</Link> */}
                          </ListItem>
                        ))
                      : ""
                    : loginMenuItems.length > 0
                    ? loginMenuItems.map((item, i) => (
                        <ListItem button key={i}>
                          <NavLink exact to={item.to} activeClassName="active">
                            {item.title}
                          </NavLink>
                          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                          {/* <Link className={i === 0?'active':''} to={item.to}>{item.title}</Link> */}
                        </ListItem>
                      ))
                    : ""}
                </List>
              </Grid>
              <Grid
                item
                md={4}
                className="hide-xl hide-lg hide-sm hide-xs text-right md-text-left clock"
              ></Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={2}
                sm={12}
                xs={12}
                className="hide-sm hide-xs text-right md-text-left clock"
              >
                <div className="responsible_wagering_logo">
                  <img src={responsible_wagering} alt="img" />
                </div>
              </Grid>
              <Grid
                item
                lg={2}
                md={6}
                sm={12}
                xs={12}
                className="hide-sm hide-xs text-right md-text-left clock"
              >
                <Button
                  onClick={() => {
                    window.location.href = "/responsiblegamblingpolicy";
                  }}
                >
                  <Avatar className="size-small bg-orange">18+</Avatar>
                </Button>
                <Button
                  onClick={() => {}}
                  className="watch-clock"
                  startIcon={
                    <img src={clockimg} className="App-logo-main" alt="logo" />
                  }
                >
                  <span className="text-left">
                    <WatchClock />
                  </span>
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item md={5} sm={12} xs={12} className="text-left">
                <Typography variant="subtitle2">Secure Payments</Typography>
                <List className="horizontal-list with-rightspace secure-payment-list">
                  <ListItem disableGutters>
                    <img src={visa} className="payment-logo" alt="visa" />
                  </ListItem>
                  <ListItem disableGutters>
                    <img src={paypal} className="payment-logo" alt="paypal" />
                  </ListItem>
                  <ListItem disableGutters>
                    <img src={poli} className="payment-logo" alt="poli" />
                  </ListItem>
                  <ListItem disableGutters>
                    <img src={bpay} className="payment-logo" alt="bpay" />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                className="text-left hide-sm hide-xs"
              >
                <Typography variant="subtitle2"> Social Media</Typography>
                <List className="horizontal-list">
                  <ListItem disableGutters>
                    <a
                      href="https://www.facebook.com/motzzaAUS/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar className="size-small bg-transparent">
                        <img
                          src={facebook}
                          className="social-icon"
                          alt="facebook"
                        />
                      </Avatar>
                    </a>
                  </ListItem>
                  <ListItem disableGutters>
                    <a
                      href="https://twitter.com/motzzaAUS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar className="size-small bg-transparent">
                        <img
                          src={twitter}
                          className="social-icon"
                          alt="twitter"
                        />
                      </Avatar>
                    </a>
                  </ListItem>
                  <ListItem disableGutters>
                    <a
                      href="https://www.linkedin.com/company/motzzaaus/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar className="size-small bg-transparent">
                        <img
                          src={linkedin}
                          className="social-icon"
                          alt="linkedin"
                        />
                      </Avatar>
                    </a>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <div>
              <div className="current-affiliates">
                <LogoLists
                  title="Current Affiliates and Partnerships"
                  placeholder={this.state.current_affiliates_placeholder}
                  logos={this.state.current_affiliates_logos}
                />
              </div>
              {/* <div className="affiliate-logo">
                <LogoLists
                  title="Poker, Memorabilia, Punter’s Clubs Affiliate"
                  placeholder={this.state.pmpc_affiliates_placeholder}
                  logos={this.state.pmpc_affiliates_logos}
                />

                <LogoLists
                  title="News Affiliate"
                  placeholder={this.state.new_affiliates_placeholder}
                  logos={this.state.new_affiliates_logos}
                />
              </div> */}
              <Grid
                container
                spacing={0}
                justify="flex-start"
                alignItems="flex-end"
                className="hide-lg hide-md hide-xl social-media-list-wrap"
              >
                <Grid item sm={4} xs={12} className="text-left">
                  <Typography variant="subtitle2"> Social Media</Typography>
                  <List className="horizontal-list social-media-list">
                    <ListItem disableGutters>
                      <a
                        href="https://www.facebook.com/motzzaAUS/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Avatar className="size-small bg-transparent">
                          <img
                            src={facebook}
                            className="social-icon"
                            alt="facebook"
                          />
                        </Avatar>
                      </a>
                    </ListItem>
                    <ListItem disableGutters>
                      <a
                        href="https://twitter.com/motzzaAUS"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Avatar className="size-small bg-transparent">
                          <img
                            src={twitter}
                            className="social-icon"
                            alt="twitter"
                          />
                        </Avatar>
                      </a>
                    </ListItem>
                    <ListItem disableGutters>
                      <a
                        href="https://www.linkedin.com/company/motzzaaus/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Avatar className="size-small bg-transparent">
                          <img
                            src={linkedin}
                            className="social-icon"
                            alt="twitter"
                          />
                        </Avatar>
                      </a>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  className="hide-lg hide-md text-left watch-clock-wrap"
                >
                  <div className="responsible_wagering_logo">
                    <img src={responsible_wagering} alt="img" />
                  </div>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  className="hide-lg hide-md text-left watch-clock-wrap"
                >
                  <Button
                    onClick={() => {
                      window.location.href = "/responsiblegamblingpolicy";
                    }}
                  >
                    <Avatar className="size-small bg-orange">18+</Avatar>
                  </Button>
                  <Button
                    onClick={() => {}}
                    className="watch-clock"
                    startIcon={
                      <img
                        src={clockimg}
                        className="App-logo-main"
                        alt="logo"
                      />
                    }
                  >
                    <WatchClock />
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    user: authReducer.user,
  };
};

export default connect(mapStateToProps)(FooterBottom);
