import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import Carousel, { consts } from "react-elastic-carousel";
import Item from "./Item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import "./benefitsslider.scss";

import bookmarkers from "../../images/bookmarkers.png";
import kayo from "../../images/kayo.png";
import memorabilia from "../../images/memorabilia.png";
import poker from "../../images/poker.png";
import racingandsport from "../../images/racingandsport.png";
import rewards from "../../images/rewards.png";
import tippingandselection from "../../images/tippingandselection.png";
import { Link } from "react-router-dom";

const breakPoints = [
  { width: 320, itemsToShow: 1 },
  { width: 420, itemsToShow: 3 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 5 },
  { width: 1200, itemsToShow: 7 },
];

class BenefitsSlider extends Component {
  myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <FontAwesomeIcon icon={faAngleLeft} />
      ) : (
        <FontAwesomeIcon icon={faAngleRight} />
      );
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  }

  render() {
    var sliderItems = [
      {
        id: 1,
        title: "Racing & Sports Form Guide",
        img: racingandsport,
        url: "/membership/racingandsports",
      },
      {
        id: 2,
        title: "Live Sports Streaming",
        img: kayo,
        url: "/membership/livestreaming",
      },
      {
        id: 3,
        title: "Tipping & Selection Partners",
        img: tippingandselection,
        url: "/membership/tippingselection",
      },
      {
        id: 4,
        title: "Bookmakers",
        img: bookmarkers,
        url: "/membership/bookmakers",
      },
      {
        id: 5,
        title: "Memorabilia",
        img: memorabilia,
        url: "/membership/memorabilia",
      },
      {
        id: 6,
        title: "Rewards Rebate Program",
        img: rewards,
        url: "/membership/rewardsrebate",
      },
      { id: 7, title: "Poker", img: poker, url: "/membership/poker" },
    ];
    return (
      <>
        <div className="slider-main hide-xs hide-sm-tab">
          <Typography
            variant="h5"
            className="big-title color-orange heading-font"
          >
            BENEFITS OF MEMBERSHIP
          </Typography>
          <Carousel
            pagination={false}
            breakPoints={breakPoints}
            itemsToShow={7}
            enableAutoPlay
            showArrows={false}
            className="benifit_slider"
          >
            {sliderItems.length > 0
              ? sliderItems.map((slide, i) => (
                  <Link to={slide.url} className="w-100" key={i}>
                    <div className="iconwrap">
                      <Item
                        style={{
                          height: 86,
                          width: 86,
                          backgroundColor: "#00833E",
                        }}
                      >
                        <img
                          src={slide.img}
                          className="slider-logo"
                          alt="logo"
                        />
                      </Item>
                    </div>
                    <Typography variant="h4">{slide.title}</Typography>
                  </Link>
                ))
              : ""}
          </Carousel>
        </div>
        {/* benifits block for mobile */}
        <div className="benifits-block hide-xl hide-lg hide-md hide-lg-tab">
          <Typography variant="h5" className="color-orange heading-font">
            BENEFITS OF MEMBERSHIP
          </Typography>
          {sliderItems.length > 0
            ? sliderItems.map((obj, index) => (
                <Link key={index} to={obj.url}>
                  <div className="benifits-main">
                    <div className="benifits-items">
                      <img src={obj.img} alt="logo" />
                    </div>
                    <Typography variant="h6">{obj.title}</Typography>
                  </div>
                </Link>
              ))
            : ""}
        </div>
      </>
    );
  }
}

export default BenefitsSlider;
