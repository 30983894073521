import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/kayo.png";

class MembershipLiveStreaming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Live Sports Streaming
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                Don’t miss a minute of your favourite sporting event when
                streaming matches live with motzza. All motzza members get
                access to live sports streaming. We cover some of the biggest
                sports, including the A-League, MLS, W-League, FIFA Club World
                Cup, Bundesliga, FFA Cup, La Liga, Australian Internationals,
                AFC Champions League, Serie A, EFL Championship, ISL, FA Cup and
                Ligue 1.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                Our live sports streaming services give you personalised sports
                coverage and content. We make it make it easy to stream all the
                primetime games and general sports coverage you could want,
                right here at motzza.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                There has never been a better time to watch sports live than
                right now. From soccer to cricket to league to rugby, the
                biggest names in sports are either bringing their season to an
                exciting close or kicking off a new season.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                In addition to the BBL and international sporting events
                involving New Zealand and England, Aussie motzza members can
                catch the Trans-Tasman T20I series between men in gold and green
                and their Kiwi counterparts. Stream this year’s NBA season
                easily from your mobile device and see Lebron create history for
                the Lakers as it happens. You can even watch every 2021 A-League
                game live across the season thanks to motzza live sports
                streaming.
              </Typography>
              <Typography variant="body1" className="afiliate-signup-content">
                Become a{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  registered motzza member
                </span>{" "}
                today and start streaming your favourite sporting events now.
                and get access today.
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipLiveStreaming;
