import { Validators } from "../../../library/utilities/Validators";

export const bannersFormModel = [
  {
    label: "URL*",
    value: "",
    type: "text",
    placeholder: "URL",
    field: "url",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Page*",
    value: "",
    type: "dropdown",
    placeholder: "Page",
    field: "pageId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [],
  },
];
