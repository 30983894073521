import { Validators } from "../../../library/utilities/Validators";

export const marketsFormModel = [
  {
    label: "Event*",
    value: "",
    type: "dropdown",
    placeholder: "Event",
    field: "eventId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Is Race Event",
    value: "",
    type: "dropdown",
    placeholder: "isRaceEvent",
    field: "isRaceEvent",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "1", label: "Yes" },
      { id: 2, value: "0", label: "No" },
    ],
  },
  {
    label: "Partipants",
    value: "",
    type: "dropdown",
    placeholder: "partipantsId",
    field: "partipantsId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Team",
    value: "",
    type: "dropdown",
    placeholder: "teamId",
    field: "teamId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "player",
    value: "",
    type: "dropdown",
    placeholder: "playerId",
    field: "playerId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  // {
  //   label: "BookKeeper",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "BookKeeper",
  //   field: "bookKeeperId",
  //   validators: [{ check: Validators.required }],
  //   required: true,
  //   className: "6",
  //   options: [],
  // },
  {
    label: "Market Name",
    value: "",
    type: "text",
    placeholder: "Market Name",
    field: "marketName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
];
