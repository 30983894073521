import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import ButtonComponent from "../../library/common/components/Button";
import { Loader } from "../../library/common/components";
import ActionMessage from "../../library/common/components/ActionMessage";
import axiosInstance from "../../helpers/Axios";
import "./adsStyles.scss";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";

export default class Ads extends Component {
  state = {
    allAds: [],
    isModalOpen: false,
    itemToDelete: null,
    isLoading: false,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
    currentPage: 1,
    rowPerPage: 20,
  };

  componentDidMount() {
    //this.fetchAds();
  }

  async fetchAds() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get("/ads");
    if (status === 200) {
      this.setState({ allAds: data, isLoading: false });
    }
  }

  createNew = () => {
    this.props.history.push("/admin/new-ad");
  };

  navigateToEdit = (id) => () => {
    this.props.history.push(`/admin/edit-ad/${id}`);
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: false,
      itemToDelete: null,
    });
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  deleteAd = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `/ads/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAds();
        });
        this.setActionMessage(true, "Success", "Ad Deleted Successfully!");
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occured while deleting.");
    }
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, allAds } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < allAds.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    const {
      allAds,
      isModalOpen,
      messageBox,
      isLoading,
      rowPerPage,
      currentPage,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = allAds;

    if (allAds?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = allAds.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(allAds.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={10}>
                <h3 className="text-left">Ad Management</h3>
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="addButton admin-btn-green"
                  onClick={this.createNew}
                  color="primary"
                  value="Add New"
                />
              </Grid>
            </Grid>

            {isLoading && <Loader />}
            {!isLoading && allAds.length === 0 && <p>No Data Available</p>}
            {!isLoading && allAds.length > 0 && (
              <>
                <TableContainer component={Paper}>
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ad Title</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Created Date</TableCell>
                        <TableCell>Updated Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentPageRow.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>
                            {moment(item.startDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {moment(item.endDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {moment(item.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {moment(item.updatedAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={this.navigateToEdit(item.id)}
                              color="primary admin-btn-green"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                            <DeleteOutlineIcon
                              onClick={this.setItemToDelete(item.id)}
                              color="secondary admin-btn-orange"
                              className="cursor iconBtn admin-btn-orange"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="tablePagination">
                  <button
                    className={
                      allAds.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={allAds.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={allAds.length / rowPerPage > 1 ? false : true}
                    page={currentPage}
                    onChange={this.handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      allAds.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={allAds.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              </>
            )}
          </Paper>

          <Modal
            className="modal"
            open={isModalOpen}
            onClose={this.toggleModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={"paper"}>
              Are you sure you want to delete?
              <br /> <br />
              <Button color="primary" onClick={this.deleteAd}>
                Yes
              </Button>
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </div>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}
