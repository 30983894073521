import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import StorageService from '../../../services/storage'
import { STORAGE_ENGINES } from '../../../helpers/constants'
import { Container, Grid, List, ListItem } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import MainMenuBar from '../../mainmenubar/mainmenubar'
import HeaderTopBar from '../../headertopbar/headertopbar'
import FooterTopBar from '../../footertopbar/footertopbar'
import FooterBottom from '../../footerbottom/footerbottom'
import './userlayout.scss'

import bigwinadd from '../../../images/bigwinadd.png'
import footerContetnadd from '../../../images/footer-content-add.jpg'
import BetsSidebar from '../../betssidebar/betssidebar'
import BetsOddsSlip from '../../betsoddsslip/betsoddsslip'
import RugbySidebar from '../../rugbysidebar/rugby.sidebar'

import payupsbanner from '../../../images/advertise_banners/sports-300x250.gif'
import sidebaradd1 from '../../../images/advertise_banners/300x250_BM_generic.jpg'
import sidebaradd2 from '../../../images/advertise_banners/300x250_BS_generic.gif'
import sidebaradd3 from '../../../images/advertise_banners/0329_BRAND_LadbrokeIt_300x250.jpeg'
import sidebaradd4 from '../../../images/advertise_banners/09-16-Neds-Level-banner-300x250.jpg'
import sidebaradd6 from '../../../images/advertise_banners/PalmerBet_Racing_300x250.gif'
// import sidebaradd5 from "../../../images/sidebaradd5.png";

class UserLayout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: true,
      sidebaradds: [
        {
          id: 101,
          content: 'payups',
          image: payupsbanner,
          url:
            'https://record.affiliates.playup.com/_Y_Ng50hdX2Zhg6WO2I1rgWNd7ZgqdRLk/1/',
        },
        {
          id: 1,
          content: 'one',
          image: sidebaradd1,
          url:
            'https://record.wageringaffiliates.com.au/_aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk/1/',
          script:
            'https://js.wageringaffiliates.com.au/javascript.php?prefix=aoh4_X4Dx1X19bTZaEishGNd7ZgqdRLk&amp;media=116&amp;campaign=1',
        },
        {
          id: 2,
          content: 'two',
          image: sidebaradd2,
          url:
            'https://record.wageringaffiliates.com.au/_vB8fbcQfs2keR0indqtVW2Nd7ZgqdRLk/1/',
          script:
            'https://js.wageringaffiliates.com.au/javascript.php?prefix=dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk&amp;media=153&amp;campaign=1',
        },
        {
          id: 3,
          content: 'three',
          image: sidebaradd3,
          url:
            'https://record.wageringaffiliates.com.au/_rKStVKr_NJ8B0WjZNehKqWNd7ZgqdRLk/1/',
          script:
            'https://js.wageringaffiliates.com.au/javascript.php?prefix=rKStVKr_NJ8B0WjZNehKqWNd7ZgqdRLk&amp;media=183&amp;campaign=1',
        },
        {
          id: 4,
          content: 'three',
          image: sidebaradd4,
          url:
            'https://record.nedsaffiliates.com.au/_dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk/1/',
          script:
            'https://js.wageringaffiliates.com.au/javascript.php?prefix=aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk&amp;media=116&amp;campaign=1',
        },
        {
          id: 5,
          content: 'five',
          image:
            'https://imstore.bet365affiliates.com/AffiliateCreativeBanners/Sports/General/no1sportsopenoffer/en-GB/ROW7/STD/300x250_1.gif',
          url:
            'https://imstore.bet365affiliates.com/Tracker.aspx?AffiliateId=125502&AffiliateCode=365_00967140&CID=194&DID=72&TID=1&PID=149&LNG=1',
          // (
          //   <a
          //     href='https://imstore.bet365affiliates.com/Tracker.aspx?AffiliateId=125502&AffiliateCode=365_00967140&CID=194&DID=72&TID=1&PID=149&LNG=1'
          //     target='_blank'
          //     rel='noopener noreferrer'
          //   >
          //     <img
          //       alt=''
          //       src='https://imstore.bet365affiliates.com/?AffiliateCode=365_00967140&amp;CID=194&amp;DID=72&amp;TID=1&amp;PID=149&amp;LNG=1'
          //       border='0'
          //     />
          //   </a>
          // ),
        },
        {
          id: 6,
          content: 'six',
          image: sidebaradd6,
          url:
            'https://record.palmerbet.com/_aK97AHmof7-WLGrCaiaBQGNd7ZgqdRLk/1/',
        },
      ],
      min: 0,
      sec: 0,
      hour: 0,
    }
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION)
  }

  componentDidMount() {
    this.createTimer()
    // this.shufflesidebaradds();
    this.setAdvtBanners()
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (match?.params?.id !== prevProps.match?.params?.id) {
      this.setAdvtBanners()
    }
  }

  setAdvtBanners = () => {
    const { LogedInTopAds, LogedInBottomAds } = this.props
    let topadid = 0,
      activeLogedInTopAds = this.sessionStorage.get('activeLogedInTopAds')

    topadid = Math.floor(Math.random() * LogedInTopAds.length)

    if (LogedInTopAds.length > 1) {
      while (topadid === Number(activeLogedInTopAds)) {
        topadid = Math.floor(Math.random() * LogedInTopAds.length)
      }
    }
    this.sessionStorage.set('activeLogedInTopAds', String(topadid))

    let bottomadid = 0,
      activeLogedInBottomAds = this.sessionStorage.get('activeLogedInBottomAds')
    bottomadid = Math.floor(Math.random() * LogedInBottomAds.length)

    if (LogedInBottomAds.length > 1) {
      while (bottomadid === Number(activeLogedInBottomAds)) {
        bottomadid = Math.floor(Math.random() * LogedInBottomAds.length)
      }
    }

    this.sessionStorage.set('activeLogedInBottomAds', String(bottomadid))

    this.setState({
      LogedInTopAdsItem: LogedInTopAds[topadid],
      LogedInBottomAdsItem: LogedInBottomAds[bottomadid],
    })
  }

  createTimer() {
    setInterval(() => {
      this.setState({ sec: this.state.sec + 1 }, () => {
        if (this.state.sec === 60) {
          this.setState({ min: this.state.min + 1, sec: 0 }, () => {
            if (this.state.min === 60) {
              this.setState({ hour: this.state.hour + 1, min: 0 })
            }
          })
        }
      })
    }, 1000)
  }

  toggleMenu = (flag) => {
    this.setState({ showMenu: flag })
  }

  renderSidebar() {
    const { location } = this.props

    if (
      location.pathname.includes('rugby') ||
      location.pathname.includes('australian') ||
      location.pathname.includes('cricket')
    ) {
      const type = location.pathname.includes('rugby')
        ? 'Rugby League'
        : location.pathname.includes('australian')
        ? 'Australian Rule'
        : 'Cricket'
      return <RugbySidebar type={type} />
    } else {
      //return <BetsSidebar />;
      return <BetsOddsSlip />
    }
  }

  shufflesidebaradds = () => {
    let { sidebaradds } = this.state
    let i = sidebaradds.length - 1
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = sidebaradds[i]
      sidebaradds[i] = sidebaradds[j]
      sidebaradds[j] = temp
    }
    this.setState({ sidebaradds: sidebaradds })
  }

  render() {
    const { LogedInTopAdsItem, LogedInBottomAdsItem } = this.state
    const { children } = this.props

    return (
      <>
        <HeaderTopBar {...this.props} />
        <MainMenuBar {...this.props} />
        <div className="content">
          <Container>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid
                item
                lg={9}
                md={12}
                sm={12}
                xs={12}
                className="page-contents text-left"
              >
                <div className="content-top-banneradd margin-minus-15">
                  {LogedInTopAdsItem !== undefined &&
                  LogedInTopAdsItem !== '' ? (
                    <a
                      href={LogedInTopAdsItem?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ cursor: 'pointer', display: 'flex' }}
                    >
                      <img src={LogedInTopAdsItem?.image} alt="" />
                    </a>
                  ) : (
                    <img src={bigwinadd} alt="" />
                  )}
                </div>
                {children}
                <div
                  className="content-bottom-banneradd hide-xs"
                  style={{ marginTop: 44, marginBottom: 35 }}
                >
                  {LogedInBottomAdsItem !== undefined &&
                  LogedInBottomAdsItem !== '' ? (
                    <a
                      href={LogedInBottomAdsItem?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={LogedInBottomAdsItem?.image} alt="" />
                    </a>
                  ) : (
                    <img src={footerContetnadd} alt="" />
                  )}
                </div>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                className="page-sidebar"
              >
                <div className="bet-sidebar-lg">{this.renderSidebar()}</div>
                {this.state.sidebaradds.length > 0 ? (
                  <>
                    <List className="sidebar-ads-list-wrap">
                      {this.state.sidebaradds.map((add, index) => (
                        <ListItem
                          disableGutters
                          key={index}
                          className="sidebar-add-box box"
                        >
                          {add.url && add.url !== '' ? (
                            <a
                              className="box-content"
                              href={add.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={add.image} alt="" />
                            </a>
                          ) : add.script && add.script !== '' ? (
                            <div className="box-content">
                              <script
                                type="text/javascript"
                                src={add.script}
                              ></script>
                            </div>
                          ) : add.image && add.image !== '' ? (
                            <img src={add.image} alt="" />
                          ) : (
                            <div className="box-content">{add.content}</div>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
        <FooterTopBar {...this.props} />
        <FooterBottom
          {...this.props}
          min={this.state.min}
          sec={this.state.sec}
          hour={this.state.hour}
        />
      </>
    )
  }
}

const mapStateToProps = ({ authReducer, adsReducer }) => {
  return {
    LogedInTopAds: adsReducer?.LogedInTopAds,
    LogedInBottomAds: adsReducer?.LogedInBottomAds,
    isLoggedIn: authReducer.isLoggedIn,
  }
}

export default withRouter(connect(mapStateToProps)(UserLayout))
