/* eslint-disable camelcase */
import _ from "lodash";
import moment from "moment";
//import horse from "../images/sport_icons/horse-head.svg";
import greys from "../images/sport_icons/Greyhound.svg";
import harnes from "../images/sport_icons/Harness.svg";
import horse from "../images/hourse_img_small.svg";
import MixedMartialArts from "../images/sport_icons/MixedMartialArts.svg";
import soccer from "../images/sport_icons/soccer.svg";
import tennis from "../images/sport_icons/tennis.svg";
import boxing from "../images/menu_icons/Boxing.svg";
import cricket from "../images/menu_icons/Cricket.svg";

/*
  @params source:=> source object
  @params destination:=> destination object
  @params field:=> field array

  return new object with the fields defined in field array
*/
export const intersection = (source, destination, fields) => {
  const result = {};
  fields.forEach((field) => {
    if (destination[field]) {
      result[field] = destination[field];
    } else {
      result[field] = source[field];
    }
  });
  return result;
};

export const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  return _.difference(keys1, keys2);
};

export const isObjectEmpty = (obj) => !Object.keys(obj || {}).length;

export function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}

export function raceIcon(name) {
  if (name === "Horse Racing") {
    return horse;
  } else if (name === "Harness Racing") {
    return harnes;
  } else if (name === "Greyhound Racing") {
    return greys;
  }
}

export function sportIcon(name) {
  if (name === "Horse Racing") {
    return horse;
  } else if (name === "Harness Racing") {
    return harnes;
  } else if (name === "Greyhound Racing") {
    return greys;
  } else if (name === "Mixed Martial Arts") {
    return MixedMartialArts;
  } else if (name === "Boxing") {
    return boxing;
  } else if (name === "Tennis") {
    return tennis;
  } else if (name === "Soccer") {
    return soccer;
  } else if (name === "Cricket") {
    return cricket;
  }
}

export function getRoute(itemName, itemId, sportType) {
  let route = `/race/${itemId}`;
  if (sportType === "racing") {
    route = `/race/${itemId}/${itemName}`;
  } else if (sportType === "combat") {
    route = `/sports/${itemName}/${itemId}`;
  } else if (sportType === "teamsport") {
    route = `/sports/${itemName}/${itemId}`;
  } /* else if (itemName === "Rugby League" && itemId !== "") {
    route = `/sports/rugby/${itemId}`;
  } else if (itemName === "Australian Rules" && itemId !== "") {
    route = `/sports/australian/${itemId}`;
  } */
  return route;
}

export function showVariations(variation) {
  if (typeof variation === "object") {
    let allItems =
      variation?.length > 0 && variation.map((item, index) => item?.value);
    return allItems?.length > 0 ? allItems.join(", ") : [];
  } else if (typeof variation === "string") {
    try {
      let variationArray = JSON.parse("[" + variation + "]");
      if (variationArray.length > 0 && variationArray[0] !== "") {
        let allItems = variationArray[0]?.map((item, index) => item?.value);
        return allItems.join(", ");
      } else {
        return [];
      }
    } catch {
      return [];
    }
  } else {
    return [];
  }
}

export function getFormetedDate(date) {
  let FormetedDate = moment(date).format("DD-MM-YYYY HH:mm:ss");
  return FormetedDate;
}

export function setValidation(fieldItem, values) {
  if (fieldItem?.required === true && values[fieldItem?.field] === "") {
    return { ...fieldItem, errorMessage: "This field is mandatory" };
  } else {
    return { ...fieldItem, errorMessage: "" };
  }
}
