import { Validators } from "../../../library/utilities/Validators";

export const leagueFormModel = [
  {
    label: "League Name*",
    value: "",
    type: "text",
    placeholder: "League Name",
    field: "leagueName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
  // {
  //   label: "Sport*",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Sport",
  //   field: "sportsId",
  //   validators: [{ check: Validators.required }],
  //   required: true,
  //   className: "12",
  //   options: [],
  // },
];
