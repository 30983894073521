import React from "react";
import { Grid } from "@material-ui/core";

const Competition = () => {
  return (
    <Grid container spacing={15}>
      <Grid item x={12} style={{ margin: "80px 0" }}>
        <h3>Competitions</h3>
        <p>
          There is no active competition at the moment. Please visit after
          sometime.
        </p>
      </Grid>
    </Grid>
  );
};

export default Competition;
