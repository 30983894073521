import React, { Component } from "react";
import { Grid, Button, Typography, List, ListItem } from "@material-ui/core";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { config } from "../../../helpers/config";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import blogSingle from "../../../images/blog_img/blogSingle.jpg";
import facebook from "../../../images/blog_img/FACEBOOK.svg";
import twiter from "../../../images/blog_img/TWITTER.svg";
import linkedin from "../../../images/blog_img/LinkedIn.svg";
import reddit from "../../../images/blog_img/reddit-alien.svg";
import whatsapp from "../../../images/blog_img/whatsapp.svg";
import tumblr from "../../../images/blog_img/tumblr.svg";
import pinterest from "../../../images/blog_img/pinterest.svg";
import mail from "../../../images/blog_img/mail.svg";

import axiosInstance from "../../../helpers/Axios";
// import { URLS } from "../../../library/common/constants";
import { Loader } from "../../../library/common/components";
import { fetchBlogsAction } from "../../../store/common/actions/blogs.actions";
import moment from "moment";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";

import "./blogItemStyle.scss";

class BlogItem extends Component {
  state = {
    blog: null,
    isLoading: false,
    nextSlug: null,
    prevSlug: null,
    shareSocial: [
      {
        link: "",
        icon: (
          <FacebookShareButton url={window.location.href}>
            <ReactSVG src={facebook} />
          </FacebookShareButton>
        ),
      },
      {
        link: "",
        icon: (
          <TwitterShareButton url={window.location.href}>
            <ReactSVG src={twiter} />
          </TwitterShareButton>
        ),
      },
      {
        icon: (
          <LinkedinShareButton url={window.location.href}>
            <ReactSVG src={linkedin} />
          </LinkedinShareButton>
        ),
        link: "",
      },
      {
        icon: (
          <RedditShareButton url={window.location.href}>
            <ReactSVG src={reddit} />
          </RedditShareButton>
        ),
        link: "",
      },
      {
        icon: (
          <WhatsappShareButton url={window.location.href}>
            <ReactSVG src={whatsapp} />
          </WhatsappShareButton>
        ),
        link: "",
      },
      {
        icon: (
          <TumblrShareButton url={window.location.href}>
            <ReactSVG src={tumblr} />
          </TumblrShareButton>
        ),
        link: "",
      },
      {
        icon: (
          <PinterestShareButton url={window.location.href}>
            <ReactSVG src={pinterest} />
          </PinterestShareButton>
        ),
        link: "",
      },
      {
        icon: (
          <EmailShareButton url={window.location.href}>
            <ReactSVG src={mail} />
          </EmailShareButton>
        ),
        link: "",
      },
    ],
  };

  async componentDidMount() {
    await this.fetchBlogDetail();
    if (this.props.blogs.length === 0) {
      await this.props.fetchBlogsAction();
      this.checkNextPreviousPage();
    }
  }

  async fetchBlogDetail() {
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `/blogs/${this.props.match.params.slug}`
      );
      if (status === 200) {
        this.setState({ blog: data, isLoading: false });
        document.title = data.title;
        this.checkNextPreviousPage();
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  checkNextPreviousPage() {
    const { blogs } = this.props;
    const { blog } = this.state;
    let nextSlug = null;
    let prevSlug = null;

    const currentBlogIndex = blogs
      ?.sort((a, b) => (a.id < b.id ? 1 : -1))
      .findIndex((item) => item?.id === blog?.id);
    if (currentBlogIndex >= 0) {
      nextSlug = blogs[currentBlogIndex + 1] && blogs[currentBlogIndex + 1].id;
    }

    if (currentBlogIndex > 0 && currentBlogIndex <= blogs.length - 1) {
      prevSlug = blogs[currentBlogIndex - 1].id;
    }

    this.setState({ prevSlug, nextSlug });
  }

  navigate = (link) => () => {
    this.props.history.push("/blog/" + link);
    window.location.reload();
  };

  render() {
    const { blog, isLoading, prevSlug, nextSlug, shareSocial } = this.state;

    // const date = new Date(blog?.publishDate);
    let localDate = moment(blog?.publishDate).format("YYYY-MM-DD");
    return (
      <>
        <div className="page-banner blog-detail-container">
          <Grid container>
            {isLoading && !blog && <Loader />}

            {blog && (
              <Grid xs={12} className="text-left">
                <Typography className="blog-detail-heading hide-sm hide-md hide-lg hide-xl">
                  {blog.title}
                </Typography>
                <div className="blog-detail-img-wrap">
                  <img
                    src={
                      blog?.image ? config?.baseUrl + blog?.image : blogSingle
                    }
                    alt="Blog detail profile"
                  />
                </div>
                <Typography className="blog-detail-heading hide-xs">
                  {blog.title}
                </Typography>
                <Typography>Publish Date : {localDate}</Typography>
                <Typography className="blog-detail-description">
                  <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </Typography>
              </Grid>
            )}
          </Grid>

          <div className="navigate">
            <Button
              disabled={prevSlug ? false : true}
              startIcon={<ArrowBackIcon />}
              onClick={this.navigate(prevSlug)}
            >
              Previous
            </Button>
            <Button
              disabled={nextSlug ? false : true}
              endIcon={<ArrowForwardIcon />}
              onClick={this.navigate(nextSlug)}
            >
              Next
            </Button>
          </div>
        </div>
        <div className="blog-detail-footer">
          <Typography variant="h6">
            Share This Story, Choose Your Platform!
          </Typography>
          <List>
            {shareSocial?.length > 0 &&
              shareSocial?.map((social) => <ListItem>{social?.icon}</ListItem>)}
          </List>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ blogs }) => {
  return {
    blogs: blogs.blogs,
  };
};

export default connect(mapStateToProps, { fetchBlogsAction })(BlogItem);
