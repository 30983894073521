import { Validators } from "../../../library/utilities/Validators";

export const apiKeyIdentifireFormModel = [
  {
    label: "Api Key Identifire*",
    value: "",
    type: "text",
    placeholder: "Api Key Identifire",
    field: "key",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
];
