import React, { Component } from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import { connect } from "react-redux";
import "./membership.scss";
import Loader from "../../library/common/components/Loader";

import axiosInstance from "../../helpers/Axios";
import { setMetaTags } from "../../library/common/actions/MetaActions";

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  async fetchPageData() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      "/cms/page/detail/memberships"
    );
    if (status === 200) {
      this.setState({ pageData: data.result, isLoading: false });
      if (data.hasOwnProperty("tags")) {
        this.props.setMetaTags(data.tags);
      }
    }
  }

  render() {
    const { pageData, isLoading } = this.state;

    let headerData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Header"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    let bodyData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Body"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    return (
      <>
        <Container className="page-banner membership-banner">
          <Grid
            container
            justify="center"
            className="contact-banner "
            alignItems="flex-start"
          >
            {isLoading && <Loader />}
            <Typography
              variant="h5"
              className="title contact-title mobile-heading text-center color-blue text-upper"
            >
              {pageData?.pageTitle}
            </Typography>
            <div
              variant="body1"
              className="text-center cms_membership"
              dangerouslySetInnerHTML={{
                __html:
                  headerData &&
                  headerData?.length > 0 &&
                  headerData[0]?.content,
              }}
            ></div>
          </Grid>
        </Container>

        <Container>
          <div className="text-left section membershipMain">
            <Grid container alignContent="center" justify="center">
              {bodyData &&
                bodyData?.length > 0 &&
                bodyData.map((obj, i) => (
                  <Grid item xs={12} key={i}>
                    <div
                      className="CMS_members"
                      dangerouslySetInnerHTML={{
                        __html: obj?.content,
                      }}
                    ></div>
                  </Grid>
                ))}
            </Grid>
          </div>
        </Container>
      </>
    );
  }
}

export default connect(null, { setMetaTags })(Membership);
