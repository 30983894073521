import React, { PureComponent } from "react";
import "./betsoddsslip.scss";
import { Grid, Button, Divider } from "@material-ui/core";
import { config } from "../../helpers/config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class BetsoddsSlipBookmarkerRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oddsArray: [],
    };
  }
  renderBookmarkerRow = (bet, bookmark, i) => {
    let { bookmarks_data } = this.props;
    let bookmaker_value = bookmarks_data?.filter(
      (obj) => obj?.id == bookmark?.bookKeepersId
    );
    // console.log("bookmark", bookmark);
    bookmaker_value = bookmaker_value?.length > 0 ? bookmaker_value[0] : {};
    let sportType = bet?.sportType[0];
    const { stakes } = this.props;
    let wins =
      bet?.sportType[0] === "racing"
        ? Number(bookmark?.Win).toFixed(2)
        : Number(bookmark?.value).toFixed(2);

    let place =
      bookmark?.bookKeepersId == 1
        ? Number(0).toFixed(2)
        : bookmark?.bookKeepersId == 2
        ? Number(0).toFixed(2)
        : Number(bookmark?.NSEPlcce).toFixed(2);
    // if (wins > 0 || place > 0) {
    return (
      <>
        <Grid container key={i}>
          <Grid item xs={6} className="odds-first-row-first text-center">
            {bookmaker_value?.logo !== null ? (
              <img
                // src={config?.baseUrl + bookmark?.bookKeeper?.logo}
                src={bookmaker_value?.logo}
                alt={bookmaker_value?.key}
              />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}

            {/* {bookmark?.bookKeeper?.logo !== null ? (
              bookmark.link !== "" ? (
                <img
                  src={bookmark.logo}
                  onClick={() => window.open(bookmark.link, "_blank")}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              ) 
              : (
                <img src={bookmark.logo} alt="" />
              )
            )
             : (
              <div className="imagePlaceholder">Logo</div>
            )} */}
          </Grid>
          <Grid item xs={6}>
            <div className="betsnow-button">
              <Button
                variant="contained"
                className="btn"
                onClick={() => window.open(bookmaker_value?.link, "_blank")}
                type="submit"
                component="button"
              >
                Bet Now
              </Button>
            </div>
          </Grid>
          <Grid container className="odds-second-row text-center">
            <Grid
              item
              xs={sportType === "racing" ? 3 : 6}
              // xs={3}
              // className={bet?.showLineCol === false && "saprator"}
            >
              {wins !== "NaN" ? Number(wins).toFixed(2) : "0.00"}
            </Grid>
            {sportType === "racing" && (
              <Grid item xs={3} className="saprator">
                {Number(place).toFixed(2)}
              </Grid>
            )}
            {/* {bet?.showLineCol !== false && bookmark.enabled === true && (
              <Grid item xs={3} className="saprator">
                {Number(place).toFixed(2)}
              </Grid>
            )} */}
            <Grid
              item
              xs={sportType === "racing" ? 3 : 6}
              // xs={3}
              className="odds-second-row-third"
            >
              <div style={{ overflowWrap: "break-word" }}>
                {stakes && stakes[bet.id]
                  ? Number(wins * stakes[bet.id]).toFixed(2)
                  : ""}
              </div>
            </Grid>
            {sportType === "racing" && (
              <Grid item xs={3} className="odds-second-row-forth">
                <div style={{ overflowWrap: "break-word" }}>
                  {stakes && stakes[bet.id]
                    ? Number(place * stakes[bet.id]).toFixed(2)
                    : ""}
                </div>
              </Grid>
            )}
            {/* {bet?.showLineCol !== false && (
              <Grid item xs={3} className="odds-second-row-forth">
                <div style={{ overflowWrap: "break-word" }}>
                  {stakes && stakes[bet.id]
                    ? bookmark.enabled === true &&
                      Number(place * stakes[bet.id]).toFixed(2)
                    : ""}
                </div>
              </Grid>
            )} */}
          </Grid>
        </Grid>
        <Divider className="odds-divider" />
      </>
    );
    // } else {
    //   return "";
    // }
  };

  renderBookmarker = (bet, i) => {
    const { bookmarks, sortBy, sortOrder, sportsData } = this.props;
    let filtered_bookmarks = bookmarks;
    let bookmarks_new = bet?.RunnerOdds;
    let sportType = bet?.sportType[0];
    let finaleData = bet?.RunnerOdds?.odd;
    if (bookmarks_new?.odd?.length > 0) {
      if (sportType === "racing") {
        finaleData = bet?.RunnerOdds?.odd;
        let finaleDataRow = finaleData?.filter(
          (obj) => obj.bookKeepersId !== null
        );
        finaleData = finaleDataRow.map((item) => {
          let wins = item?.NSWWin;
          return { ...item, wins: wins };
        });
        // console.log("latest", finaleData);
        // filtered_bookmarks = bookmarks?.map((bItem, i) => {
        //   let finaleDataRow = finaleData
        //     ? finaleData?.filter(
        //         (RO) =>
        //           /*RO?.bookKeeper?.name*/ "PUP" === bItem?.key ||
        //           "ladbrokes" === bItem?.key ||
        //           "neds" === bItem?.key ||
        //           "VBT" === bItem?.key ||
        //           "bookmaker" === bItem?.key ||
        //           "bet365" === bItem?.key ||
        //           "betstar" === bItem?.key
        //       )
        //     : [];

        //   let wins =
        //     finaleDataRow?.length > 0
        //       ? Number(finaleDataRow[0].Win).toFixed(2)
        //       : Number(bItem?.wins).toFixed(2);
        //   let place =
        //     finaleDataRow?.length > 0
        //       ? Number(finaleDataRow[0].Place).toFixed(2)
        //       : Number(bItem?.place).toFixed(2);
        //   return { ...bItem, wins: wins, place: place };
        // });
      }
      // else if (
      //   (sportType === "combat" || sportType === "teamsport") &&
      //   bet?.itemData?.WinPrices
      // ) {
      //   if (bet?.ShowOtherSportMarket === true) {
      //     let finaleData = bet?.itemData?.WinPrices;

      //     filtered_bookmarks = bookmarks?.map((bItem, i) => {
      //       let bookmark_key = bItem?.key;
      //       let SameDataBookmarkers = [
      //         "ladbrokes",
      //         "neds",
      //         "bookmaker",
      //         "betstar",
      //       ];
      //       if (SameDataBookmarkers.some((item) => item === bookmark_key)) {
      //         bookmark_key = "neds";
      //       }
      //       let finaleDataRow = finaleData
      //         ? finaleData?.filter((RO) => {
      //             return RO.key === bookmark_key;
      //           })
      //         : [];
      //       if (bookmark_key === "PUP") {
      //         finaleDataRow = finaleData
      //           ? finaleData?.filter((RO) => RO.key === "generationWeb")
      //           : [];
      //       }

      //       let finaleDataRow_length = finaleDataRow?.length;
      //       let wins = finaleDataRow[finaleDataRow_length - 1]
      //         ? finaleDataRow[finaleDataRow_length - 1].team?.WinPrice
      //         : 0;
      //       return { ...bItem, wins: wins };
      //     });
      //   } else {
      //     finaleData = bet?.itemData?.WinPrices;
      //     filtered_bookmarks = bookmarks?.map((bItem, i) => {
      //       let bookmark_key = bItem?.key;
      //       let SameDataBookmarkers = [
      //         "ladbrokes",
      //         "neds",
      //         "bookmaker",
      //         "betstar",
      //       ];
      //       if (SameDataBookmarkers.some((item) => item === bookmark_key)) {
      //         bookmark_key = "neds";
      //       }
      //       let finaleDataRow = finaleData
      //         ? finaleData?.filter((RO) => {
      //             return RO.key === bookmark_key;
      //           })
      //         : [];
      //       if (bookmark_key === "PUP") {
      //         finaleDataRow = finaleData
      //           ? finaleData?.filter((RO) => RO.key === "generationWeb")
      //           : [];
      //       }
      //       let finaleDataRow_length = finaleDataRow?.length;

      //       if (bet?.ShowTeam === "TeamA") {
      //         let wins = finaleDataRow[finaleDataRow_length - 1]
      //           ? finaleDataRow[finaleDataRow_length - 1].teamA?.WinPrice
      //           : 0;
      //         let place = finaleDataRow[finaleDataRow_length - 1]
      //           ? finaleDataRow[finaleDataRow_length - 1].teamA?.ALine
      //           : 0;
      //         return { ...bItem, wins: wins, place: place };
      //       } else if (bet?.ShowTeam === "TeamB") {
      //         let wins = finaleDataRow[finaleDataRow_length - 1]
      //           ? finaleDataRow[finaleDataRow_length - 1].teamB?.WinPrice
      //           : 0;
      //         let place = finaleDataRow[finaleDataRow_length - 1]
      //           ? finaleDataRow[finaleDataRow_length - 1].teamB?.ALine
      //           : 0;
      //         return { ...bItem, wins: wins, place: place };
      //       } else {
      //         return { ...bItem, wins: 0, place: 0 };
      //       }
      //     });
      //   }
      // }
    }

    // if (sortBy !== "") {
    //   if (sortBy === "wins") {
    //     filtered_bookmarks = filtered_bookmarks.sort((a, b) =>
    //       (sortOrder ? a.wins > b.wins : a.wins < b.wins) ? 1 : -1
    //     );
    //   } else if (sortBy === "place") {
    //     filtered_bookmarks = filtered_bookmarks.sort((a, b) =>
    //       (sortOrder ? a.place > b.place : a.place < b.place) ? 1 : -1
    //     );
    //   } else {
    //   }
    // }

    return (
      <>
        <div className="box bookmark-item p15" key={bet}>
          {filtered_bookmarks?.map((bookmark, i) =>
            this.renderBookmarkerRow(bet, bookmark, i)
          )}
        </div>
      </>
    );
  };

  renderOddSlip = (bet) => {
    const { sortBy, sortOrder, match } = this.props;

    let sportType = bet?.sportType[0];
    let RunnerOdds = [];
    let odds = [];
    let ArrayOdds = [];
    if (sportType === "racing") {
      RunnerOdds = bet?.RunnerOdds;

      let array_temp =
        RunnerOdds?.length > 0
          ? RunnerOdds?.map((obj) => {
              return obj.odd?.length > 0 &&
                obj.odd !== "undefined" &&
                obj.odd !== null
                ? obj.odd.map((value) => odds.push(value))
                : {};
            })
          : [];

      // var sorted_runnerodds = RunnerOdds.sort((a, b) => {
      //   return (
      //     new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      //   );
      // }).reverse();

      // ArrayOdds =
      //   sorted_runnerodds?.length > 0
      //     ? sorted_runnerodds?.map((obj) => {
      //         return obj.odd?.length > 0 &&
      //           obj.odd !== "undefined" &&
      //           obj.odd !== null
      //           ? obj.odd.map((value) => odds.push(value))
      //           : {};
      //       })
      //     : [];

      // const uniqueOdds = Array.from(
      //   new Set(odds.map((a) => a.bookKeeper.name))
      // ).map((id) => {
      //   return odds.find((a) => a.bookKeeper.name === id);
      // });

      // if (uniqueOdds?.length > 0) {
      //   odds = uniqueOdds;
      // }

      if (odds?.length > 0) {
        odds = odds?.filter((obj) => obj?.bookKeepersId !== null);

        let ref_array = [];
        let odds_clone = [];
        odds.map((element) => {
          if (ref_array.includes(element.bookKeepersId)) {
            let index = ref_array.indexOf(element.bookKeepersId);
            let obj = odds_clone[index];
            if (element.key == 2) {
              obj["NSEPlcce"] = element.value;
            } else {
              obj["Win"] = element.value;
            }
            odds_clone[index] = obj;
          } else {
            ref_array.push(element.bookKeepersId);
            let obj = element;

            if (element.key == 1) {
              obj["NSEPlcce"] = 0;
              obj["Win"] = element.value;
            } else {
              obj["Win"] = 0;
              obj["NSEPlcce"] = element.value;
            }
            odds_clone.push(obj);
          }
        });

        if (odds_clone?.length > 0) {
          // let Win = Number(obj?.Win);
          // let NSEPlcce = Number(obj?.NSEPlcce);
          // Win = isNaN(Win) ? 0 : Win;
          // NSEPlcce = isNaN(NSEPlcce) ? 0 : NSEPlcce;
          odds = odds_clone?.filter((obj) => {
            let Win = Number(obj?.Win);
            let NSEPlcce = Number(obj?.NSEPlcce);
            Win = isNaN(Win) ? 0 : Win;
            NSEPlcce = isNaN(NSEPlcce) ? 0 : NSEPlcce;
            return Win != 0 || NSEPlcce != 0;
          });
        }
        if (
          match.params.sportName === "Harness Racing" ||
          match.params.sportName === "Greyhound Racing"
        ) {
          odds =
            odds?.length > 0
              ? odds?.filter((obj) => obj?.bookKeepersId !== 2)
              : odds;
        }
        // odds =
        //   odds_clone?.length > 0 &&
        //   odds_clone?.filter(
        //     (obj) => Number(obj?.Win) != 0 || Number(obj?.NSEPlcce) != 0
        //   );
      }
    } else if (sportType === "combat" || sportType === "teamsport") {
      RunnerOdds = bet?.itemData;

      if (bet.ShowTeam === "TeamA") {
        odds = RunnerOdds.filter((obj) => obj.MarketValueKey.key === "AWin");
      } else if (bet.ShowTeam === "TeamB") {
        odds = RunnerOdds.filter((obj) => obj.MarketValueKey.key === "BWin");
      }
      odds = odds
        .sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        })
        .reverse();

      odds = Array.from(new Set(odds.map((a) => a.bookKeeper.name))).map(
        (id) => {
          return odds.find((a) => a.bookKeeper.name === id);
        }
      );
      odds = odds?.filter((obj) => Number(obj?.value).toFixed(2) != "0.00");
      // odds =
      //   RunnerOdds !== "undefined" && RunnerOdds?.length > 0 ? RunnerOdds : [];
    }

    // if (sortBy !== "") {
    //   if (sortBy === "wins") {
    //     odds =
    //       sportType === "racing"
    //         ? odds.sort((a, b) =>
    //             (
    //               sortOrder
    //                 ? Number(a.Win) > Number(b.Win)
    //                 : Number(a.Win) < Number(b.Win)
    //             )
    //               ? 1
    //               : -1
    //           )
    //         : odds.sort((a, b) =>
    //             (
    //               sortOrder
    //                 ? Number(a.value) > Number(b.value)
    //                 : Number(a.value) < Number(b.value)
    //             )
    //               ? 1
    //               : -1
    //           );
    //   } else if (sortBy === "place") {
    //     sportType === "racing"
    //       ? odds.sort((a, b) =>
    //           (
    //             sortOrder
    //               ? Number(a.NSEPlcce) > Number(b.NSEPlcce)
    //               : Number(a.NSEPlcce) < Number(b.NSEPlcce)
    //           )
    //             ? 1
    //             : -1
    //         )
    //       : odds.sort((a, b) =>
    //           (
    //             sortOrder
    //               ? Number(a.value) > Number(b.value)
    //               : Number(a.value) < Number(b.value)
    //           )
    //             ? 1
    //             : -1
    //         );
    //   } else {
    //   }
    // }

    return (
      <>
        <div className="box bookmark-item p15" key={bet}>
          {odds.map((bookmark, i) =>
            this.renderBookmarkerRow(bet, bookmark, i)
          )}
        </div>
      </>
    );
  };
  render() {
    const { bets } = this.props;
    // console.log("bets", bets);
    return (
      <>
        {
          /* {bets.map((bet, i) => this.renderBookmarker(bet, i))} */
          bets.map((bet, i) => this.renderOddSlip(bet, i))
        }
      </>
    );
  }
}

const mapStateToProps = ({ common, bets }) => {
  return {
    bookmarks_data: bets.bookmarks,
  };
};
export default withRouter(
  connect(mapStateToProps, {})(BetsoddsSlipBookmarkerRow)
);
