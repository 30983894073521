import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/tippingandselection.png";

class MembershipTippingSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Tipping & Selection Partners
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                Get access to a range of tipping and selection partners who give
                you data, tips and recourses to enable you to refine your
                strategy. By registering with motzza, you will be able to view
                tips and selections from all our partners.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                Our tipping and selection partners can assist you with your
                sporting and racing decisions and provide you with insight into
                the tipping process. Our aim is to help educate you on how to
                participate in sporting events so that you can make more
                informed decisions every time you play.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                All you need to do is choose the tipping and selection partners
                you would like to follow. With their analysis services, you will
                be able to generate the best strategy for your needs. You can
                apply different staking plans to your tipping history and find
                the most ideal staking plan to help you work towards achieving
                your desired outcomes.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                Choosing your selections and placing bets can take a long time.
                Bet placement can also be difficult to follow and is prone to
                mistakes. By utilising the services provided by our tipping and
                selection partners, you can quickly place bets on your tips,
                trigger notifications and start playing before the sporting
                event has even begun.
              </Typography>
              <Typography variant="body1" className="afiliate-signup-content">
                All motzza members can experience the benefits of our portal of
                odds comparison services available in Australia.{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  Become a motzza member today.
                </span>{" "}
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipTippingSelection;
