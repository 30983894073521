import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Typography, Grid, Card, CardContent } from "@material-ui/core";

import styles from "./sports.rugby.scss";
import TabList from "../../common/tablists/tablist";
import axiosInstance from "../../../helpers/Axios";
import Loader from "../../../library/common/components/Loader";
import RugbyTeams from "./rugby.team";

import { EventEmitter } from "../../../services/event";
import SportsMenu from "../../sportlist/SportsMenu";
import { URLS } from "../../../library/common/constants/UrlConstants";

class SportRugby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: { id: 1, title: "NRL" },
      raceTypes: [
        { id: 1, title: "NRL" },
        { id: 2, title: "World Cup" },
        { id: 3, title: "Daily M Medal" },
        { id: 4, title: "State of Origin" },
        { id: 5, title: "Futures" },
      ],
      selectedSubType: { id: 1, title: "Matches" },
      raceSubTypes: [
        { id: 1, leagueName: "Matches" },
        // { id: 2, leagueName: "Futures" },
      ],
      categories: [],
      leagueTabs: [],
      leagueDetails: [],
      allSportsType: [],
      allSports: [],
      selectedLeagueTab: null,
      isLoading: "",
      isEventSelected: false,
      selectedEvent: null,
      showMarketData: false,
      MarketDataItem: false,
    };
  }

  componentDidMount() {
    this.fetchCategoryData();
    this.fetchAllSportsType();
    this.fetchAllSports();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.hideMarketData();
      this.fetchCategoryData();
    }
  }

  async fetchCategoryData() {
    let sportId = this.props.match.params.id;
    try {
      this.setState({ isLoading: "league" });
      await axiosInstance
        .get(`sports/league/list/${sportId}`)
        .then((response) => {
          if (response?.status === 200) {
            this.setState({
              leagueTabs: response.data.result,
              selectedLeagueTab: response.data.result[0],
              isLoading: "",
            });
            if (response.data.result.length > 0) {
              this.fetchLeagueDetails(response.data.result[0].id);
            }
            //   const leagues = [];
            //   if (response?.data?.categories[0]?.rls?.length > 0) {
            //     const sortedleagues = []
            //       .concat(response?.data?.categories[0].rls)
            //       .sort((a, b) => (a.LeagueID > b.LeagueID ? -1 : 1));
            //     sortedleagues.forEach((item, index) => {
            //       if (item.Event.length > 0) {
            //         leagues.unshift({
            //           ...item,
            //           id: item.id,
            //           title: item.League,
            //         });
            //       }
            //     });
            //   }
            //   let finalLeaguesOverview = [];
            //   let finalLeagues = leagues?.filter((league) => {
            //     let hasEvents = false,
            //       hasMarkets = false,
            //       hasOutcome = false,
            //       hasOutcomeValue = false;
            //     if (league?.Event?.length > 0) {
            //       hasEvents = true;
            //       let Event = league?.Event?.map((event) => {
            //         if (event?.Market?.length > 0) {
            //           hasMarkets = true;
            //           let Market = event?.Market?.map((market) => {
            //             hasOutcome = true;
            //             if (
            //               (event.TeamA !== "" &&
            //                 event.TeamB !== "" &&
            //                 market?.ALine !== null &&
            //                 market?.ALineDiv !== null &&
            //                 market?.AWin !== null) ||
            //               (market?.BLine !== null &&
            //                 market?.BLineDiv !== null &&
            //                 market?.BWin !== null)
            //             ) {
            //               hasOutcomeValue = true;
            //             }
            //             if (market?.Outcome?.length > 0) {
            //               let Outcome = market?.Outcome?.map((outcome) => {
            //                 if (outcome.Win > 0) {
            //                   hasOutcomeValue = true;
            //                 }
            //                 return outcome;
            //               });
            //             }
            //             finalLeaguesOverview[league?.id] = {
            //               id: league?.id,
            //               hasEvents: hasEvents,
            //               hasMarkets: hasMarkets,
            //               hasOutcome: hasOutcome,
            //               hasOutcomeValue: hasOutcomeValue,
            //             };
            //             return market;
            //           });
            //           return event;
            //         }
            //       });
            //     }
            //     if ((hasEvents && hasMarkets && hasOutcome) || hasOutcomeValue) {
            //       return {
            //         ...league,
            //         hasEvents: hasEvents,
            //         hasMarkets: hasMarkets,
            //         hasOutcome: hasOutcome,
            //         hasOutcomeValue: hasOutcomeValue,
            //       };
            //     }
            //   });
            //   this.setState({
            //     categories: response?.data?.categories,
            //     leagueTabs: finalLeagues,
            //     selectedLeagueTab: finalLeagues[0],
            //     isLoading: false,
            //   });
          }
        });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: "" });
    }
  }

  fetchLeagueDetails = async (id) => {
    this.setState({ isLoading: "league_detail" });
    const { status, data } = await axiosInstance.get(
      `sports/league/detail/${id}`
    );
    if (status === 200) {
      this.setState({ leagueDetails: data.result, isLoading: "" });
    }
  };

  handleChange = (event, newValue) => {
    let { selectedLeagueTab } = this.state;
    this.state.leagueTabs.map((raceItem) => {
      if (raceItem.id === newValue) {
        selectedLeagueTab = raceItem;
      }
      return raceItem;
    });
    this.fetchLeagueDetails(newValue);
    this.setState({ selectedLeagueTab });
  };
  handleChangeSubtype = (event, newValue) => {
    let { selectedSubType } = this.state;
    this.state.raceSubTypes.map((raceItem) => {
      if (raceItem.id === newValue) {
        selectedSubType = raceItem;
      }
      return raceItem;
    });
    this.setState({ selectedSubType });
  };

  handleDisplayMarket = (item, league, totalMarkets, Market) => (event) => {
    this.setState({
      showMarketData: true,
      MarketDataItem: {
        ...item,
        leagueName: league.League,
        sportsData: this.props.sportsData,
      },
    });
    // event.stopPropagation();
    // const { sportName } = this.props.match.params;
    // if (totalMarkets > 0) {
    //   this.props.history.push("/markets/" + sportName + "/" + item?.id, {
    //     item: {
    //       ...item,
    //       leagueName: league.League,
    //       sportsData: this.props.sportsData,
    //     },
    //   });
    // }
  };

  hideMarketData = () => {
    this.setState({ showMarketData: false, MarketDataItem: false });
  };

  async fetchAllSportsType() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sportType);
      if (status === 200) {
        this.setState({ allSportsType: data.result });
      }
    } catch (err) {}
  }

  async fetchAllSports() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sports);
      if (status === 200) {
        this.setState({ allSports: data.result });
      }
    } catch (err) {}
  }

  getSportTypeId = (name) => {
    let { allSports } = this.state;
    let sportTypeId = allSports
      .filter((obj) => obj.sportName == name)
      .map((object) => object.sportTypeId);
    return sportTypeId;
  };

  getSportType = () => {
    let sportTypeId = this.getSportTypeId(this.props.match.params.sportName);
    let { allSportsType } = this.state;
    let sportType = allSportsType
      .filter((obj) => obj.id == sportTypeId[0])
      .map((object) => object.sportType);
    return sportType;
  };

  handleCompare = (itemToSend) => (event) => {
    event.stopPropagation();

    EventEmitter.dispatch("draweropen");
    EventEmitter.dispatch("updatebetsList", itemToSend);
  };

  showCompareButton = (item, league, type, team) => {
    let sportName = this.getSportType();
    let itemData = "";
    if (item.Markets?.length > 0) {
      let itemDatatemp = [];
      itemDatatemp = item.Markets?.filter(
        (marketitem) =>
          marketitem?.marketName === "Match Betting" ||
          marketitem?.marketName === "Head To Head" ||
          marketitem?.marketName === "Fight Betting"
      );

      itemData = itemDatatemp?.length > 0 ? itemDatatemp[0] : null;
    }

    // let wins = 0;
    // let place = 0;
    // if (item?.ShowTeam === "TeamA") {
    //   let winsData = itemData?.MarketValues.filter(
    //     (obj) => obj.MarketValueKey.key === "AWin"
    //   );
    //   wins = winsData?.length > 0 ? winsData[0].value : wins;
    //   // place = itemData?.APlace ? itemData?.APlace : place;
    // } else if (item?.ShowTeam === "TeamB") {
    //   let winsData = itemData?.MarketValues.filter(
    //     (obj) => obj.MarketValueKey.key === "BWin"
    //   );
    //   wins = winsData?.length > 0 ? winsData[0].value : wins;
    //   // place = itemData?.BPlace ? itemData?.BPlace : place;
    // }

    const itemToSend = { ...item };
    itemToSend.itemData = itemData;
    itemToSend.leagueName =
      league.leagueName !== "undefined" ? league.leagueName : "";
    itemToSend.Market = type === "head" ? "Head to Head" : "Line";
    itemToSend.Team = team;
    itemToSend.teams = `${
      item.Participants.length > 1 && item.Participants[0].Team.name
    } VS ${item.Participants.length > 1 && item.Participants[1].Team.name}`;
    itemToSend.imageSrc = item.categoriesName;
    itemToSend.title = team;
    itemToSend.sportType = sportName;

    let buttonDisabled = "false";
    // if (itemData?.WinPrices?.length > 0) {
    //   let bestOdds = itemData?.WinPrices?.sort(function (a, b) {
    //     return Number(b.FixedWin) - Number(a.FixedWin);
    //   });
    //   bestOdds = bestOdds[0];

    //   if (item?.ShowTeam === "TeamA") {
    //     if (!Number(bestOdds?.teamA?.WinPrice) > 0) {
    //       buttonDisabled = "true";
    //     }
    //   } else if (item?.ShowTeam === "TeamB") {
    //     if (!Number(bestOdds?.teamB?.WinPrice) > 0) {
    //       buttonDisabled = "true";
    //     }
    //   }
    // } else {
    //   buttonDisabled = "true";
    // }
    return (
      <button
        disabled={itemData !== null && itemData !== "" ? false : true}
        className='compare-button'
        variant='outlined'
        onClick={this.handleCompare(itemToSend)}
      >
        Compare
      </button>
    );
  };

  showMarketLength(market) {
    let count = 0;
    let markettemp = market;
    if (market?.length > 0) {
      markettemp = markettemp?.map((m) => {
        if (m?.MarketValues?.length > 0) {
          count++;
        }
      });
    }
    return count;
  }

  render() {
    const {
      // raceTypes,
      // selectedType,
      raceSubTypes,
      selectedSubType,
      leagueTabs,
      selectedLeagueTab,
      categories,
      isLoading,
      showMarketData,
      MarketDataItem,
      leagueDetails,
    } = this.state;
    let sportName = this.props.match.params.sportName;
    let filteredLeagueEvents = leagueDetails?.filter(
      (item) => item.Participants?.length > 1 /* && item.Markets?.length > 0 */
    );
    const hideLineForSports = [
      "Cricket",
      "Mixed Martial Arts",
      "Boxing",
      "Tennis",
      "Soccer",
    ];
    const showLineCol = hideLineForSports.some(
      (item) => this.props.match.params.sportName === item
    )
      ? false
      : true;

    return (
      <>
        <div className={styles.sportsContentContainer}>
          <SportsMenu />
          {showMarketData ? (
            <RugbyTeams
              MarketDataItem={MarketDataItem}
              hideMarketData={this.hideMarketData}
            />
          ) : (
            <>
              {isLoading === "league" ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <>
                  <Typography
                    variant='h5'
                    className=' title-with-border sub-title'
                  >
                    {sportName}
                  </Typography>
                  {leagueTabs.length > 0 && (
                    <>
                      <TabList
                        isRugby
                        handleChange={this.handleChange}
                        tabs={leagueTabs}
                        selectedTab={selectedLeagueTab}
                      />
                    </>
                  )}
                </>
              )}
              <div className='race-details'>
                {isLoading === "league_detail" ? (
                  <div className='text-center racelist-loader'>
                    <Loader />
                  </div>
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <TabList
                        isRugby
                        handleChange={this.handleChangeSubtype}
                        tabs={raceSubTypes}
                        selectedTab={selectedSubType}
                        containerClasses={"pd-15"}
                        itemClasses={"subtabItem"}
                      />

                      {filteredLeagueEvents &&
                        filteredLeagueEvents.length === 0 &&
                        isLoading !== "league_detai" &&
                        isLoading !== "league" && <p>No Matches Available</p>}

                      {/* <div className="rounds ">
                    <h4>Round: </h4>
                    <List>
                      <ListItem className="active">4</ListItem>
                      <ListItem>5</ListItem>
                      <ListItem>6</ListItem>
                      <ListItem>7</ListItem>
                    </List>
                  </div> */}

                      <Grid container>
                        {
                          filteredLeagueEvents &&
                          filteredLeagueEvents.length > 0
                            ? filteredLeagueEvents.map((item, index) => {
                                item = {
                                  ...item,
                                  showLineCol: showLineCol,
                                  SportName: sportName,
                                  categoriesName: sportName,
                                };

                                return (
                                  <>
                                    {
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        className='cursor'
                                      >
                                        <div
                                          className='matchDetailItem'
                                          key={index}
                                        >
                                          <Typography variant='body2'>
                                            <strong>
                                              {moment
                                                .utc(item.eventDate)
                                                .local()
                                                .format("dddd DD/MM/YYYY")}
                                            </strong>
                                          </Typography>
                                          <Card>
                                            <CardContent className='hide-xs hide-sm '>
                                              <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                  {moment
                                                    .utc(item.eventDate)
                                                    .local()
                                                    .format(
                                                      "DD/MM/YYYY hh:mm:ss"
                                                    )}{" "}
                                                  {this.showMarketLength(
                                                    item.Markets
                                                  )}{" "}
                                                  Markets
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={3}
                                                  className='text-center'
                                                >
                                                  Head to head
                                                </Grid>
                                                {showLineCol && (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    className='text-center'
                                                  >
                                                    Line
                                                  </Grid>
                                                )}
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  className='teamTitle'
                                                  onClick={
                                                    this.showMarketLength(
                                                      item.Markets
                                                    ) > 0 &&
                                                    this.handleDisplayMarket(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamA",
                                                      },
                                                      selectedLeagueTab,
                                                      item.Markets.length,
                                                      item.Markets
                                                    )
                                                  }
                                                >
                                                  {item.Participants.length >
                                                    1 &&
                                                    item.Participants[0].Team
                                                      .name}
                                                </Grid>

                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={3}
                                                  className='text-center'
                                                >
                                                  {this.showCompareButton(
                                                    {
                                                      ...item,
                                                      ShowTeam: "TeamA",
                                                    },
                                                    selectedLeagueTab,
                                                    "head",
                                                    item.Participants.length >
                                                      1 &&
                                                      item.Participants[0].Team
                                                        .name
                                                  )}
                                                </Grid>
                                                {showLineCol && (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    className='text-center'
                                                  >
                                                    {this.showCompareButton(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamA",
                                                      },
                                                      selectedLeagueTab,
                                                      "line",
                                                      item.Participants.length >
                                                        1 &&
                                                        item.Participants[0]
                                                          .Team.name
                                                    )}
                                                  </Grid>
                                                )}
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  className='teamTitle'
                                                  onClick={
                                                    this.showMarketLength(
                                                      item.Markets
                                                    ) > 0 &&
                                                    this.handleDisplayMarket(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamB",
                                                      },
                                                      selectedLeagueTab,
                                                      item.Markets.length,
                                                      item.Markets
                                                    )
                                                  }
                                                >
                                                  {item.Participants.length >
                                                    1 &&
                                                    item.Participants[1].Team
                                                      .name}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={3}
                                                  className='text-center '
                                                >
                                                  {this.showCompareButton(
                                                    {
                                                      ...item,
                                                      ShowTeam: "TeamB",
                                                    },
                                                    selectedLeagueTab,
                                                    "head",
                                                    item.Participants.length >
                                                      1 &&
                                                      item.Participants[1].Team
                                                        .name
                                                  )}
                                                </Grid>
                                                {showLineCol && (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    className='text-center'
                                                  >
                                                    {this.showCompareButton(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamB",
                                                      },
                                                      selectedLeagueTab,
                                                      "line",
                                                      item.Participants.length >
                                                        1 &&
                                                        item.Participants[1]
                                                          .Team.name
                                                    )}
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </CardContent>

                                            <CardContent className='hide-md hide-lg hide-xl'>
                                              <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                  {moment
                                                    .utc(item.eventDate)
                                                    .local()
                                                    .format(
                                                      "DD/MM/YYYY hh:mm:ss"
                                                    )}{" "}
                                                  {item.Markets?.length} Markets
                                                </Grid>
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  className='teamTitle'
                                                  onClick={
                                                    this.showMarketLength(
                                                      item.Markets
                                                    ) > 0 &&
                                                    this.handleDisplayMarket(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamA",
                                                      },
                                                      selectedLeagueTab,
                                                      item.Markets.length,
                                                      item.Markets
                                                    )
                                                  }
                                                >
                                                  {item.Participants.length >
                                                    1 &&
                                                    item.Participants[0].Team
                                                      .name}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  md={3}
                                                  className='text-left'
                                                >
                                                  Head to head
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  md={3}
                                                  className='text-center'
                                                >
                                                  {this.showCompareButton(
                                                    {
                                                      ...item,
                                                      ShowTeam: "TeamA",
                                                    },
                                                    selectedLeagueTab,
                                                    "head",
                                                    item.Participants.length >
                                                      1 &&
                                                      item.Participants[0].Team
                                                        .name
                                                  )}
                                                </Grid>

                                                {showLineCol && (
                                                  <>
                                                    {" "}
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={3}
                                                      className='text-left'
                                                    >
                                                      Line
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={3}
                                                      className='text-center'
                                                    >
                                                      {this.showCompareButton(
                                                        {
                                                          ...item,
                                                          ShowTeam: "TeamA",
                                                        },
                                                        selectedLeagueTab,
                                                        "line",
                                                        item.Participants
                                                          .length > 1 &&
                                                          item.Participants[0]
                                                            .Team.name
                                                      )}
                                                    </Grid>{" "}
                                                  </>
                                                )}
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={6}
                                                  className='teamTitle'
                                                  onClick={
                                                    this.showMarketLength(
                                                      item.Markets
                                                    ) > 0 &&
                                                    this.handleDisplayMarket(
                                                      {
                                                        ...item,
                                                        ShowTeam: "TeamB",
                                                      },
                                                      selectedLeagueTab,
                                                      item.Markets.length,
                                                      item.Markets
                                                    )
                                                  }
                                                >
                                                  {item.Participants.length >
                                                    1 &&
                                                    item.Participants[1].Team
                                                      .name}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  md={3}
                                                  className='text-left'
                                                >
                                                  Head to head
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  md={3}
                                                  className='text-center'
                                                >
                                                  {this.showCompareButton(
                                                    {
                                                      ...item,
                                                      ShowTeam: "TeamB",
                                                    },
                                                    selectedLeagueTab,
                                                    "head",
                                                    item.Participants.length >
                                                      1 &&
                                                      item.Participants[1].Team
                                                        .name
                                                  )}
                                                </Grid>

                                                {showLineCol && (
                                                  <>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={3}
                                                      className='text-left'
                                                    >
                                                      Line
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={6}
                                                      md={3}
                                                      className='text-center'
                                                    >
                                                      {this.showCompareButton(
                                                        {
                                                          ...item,
                                                          ShowTeam: "TeamB",
                                                        },
                                                        selectedLeagueTab,
                                                        "line",
                                                        item.Participants
                                                          .length > 1 &&
                                                          item.Participants[1]
                                                            .Team.name
                                                      )}
                                                    </Grid>
                                                  </>
                                                )}
                                              </Grid>
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </Grid>
                                    }
                                  </>
                                );
                              })
                            : ""
                          // <p>No Data Available</p>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ common }) => {
  return {
    sportsData: common.sportsData,
  };
};

export default connect(mapStateToProps, {})(SportRugby);
