import { storeConstants } from "../../library/common/constants";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";

export const fetchCategory = () => (dispatch) => {
  dispatch({
    type: storeConstants.CATEGORY,
    payload: false,
  });

  return axiosInstance
    .get(URLS.sports)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.CATEGORY,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.CATEGORY,
        payload: [],
      });
    });
};
