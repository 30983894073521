import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { withRouter } from "react-router";

import LoginForm from "../loginform/loginform";

import banner_advt_img from "../../images/advertise_banners/728x90_BM_generic.jpg";

import StorageService from "../../services/storage";
import { STORAGE_ENGINES } from "../../helpers/constants";

import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants/UrlConstants";
import { setActiveAdsId } from "../../store/common/actions/ads.actions";

import "./headerloginbar.scss";

class HeaderLoginBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      headerloginbarAdsItem: "",
      topLeftAd: null,
    };
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
    this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
    this.setUser();
  }

  async setUser() {
    this.local_user = await this.localStorage.get("user");
    this.session_user = await this.sessionStorage.get("user");
    this.setState({ user: this.local_user || this.session_user });
  }

  setAd() {
    const topLeftAd = this.props.ads.find(
      (ad) => ad.position === "top-left-banner"
    );
    this.setState({ topLeftAd });

    if (topLeftAd) {
      //this.increaseAdLoads(topLeftAd.id);
    }
  }

  componentDidMount() {
    // this.setAd();
    this.setAdvtBanners();
  }

  setAdvtBanners = () => {
    const { headerloginbarAds } = this.props;
    let activeheaderloginbarAds = this.sessionStorage.get(
      "activehometoprightAds"
    );

    let id = 0;
    id = Math.floor(Math.random() * headerloginbarAds.length);

    if (headerloginbarAds.length > 1) {
      while (id === Number(activeheaderloginbarAds)) {
        id = Math.floor(Math.random() * headerloginbarAds.length);
      }
    }

    this.sessionStorage.set("activeheaderloginbarAds", String(id));
    this.setState({
      headerloginbarAdsItem: headerloginbarAds[id],
    });
  };

  componentDidUpdate(prevProps) {
    // if (this.props.ads !== prevProps.ads) {
    //   this.setAd();
    // }
  }

  async increaseAdLoads(id) {
    const { status, data } = await axiosInstance.post(URLS.ads + "/load", {
      ids: [id],
    });
    if (status === 200) {
      console.log(data);
    }
  }

  handleAdClick = (item) => async () => {
    await axiosInstance.get(URLS.ads + "/click/" + item.id);

    window.open(item.url, "_blank");
  };

  render() {
    const { headerloginbarAdsItem } = this.state;
    const { isLoggedIn, match } = this.props;
    return (
      <>
        <Container className="login-bar">
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            spacing={2}
            className="MuiGrid-spacing-xs-1 MuiGrid-spacing-sm-2 MuiGrid-spacing-md-2 MuiGrid-spacing-lg-3 login-container"
          >
            <Grid item lg={6} md={5} sm={6} xs={12} className="text-left">
              {headerloginbarAdsItem !== undefined &&
              headerloginbarAdsItem !== "" ? (
                <a
                  href={headerloginbarAdsItem?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <img src={headerloginbarAdsItem?.image} alt="" />
                </a>
              ) : (
                <a
                  href="https://record.wageringaffiliates.com.au/_aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk/1/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <img src={banner_advt_img} alt="" />
                </a>
              )}

              {/* <span
                onclick={this.handleAdClick}
                target='_blank'
                style={{ cursor: "pointer" }}>
                <img
                  src={
                    topLeftAd
                      ? config.baseUrl + "/" + topLeftAd.filePath
                      : banner_advt_img
                  }
                  alt=''
                />
              </span> */}
            </Grid>
            {!isLoggedIn ? (
              <>
                <Grid
                  item
                  lg={6}
                  md={7}
                  sm={6}
                  xs={6}
                  className="hide-xs hide-sm-tab MuiGrid-spacing-xs-0"
                >
                  <LoginForm />
                </Grid>
              </>
            ) : (
              ""
            )}
            {/* {!this.state.user ? (
                <LoginForm {...this.props} inputClass="border-green" />
              ) : (
                ""
              )} */}
            {/* </Grid> */}
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer, adsReducer }) => {
  return {
    ads: adsReducer.allAds,
    headerloginbarAds: adsReducer?.headerloginbarAds,
    activeheaderloginbarAds: adsReducer?.activeheaderloginbarAds,
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default withRouter(
  connect(mapStateToProps, { setActiveAdsId })(HeaderLoginBar)
);
