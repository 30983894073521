import React from "react";
import "./sidebarStyles.scss";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { AccountCircle, ExitToAppRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { logoutUser } from "../../../library/common/actions/AuthActions";

const logo = require("../../../images/logo.svg");

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

const DefaultHeader = ({ user, logoutUser }) => {
  const classes = useStyles();
  var loginuser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : JSON.parse(sessionStorage.getItem("user"));

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <div className="headerWrapper">
      <AppBar position="static" className="bg-green top-bar">
        <Toolbar variant="dense">
          {/* <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <img src={logo} alt="motzza logo" />

          <div className="header-user">
            <p>{loginuser && loginuser?.username}</p>
            <button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              className="logout-icon"
              onClick={handleLogout}
            >
              <ExitToAppRounded />
            </button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    user: authReducer.user,
  };
};

export default connect(mapStateToProps, { logoutUser })(DefaultHeader);
