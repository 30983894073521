import { Validators } from "../../../library/utilities/Validators";

export const categoriesFormModel = [
  {
    label: "Sport Type",
    value: "",
    type: "dropdown",
    placeholder: "Sport Type",
    field: "sporttype",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
    options: [
      { value: "", label: "No Sport Type Selected" },
      { id: 1, value: "Combat", label: "Combat" },
      { id: 2, value: "Teams Sport", label: "Teams Sport" },
      { id: 3, value: "Fighting", label: "Fighting" },
      { id: 4, value: "Swimming", label: "Swimming" },
    ],
  },
  {
    label: "Categories Name",
    value: "",
    type: "text",
    placeholder: "Categories Name",
    field: "categoriesname",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "text",
    placeholder: "Variation",
    field: "variation",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
];
