import React from "react";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";

const WhatsNew = () => {
  return (
    <Grid container spacing={15}>
      <Grid item xs={12} style={{ margin: "40px 0 80px 0" }}>
        <h3>What's New?</h3>
        <List>
          <ListItem>
            <ListItemText
              primary="1. Improved betting system"
              secondary="Improved performance of betting system"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. Improved betting system"
              secondary="Improved performance of betting system"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. Improved betting system"
              secondary="Improved performance of betting system"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="4. Improved betting system"
              secondary="Improved performance of betting system"
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default WhatsNew;
