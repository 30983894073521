import React from 'react'
import { Grid, Divider, Button } from '@material-ui/core'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import { ReactSVG } from 'react-svg'
import { FaChevronCircleRight } from 'react-icons/fa'
import hourse_img_small from '../../../images/hourse_img_small.svg'
import mma from '../../../images/sport_icons/mma.svg'
import soccer from '../../../images/sport_icons/soccer.svg'
import tennis from '../../../images/sport_icons/tennis.svg'
import boxing from '../../../images/menu_icons/Boxing.svg'
import cricket from '../../../images/menu_icons/Cricket.svg'

class AmericalFootball extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Boxing</p>
                <p>
                  See All Boxing <FaChevronCircleRight />
                </p>
              </div>
              <p className="allsports_subhead_date">Sunday 06/09/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={boxing} />
                    Samir Ziani v Alex Dilmaghani
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={boxing} />
                    Jamel Herring v Jonathan Oquendo
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
            </div>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Cricket</p>
                <p>
                  See All Cricket <FaChevronCircleRight />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={cricket} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={cricket} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Racing</p>
                <p>
                  See All Racing <FaChevronCircleRight />
                </p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match m10">
                    <ReactSVG src={hourse_img_small} />
                    <span className="allsports_racing_timer">R6</span>
                    &nbsp;&nbsp;&nbsp;Healsville
                  </p>
                  <p className="allsports_racing_timer m10">1m 25s</p>
                </div>
                <Divider className="allsports_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match m10">
                    <ReactSVG src={hourse_img_small} />
                    <span className="allsports_racing_timer">R12</span>
                    &nbsp;&nbsp;&nbsp;Toowoomaba
                  </p>
                  <p className="allsports_racing_timer m10">2m 50s</p>
                </div>
                <Divider className="allsports_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match m10">
                    <ReactSVG src={hourse_img_small} />
                    <span className="allsports_racing_timer">R7</span>
                    &nbsp;&nbsp;&nbsp;Hatrick
                  </p>
                  <p className="allsports_racing_timer m10">3m 25s</p>
                </div>
                <Divider className="allsports_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match m10">
                    <ReactSVG src={hourse_img_small} />
                    <span className="allsports_racing_timer">R12</span>
                    &nbsp;&nbsp;&nbsp;Toowoomaba
                  </p>
                  <p className="allsports_racing_timer m10">2m 50s</p>
                </div>
              </div>
            </div>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>MMA</p>
                <p>
                  See All MMA <FaChevronCircleRight />
                </p>
              </div>
              <p className="allsports_subhead_date">Sunday 06/09/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={mma} />
                    Adrian Yanez v Gustavo Lopez
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={mma} />
                    Max Griffin v Song Kenan
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
            </div>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Soccer</p>
                <p>
                  See All Soccer <FaChevronCircleRight />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={soccer} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={soccer} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Tennis</p>
                <p>
                  See All Tennis <FaChevronCircleRight />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={tennis} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={tennis} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}
export default AmericalFootball
