import React, { Component } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import Slider from "react-slick";
import { connect } from "react-redux";
import StorageService from "../../services/storage";
import { STORAGE_ENGINES } from "../../helpers/constants";
import BenefitsSlider from "../benefitsslider/benefitsslider";
import NewsletterForm from "../newsletterform/newsletterform";
import BannerSignupForm from "../newsletterform/bannerSignupForm";

import bannerimg from "../../images/advt_banner.jpg";
import SidebarLogos from "../sidebarlogos/sidebarlogos";
import start_advt_bg from "../../images/star-advt-bg.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./landing.scss";
import { setMetaTags } from "../../library/common/actions/MetaActions";
import axiosInstance from "../../helpers/Axios";
import AppMeta from "../AppMeta";
import { config } from "../../helpers/config";
import { URLS } from "../../library/common/constants/UrlConstants";

import image1 from "../../images/landing_banners/image1.jpg";
import image2 from "../../images/landing_banners/image2.jpg";
import image3 from "../../images/landing_banners/image3.jpg";
import image4 from "../../images/landing_banners/image4.jpg";
import image5 from "../../images/landing_banners/image5.jpg";
import image6 from "../../images/landing_banners/image6.jpg";
import image7 from "../../images/landing_banners/image7.jpg";
import slide1 from "../../images/landing_banners/slide1.png";
import slide2 from "../../images/landing_banners/slide2.png";
import slide3 from "../../images/landing_banners/slide3.png";
import slide4 from "../../images/landing_banners/slide4.png";
import slide5 from "../../images/landing_banners/slide5.png";
import slide6 from "../../images/landing_banners/slide6.png";
import slide7 from "../../images/landing_banners/slide7.png";
import bottomCenterAd_banner from "../../images/advertise_banners/0329_BRAND_LadbrokeIt_728x90.jpg";
import advt_banner from "../../images/advertise_banners/300x600_BS_generic.gif";

import banner1 from "../../images/landing_banners_new/Banner-Soccer.png";
import banner2 from "../../images/landing_banners_new/Banner-Race.png";
import banner3 from "../../images/landing_banners_new/Banner-MMA.png";
import banner4 from "../../images/landing_banners_new/Banner-Cricket.png";
import banner5 from "../../images/landing_banners_new/Banner-Soccer-Mobile.png";
import banner6 from "../../images/landing_banners_new/Banner-Race-Mobile.png";
import banner7 from "../../images/landing_banners_new/Banner-MMA-Mobile.png";
import banner8 from "../../images/landing_banners_new/Banner-Cricket-Mobile.png";

// let slide_images = [image1, image2, image3, image4, image5, image6, image7];
// slide_images = slide_images
//   .map((a) => ({ sort: Math.random(), value: a }))
//   .sort((a, b) => a.sort - b.sort)
//   .map((a) => a.value);
let mobile_slide = [slide1, slide2, slide3, slide4, slide5, slide6, slide7];

const newBanner = [banner1, banner2, banner3, banner4];
const newMobileBanner = [banner5, banner6, banner7, banner8];

class Landing extends Component {
  state = {
    pageData: [],
    bottomCenterAd: null,
    topRightAd: null,
    hometoprightAdsItem: "",
  };
  constructor(props) {
    super(props);
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
  }

  componentDidMount() {
    this.fetchPageData();
    // this.setAd();
    this.setAdvtBanners();
  }

  componentDidUpdate(prevProps) {
    // if (this.props.ads !== prevProps.ads) {
    //   this.setAd();
    // }
  }

  setAdvtBanners = () => {
    const { hometoprightAds, homebottomCenterAds } = this.props;
    let id = 0,
      activehometoprightAds = this.sessionStorage.get("activehometoprightAds");

    id = Math.floor(Math.random() * hometoprightAds.length);

    if (hometoprightAds.length > 1) {
      while (id === Number(activehometoprightAds)) {
        id = Math.floor(Math.random() * hometoprightAds.length);
      }
    }
    this.sessionStorage.set("activehometoprightAds", String(id));

    let bottomCenterid = 0,
      activehomebottomCenterAds = this.sessionStorage.get(
        "activehomebottomCenterAds"
      );
    bottomCenterid = Math.floor(Math.random() * homebottomCenterAds.length);

    if (homebottomCenterAds.length > 1) {
      while (bottomCenterid === Number(activehomebottomCenterAds)) {
        bottomCenterid = Math.floor(Math.random() * homebottomCenterAds.length);
      }
    }

    this.sessionStorage.set(
      "activehomebottomCenterAds",
      String(bottomCenterid)
    );

    this.setState({
      hometoprightAdsItem: hometoprightAds[id],
      homebottomCenterAdsItem: homebottomCenterAds[bottomCenterid],
    });
  };

  async fetchPageData() {
    const { status, data } = await axiosInstance.get("/cms/page/detail/home");
    if (status === 200) {
      this.setState({ pageData: data.result });
      if (data.hasOwnProperty("tags")) {
        this.props.setMetaTags(data.tags);
      }
    }
  }

  // setAd() {
  //   const bottomCenterAd = this.props.ads.find(
  //     (ad) => ad.position === "bottom-center-banner"
  //   );
  //   const topRightAd = this.props.ads.find(
  //     (ad) => ad.position === "top-right-banner"
  //   );
  //   this.setState({ topRightAd, bottomCenterAd });
  //   const ids = [];
  //   if (topRightAd) {
  //     ids.push(topRightAd.id);
  //   }
  //   if (bottomCenterAd) {
  //     ids.push(bottomCenterAd.id);
  //   }
  //   //this.increaseAdLoads(ids);
  // }

  async increaseAdLoads(ids) {
    try {
      const { status, data } = await axiosInstance.post(URLS.ads + "/load", {
        ids: ids,
      });
      if (status === 200) {
        console.log(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleAdClick = (item) => async () => {
    await axiosInstance.get(URLS.ads + "/click/" + item.id);

    window.open(item.url, "_blank");
  };

  render() {
    const {
      hometoprightAdsItem,
      homebottomCenterAdsItem,
      pageData,
      // bottomCenterAd,
      // topRightAd,
    } = this.state;

    var settings = {
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };

    let aboutData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Footer"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    let sliderBanners = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Header" && obj.column === "firstColumn"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    return (
      <>
        {/* {this.renderHeaderMeta()} */}
        <Container>
          <Grid container spacing={2} justify="flex-start" alignItems="stretch">
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className="newsletter-container-wrap"
            >
              <div className="bg-wrap hide-xs">
                <Slider {...settings}>
                  {newBanner &&
                    newBanner?.length > 0 &&
                    newBanner.map((img, i) => (
                      <div key={i}>
                        <img src={img} alt={img} />
                      </div>
                    ))}
                  {/* {pageData &&
                    pageData.banners.map((image, i) => (
                      <div key={i}>
                        <img
                          src={URLS.uploadPath + image.fileName}
                          alt={image.fileName}
                        />
                      </div>
                    ))} */}
                </Slider>
              </div>
              <div className="bg-wrap hide-xl hide-lg hide-md hide-sm">
                <Slider {...settings}>
                  {newMobileBanner &&
                    newMobileBanner?.length > 0 &&
                    newMobileBanner.map((img, i) => (
                      <div key={i}>
                        <img src={img} alt={img} />
                      </div>
                    ))}
                  {/* {mobile_slide.map((image, i) => (
                    <div key={i}>
                      <img src={image} alt={image} />
                    </div>
                  ))} */}
                </Slider>
              </div>
              {/* <NewsletterForm {...this.props} /> */}
              {/* <BannerSignupForm {...this.props} /> */}
            </Grid>
            {/* <Grid item md={3} sm={12} xs={12} className=" text-left">
              {hometoprightAdsItem !== undefined &&
              hometoprightAdsItem !== "" ? (
                <a
                  href={hometoprightAdsItem?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="start-advt-d"
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  <img src={hometoprightAdsItem?.image} alt="" height="460px" />
                </a>
              ) : (
                <>
                  <a
                    href="https://record.wageringaffiliates.com.au/_vB8fbcQfs2keR0indqtVW2Nd7ZgqdRLk/1/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="start-advt-d"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <img src={advt_banner} alt="" height="460px" />
                  </a>
                   <div
                    className='start-advt'
                    style={{
                      backgroundImage: "url(" + start_advt_bg + ")",
                      backgroundSize: "cover",
                    }}>
                    <Typography variant='h2'>
                      ADVERTISE HERE. CONTACT US TO FIND OUT HOW
                    </Typography>
                    <Typography variant='h3'>
                      Be the first to advertise with motzza now.
                    </Typography>
                    <Typography variant='body2'>
                      Contact us to find out more about advertising rates and
                      how you can appear here for every visitor to the motzza
                      site.
                    </Typography>
                  </div> 
                </>
              )}
            </Grid> */}
          </Grid>
        </Container>
        <Container>
          <BenefitsSlider />
        </Container>
        <Container>
          <Grid
            container
            spacing={0}
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              lg={9}
              md={9}
              sm={12}
              xs={12}
              className="text-left about-content"
            >
              {homebottomCenterAdsItem !== undefined &&
              homebottomCenterAdsItem !== "" ? (
                <a
                  href={homebottomCenterAdsItem?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img src={homebottomCenterAdsItem?.image} alt="" />
                </a>
              ) : (
                <a
                  href="https://record.wageringaffiliates.com.au/_rKStVKr_NJ8B0WjZNehKqWNd7ZgqdRLk/1/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <img src={bottomCenterAd_banner} alt="" />
                </a>
                /* <span
                onClick={this.handleAdClick(bottomCenterAd)}
                style={{ cursor: "pointer" }}>
                <img
                  src={
                    bottomCenterAd
                      ? config.baseUrl + "/" + bottomCenterAd.filePath
                      : bannerimg
                  }
                  className='item-logo'
                  alt=''
                />
              </span> */
              )}

              <Typography variant="h5" className="color-orange heading-font">
                ABOUT MOTZZA
              </Typography>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: aboutData?.length > 0 && aboutData[0]?.content,
                }}
              ></Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <SidebarLogos />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ adsReducer }) => {
  return {
    ads: adsReducer.allAds,
    hometoprightAds: adsReducer?.hometoprightAds,
    homebottomCenterAds: adsReducer?.homebottomCenterAds,
  };
};

export default connect(mapStateToProps, { setMetaTags })(Landing);
