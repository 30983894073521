import {CATEGORY_MENU_STATUS,CATEGORY_SPORT_TYPES}from '../../../helpers/constants';

export const categoryModel = [
  {
    label: "Category Name",
    value: "",
    type: "text",
    placeholder: "",
    field: "name",
    validators: [],
    required: true,
    className: "12",
    extraProps: {},
  },
  {
    label: "Description",
    value: "",
    type: "editor",
    placeholder: "",
    field: "description",
    validators: [],
    required: false,
    className: "12",
    extraProps: {},
  },
  {
    label:"Status",
    type: "dropdown",
    value:"active",
    field:"status",
    placeholder: "Status",
    validators: [],
    required: false,
    className: "12",
    extraProps: {},
    options:[
      {id:'active',label:'Active',value:CATEGORY_MENU_STATUS.ACTIVE},
      {id:'active',label:'Deactive',value:CATEGORY_MENU_STATUS.DEACTIVE}]
  },
  {
    label:"Sport Type",
    type: "dropdown",
    value:"active",
    field:"sportType",
    placeholder: "Sport Type",
    validators: [],
    required: false,
    className: "12",
    extraProps: {},
    options:CATEGORY_SPORT_TYPES?.map((item,index) => {return {id:index,label:item.label,value:item.value}})
  },
];
