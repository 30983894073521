import { Validators } from "../../../library/utilities/Validators";

export const animalFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Variation*",
    value: "",
    type: "text",
    placeholder: "Variation",
    field: "variation",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Runner Number",
    value: "",
    type: "text",
    placeholder: "Runner Number",
    field: "runnerNumber",
    required: false,
    validators: [{ check: Validators.number }],
    className: "6",
  },
  {
    label: "Age",
    value: "",
    type: "text",
    placeholder: "Age",
    field: "age",
    required: false,
    validators: [{ check: Validators.number }],
    className: "6",
  },
  {
    label: "Gender Id",
    value: "",
    type: "dropdown",
    placeholder: "Gender Id",
    field: "genderId",
    required: false,
    className: "6",
    options: [],
  },
  {
    label: "Colour Id",
    value: "",
    type: "dropdown",
    placeholder: "Colour Id",
    field: "colourId",
    required: false,
    className: "6",
    options: [],
  },
  {
    label: "Silk Colour",
    value: "",
    type: "text",
    placeholder: "Silk Colour",
    field: "silkColour",
    required: false,
    className: "6",
  },
];
