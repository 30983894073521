import React, { PureComponent } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Typography,
  Input,
  Grid,
  InputLabel,
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { ReactSVG } from "react-svg";
import "../betssidebar.scss";

const smallBall = require("../../../images/combat_b.svg");

class MotorRacingSidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "",
      sortOrder: false,
      stakes: null,
    };
  }
  componentDidMount() {
    this.clearodds();
  }

  updateList(item) {
    this.setState({
      stake: "",
      bets: [item],
    });
  }

  handleInputChange = (id) => (event) => {
    const { value } = event.target;
    this.setState({
      stakes: {
        ...this.state.stakes,
        [id]: value,
      },
    });
  };

  handleDelete = (index) => () => {
    const clonedBets = [...this.state.bets];
    clonedBets.splice(index, 1);
    this.setState({ bets: clonedBets });
  };

  handleAllBetChange = (event) => {
    const { value } = event.target;
    const stakes = {};

    this.state.bets.forEach((item) => {
      stakes[item.id] = value;
    });

    this.setState({ stakes });
  };

  stakeChange(e) {
    this.setState({ stake: e.target.value });
  }
  clearodds = () => {
    this.setState({ stake: "", bets: [] });
  };

  handleHorseChange = (event) => {
    var val = event.target.value;
    this.setState({ selectedHorse: val });
  };

  showBookmarkRow = (bookmark, bet) => {
    const { stakes } = this.state;
    let RunnerOdd = bet?.RunnerOdds
      ? bet?.RunnerOdds?.filter((RO) => RO.Key === bookmark?.key)
      : [];
    let wins =
      RunnerOdd?.length > 0
        ? Number(RunnerOdd[0].FixedWin).toFixed(2)
        : Number(bookmark?.wins).toFixed(2);
    if (RunnerOdd?.length > 0) {
      return (
        <div className="box bookmark-item" key={bet}>
          <div className="bookmark-logo">
            {bookmark.logo !== "" ? (
              bookmark.link !== "" ? (
                <img
                  src={bookmark.logo}
                  onClick={() => window.open(bookmark.link, "_blank")}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img src={bookmark.logo} alt="" />
              )
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </div>
          <div className="bookmark-item-values-wrap text-left">
            <div className="bookmark-item-values">
              <div className="innerbox inner-center">
                <div className="biv-item">
                  {bookmark.enabled === true && wins}
                </div>
              </div>
              {bookmark.enabled === true && (
                <>
                  {stakes && stakes[bet.id] ? (
                    <div className="innerbox inner-center">
                      <div className="biv-item">
                        {bookmark.enabled === true &&
                          Number(wins * stakes[bet.id]).toFixed(2)}
                      </div>
                    </div>
                  ) : (
                    <div className="innerbox">
                      <div className="biv-item"></div>
                      <div className="biv-item"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="betnow-button">
            <Button
              variant="contained"
              className="btn"
              onClick={(e) => {
                console.log("hello clicked event!");
              }}
              type="submit"
              component="button"
            >
              Bet Now
            </Button>
          </div>
        </div>
      );
    }
  };

  toggleSorting = (by) => {
    this.setState((prevState) => {
      return { sortBy: by, sortOrder: !prevState.sortOrder };
    });
  };

  renderBetContent = (bet, index) => {
    const { stakes, bookmarks, sortBy, sortOrder } = this.state;
    let filtered_bookmarks = bookmarks;
    if (bookmarks?.length > 0) {
      filtered_bookmarks = bookmarks?.map((bItem, i) => {
        let RunnerOdd = bet?.RunnerOdds
          ? bet?.RunnerOdds?.filter((RO) => RO.Key === bItem?.key)
          : [];
        let wins =
          RunnerOdd?.length > 0
            ? Number(RunnerOdd[0].FixedWin).toFixed(2)
            : Number(bItem?.wins).toFixed(2);

        return { ...bItem, wins: wins };
      });
    }

    if (sortBy !== "") {
      if (sortBy === "wins") {
        filtered_bookmarks = filtered_bookmarks.sort((a, b) =>
          (sortOrder ? a.wins > b.wins : a.wins < b.wins) ? 1 : -1
        );
      } else {
      }
    }

    return (
      <div
        className="bets_bookmark_content"
        key={"bets_bookmark_content" + index}
      >
        <Grid
          container
          spacing={2}
          key={0}
          className="box racing-details text-left"
        >
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography variant="body1">{"leauge name"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{"Event Description"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{"Event OutcomeAt"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{"Market Market"}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className="bet-row-item box racing-bet-middle text-left"
        >
          <Grid item xs={12}>
            <ReactSVG className="horse_img" src={smallBall} />
            <p style={{ margin: 0, paddingLeft: 50 }}>{"Outcome Team"}</p>
          </Grid>
          <Grid item lg={6} md={5} sm={5} xs={5}></Grid>
          <Grid item lg={6} md={5} sm={5} xs={5}>
            <div className="stackinput full-width">
              <InputLabel htmlFor="stake">Stake $</InputLabel>
              <Input
                inputProps={{ "aria-label": "stake" }}
                id="stake"
                name="stake"
                type="number"
                value={
                  (stakes && stakes[bet?.id]) !== null ? stakes[bet?.id] : ""
                }
                onChange={this.handleInputChange(bet.id)}
              />
            </div>
          </Grid>
        </Grid>
        <div className="bookmarks-list">
          <Grid container className="box main-header text-left">
            <Grid item sm={4} xs={3}></Grid>
            <Grid item sm={2} xs={2} className="title-odds">
              Odds
            </Grid>
            {stakes && stakes[bet.id] ? (
              <>
                <Grid item sm={6} xs={6} className="text-right">
                  Potential Winningsssssss
                </Grid>
              </>
            ) : (
              <Grid item sm={6} xs={6} className="text-right"></Grid>
            )}
          </Grid>
          <Grid container className="box text-left header">
            <Grid item sm={4} xs={4}>
              Bookmaker
            </Grid>
            <Grid item sm={8} xs={8}>
              <Grid container className="bookmark-item-values" spacing={0}>
                <Grid item sm={6} xs={6}>
                  <span onClick={() => this.toggleSorting("wins")}>
                    Win
                    <span>
                      <ImportExportIcon />
                    </span>
                  </span>
                </Grid>
                {stakes && stakes[bet.id] ? (
                  <>
                    <Grid item sm={6} xs={6} className="win-stake">
                      Win
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {filtered_bookmarks.map((bookmark, index) => (
            <>{this.showBookmarkRow(bookmark, bet)}</>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { bets } = this.props;

    return (
      <>
        <Card className="sidebar-card" variant="outlined">
          <CardHeader title="Odds - Motor Racing" />
          <CardContent className="rm-padding-i">
            {bets.length > 0 ? (
              <>{bets.map((bet, index) => this.renderBetContent(bet, index))}</>
            ) : (
              <Typography
                className="color-white bg-alert no-bets-msg"
                variant="body2"
              >
                Click compare to see the best odds.
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant={bets.length > 0 ? "contained" : "outlined"}
              className="btn-link btn-block btn-clear-odds"
              onClick={this.clearodds}
            >
              Clear Odds Slip
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}
export default MotorRacingSidebar;
