import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";

import FileUploader from "../../library/common/components/FileUploader";
import { URLS } from "../../library/common/constants";
import axiosInstance from "../../helpers/Axios";

export default class Media extends Component {
  state = {
    files: [],
    urls: [],
    anchorElement: null,
  };

  componentDidMount() {
    this.fetchAllMedia();
  }

  async fetchAllMedia() {
    const { status, data } = await axiosInstance.get(URLS.media);
    if (status === 200) {
      this.setState({ urls: data });
    }
  }

  handleMediaUpload = (files) => {
    this.setState({ files: [...this.state.files, ...files] });
  };

  copyPath = (file) => (event) => {
    try {
      this.setState({ anchorElement: event.currentTarget });
      navigator.clipboard.writeText(file.preview);
    } catch (err) {
      alert("An error occurred while copying");
    }
  };

  handleClose = () => {
    this.setState({ anchorElement: null });
  };

  imageErrorHandler = (event) => {
    event.target.src =
      "https://via.placeholder.com/150?text=Preview+Not+Available";
  };

  renderImages(files, fieldName) {
    const { anchorElement } = this.state;
    return (
      <>
        {files.map((file, index) => (
          <>
            <img
              key={index}
              src={file[fieldName]}
              alt="file"
              className="media-files"
              onClick={this.copyPath(file)}
              onError={this.imageErrorHandler}
            />
            <Popover
              id={`image-popover-${index + 1}`}
              open={anchorElement && anchorElement}
              anchorEl={anchorElement}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <p className="copied-url">File URL copied to clipboard</p>
            </Popover>
          </>
        ))}
      </>
    );
  }

  render() {
    const { files, urls } = this.state;

    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            <h3 className="text-left">Media</h3>

            <Grid item xs={12}>
              {/* <FileUploader multiple onDrop={this.handleMediaUpload} /> */}
              <p className="text-left">
                <em>Note: Click on file to copy the path</em>
              </p>
            </Grid>

            <Grid item xs={12}>
              {this.renderImages(urls, "url")}
            </Grid>
            <Grid item xs={12}>
              {this.renderImages(files, "preview")}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
