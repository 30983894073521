import React, { Component } from "react";
import ApiService from "../../services/ApiService";
import {
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

import "./contactus.scss";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { EventEmitter } from "../../services/event";
import Loader from "../../library/common/components/Loader";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      message: "",
      errors: {},
      isLoading: false,
      pageData: [],
      isAlertOpen: false,
      apiMessage: "",
      messageType: "success",
      isLoading: false,
      isFormLoading: false,
    };
    this.ApiService = new ApiService();
  }

  componentDidMount() {
    this.fetchPageData();
  }

  async fetchPageData() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(
      "/cms/page/detail/contactus"
    );
    if (status === 200) {
      this.setState({ pageData: data.result, isLoading: false });
      EventEmitter.dispatch("setPageMeta", data.tags);
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isAlertOpen: false });
  };

  submit = () => {
    let {
      email,
      firstname,
      lastname,
      errors,
      message,
      additional_details,
    } = this.state;
    errors.email = "";
    errors.firstname = "";
    errors.lastname = "";
    var validflag = true;
    if (email === "") {
      errors.email = "Email is required";
      validflag = false;
    } else {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexp.test(email)) {
        errors.email = "Invalid Email";
        validflag = false;
      }
    }
    if (firstname === "") {
      errors.firstname = "Firstname is required";
      validflag = false;
    }
    if (lastname === "") {
      errors.lastname = "Lastname is required";
      validflag = false;
    }
    if (message === "") {
      errors.message = "Message is required";
      validflag = false;
    }
    this.setState((prevState) => {
      return { errors: { ...prevState.errors, errors } };
    });

    if (validflag) {
      this.setState({ isFormLoading: true });
      const data = {
        email: email,
        firstname: firstname,
        lastname: lastname,
        message: message,
        additional_details: additional_details,
      };
      axiosInstance
        .post(URLS.contact, data)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({
              isFormLoading: false,
              isAlertOpen: true,
              apiMessage: "Message sent successfully!",
              messageType: "success",
              email: "",
              firstname: "",
              lastname: "",
              message: "",
              additional_details: "",
            });
          }
        })
        .catch((err) => {
          this.setState({ isFormLoading: false });
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                errors: {
                  ...prevState.errors,
                  errors,
                },
                isAlertOpen: true,
                apiMessage: "Error Occured while sending message",
                messageType: "error",
              };
            });
          } else {
            errors.email = err.response.data.message;
            this.setState({
              errors,
              isAlertOpen: true,
              apiMessage: "Error Occured while sending message",
              messageType: "error",
            });
          }
        });

      // const res = this.ApiService.contact(data)
      // if(!res){
      //   // console.log(res);
      //   // this.setState((prevState) => {return {error:{...prevState.error, username:'Invalid Login'}}});
      // }
    } else {
      console.error("Invalid Form");
    }
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, errors: { ...prevState.errors, [name]: "" } };
    });
  };

  render() {
    const {
      errors,
      isLoading,
      isFormLoading,
      pageData,
      email,
      firstname,
      lastname,
      message,
      additional_details,
    } = this.state;
    let headerData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Header"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    let bodyData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Body"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    return (
      <>
        <Container className='page-banner'>
          <Grid
            container
            justify='center'
            className='contact-banner'
            alignItems='flex-start'
          >
            <Typography
              variant='h5'
              className='title contact-title mobile-heading text-center color-blue text-upper'
            >
              {pageData?.pageTitle}
            </Typography>
            <div
              variant='body1'
              className='text-center cms_contactus'
              dangerouslySetInnerHTML={{
                __html: headerData?.length > 0 && headerData[0]?.content,
              }}
            ></div>
          </Grid>
        </Container>

        <Container
          maxWidth={"false"}
          className='contact-form-wrap contactus-form-mb mt-50'
        >
          <div className='text-left '>
            <Container maxWidth={"lg"}>
              <div className='contact-form-outer'>
                <Grid container>
                  <Grid
                    item
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    className='contact-form-inner-wrap'
                  >
                    <form
                      className='contact-form form'
                      noValidate
                      autoComplete='off'
                    >
                      <Grid
                        container
                        spacing={3}
                        justify='flex-end'
                        alignItems='flex-end'
                      >
                        <Grid item sm={6} xs={12} className='text-left'>
                          <FormControl>
                            <InputLabel shrink htmlFor='First Name'>
                              First Name
                            </InputLabel>
                            <Input
                              className={" rounded-input"}
                              inputProps={{}}
                              value={firstname}
                              id='firstname'
                              name='firstname'
                              disableUnderline
                              onChange={this.handleChange}
                            />
                            {errors.firstname && (
                              <span className='error'>{errors.firstname}</span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12} className='text-left'>
                          <FormControl>
                            <InputLabel shrink htmlFor='Last Name'>
                              Last Name
                            </InputLabel>
                            <Input
                              className={" rounded-input"}
                              inputProps={{}}
                              value={lastname}
                              id='lastname'
                              name='lastname'
                              disableUnderline
                              onChange={this.handleChange}
                            />
                            {errors.lastname && (
                              <span className='error'>{errors.lastname}</span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12} className='text-left'>
                          <FormControl>
                            <InputLabel shrink htmlFor='Email Address'>
                              Email
                            </InputLabel>
                            <Input
                              className={" rounded-input"}
                              inputProps={{}}
                              value={email}
                              id='email'
                              name='email'
                              disableUnderline
                              onChange={this.handleChange}
                            />
                            {errors.email && (
                              <span className='error'>{errors.email}</span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12} className='text-left'>
                          <FormControl>
                            <InputLabel shrink htmlFor='Message'>
                              Message
                            </InputLabel>
                            <Input
                              multiline
                              className={" rounded-input"}
                              inputProps={{}}
                              value={message}
                              id='message'
                              name='message'
                              disableUnderline
                              onChange={this.handleChange}
                            />
                            {errors.message && (
                              <span className='error'>{errors.message}</span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12} className='text-left'>
                          <FormControl>
                            <InputLabel shrink htmlFor='additional_details'>
                              Additional Details
                            </InputLabel>
                            <Input
                              multiline
                              className={" rounded-input"}
                              inputProps={{}}
                              value={additional_details}
                              id='additional_detail'
                              name='additional_details'
                              disableUnderline
                              onChange={this.handleChange}
                              rows={4}
                            />
                            {errors.additional_details && (
                              <span className='error'>
                                {errors.additional_details}
                              </span>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12} className='text-left'>
                          <Button
                            onClick={this.submit.bind(this)}
                            className='submit-btn btn-block'
                            variant='contained'
                          >
                            {isFormLoading ? "Loading.." : "Send Message"}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justify='flex-end'
                        alignItems='flex-end'
                        className='contact-info'
                      >
                        <Grid item sm={12} xs={12} className='text-left'>
                          <strong className='font-weight-500'>
                            Contact Information
                          </strong>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          className='text-left'
                        >
                          <a href='tel:1800668992'>
                            <FaPhoneAlt /> +61 9870 983 322
                          </a>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          className='text-left'
                        >
                          <a href='mailto:contact@motzza.com.au'>
                            <IoMdMail /> contact@motzza.com.au
                          </a>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  {isLoading && <Loader />}
                  <Grid
                    item
                    lg={7}
                    md={6}
                    sm={12}
                    xs={12}
                    className='contact-right-content'
                    dangerouslySetInnerHTML={{
                      __html:
                        bodyData &&
                        bodyData?.length > 0 &&
                        bodyData[0]?.content,
                    }}
                  ></Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <Snackbar
            open={this.state.isAlertOpen}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity={this.state.messageType}>
              {this.state.apiMessage}
            </Alert>
          </Snackbar>
        </Container>
      </>
    );
  }
}

export default Contactus;
