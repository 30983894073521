import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { teamsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let teamsFormModelArray = teamsFormModel;

class CreateTeams extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
      },
      sportId: "",
      leaugueId: "",
      eventId: "",
      state: null,
      stateAll: [],
      country: null,
      countryAll: [],
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentTeam(this.props.id);
    }
    this.fetchAllCountry();
  }

  componentWillUnmount() {
    teamsFormModelArray = teamsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentTeam = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.teams + `/${id}`);
    if (status === 200) {
      this.setState({
        values: data.result,
        country: data.result.country,
        state: data.result.state,
      });
      this.fetchAllState(data.result.country);
    }
  };

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(URLS.country);
    if (status === 200) {
      this.setState({ countryAll: data.result });
    }
  };

  fetchAllState = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.state + `/country/${id}`
    );
    if (status === 200) {
      this.setState({ stateAll: data.result });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name } = this.state.values;
    let { country, state, error } = this.state;
    let flag = true;

    if (name === "" || state === null || country === null) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    if (country === null) {
      error.country = "This field id mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.country = "";
    }

    if (state === null) {
      error.state = "This field is mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.state = "";
    }

    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { country, state } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.teams}/${this.props.id}` : URLS.teams;

      const values = removeErrorFieldsFromValues(form.formData);
      values["country"] = country;
      values["state"] = state;
      teamsFormModelArray = teamsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllTeams();
          this.setActionMessage(
            true,
            "Success",
            `Teams ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} Teams`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    teamsFormModelArray = teamsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "state") {
      this.setState({
        state: value,
        error: { ...this.state.error, ["state"]: "" },
      });
    }
    if (name === "country") {
      this.setState(
        { country: value, error: { ...this.state.error, ["country"]: "" } },
        () => this.fetchAllState(value)
      );
    }
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      countryAll,
      stateAll,
      country,
      state,
      error,
      sportsId,
      leagueId,
      eventId,
    } = this.state;
    var { isEditMode, allSports } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div>
                <label>Sports*</label>
                <select
                  className="select-box-manual"
                  name="sportsId"
                  onChange={this.handleSelect}
                  value={sportsId}
                >
                  <option value="">No Sport Selected</option>
                  {allSports?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.sportName}
                    </option>
                  ))}
                </select>
                {/* {typeof error !== "undefined" && error !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error}
                  </p>
                )} */}
                <label>League*</label>
                <select
                  className="select-box-manual"
                  name="leagueId"
                  onChange={this.handleSelect}
                  value={leagueId}
                >
                  <option value="">No League Selected</option>
                  {/* {allSports?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.sportName}
                    </option>
                  ))} */}
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
                <label>Event*</label>
                <select
                  className="select-box-manual"
                  name="eventId"
                  onChange={this.handleSelect}
                  value={eventId}
                >
                  <option value="">No Event Selected</option>
                  {/* {allSports?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.name}
                    </option>
                  ))} */}
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>

              <Form
                values={values}
                model={teamsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div>
                <label>Country*</label>
                <select
                  className="select-box-manual"
                  name="country"
                  onChange={this.handleSelect}
                  value={country}
                >
                  <option>No Country Selected</option>
                  {countryAll?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.country}
                    </option>
                  ))}
                </select>
                {typeof error.country !== "undefined" &&
                  error.country !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.country}
                    </p>
                  )}
                <label>State*</label>
                <select
                  className="select-box-manual"
                  name="state"
                  onChange={this.handleSelect}
                  value={state}
                >
                  {this.state.country === null ? (
                    <option>Select Country First</option>
                  ) : (
                    <>
                      <option>No State Selected</option>
                      {stateAll?.map((obj, i) => (
                        <option key={i} value={obj.id}>
                          {obj.state}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {typeof error.state !== "undefined" && error.state !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.state}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateTeams;
