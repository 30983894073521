import { Validators } from "../../../library/utilities/Validators";

export const teamsFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  // {
  //   label: "State",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "State",
  //   field: "state",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  //   options: [
  //     { value: "", label: "No State Selected" },
  //     { id: 1, label: "Gujrat", value: 1 },
  //     { id: 2, label: "Maharastra", value: 2 },
  //     { id: 3, label: "Rajsthan", value: 3 },
  //     { id: 4, label: "Delhi", value: 4 },
  //     { id: 5, label: "Tamilnadu", value: 5 },
  //     { id: 6, label: "Bengluru", value: 6 },
  //     { id: 7, label: "Goa", value: 7 },
  //     { id: 8, label: "Kerala", value: 8 },
  //   ],
  // },
  // {
  //   label: "Country",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Country",
  //   field: "country",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  //   options: [
  //     { value: "", label: "No Country Selected" },
  //     { id: 1, label: "India", value: 1 },
  //     { id: 2, label: "Australia", value: 2 },
  //     { id: 3, label: "England", value: 3 },
  //     { id: 4, label: "Newzeland", value: 4 },
  //     { id: 5, label: "UAE", value: 5 },
  //     { id: 6, label: "Dubai", value: 6 },
  //     { id: 7, label: "Shrilanka", value: 7 },
  //     { id: 8, label: "Rasia", value: 8 },
  //   ],
  // },
];
