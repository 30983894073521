import React from "react";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Divider,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { EventEmitter } from "../../../services/event";
import AllRacing from "./AllRacing";
import SingleRacing from "./SingleRacing";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./racing.scss";
import axiosInstance from "../../../helpers/Axios";

import d_horse_racing from "../../../images/all_race_dropdown/horse_racing.svg";
import d_harness_racing from "../../../images/all_race_dropdown/harness_racing.svg";
import d_greyhound_racing from "../../../images/all_race_dropdown/greyhound_racing.svg";
import { ReactSVG } from "react-svg";

const sportsList = [
  { name: "All Racing" },
  { name: "Horse Racing" },
  { name: "Greyhounds Racing" },
  { name: "Harness Racing" },
];

const styles = (theme) => ({
  dropdownStyle: {
    borderRadius: "5px",
    width: 251,
    height: 163,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderRadius: "10px 10px 0px 0px",
    },
  },
});

class AllSportsAllRacing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRace: "",
      selectedCountry: "",
      selectedLocation: "",
      selectedEvent: "",
      raceTrackClicked: false,
      raceTrackLists: [],
      singleRaceData: [],
      trackNumber: 0,
      countryList: [],
      stateList: [],
      raceTrackdata: "",
    };
    EventEmitter.subscribe("show_to_race", this.showToRace);
    EventEmitter.subscribe("racetrack_clicked", this.showRaceTrack);
    EventEmitter.subscribe("three_race_clicked", this.threeRaceClickUpdate);
  }

  componentDidMount = () => {
    let { title, raceTrackClicked, raceTrackdata } = this.props;
    this.fetchAllCountry();
    this.setState({
      selectedRace: title !== undefined ? title : sportsList[0].name,
      raceTrackClicked:
        raceTrackClicked !== undefined
          ? raceTrackClicked
          : this.state.raceTrackClicked,
      raceTrackdata:
        raceTrackdata !== undefined ? raceTrackdata : this.state.raceTrackdata,
      selectedCountry: "none",
      selectedLocation: "none",
    });
    if (raceTrackClicked) {
      this.showRaceTrack(this.props.allData);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.raceTrackClicked !== this.props.raceTrackClicked) {
      this.setState({ raceTrackClicked: this.props.raceTrackClicked });
    }
  };

  handleSelectedRaceIcon = (race) => {
    switch (race) {
      case "All Racing":
        return d_horse_racing;
      case "Horse Racing":
        return d_horse_racing;
      case "Greyhounds Racing":
        return d_greyhound_racing;
      case "Harness Racing":
        return d_harness_racing;
      default:
        return;
    }
  };

  threeRaceClickUpdate = (item) => {
    this.setState(
      {
        selectedRace: item?.title,
        raceTrackClicked: item?.raceTrackClicked,
        raceTrackdata: item?.full_race,
      },
      () => {
        if (this.state.raceTrackClicked) {
          this.showRaceTrack(item);
        }
      }
    );
  };

  fetchAllCountry = async () => {
    try {
      const { status, data } = await axiosInstance.get(`countries/country`);
      if (status === 200) {
        this.setState({
          countryList: data?.result,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchAllState = async (countryId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `countries/state/country/${countryId}`
      );
      if (status === 200) {
        this.setState({
          stateList: data?.result,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  showToRace = (race) => {
    this.setState({ selectedRace: race });
  };

  showRaceTrack = (race) => {
    this.setState({
      raceTrackClicked:
        race?.raceTrackClicked !== undefined
          ? race?.raceTrackClicked
          : this.state.raceTrackClicked,
      raceTrackLists: race?.full_race?.race,
      trackNumber: race?.selectedRaceTrack,
      singleRaceData: race?.singleRaceData,
      selectedEvent: race?.full_race?.id,
    });
  };

  handleChange = (e) => {
    if (e.target.value === "All Racing") {
      this.props.fetchThreeRacing(
        "1,2,3",
        this.state.selectedCountry,
        this.state.selectedLocation,
        true
      );
    }
    this.setState(
      { selectedRace: e.target.value, raceTrackClicked: false },
      () => this.setState({ selectedCountry: "none", selectedLocation: "none" })
    );
    EventEmitter.dispatch("hide_raceclicked", false);
    EventEmitter.dispatch("resetbetsList");
    EventEmitter.dispatch("change_race_clicked", false);
    EventEmitter.dispatch("clear_date_filter");
  };
  handleCountry = (e) => {
    this.setState(
      { selectedCountry: e.target.value, selectedLocation: "none" },
      () => {
        this.fetchAllState(this.state.selectedCountry);
        if (this.state.selectedRace === "All Racing") {
          this.props.fetchThreeRacing(
            "1,2,3",
            this.state.selectedCountry,
            this.state.selectedLocation,
            true
          );
        }
      }
    );
  };
  handleLocation = (e) => {
    this.setState({ selectedLocation: e.target.value }, () => {
      if (this.state.selectedRace === "All Racing") {
        this.props.fetchThreeRacing(
          "1,2,3",
          this.state.selectedCountry,
          this.state.selectedLocation,
          true
        );
      }
    });
  };
  handleChangeTrack = (e, newValue) => {
    let { selectedEvent, singleRaceData, trackNumber } = this.state;
    let selected_obj = singleRaceData?.find((obj) => obj?.id === selectedEvent);
    selected_obj = selected_obj?.race;
    let race_data = selected_obj?.find((obj) => obj?.id === newValue);
    if (newValue !== trackNumber) {
      EventEmitter.dispatch("check_race_ispast", race_data);
      EventEmitter.dispatch(
        "Race_time_restart",
        new Date(race_data.startTimeDate).getTime()
      );
    }
    this.setState({ trackNumber: newValue, raceTrackdata: race_data });
    EventEmitter.dispatch("resetbetsList");
  };
  handleChangeEvent = (e) => {
    let { singleRaceData } = this.state;
    let data_obj = singleRaceData?.find((obj) => obj?.id === e.target.value);
    let race_data = data_obj?.race?.length > 0 ? data_obj?.race[0] : "";
    EventEmitter.dispatch("check_race_ispast", race_data);
    EventEmitter.dispatch("resetbetsList");
    this.setState({
      selectedEvent: e.target.value,
      raceTrackLists: data_obj?.race,
      trackNumber:
        data_obj?.race?.length > 0
          ? data_obj?.race[0]?.id !== undefined
            ? data_obj?.race[0]?.id
            : null
          : null,
      raceTrackdata: race_data,
    });
  };

  selectedRacing = (race) => {
    let {
      horseRaceData,
      harnessRaceData,
      greyhoundRaceData,
      selectedDate,
      isRaceLoading,
    } = this.props;
    let {
      selectedCountry,
      selectedLocation,
      trackNumber,
      raceTrackdata,
      selectedEvent,
      singleRaceData,
      raceTrackClicked,
    } = this.state;
    let selected_event = singleRaceData?.find(
      (obj) => obj?.id === selectedEvent
    );
    switch (race) {
      case "All Racing":
        return (
          <AllRacing
            horseRaceData={horseRaceData}
            harnessRaceData={harnessRaceData}
            greyhoundRaceData={greyhoundRaceData}
            isRaceLoading={isRaceLoading}
            fetchRace={this.props.fetchRace}
            fetchThreeRacing={this.props.fetchThreeRacing}
            isThreeRaceLoading={this.props.isThreeRaceLoading}
            selectedCountry={selectedCountry}
            selectedLocation={selectedLocation}
          />
        );
      case "Horse Racing":
        return (
          <SingleRacing
            title={"Horse Racing"}
            selectedCountry={selectedCountry}
            selectedLocation={selectedLocation}
            selectedDate={selectedDate}
            isRaceLoading={isRaceLoading}
            trackNumber={trackNumber}
            raceTrackdata={raceTrackdata}
            selected_event={selected_event}
            raceTrackClicked={raceTrackClicked}
          />
        );
      case "Greyhounds Racing":
        return (
          <SingleRacing
            title={"Greyhounds Racing"}
            selectedCountry={selectedCountry}
            selectedLocation={selectedLocation}
            selectedDate={selectedDate}
            isRaceLoading={isRaceLoading}
            trackNumber={trackNumber}
            raceTrackdata={raceTrackdata}
            selected_event={selected_event}
            raceTrackClicked={raceTrackClicked}
          />
        );
      case "Harness Racing":
        return (
          <SingleRacing
            title={"Harness Racing"}
            selectedCountry={selectedCountry}
            selectedLocation={selectedLocation}
            selectedDate={selectedDate}
            isRaceLoading={isRaceLoading}
            trackNumber={trackNumber}
            raceTrackdata={raceTrackdata}
            selected_event={selected_event}
            raceTrackClicked={raceTrackClicked}
          />
        );
      default:
        return "";
    }
  };

  render() {
    let {
      selectedRace,
      selectedCountry,
      selectedLocation,
      raceTrackClicked,
      raceTrackLists,
      trackNumber,
      countryList,
      stateList,
      singleRaceData,
      selectedEvent,
    } = this.state;
    let { classes } = this.props;
    return (
      <>
        <Grid
          container
          className="allsports_head hide-xs hide-sm"
          style={{
            marginTop: 10,
            marginBottom: 9,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={9}
            sm={12}
            xs={12}
            className="allsports_head_left"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl style={{ minWidth: "auto", marginRight: 15 }}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                className={
                  selectedRace === "All Racing"
                    ? "sports_select racing-select hideallracicn"
                    : "sports_select racing-select"
                }
                IconComponent={KeyboardArrowDownIcon}
                value={selectedRace}
                onChange={this.handleChange}
                style={{
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                }}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {sportsList.map((obj, i) => (
                  <MenuItem
                    key={i}
                    value={obj.name}
                    style={{
                      color: "#707070",
                      fontSize: 15,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                    className={
                      selectedRace === obj.name
                        ? "selected_raceoption"
                        : "normal_raceoption"
                    }
                  >
                    <ReactSVG
                      className="selected_racesvg"
                      style={{ width: 35 }}
                      src={this.handleSelectedRaceIcon(obj.name)}
                    />
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {raceTrackClicked && (
              <>
                <FormControl
                  style={{ display: "block", minWidth: 89, marginRight: 15 }}
                >
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="race_events"
                    className="sports_select racing-event-select racing-select"
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedEvent}
                    onChange={this.handleChangeEvent}
                    style={{
                      fontWeight: 400,
                      color: "#000",
                      fontFamily: "Open Sans",
                      fontSize: 14,
                    }}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    {singleRaceData?.length > 0 &&
                      singleRaceData.map((obj, i) => (
                        <MenuItem
                          style={{
                            color: "#707070",
                            fontSize: 15,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                          key={i}
                          className={
                            selectedEvent === obj?.id
                              ? "selected_raceoption"
                              : "normal_raceoption"
                          }
                          value={obj?.id}
                        >
                          {obj?.eventName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <AppBar
                  className="racing-track-tabbar"
                  position="static"
                  color="default"
                >
                  <Toolbar>
                    <Tabs
                      value={trackNumber}
                      onChange={this.handleChangeTrack}
                      variant="scrollable"
                      scrollButtons="off"
                    >
                      {raceTrackLists?.length > 0 &&
                        raceTrackLists?.map((obj, i) => (
                          <Tab
                            style={{ fontFaily: "Open Sans" }}
                            label={`${i + 1}`}
                            key={i}
                            value={obj?.id}
                            disabled={obj?.id === undefined ? true : false}
                          />
                        ))}
                    </Tabs>
                  </Toolbar>
                </AppBar>
              </>
            )}
          </Grid>

          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className="allsports_head_right"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedCountry}
                className="select-country"
                onChange={this.handleCountry}
                style={{
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                }}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <MenuItem
                  style={{
                    color: "#707070",
                    fontSize: 15,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                  }}
                  value="none"
                >
                  Country
                </MenuItem>
                {countryList?.length > 0 &&
                  countryList.map((obj, i) => (
                    <MenuItem
                      style={{
                        color: "#707070",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                      className={
                        selectedCountry === obj?.id
                          ? "selected_raceoption"
                          : "normal_raceoption"
                      }
                      key={i}
                      value={obj?.id}
                    >
                      {obj?.country}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedLocation}
                className="select-location"
                onChange={this.handleLocation}
                style={{
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                }}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <MenuItem
                  style={{
                    color: "#707070",
                    fontSize: 15,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                  }}
                  value="none"
                >
                  State
                </MenuItem>
                {stateList?.length > 0 &&
                  stateList.map((obj, i) => (
                    <MenuItem
                      style={{
                        color: "#707070",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                      className={
                        selectedLocation === obj?.id
                          ? "selected_raceoption"
                          : "normal_raceoption"
                      }
                      key={i}
                      value={obj?.id}
                    >
                      {obj?.state}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          className="allsports_head hide-md hide-lg hide-xl"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="allsports_head_left allsport_mobile_head_allracing"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControl>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                className={
                  selectedRace === "All Racing"
                    ? "sports_select racing-select hideallracicn"
                    : "sports_select racing-select"
                }
                IconComponent={KeyboardArrowDownIcon}
                value={selectedRace}
                onChange={this.handleChange}
                style={{
                  fontSize: 12,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                }}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {sportsList.map((obj, i) => (
                  <MenuItem
                    key={i}
                    value={obj.name}
                    style={{
                      color: "#707070",
                      fontSize: 15,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                    className={
                      selectedRace === obj.name
                        ? "selected_raceoption"
                        : "normal_raceoption"
                    }
                  >
                    <ReactSVG
                      className="selected_racesvg"
                      style={{ width: 35 }}
                      src={this.handleSelectedRaceIcon(obj.name)}
                    />
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className="hide-md hide-lg hide-xl">
            <Divider className="allsports_hr" style={{ width: "100%" }} />
          </Grid>

          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            className="allsports_head_right"
            style={{
              display: "flex",
              justifyContent: raceTrackClicked ? "space-between" : "flex-start",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            {raceTrackClicked && (
              <div>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="race_events"
                  className="sports_select racing-event-select racing-select"
                  IconComponent={KeyboardArrowDownIcon}
                  value={selectedEvent}
                  onChange={this.handleChangeEvent}
                  style={{
                    fontSize: 12,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {singleRaceData?.length > 0 &&
                    singleRaceData.map((obj, i) => (
                      <MenuItem
                        key={i}
                        value={obj?.id}
                        className={
                          selectedEvent === obj?.id
                            ? "selected_raceoption"
                            : "normal_raceoption"
                        }
                      >
                        {obj?.track?.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
            {selectedRace === "All Racing" ? null : (
              <div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  IconComponent={KeyboardArrowDownIcon}
                  value={selectedCountry}
                  className="select-country"
                  onChange={this.handleCountry}
                  style={{
                    fontSize: 12,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    marginRight: 15,
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem
                    style={{
                      color: "#707070",
                      fontSize: 15,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                    value="none"
                    className="normal_raceoption"
                  >
                    Country
                  </MenuItem>
                  {countryList?.length > 0 &&
                    countryList.map((obj, i) => (
                      <MenuItem
                        style={{
                          color: "#707070",
                          fontSize: 15,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                        key={i}
                        className={
                          selectedCountry === obj?.id
                            ? "selected_raceoption"
                            : "normal_raceoption"
                        }
                        value={obj?.id}
                      >
                        {obj?.country}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
            {selectedRace === "All Racing" ? null : (
              <div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  IconComponent={KeyboardArrowDownIcon}
                  value={selectedLocation}
                  className="select-location"
                  onChange={this.handleLocation}
                  style={{
                    fontSize: 12,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem
                    style={{
                      color: "#707070",
                      fontSize: 15,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                    value="none"
                    className="normal_raceoption"
                  >
                    State
                  </MenuItem>
                  {stateList?.length > 0 &&
                    stateList.map((obj, i) => (
                      <MenuItem
                        style={{
                          color: "#707070",
                          fontSize: 15,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                        className={
                          selectedLocation === obj?.id
                            ? "selected_raceoption"
                            : "normal_raceoption"
                        }
                        key={i}
                        value={obj?.id}
                      >
                        {obj?.state}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
          </Grid>

          {raceTrackClicked && (
            <>
              <Grid item xs={12} className="hide-md hide-lg hide-xl">
                <Divider className="allsports_hr" style={{ width: "100%" }} />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className="allsports_head_left d-flex-center allsport_mobile_head_allracing"
              >
                <AppBar
                  className="racing-track-tabbar"
                  position="static"
                  color="default"
                >
                  <Toolbar>
                    <Tabs
                      value={trackNumber}
                      onChange={this.handleChangeTrack}
                      variant="scrollable"
                      scrollButtons="off"
                    >
                      {raceTrackLists?.length > 0 &&
                        raceTrackLists?.map((obj, i) => (
                          <Tab
                            style={{ fontFaily: "Open Sans" }}
                            label={`${i + 1}`}
                            key={i}
                            value={obj?.id}
                            disabled={obj?.id === undefined ? true : false}
                          />
                        ))}
                    </Tabs>
                  </Toolbar>
                </AppBar>
              </Grid>
            </>
          )}
        </Grid>
        {/* {selectedRace === "All Racing" ? null : (
          <Divider className="allsports_hr" />
        )} */}
        <Divider className="allsports_hr hide-xs hide-sm" />

        {this.selectedRacing(selectedRace)}
      </>
    );
  }
}
AllSportsAllRacing.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AllSportsAllRacing);
