import React, { PureComponent } from "react";
import { List, ListItem, Typography } from "@material-ui/core";

import "./logolists.scss";
class LogoLists extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, logos, placeholder } = this.props;
    return (
      <>
        <div>
          <div className="text-left logolist">
            {typeof title !== "undefined" && title !== "" ? (
              <Typography className="logolist-title" variant="subtitle2">
                {title}
              </Typography>
            ) : (
              ""
            )}
            {typeof logos !== "undefined" && logos.length > 0 ? (
              <div className="logo-box-container">
                <List className="horizontal-list with-rightspace">
                  {logos.map((logo, i) => (
                    <ListItem key={i} disableGutters>
                      <a
                        href={logo?.link ? logo.link : "#"}
                        target="_blank"
                        rel="noopener noreferrer">
                        <div className="logo-box noborder" key={logo}>
                          {logo.type === "url" ? (
                            <img
                              src={logo.content}
                              className="item-logo"
                              alt=""
                            />
                          ) : (
                            <div className="logo-string">{logo.content}</div>
                          )}
                        </div>
                      </a>
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : typeof placeholder !== "undefined" && placeholder !== "" ? (
              // <img src={placeholder} alt="Add Logo Here" />
              <div className="logo-box-container">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                  <div className="logo-box" key={item}>
                    Logo
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default LogoLists;
