import { storeConstants } from "../constants/StoreConstants";

const initialState = {
  tags: [],
};

const MetaReducer = (state = initialState, action) => {
  if (action.type === storeConstants.UPDATE_META) {
    return {
      ...state,
      tags: action.payload,
    };
  }
  return state;
};

export default MetaReducer;
