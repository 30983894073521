import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Sidebar from "./sidebar/sidebar";
import DefaultHeader from "./DefaultHeader";

export class DefaultLayout extends Component {
  render() {
    return (
      <div className="mainLayout">
        <DefaultHeader />
        <Grid container>
          <Grid container item xs={2}>
            <Sidebar />
          </Grid>
          <Grid container className="pageInfo" item xs={10}>
            {this.props.children}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    user: authReducer.token,
    isToggle: authReducer.isToggle,
    permission: authReducer.token.privileges,
    translation: authReducer.translation,
  };
};

export default connect(mapStateToProps, {})(DefaultLayout);
