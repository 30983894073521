import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { blogFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import FileUploader from "../../../library/common/components/FileUploader";
import ActionMessage from "../../../library/common/components/ActionMessage";
import {
  slugify,
  removeErrorFieldsFromValues,
} from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { config } from "../../../helpers/config";
import { URLS } from "../../../library/common/constants/UrlConstants";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Moment from "moment";

export default class CreateBlog extends Component {
  formRef = createRef();

  state = {
    values: { title: null, slug: "", content: "", excerpt: "" },
    files: [],
    thumbnail: [],
    isEditMode: false,
    selectedDate: null,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
  };

  componentDidMount() {
    if (this.props.match.url.includes("edit")) {
      this.fetchCurrentAd(this.props.match.params.id);
      this.setState({ isEditMode: true });
    }
  }

  fetchCurrentAd = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.page + `/${id}`);
    if (status === 200) {
      this.setState({ values: data });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  handleChange = (field, value) => {
    if (field === "pagename") {
      const slug = slugify(value);
      this.setState({
        values: { ...this.state.values, slug, pagename: value },
      });
      this.setActionMessage(false);
    }
  };

  handleFileUpload = (name, files) => {
    this.setState({ [name]: files });
  };

  handleSave = async () => {
    const { isEditMode, files, thumbnail, selectedDate } = this.state;
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.page}/${this.props.match.params.id}`
        : URLS.page;

      const values = removeErrorFieldsFromValues(form.formData);
      values["type"] = "post";

      if (form.isFormValid) {
        if (files.length > 0) {
          let fileData = await this.setMedia(files[0]);
          if (fileData) {
            values["url"] = fileData?.image?.filePath;
          }
        }
        if (thumbnail.length > 0) {
          let thumbnailfileData = await this.setMedia(thumbnail[0]);
          if (thumbnailfileData) {
            values["thumbnail"] = thumbnailfileData?.image?.filePath;
          }
        }

        if (selectedDate !== null) {
          values["publishDate"] = Moment(selectedDate).format("YYYY-MM-DD");
        }
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setActionMessage(
            true,
            "Success",
            `Blog ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} blog`
      );
    }
  };

  setMedia = async (files) => {
    const formData = new FormData();
    formData.append("image", files ? files : null);
    if (files !== undefined) {
      const { status, data } = await axiosInstance.post(URLS.media, formData, {
        header: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    }
    return false;
  };

  back = () => {
    this.props.history.push("/admin/blog");
  };

  handleDateChange = (date) => {
    let selectedDate = date;
    this.setState({ selectedDate: selectedDate });
  };

  render() {
    const { values, files, thumbnail, messageBox, selectedDate } = this.state;

    return (
      <Grid container className="page-content adminLogin text-left">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <h3 className="text-left">Create New Blog</h3>

            <Form
              values={values}
              model={blogFormModel}
              ref={this.formRef}
              onChange={this.handleChange}
            />

            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Publish Date"
                  value={selectedDate}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="blog-file-upload">
              <h2>Post Image</h2>
              <FileUploader
                onDrop={(files) => this.handleFileUpload("files", files)}
              />
              {files?.length > 0
                ? files.map((file, index) => (
                    <img key={index} src={file.preview} alt="file" />
                  ))
                : values?.url &&
                  values?.url !== "" && (
                    <img src={config.baseUrl + values?.url} alt="file" />
                  )}
            </div>
            <div className="blog-file-upload">
              <h2>Thumbnail</h2>
              <FileUploader
                onDrop={(files) => this.handleFileUpload("thumbnail", files)}
              />
              {thumbnail?.length > 0
                ? thumbnail.map((file, index) => (
                    <img key={index} src={file.preview} alt="file" />
                  ))
                : values?.thumbnail &&
                  values?.thumbnail !== "" && (
                    <img src={config.baseUrl + values?.thumbnail} alt="file" />
                  )}
            </div>

            <Grid container>
              <Grid item xs={3}>
                <div style={{ marginTop: "20px" }}>
                  <ButtonComponent
                    className="mt-3 admin-btn-green"
                    onClick={this.handleSave}
                    color="primary"
                    value="Save"
                  />

                  <ButtonComponent
                    onClick={this.back}
                    className="mr-lr-30"
                    value="Back"
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
