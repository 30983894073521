import React, { Component } from "react";
import "./forgotpassword.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import ApiService from "../../services/ApiService";
import loginbg from "../../images/forgotbg.png";
import logo from "../../images/logo.svg";
import forgotpassMBG from "../../images/forgotpasswordMBG.png";
import axiosInstance from "../../helpers/Axios";
import { connect } from "react-redux";
import { setAuthentication } from "../../library/common/actions";
import { URLS } from "../../library/common/constants";
import { PAGEMETASVALUES } from "../../helpers/constants";
import { EventEmitter } from "../../services/event";
import CircularProgress from "@material-ui/core/CircularProgress";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      email: "",
      errors: {},
      isLoading: false,
    };
    this.ApiService = new ApiService();
  }

  componentDidMount = () => {
    PAGEMETASVALUES?.forgotpwd &&
      EventEmitter.dispatch("setPageMeta", [PAGEMETASVALUES?.forgotpwd]);
  };

  submit = () => {
    let { email, errors } = this.state;
    errors.email = "";
    var validflag = true;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === "") {
      errors.email = "Please complete this field";
      validflag = false;
    } else if (!regexp.test(email)) {
      errors.email = "Invalid Email";
      validflag = false;
    } else {
      errors.email = "";
    }
    this.setState((prevState) => {
      return { errors: { ...prevState.errors, errors } };
    });

    if (validflag) {
      this.setState({ isLoading: true });
      const { errors } = this.state;
      // const response=this.ApiService.forgotPasword({email:this.state.email})
      const data = { email: this.state.email };
      const { email } = this.state;
      axiosInstance
        .get(URLS.forgotPassword(email))
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({
              isLoading: false,
              message: "The link has been sent to your email address", // data?.message
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return { errors: { ...prevState.errors, errors } };
            });
          } else {
            errors.email = err.response.data.message;
            this.setState({ errors });
          }
        });
    }
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, errors: { ...prevState.errors, [name]: "" } };
    });
  };

  render() {
    const { isLoading, errors } = this.state;
    return (
      <>
        <div className="page-content page-bg forgot-pass">
          <Card className={"sign-page-root forgot-bg"}>
            <div className={"sign-page-details"}>
              <CardContent className={"sign-page-content"}>
                <Container>
                  <div className={"forgot-page-mbg"}>
                    <form
                      className="form login-form"
                      noValidate
                      autoComplete="off"
                    >
                      <Grid
                        container
                        spacing={2}
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item sm={12} xs={12}>
                          <Link to="/" className="logo">
                            <img src={logo} alt="Motzza" />
                          </Link>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className="forgot-description"
                        >
                          {isLoading && <CircularProgress />}
                          <Typography>
                            Please enter{" "}
                            <span className="color-green">your email</span>{" "}
                            address and submit. We will send you instructions to{" "}
                            <span className="color-green">
                              reset your password.
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof errors?.email !== "undefined" &&
                              errors?.email !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="email">Email Address</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Email" }}
                              id="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof this.state.errors.email !== "undefined" &&
                          this.state.errors.email !== "" ? (
                            <FormHelperText
                              className="text-left"
                              id="component-helper-text-email"
                            >
                              <span className="error color-danger">
                                {this.state.errors.email}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                          {typeof this.state.message !== "undefined" &&
                          this.state.message !== "" ? (
                            <FormHelperText
                              className="text-left"
                              id="component-helper-text-email"
                            >
                              <span className="color-green">
                                {this.state.message}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item sm={12} xs={12} className="register-button">
                          <Button
                            onClick={this.submit.bind(this)}
                            //   className="submit-btn bg-orange"
                            className="btn btn-warning"
                            variant="contained"
                          >
                            RESET
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Container>
              </CardContent>
            </div>
            <img
              className={"sign-page-cover-mbg hide-sm hide-md hide-lg hide-xl"}
              src={forgotpassMBG}
              alt=""
            />
            {/* <CardMedia
              className={"sign-page-cover-mbg hide-sm hide-md hide-lg hide-xl"}
            >
              <img src={forgotpassMBG} alt="" />
            </CardMedia> */}
            <CardMedia
              className={"sign-page-cover hide-xs hide-sm hide-bg"}
              image={loginbg}
            >
              <img src={loginbg} alt="" />
            </CardMedia>
          </Card>
        </div>
      </>
    );
  }
}

export default connect(null, { setAuthentication })(ForgotPassword);
