import React from "react";
import "./userdetail.scss";
import {
  Container,
  Typography,
  Grid,
  Divider,
  Input,
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Loader from "../../library/common/components/Loader";
import Visibility from "@material-ui/icons/Visibility";
import axiosInstance from "../../helpers/Axios";
import ShowModal from "../common/ShowModal/ShowModal";

class UserDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      isSaveLoading: false,
      showNewPass: false,
      showRepeatPass: false,
      updatePassInput: "none",
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        residentalAddress: "",
        password: "",
        newPass: "",
        repeatPass: "",
      },
      error: {},
      isSuccessModalOpen: false,
    };
  }

  componentDidMount() {
    var loginuser = JSON.parse(sessionStorage.getItem("user"));

    if (loginuser?.id && loginuser?.id !== "") {
      this.setState({ isLoading: true });
      axiosInstance
        .get("/user/" + loginuser?.id)
        .then(({ status, data }) => {
          if (status === 200) {
            let userData = {
              ...data,
              firstName: data.firstName ? data.firstName : data.fullname,
              email: data.email ? data.email : data?.username,
              password: "********",
            };

            this.setState({ isLoading: false, userData });
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: true });
        });
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showNewPass: !this.state.showNewPass });
  };
  handleClickShowRepeatPassword = () => {
    this.setState({ showRepeatPass: !this.state.showRepeatPass });
  };

  handleShowPassField = () => {
    this.setState((prevState) => {
      return {
        userData: { ...prevState.userData, newPass: "", repeatPass: "" },
        error: { ...prevState.error, newPass: "", repeatPass: "" },
        updatePassInput:
          this.state.updatePassInput === "none" ? "block" : "none",
      };
    });
  };

  handleChangeInput = (e) => {
    var { name, value } = e.target;
    this.setState((prevState) => {
      return {
        userData: { ...prevState.userData, [name]: value },
        error: { ...prevState.error, [name]: "" },
      };
    });
  };

  handleChangePhone = (e) => {
    const { name, value } = e.target;
    var re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      this.setState((prevState) => {
        return {
          userData: {
            ...prevState.userData,
            [name]: value,
          },
          error: { ...prevState.error, [name]: "" },
        };
      });
    }
  };

  validate() {
    var {
      firstName,
      lastName,
      email,
      phone,
      residentalAddress,
      newPass,
      repeatPass,
    } = this.state.userData;
    var { error } = this.state;
    var flag = true;

    if (firstName === "") {
      error.firstName = "First Name is requierd";
      flag = false;
    } else {
      error.firstName = "";
    }

    if (lastName === "") {
      error.lastName = "Last Name is requierd";
      flag = false;
    } else {
      error.lastName = "";
    }

    if (email === "") {
      error.email = "Email is requierd";
      flag = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      error.email = "Invalid email id";
      flag = false;
    } else {
      error.email = "";
    }

    if (phone === "") {
      error.phone = "Phone Number is requierd";
      flag = false;
    } else {
      error.phone = "";
    }

    if (residentalAddress === "") {
      error.residentalAddress = "Address is requierd";
      flag = false;
    } else {
      error.residentalAddress = "";
    }

    if (typeof newPass !== "undefined" && newPass !== "") {
      if (newPass?.length < 6) {
        error.newPass = "New Password must be 6 character";
        flag = false;
      } else {
        error.newPass = "";
      }

      if (repeatPass !== "" || newPass !== "") {
        if (repeatPass === "") {
          error.repeatPass = "Repeat New Password is required";
          flag = false;
        } else if (repeatPass?.length < 6) {
          error.repeatPass = "Repeat New Password must be 6 character";
          flag = false;
        } else if (repeatPass !== newPass) {
          error.repeatPass = "These must be exactly same";
          flag = false;
        } else {
          error.repeatPass = "";
        }
      }
    }

    this.setState({ error });
    return flag;
  }
  async onSubmit() {
    if (this.validate()) {
      const { userData } = this.state;
      var loginuser = JSON.parse(sessionStorage.getItem("user"));

      let updateData = {};
      if (loginuser?.id !== "") {
        updateData = {
          email: userData?.email,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          fullname: userData?.firstName + " " + userData?.lastName,
          // location: userData?.location,
          phone: userData?.phone,
          residentalAddress: userData?.residentalAddress,
          // username: userData?.username,
        };
        if (userData?.newPass !== "") {
          updateData.password = userData?.newPass;
        }

        this.setState({ isSaveLoading: true });
        axiosInstance
          .put("/user/" + loginuser?.id, { ...updateData })
          .then(({ status, data }) => {
            if (status === 200) {
              this.setState({ isSaveLoading: false, isSuccessModalOpen: true });
            } else {
              this.setState({ isSaveLoading: false });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ isSaveLoading: true });
          });
      }
    }
  }
  toggleLoginModal = () => {
    this.setState((prevState) => {
      return { isSuccessModalOpen: !prevState.isSuccessModalOpen };
    });
  };
  render() {
    var { error, userData, isSaveLoading, isLoading } = this.state;
    return (
      <>
        <ShowModal
          isModalOpen={this.state.isSuccessModalOpen}
          Content={"Your details has now been changed successfully."}
          onModalClose={this.toggleLoginModal}
          onOkay={this.toggleLoginModal}
        />
        <Container>
          <div className='userdetail-heading-main'>
            <Typography variant='h5'>Your Account Details</Typography>
          </div>
        </Container>
        <div className='userdetail-body'>
          <div className='userdetail-container'>
            <Grid container className='userdetail-inner-body'>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Grid item md={12} lg={6} className='userdetail-inner-left'>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <Typography className='userdetail-heading'>
                          Account Details
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <form className='userdetail-form'>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} className='pd-btm'>
                          <InputLabel>First Name</InputLabel>
                          <Input
                            className={
                              typeof error?.firstName !== "undefined" &&
                              error?.firstName !== ""
                                ? " invalid"
                                : ""
                            }
                            placeholder={"First Name"}
                            id='firstname'
                            name='firstName'
                            value={userData?.firstName}
                            onChange={this.handleChangeInput}
                          />
                          {typeof error?.firstName !== "undefined" &&
                            error?.firstName !== "" && (
                              <FormHelperText
                                className='userdetail-error'
                                id='component-helper-text-firstname'
                              >
                                <span className='error color-danger text-field-error'>
                                  {error?.firstName}
                                </span>
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} className='pd-top'>
                          <InputLabel>Last Name</InputLabel>
                          <Input
                            className={
                              typeof error?.lastName !== "undefined" &&
                              error?.lastName !== ""
                                ? " invalid"
                                : ""
                            }
                            placeholder={"Last Name"}
                            id='lastname'
                            name='lastName'
                            value={userData?.lastName}
                            onChange={this.handleChangeInput}
                          />
                          {typeof error?.lastName !== "undefined" &&
                            error?.lastName !== "" && (
                              <FormHelperText
                                className='userdetail-error'
                                id='component-helper-text-lastname'
                              >
                                <span className='error color-danger text-field-error'>
                                  {error?.lastName}
                                </span>
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Email</InputLabel>
                        <Input
                          className={
                            typeof error?.email !== "undefined" &&
                            error?.email !== ""
                              ? " invalid"
                              : ""
                          }
                          placeholder={"Email"}
                          id='email'
                          name='email'
                          value={userData?.email}
                          onChange={this.handleChangeInput}
                        />
                        {typeof error?.email !== "undefined" &&
                        error?.email !== "" ? (
                          <FormHelperText
                            className='userdetail-error'
                            id='component-helper-text-email'
                          >
                            <span className='error color-danger text-field-error'>
                              {error?.email}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Phone number</InputLabel>
                        <Input
                          className={
                            typeof error?.phone !== "undefined" &&
                            error?.phone !== ""
                              ? " invalid"
                              : ""
                          }
                          placeholder={"Phone Number"}
                          id='phone'
                          name='phone'
                          value={userData?.phone}
                          onChange={this.handleChangePhone}
                        />
                        {typeof error?.phone !== "undefined" &&
                        error?.phone !== "" ? (
                          <FormHelperText
                            className='userdetail-error'
                            id='component-helper-text-phone'
                          >
                            <span className='error color-danger text-field-error'>
                              {error?.phone}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Residential Address</InputLabel>
                        <Input
                          className={
                            typeof error?.residentalAddress !== "undefined" &&
                            error?.residentalAddress !== ""
                              ? " invalid"
                              : ""
                          }
                          placeholder={"Residental Address"}
                          id='residentalAddress'
                          name='residentalAddress'
                          value={userData?.residentalAddress}
                          onChange={this.handleChangeInput}
                        />
                        {typeof error?.residentalAddress !== "undefined" &&
                        error?.residentalAddress !== "" ? (
                          <FormHelperText
                            className='userdetail-error'
                            id='component-helper-text-address'
                          >
                            <span className='error color-danger text-field-error'>
                              {error?.residentalAddress}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item md={12} lg={6} className='userdetail-inner-right'>
                    {/* <Button
                      className='button-save hide-md hide-sm hide-xs'
                      onClick={this.onSubmit.bind(this)}
                    >
                      {isSaveLoading ? "Loading" : "Save"}
                    </Button> */}
                    <form className='userdetail-form form-right'>
                      <Grid item xs={12}>
                        <InputLabel>Password</InputLabel>
                        <Input
                          id='password'
                          name='password'
                          type='password'
                          value={userData?.password}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={this.handleShowPassField}
                          className='button-change-password'
                        >
                          Change Password
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: this.state.updatePassInput }}
                      >
                        <InputLabel>New Password</InputLabel>
                        <Input
                          className={
                            typeof error?.newPass !== "undefined" &&
                            error?.newPass !== ""
                              ? " invalid"
                              : ""
                          }
                          id='newpassword'
                          name='newPass'
                          value={userData?.newPass}
                          onChange={this.handleChangeInput}
                          type={this.state.showNewPass ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.handleClickShowPassword}
                              >
                                {this.state.showNewPass ? (
                                  <Visibility className='visiblity' />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {typeof error?.newPass !== "undefined" &&
                        error?.newPass !== "" ? (
                          <FormHelperText
                            className='userdetail-error'
                            id='component-helper-text-newpass'
                          >
                            <span className='error color-danger text-field-error'>
                              {error?.newPass}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: this.state.updatePassInput }}
                      >
                        <InputLabel>Repeat New Password</InputLabel>
                        <Input
                          className={
                            typeof error?.repeatPass !== "undefined" &&
                            error?.repeatPass !== ""
                              ? " invalid"
                              : ""
                          }
                          id='repeatnewpassword'
                          name='repeatPass'
                          value={userData?.repeatPass}
                          onChange={this.handleChangeInput}
                          type={this.state.showRepeatPass ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.handleClickShowRepeatPassword}
                              >
                                {this.state.showRepeatPass ? (
                                  <Visibility className='visiblity' />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {typeof error?.repeatPass !== "undefined" &&
                        error?.repeatPass !== "" ? (
                          <FormHelperText
                            className='userdetail-error'
                            id='component-helper-text-repeatpass'
                          >
                            <span className='error color-danger text-field-error'>
                              {error?.repeatPass}
                            </span>
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>

                      {/* <Grid item xs={12} sm={12} className='text-center'>
                        <Button
                          className='button-save mobile'
                          onClick={this.onSubmit.bind(this)}
                        >
                          {isSaveLoading ? "Loading" : "Save"}
                        </Button>
                      </Grid> */}
                    </form>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} className='button-save-wrap'>
                <Button
                  className='button-save'
                  onClick={this.onSubmit.bind(this)}
                  disabled={isSaveLoading ? true : false}
                >
                  {isSaveLoading ? "Loading" : "Save"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
export default UserDetail;
