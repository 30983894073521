import React, { Component } from "react";
import { Grid, Typography, Container, Card } from "@material-ui/core";
import Loader from "../../library/common/components/Loader";

import "./partners.scss";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { config } from "../../helpers/config";

import playup from "../../images/partners_logo/partners_logo/playup.png";
import ladbrokes from "../../images/partners_logo/partners_logo/ladbrokes.png";
import neds from "../../images/partners_logo/partners_logo/neds.png";
import VICBet from "../../images/partners_logo/partners_logo/VICBet.png";
import bookmaker from "../../images/partners_logo/partners_logo/bookmaker.png";
import bet365 from "../../images/partners_logo/partners_logo/bet365.png";
import betstar from "../../images/partners_logo/partners_logo/betstar.png";
import palmerBet from "../../images/partners_logo/partners_logo/palmerbet.png";
import winingedge from "../../images/partners_logo/partners_logo/winingedge.png";

const logos = [
  { id: 1, logo: bet365 },
  { id: 2, logo: betstar },
  { id: 3, logo: bookmaker },
  { id: 4, logo: ladbrokes },
  { id: 5, logo: neds },
  { id: 6, logo: playup },
  { id: 7, logo: VICBet },
  { id: 8, logo: palmerBet },
  { id: 9, logo: winingedge },
];

// function importAll(r) {
//   let images = {};
//   r.keys().map((item, index) => {
//     images[item.replace("./", "")] = r(item);
//     return item;
//   });
//   return images;
// }
// const images = importAll(
//   require.context("../../images/partners_logo/", false, /\.(png|jpe?g|svg)$/)
// );

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = { cmsPageData: [], partnerData: [], isLoading: "" };
  }

  componentDidMount() {
    this.fetchCMSPageData();
    this.fetchPartnerData();
    // this.setState({ partnerData: logos });
  }

  async fetchCMSPageData() {
    this.setState({ isLoading: "cmsPageData" });
    try {
      const { status, data } = await axiosInstance.get(
        "/cms/page/detail/ourpartners"
      );
      if (status === 200) {
        this.setState({ cmsPageData: data.result, isLoading: "" });
      }
    } catch (err) {
      console.log(err);
    }
  }

  fetchPartnerData = async () => {
    this.setState({ isLoading: "partnerData" });
    try {
      const { status, data } = await axiosInstance.get(
        "/apiProviders/bookKeepers"
      );
      if (status === 200) {
        this.setState({ partnerData: data.result, isLoading: "" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { cmsPageData, isLoading, partnerData } = this.state;
    let headerData = cmsPageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Header"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    return (
      <>
        <Container className='page-banner'>
          <Grid
            container
            justify='center'
            className='contact-banner '
            alignItems='flex-start'
          >
            {isLoading === "cmsPageData" && <Loader />}
            <Typography
              variant='h5'
              className='title contact-title mobile-heading text-center color-blue text-upper'
            >
              {cmsPageData?.pageTitle}
            </Typography>

            <div
              variant='body1'
              className='text-center cms_partners'
              dangerouslySetInnerHTML={{
                __html:
                  headerData &&
                  headerData?.length > 0 &&
                  headerData[0]?.content,
              }}
            ></div>
          </Grid>
        </Container>

        <Container>
          <div className='text-center partners-logo-wrap section'>
            <Grid
              container
              spacing={7}
              justify='flex-start'
              alignItems='stretch'
            >
              {partnerData?.length > 0
                ? partnerData.map((image_key, i) => (
                    <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                      <a target='_blank' href={image_key?.affiliate_link}>
                        <Card className='fullHeightCard'>
                          <img
                            src={config.baseUrl + image_key.logo}
                            // src={image_key.logo}
                            alt=''
                          />
                        </Card>
                      </a>
                    </Grid>
                  ))
                : ""}
            </Grid>
          </div>
        </Container>
      </>
    );
  }
}

export default Partners;
