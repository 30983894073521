import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.props.onChange(address);
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        let resultAddress = results[0];
        let country = resultAddress?.address_components?.filter((item) =>
          item?.types?.includes("country")
        );
        let state = resultAddress?.address_components?.filter((item) =>
          item?.types?.includes("administrative_area_level_1")
        );
        let postal_code = resultAddress?.address_components?.filter((item) =>
          item?.types?.includes("postal_code")
        );
        console.log("country after select", country, state, postal_code);
        this.props.onChange({
          address: address,
          country: country?.length > 0 ? country[0]?.long_name : "",
          state: state?.length > 0 ? state[0]?.long_name : "",
          postCode: postal_code?.length > 0 ? postal_code[0]?.long_name : "",
        });
        getLatLng(results[0]);
      })
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address ? this.state.address : this.props.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="input-field">
            {this.props.lable ? this.props.label : "Address"}
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input form-control rounded-0",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
