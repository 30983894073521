import React from "react";
import { Grid, Divider, Button, Chip } from "@material-ui/core";
import Countdown from "react-countdown";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { ReactSVG } from "react-svg";
import { FaChevronCircleRight } from "react-icons/fa";
import { EventEmitter } from "../../../services/event";
import hourse_img_small from "../../../images/hourse_img_small.svg";
import greyhoundsIcon from "../../../images/menu_icons/Greyhounds.svg";
import harnessIcon from "../../../images/menu_icons/HarnesRacing.svg";
import mma from "../../../images/sport_icons/mma.svg";
import soccer from "../../../images/sport_icons/soccer.svg";
import tennis from "../../../images/sport_icons/tennis.svg";
import boxing from "../../../images/menu_icons/Boxing.svg";
import cricket from "../../../images/menu_icons/Cricket.svg";
import moment from "moment";
import { Loader } from "../../../library/common/components";
import AllSportsAllRacing from "../Racing/index";

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSeeRace: "", isRaceTrackClicked: true };
    EventEmitter.subscribe("change_race_clicked", this.changeRaceClicked);
  }

  componentDidMount = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  changeRaceClicked = (type) => {
    this.setState({ isRaceTrackClicked: type });
  };

  navigateToAllRace = (race) => {
    EventEmitter.dispatch("show_all_races", race);
    this.props.fetchThreeRacing("1,2,3", "", "", true);
  };

  renderer = ({ hours, minutes, seconds }) => {
    // if (hours == 0 && minutes == 0 && seconds == 0) {
    //   this.props.fetchRace("1,2,3", false);
    // }
    return (
      <Chip
        className={"allsports_racing_timer m10 "}
        style={{ cursor: "pointer" }}
        size="small"
        label={
          <span>
            {minutes <= 5 ? "" : hours > 0 ? hours + "h" : "0h"}{" "}
            {minutes > 0 ? minutes + "m" : "0m"}{" "}
            {hours == 0 && minutes <= 5
              ? seconds > 0
                ? seconds + "s"
                : "0s"
              : ""}
          </span>
        }
      />
    );
  };

  showRunners = (race) => {
    let { isRaceTrackClicked } = this.state;
    if (race !== "") {
      let selectedRace =
        race?.sportId == 1
          ? "Horse Racing"
          : race?.sportId == 2
          ? "Harness Racing"
          : "Greyhounds Racing";
      let data_to_pass = {
        raceTrackClicked: true,
        full_race: race,
        selectedRaceTrack: race?.id,
        singleRaceData: [],
      };
      return (
        <AllSportsAllRacing
          title={selectedRace}
          selectedCountry={""}
          selectedLocation={""}
          selectedDate={""}
          fetchThreeRacing={this.props.fetchThreeRacing}
          isThreeRaceLoading={this.props.isThreeRaceLoading}
          horseRaceData={this.props.horseRaceData}
          harnessRaceData={this.props.harnessRaceData}
          greyhoundRaceData={this.props.greyhoundRaceData}
          isRaceLoading={this.props.isRaceLoading}
          // trackNumber={race?.id}
          raceTrackdata={race}
          // selected_event={race?.event?.id}
          raceTrackClicked={isRaceTrackClicked}
          allData={data_to_pass}
        />
      );
    }
  };

  render() {
    let { combineRaceData, isRaceLoading } = this.props;
    let { isSeeRace } = this.state;
    combineRaceData = combineRaceData?.filter((obj) => obj?.raceNumber != 0);
    // let allUpcomingRaceData = [];
    // if (combineRaceData?.length > 0) {
    //   allUpcomingRaceData = combineRaceData;
    // }
    return (
      <>
        {isSeeRace !== "" ? (
          this.showRunners(isSeeRace)
        ) : (
          <Grid container>
            <Grid item xs={12} className="allsports_body_outer_main">
              {/* Boxing */}
              {/* <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Boxing</p>
                <p>
                  See All Boxing &nbsp;{" "}
                  <FaChevronCircleRight style={{ fontSize: 16 }} />
                </p>
              </div>
              <p className="allsports_subhead_date">Sunday 06/09/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={boxing} />
                    Samir Ziani v Alex Dilmaghani
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={boxing} />
                    Jamel Herring v Jonathan Oquendo
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
            </div> */}
              {/* cricket */}
              {/* <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Cricket</p>
                <p>
                  See All Cricket &nbsp;{" "}
                  <FaChevronCircleRight style={{ fontSize: 16 }} />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                {
                  //mobile screen size
                }
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item sm={8} xs={6} className="text-left">
                    {
                      // non mobile screen
                    }
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={cricket} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={cricket} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div> */}
              {/* racing */}
              <div className="allsports_body_outer">
                <div className="allsports_body_head">
                  <p>Racing</p>
                  <p
                    className={
                      !isRaceLoading || combineRaceData?.length > 0
                        ? ""
                        : "disabled_text"
                    }
                    onClick={
                      !isRaceLoading || combineRaceData?.length > 0
                        ? () => {
                            this.navigateToAllRace("Racing");
                          }
                        : () => {}
                    }
                  >
                    See All Racing &nbsp;{" "}
                    <FaChevronCircleRight style={{ fontSize: 16 }} />
                  </p>
                </div>
                <div className="allsports_boxing_inner">
                  {isRaceLoading && (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  )}
                  {!isRaceLoading &&
                    (combineRaceData?.length > 0 ? (
                      combineRaceData?.map((obj, i) => (
                        <>
                          <div
                            className="allsport_inner_main"
                            key={i}
                            onClick={() => {
                              this.setState({ isSeeRace: obj });
                              // EventEmitter.dispatch("show_all_races", "Racing");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="allsport_inner_match m10 allsport_inner_race_name">
                              <ReactSVG
                                src={
                                  obj?.sportId === 2
                                    ? harnessIcon
                                    : obj?.sportId === 3
                                    ? greyhoundsIcon
                                    : hourse_img_small
                                }
                              />
                              <span
                                style={{ paddingRight: 10 }}
                                className="allsports_racing_timer race_number_width"
                              >{`R${obj?.raceNumber}`}</span>
                              {obj?.event?.eventName}
                            </p>
                            <Countdown
                              date={moment.utc(obj?.startTimeDate)}
                              renderer={this.renderer}
                              onComplete={() =>
                                setTimeout(
                                  () => this.props.fetchRace("-1", false),
                                  2000
                                )
                              }
                              key={moment.utc(obj?.startTimeDate)}
                            />
                            {/* <p className='allsports_racing_timer m10'>1m 25s</p> */}
                          </div>
                          <Divider className="allsports_half_hr" />
                        </>
                      ))
                    ) : (
                      <div className="allsport-loader-center">
                        <p>No Race Available</p>
                      </div>
                    ))}
                </div>
              </div>
              {/* MMA */}
              {/* <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>MMA</p>
                <p>
                  See All MMA &nbsp;
                  <FaChevronCircleRight style={{ fontSize: 16 }} />
                </p>
              </div>
              <p className="allsports_subhead_date">Sunday 06/09/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={mma} />
                    Adrian Yanez v Gustavo Lopez
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading">
                  <p>05:00 - [Event Name]</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match">
                    <ReactSVG src={mma} />
                    Max Griffin v Song Kenan
                  </p>
                  <Button className="allsport_compare_bg">Compare</Button>
                </div>
              </div>
            </div> */}
              {/* soccer */}
              {/* <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Soccer</p>
                <p>
                  See All Soccer &nbsp;{" "}
                  <FaChevronCircleRight style={{ fontSize: 16 }} />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={soccer} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={soccer} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div> */}
              {/* tennis */}
              {/* <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p>Tennis</p>
                <p>
                  See All Tennis &nbsp;{" "}
                  <FaChevronCircleRight style={{ fontSize: 16 }} />
                </p>
              </div>
              <p className="allsports_subhead_date">Thursday 04/06/20</p>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading hide-sm hide-md hide-lg hide-xl">
                  <p>04/06/20 19:50 46 Markets</p>
                  <p>1 Market</p>
                </div>
                <Divider className="allsports_inner_hr hide-sm hide-md hide-lg hide-xl" />
                <Grid container>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_cricket_head hide-xs">
                      04/06/20 19:50 46 Markets
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Head to head</p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <p className="allsport_cricket_head">Line</p>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={tennis} />
                      Team 1
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={8} xs={6} className="text-left">
                    <p className="allsport_inner_match m10">
                      <ReactSVG src={tennis} />
                      Team 2
                    </p>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                  <Grid item sm={2} xs={3} className="text-center">
                    <Button className="allsport_compare_sm hide-xs">
                      Compare
                    </Button>
                    <Button className="allsport_compare_sm hide-sm hide-md hide-lg hide-xl">
                      <CompareArrowsIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div> */}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
export default AllSportsHome;
