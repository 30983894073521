export default function CommonReducer(
  state = {
    loader: false,
    racingsLoading:false,
    racings: [],
    upcomingRaces: [],
    sportsData:{}
  },
  action
) {
  switch (action.type) {
    case "LOADER_VIEW":
      return Object.assign({}, state,{loader: action.payload});
    case "ERROR_HANDLER":
      return Object.assign({}, state,{error: action.payload});
    case "AUTH_HANDLER":
      return Object.assign({}, state,{auth: action.payload});
    case "CLEAR_AUTH":
      return {};
    case "SET_SPORTDATA":
      return Object.assign({}, state,{ sportsData: action.payload});
    case "SET_RACINGS":
      return Object.assign({}, state,{ racings: action.payload});
    case "SET_SPORT_LOADING":
      return Object.assign({}, state,{ racingsLoading: action.payload});
    case "SET_UPCOMING_RACE":
      return Object.assign({}, state,{ upcomingRaces: action.payload});
      case "REMOVE_UPCOMMING_RACE":
        return Object.assign({}, state,
          { upcomingRaces: state.upcomingRaces?.filter((itemrace) => itemrace?.id !== action.payload?.id)}
        );
    default:
      return state;
  }
}
