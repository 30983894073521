import React, { Component } from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";

import moment from "moment";

export default class OddsHighChart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    const options = { style: "currency", currency: "USD" };
    const numberFormat = new Intl.NumberFormat("en-US", options);
    const configPrice = {
      yAxis: [
        {
          offset: 10,
          enabled: false,
          labels: {
            enabled: false,
          },
        },
      ],
      tooltip: {
        shared: true,
        formatter: function () {
          return (
            numberFormat.format(this.y, 0) +
            "</b><br/>" +
            moment(this.x).format("MMMM Do YYYY, h:mm")
          );
        },
      },
      plotOptions: {
        series: {
          showInNavigator: false,
          gapSize: 0,
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, "#003399"],
              [1, "#3366AA"],
            ],
          },
        },
      },
      rangeSelector: {
        enabled: false,
      },
      chart: {
        height: 70,
        width: 70,
      },

      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      xAxis: [
        {
          type: "date",
          enabled: false,
          labels: {
            enabled: false,
          },
        },
      ],
      series: [
        {
          name: "Odds",
          data: data,
          tooltip: {
            valueDecimals: 2,
          },
          color: "#00833e",
        },
      ],
    };
    return (
      <div>
        <ReactHighcharts config={configPrice}></ReactHighcharts>
      </div>
    );
  }
}
