import React, { useState } from "react";
import { Chip, TableCell, withStyles } from "@material-ui/core";
import { useTimer } from "react-timer-hook";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "rgb(0 0 0 / 49%)",
    backgroundColor: "transparent",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  body: {
    fontSize: 14,
    color: "#000",
    cursor: "pointer",
    fontWeight: 600,
    fontFamily: "Open Sans",
    borderRight: "0.5px solid #ded7d7",
    borderBottom: "none",
  },
}))(TableCell);

const TrackListCountdown = ({
  expiryTimestamp,
  race,
  race_obj,
  checkRaceMinCell,
  fetchRunnersWin,
  selectRaceTrackHandler,
  runnerResultFromCountdown,
  isMobile,
}) => {
  const [isRaceFinished, setIsRaceFinished] = useState(false);
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsRaceFinished(true),
  });
  return isMobile == false ? (
    <StyledTableCell
      align="center"
      style={{
        color: "#000",
        fontFamily: "Open Sans",
        fontWeight: 600,
      }}
      className={
        isRaceFinished
          ? "upcoming_race_cell_close"
          : checkRaceMinCell(race_obj?.startTimeDate, "desktop")
      }
      onClick={
        race_obj?.startTimeDate !== null
          ? moment(new Date(race_obj?.startTimeDate)).isBefore(new Date())
            ? () => {
                fetchRunnersWin(race_obj?.id);
                runnerResultFromCountdown(true);
                selectRaceTrackHandler(race_obj?.id, race_obj, race);
              }
            : () => {
                runnerResultFromCountdown(false);
                selectRaceTrackHandler(race_obj?.id, race_obj, race);
              }
          : () => {}
      }
    >
      {race_obj?.startTimeDate !== null ? (
        <Chip
          className={"singlerace-count-chip"}
          style={{
            backgroundColor: "#fff",
            fontSize: 12,
            fontFamily: "Open Sans",
            fontWeight: 600,
            cursor: "pointer",
            color: "rgba(0, 0, 0, 0.49)",
          }}
          size="small"
          label={
            hours == 0 && minutes == 0 && seconds == 0 ? (
              <span style={{ color: "red" }}>close</span>
            ) : (
              <span>
                {hours > 0 ? hours + "h" : ""}{" "}
                {minutes > 0 ? minutes + "m" : "0m"}{" "}
                {hours == 0 && minutes <= 5
                  ? seconds > 0
                    ? seconds + "s"
                    : "0s"
                  : ""}
              </span>
            )
          }
        />
      ) : (
        <span style={{ textAlign: "center" }}>-</span>
      )}
    </StyledTableCell>
  ) : (
    <td
      style={{ width: 80, textAlign: "right" }}
      className={
        isRaceFinished
          ? ""
          : checkRaceMinCell(race?.race[0].startTimeDate, "mobile")
      }
      onClick={
        race?.race[0]?.raceNumber !== undefined
          ? moment(new Date(race?.race[0].startTimeDate)).isBefore(new Date())
            ? () => {
                fetchRunnersWin(race?.race?.length > 0 && race?.race[0].id);
                runnerResultFromCountdown(true);
                selectRaceTrackHandler(
                  race?.race?.length > 0 && race?.race[0].id,
                  race?.race?.length > 0 && race?.race[0],
                  race
                );
              }
            : () => {
                runnerResultFromCountdown(false);
                selectRaceTrackHandler(
                  race?.race?.length > 0 && race?.race[0].id,
                  race?.race?.length > 0 && race?.race[0],
                  race
                );
              }
          : () => {}
      }
    >
      {race?.race?.length > 0 && race?.race[0].startTimeDate !== null ? (
        <Chip
          className={"singlerace-count-chip"}
          style={{
            backgroundColor: "#fff",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "#000",
            fontWeight: 600,
            cursor: "pointer",
          }}
          size="small"
          label={
            hours == 0 && minutes == 0 && seconds == 0 ? (
              <span style={{ color: "red" }}>close</span>
            ) : (
              <span>
                {hours > 0 ? hours + "h" : ""}{" "}
                {minutes > 0 ? minutes + "m" : "0m"}{" "}
                {hours == 0 && minutes <= 5
                  ? seconds > 0
                    ? seconds + "s"
                    : "0s"
                  : ""}
              </span>
            )
          }
        />
      ) : (
        "-"
      )}
    </td>
  );
};
export default TrackListCountdown;
