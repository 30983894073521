import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  FormControl,
  Select,
  Grid,
} from "@material-ui/core";
// import Pagination from "@material-ui/lab/Pagination";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import axiosInstance from "../../helpers/Axios";
import { raceIcon } from "../../helpers/common";
import { ReactSVG } from "react-svg";
import { EventEmitter } from "../../services/event";
import dropdownarrow from "../../images/dropdownarrow.svg";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./racelist.scss";
import Loader from "../../library/common/components/Loader";

class RaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedMetting: "",
      selectedSportTitle: 1,
      selectedRace: 1,
      selectedRaceType: 1,
      allSelectedValues: "",
      // dropdownSportTitles: [
      //   { id: 1, title: "Healsville" },
      //   { id: 2, title: "Toowoomaba" },
      //   { id: 3, title: "Hatrick" },
      // ],
      // raceLists: [
      //   { id: 1, title: "Race 1" },
      // ],
      raceTypes: [
        { id: 1, title: "Win/Place" },
        { id: 2, title: "Quinella" },
        { id: 3, title: "Trifecta" },
        { id: 4, title: "Odds & Evens" },
        { id: 5, title: "Trip" },
        { id: 6, title: "First 4" },
        { id: 7, title: "Exacta" },
        { id: 8, title: "Duet" },
        { id: 9, title: "Bundle Bet" },
        { id: 10, title: "Parlay" },
        { id: 11, title: "R Double" },
        { id: 12, title: "Quaddie" },
        { id: 13, title: "Win" },
      ],
      // raceTypesRows: [
      // {id:1,  image:hourse_img_small, horsename: 'Horse Name1',trainer:'trainer',jockey: 'jockey', form: 'form',weight: 'weight'},
      // ],
      data: null,
      raceLists: [],
      races: [],
    };
  }

  componentDidMount() {
    const { SelectedUCRace } = this.props;
    this.getMeetingsData();
    this.setState({
      SelectedMetting: SelectedUCRace?.meetingId,
      selectedRace: SelectedUCRace?.RaceNumber,
    });
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.SelectedUCRace !== this.props.SelectedUCRace) {
      this.setData();
    }
  }

  getMeetingsData = async () => {
    const { match } = this.props;
    let sportName = match.params?.sportName;
    try {
      const { status, data } = await axiosInstance.get(
        "af/meeting/" + sportName
      );
      if (status === 200 && data) {
        this.setState({ MeetingList: data?.meetings });
      } else {
        console.log("err", data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRaceByMeetingName = async (meetingName) => {
    const { sportsData } = this.props;
    try {
      let raceUrl = `/af/category?`;
      raceUrl +=
        typeof sportsData !== "undefined" && sportsData?.name !== ""
          ? "sportName=" + sportsData?.name
          : "sportName=";

      raceUrl +=
        meetingName !== "" ? "&meetingName=" + meetingName : "&meetingName=";

      this.setState({ isLoading: true });
      const { status, data } = await axiosInstance.get(raceUrl);
      if (status === 200 && data) {
        let racesCategories =
          data.categories?.length > 0 ? data.categories[0] : false;
        let races =
          racesCategories?.races?.length > 0 ? racesCategories?.races[0] : {};
        this.setState({
          isLoading: false,
          races: races,
          raceLists: races?.Race,
          raceData: data,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  setData = async () => {
    const { SelectedUCRace, SelectedMetting } = this.props;
    const { MeetingList } = this.state;
    let MettingName = SelectedUCRace?.MeetingName;
    if (MeetingList?.length > 0) {
      if (SelectedMetting !== "") {
        MettingName = MeetingList?.filter((m) => m.id === SelectedMetting);
      }
    }
    this.getRaceByMeetingName(MettingName);

    // if (typeof location !== "undefined" && location?.state) {
    //   const { all, item, raceNumber } = location?.state;
    //   if (item) {
    //     const races = item.Race.sort((a, b) => {
    //       return a.RaceNumber - b.RaceNumber;
    //     });

    //     this.setState({
    //       raceLists: racings,
    //       data: item,
    //       selectedRace: raceNumber,
    //       selectedSportTitle: item.id,
    //       races,
    //     });
    //   }
    // }
  };

  getItemFromStateById(stateItem, id) {
    if (typeof stateItem !== "undefined" && stateItem.length > 0) {
      var selectedItem = stateItem.filter((item) => item.id === id);
      if (typeof selectedItem !== "undefined" && selectedItem.length > 0) {
        return selectedItem;
      }
    }
    return [];
  }

  handleRaceChange = (event, newValue) => {
    this.setState({ selectedRace: newValue, selectedRaceType: 1 }, () => {});
  };

  handleRaceTypesChange = (event, newValue) => {
    this.setState({ selectedRaceType: newValue });
  };

  handleChangesportTitle = (event) => {
    var val = event.target.value;
    const prevMeetingid = this.state.SelectedMetting;
    const selectedRace = this.state.raceLists;
    this.setState({
      SelectedMetting: val,
      data: selectedRace,
      selectedRace: selectedRace && selectedRace[0].RaceNumber,
    });
    if (val !== prevMeetingid) this.setData();
  };

  handleRacingTab = (tab) => {
    let tab_num = tab;
    return tab_num;
  };

  handleChangeTab = (event, Races) => {
    if (event === "prev") {
      this.setState((prevState) => {
        return {
          selectedRace:
            prevState.selectedRace === 1
              ? Races?.length
              : prevState.selectedRace - 1,
        };
      });
    } else if (event === "next") {
      this.setState((prevState) => {
        return {
          selectedRace:
            prevState.selectedRace === Races?.length
              ? 1
              : prevState.selectedRace + 1,
        };
      });
    }
  };

  handleChangeTypeTab = (event, Races) => {
    if (event === "prev") {
      this.setState((prevState) => {
        return {
          selectedRaceType:
            prevState.selectedRaceType === 1
              ? Races?.length
              : prevState.selectedRaceType - 1,
        };
      });
    } else if (event === "next") {
      this.setState((prevState) => {
        return {
          selectedRaceType:
            prevState.selectedRaceType === Races?.length
              ? 1
              : prevState.selectedRaceType + 1,
        };
      });
    }
  };

  addBet = (item, race) => {
    EventEmitter.dispatch("draweropen");
    const { races } = this.state;
    var allSelectedValues = {
      SportName: races.SportName,
      sport: races.MeetingName,
      race: races?.Race?.find(
        (item) => item.RaceNumber === this.state.selectedRace
      ),
      raceType: this.getItemFromStateById(
        this.state.raceTypes,
        this.state.selectedRaceType
      ),
    };

    this.setState({
      allSelectedValues: allSelectedValues,
    });
    let runnerOddsTemp = item?.RunnerOdds?.filter(
      (itemOdd) => itemOdd?.Key === "generationWeb"
    );
    var emititem = {
      ...item,
      RunnerOdds:
        runnerOddsTemp?.length > 0
          ? [...item?.RunnerOdds]
          : [
              ...item?.RunnerOdds,
              {
                FixedPlace: item?.Tab[0]?.NSWPlace
                  ? String(item?.Tab[0]?.NSWPlace)
                  : 0,
                FixedWin: item?.Tab[0]?.NSWWin
                  ? String(item?.Tab[0]?.NSWWin)
                  : 0,
                Key: "generationWeb",
                createdAt: "",
                id: item?.id,
                runnerId: item?.id,
                updatedAt: "",
              },
            ],
    };

    emititem.SportName = allSelectedValues.SportName;
    emititem.raceNumber = race.RaceNumber;
    emititem.sportTitle = allSelectedValues.sport;
    emititem.race = race.RaceName;
    emititem.raceType = "";
    emititem.allHorses = race.Runner;
    emititem.raceTime = moment
      .utc(race.StartTimeAt)
      .local()
      .format("DD MMM YYYY hh:mm a");
    EventEmitter.dispatch("updatebetsList", emititem);
  };

  selectDropdownArrow() {
    return (
      <ReactSVG className="MuiSvgIcon-root dropdownarrow" src={dropdownarrow} />
    );
  }

  renderItemRow = (row, i, item) => {
    let Tab = row?.Tab ? row?.Tab[0] : false;

    let wins = row?.bestOdds?.FixedWin
      ? Number(row?.bestOdds?.FixedWin).toFixed(2)
      : Number(Tab?.NSWWin).toFixed(2);
    let place = row?.bestOdds?.FixedPlace
      ? Number(row?.bestOdds?.FixedPlace).toFixed(2)
      : Number(Tab?.NSWPlace).toFixed(2);
    return (
      <TableRow
        key={row.id}
        className={row.IsScratched === 1 ? "disabled" : ""}
      >
        <TableCell className="row-image">
          {/* <ReactSVG src={hourse_img_small} /> */}
          <ReactSVG src={raceIcon(row?.SportName)} />
        </TableCell>
        <TableCell component="th" scope="row">
          {`${row.RunnerNumber} ${row.OutcomeName} (${row.Barrier})`}
        </TableCell>
        {/* <TableCell>{row.trainer ? row.trainer : "-"}</TableCell> */}
        <TableCell>{row.Jockey}</TableCell>
        {/* <TableCell>{row.form ? row.form : "-"}</TableCell> */}
        <TableCell>{row.Weight}</TableCell>
        <TableCell>{wins && !isNaN(wins) ? wins : ""}</TableCell>
        <TableCell>{place && !isNaN(place) ? place : ""}</TableCell>
        <TableCell>
          <Button
            className="compare-btn"
            variant="outlined"
            onClick={() => this.addBet(row, item)}
          >
            {row.IsScratched === 1 ? "SCR" : "Compare"}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  renderItemRowMobile = (row, i, item) => {
    let Tab = row?.Tab ? row?.Tab[0] : false;
    let wins = row?.bestOdds?.FixedWin
      ? Number(row?.bestOdds?.FixedWin).toFixed(2)
      : Number(Tab?.NSWWin).toFixed(2);
    let place = row?.bestOdds?.FixedPlace
      ? Number(row?.bestOdds?.FixedPlace).toFixed(2)
      : Number(Tab?.NSWPlace).toFixed(2);
    return (
      <Grid
        container
        className={
          "racelistmb-main " + (row.IsScratched === 1 ? "disabled" : "")
        }
        key={"racelist" + row.id}
      >
        <Grid item xs={2}>
          {/* <ReactSVG src={hourse_img_small} /> */}
          <ReactSVG className="horse_img" src={raceIcon(row?.SportName)} />
        </Grid>
        <Grid item xs={6}>
          <Typography className="racelistmb-name">{`${row.RunnerNumber} ${row.OutcomeName} (${row.Barrier})`}</Typography>
          <Typography className="racelistmb-jockey">{row.Jockey}</Typography>
          <Typography className="racelistmb-weight">
            Weight: {row.Weight}
          </Typography>
          <Typography className="racelistmb-bestodds">Best Odds</Typography>
          <Divider />
          <Typography className="racelistmb-win">
            <span>Win: {wins && !isNaN(wins) ? wins : ""}</span>
            <span>Place: {place && !isNaN(place) ? place : ""}</span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="compare-btn"
            variant="outlined"
            onClick={() => this.addBet(row, item)}
          >
            {row.IsScratched === 1 ? "SCR" : "Compare"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { MeetingList, SelectedMetting, isLoading, data, races } = this.state;
    let Races = races?.Race
      ? races?.Race.sort(function (a, b) {
          return a?.RaceNumber - b?.RaceNumber;
        })
      : [];
    Races = races?.Race
      ? races?.Race?.map((itemR) => {
          return {
            ...itemR,
            Runner: itemR.Runner?.map((itemRunner) => {
              let runnerOddsTemp = itemRunner?.RunnerOdds?.filter(
                (itemOdd) => itemOdd?.Key === "generationWeb"
              );
              let newRunnerOdds =
                runnerOddsTemp?.length > 0
                  ? [...itemRunner?.RunnerOdds]
                  : [
                      ...itemRunner?.RunnerOdds,
                      {
                        id: itemRunner?.id,
                        Key: "generationWeb",
                        FixedWin: itemRunner?.Tab[0]?.NSWWin
                          ? String(itemRunner?.Tab[0]?.NSWWin)
                          : 0,
                        FixedPlace: itemRunner?.Tab[0]?.NSWPlace
                          ? String(itemRunner?.Tab[0]?.NSWPlace)
                          : 0,
                        createdAt: "",
                        updatedAt: "",
                        runnerId: itemRunner?.id,
                      },
                    ];

              let bestOdds = newRunnerOdds?.sort(function (a, b) {
                return Number(b.FixedWin) - Number(a.FixedWin);
              });
              return {
                ...itemRunner,
                RunnerOdds: newRunnerOdds,
                bestOdds: bestOdds[0],
              };
            }),
          };
        })
      : [];

    return (
      <>
        <div className="sportlist-wrap racelist-wrap">
          <div className="activeSportTitle activeSportTitle-name">
            {/* <Link
              className="backarrow"
              to={{
                pathname: "/sports/" + this.props.selected_sport_cat_id,
              }}
            >
              <ArrowBackIosIcon />
            </Link> */}
            <FormControl>
              <Select
                value={SelectedMetting}
                onChange={this.handleChangesportTitle}
                displayEmpty
                IconComponent={this.selectDropdownArrow}
              >
                {MeetingList.map((item, i) => (
                  <MenuItem key={"meeting" + i} value={item.id}>
                    {item.MeetingName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {isLoading ? (
            <div className="racelist-loader text-center">
              <Loader />
            </div>
          ) : (
            <>
              {races && Races?.length > 0 && (
                <AppBar
                  className="racetabs race-number-tab"
                  position="static"
                  color="default"
                >
                  <Toolbar>
                    <Tabs
                      value={this.state.selectedRace}
                      onChange={this.handleRaceChange}
                      variant="scrollable"
                      scrollButtons="on"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      {Races?.map((item, i) => (
                        <Tab
                          key={"racenutab" + i}
                          value={this.handleRacingTab(item.RaceNumber)}
                          label={"Race " + item.RaceNumber}
                          {...a11yProps(i)}
                        />
                      ))}
                    </Tabs>
                    <div className="race-tab-button">
                      <button
                        onClick={() => this.handleChangeTab("prev", Races)}
                      >
                        <FontAwesomeIcon color="white" icon={faChevronLeft} />
                      </button>
                      <button
                        onClick={() => this.handleChangeTab("next", Races)}
                      >
                        <FontAwesomeIcon color="white" icon={faChevronRight} />
                      </button>
                    </div>
                  </Toolbar>
                </AppBar>
              )}
              {races && Races?.length > 0 ? (
                Races?.map((item, i) => (
                  <TabPanel
                    key={"tacetabsedetails" + i}
                    value={this.state.selectedRace}
                    index={item.RaceNumber}
                  >
                    <div className="race-list">
                      <Typography variant="h5" className="jump-title">
                        {item.RaceDistance} {item.RaceName},{" "}
                        {moment(item.UTCTime).format("hh:mm a")}
                      </Typography>
                      <div className="race-list-inner racetype-tabs">
                        <hr />
                        {this.state.raceTypes && (
                          <AppBar position="static" color="default">
                            <Toolbar className="race-type-toolbar">
                              <Tabs
                                value={this.state.selectedRaceType}
                                onChange={this.handleRaceTypesChange}
                                variant="scrollable"
                                scrollButtons="off"
                                textColor="primary"
                                aria-label="scrollable force tabs example"
                              >
                                {this.state.raceTypes.map((itemracetype, i) => (
                                  <Tab
                                    key={i}
                                    value={itemracetype.id}
                                    label={itemracetype.title}
                                    {...a11yProps(i)}
                                  />
                                ))}
                              </Tabs>
                              <div className="race-tab-button racetype-tab-button">
                                <button
                                  onClick={() =>
                                    this.handleChangeTypeTab(
                                      "prev",
                                      this.state.raceTypes
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    color="white"
                                    icon={faChevronLeft}
                                  />
                                </button>
                                <button
                                  onClick={() =>
                                    this.handleChangeTypeTab(
                                      "next",
                                      this.state.raceTypes
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    color="white"
                                    icon={faChevronRight}
                                  />
                                </button>
                              </div>
                            </Toolbar>
                          </AppBar>
                        )}
                        <hr />
                      </div>
                      {this.state.raceTypes.map((itemracetype, i) => (
                        <>
                          <TabPanel
                            key={i}
                            value={this.state.selectedRaceType}
                            index={itemracetype.id}
                            className={"hide-xs hide-sm-tab"}
                          >
                            <div className="race-list-inner">
                              <TableContainer>
                                <Table
                                  className={"race-table"}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: "20px",
                                          padding: "8px",
                                        }}
                                      ></TableCell>
                                      <TableCell></TableCell>
                                      {/* <TableCell></TableCell> */}
                                      <TableCell></TableCell>
                                      {/* <TableCell></TableCell> */}
                                      <TableCell></TableCell>
                                      <TableCell colspan="2">
                                        <Typography
                                          className="racelistmb-bestodds"
                                          style={{
                                            color: "#6abf4b",
                                            fontWeight: "bold",
                                            borderBottom: "1px solid #ccc",
                                          }}
                                        >
                                          Best Odds
                                        </Typography>
                                      </TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: "20px",
                                          padding: "8px",
                                        }}
                                      ></TableCell>
                                      <TableCell>Horse Name</TableCell>
                                      {/* <TableCell>Trainer </TableCell> */}
                                      <TableCell>Jockey</TableCell>
                                      {/* <TableCell>Form</TableCell> */}
                                      <TableCell>Weight</TableCell>
                                      <TableCell>Win</TableCell>
                                      <TableCell>Place</TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.Runner.map((row, i) =>
                                      this.renderItemRow(row, i, item)
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </TabPanel>
                          <TabPanel
                            key={i}
                            value={this.state.selectedRaceType}
                            index={itemracetype.id}
                            className={"hide-xl hide-lg hide-md hide-lg-tab"}
                          >
                            {item.Runner.map((row, i) => (
                              <Grid container className="racelist-mobile">
                                <Grid item xs={12} sm={12}>
                                  {this.renderItemRowMobile(row, i, item)}
                                </Grid>
                              </Grid>
                            ))}
                            {/* <Pagination count={10} /> */}
                          </TabPanel>
                        </>
                      ))}
                    </div>
                  </TabPanel>
                ))
              ) : (
                <p>No Race Available</p>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ common, sports }) => {
  return {
    racings: common.racings,
    selected_sport: sports.selected_sport,
    selected_sport_cat_id: sports.selected_sport_cat_id,
  };
};

export default connect(mapStateToProps)(RaceList);

class TabPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <>
        <div
          role="tabpanel"
          className="racelist-tabpanle"
          hidden={value !== index}
          id={`scrollable-force-tabpanel-${index}`}
          aria-labelledby={`scrollable-force-tab-${index}`}
          {...other}
        >
          {value === index && <>{children}</>}
        </div>
      </>
    );
  }
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
