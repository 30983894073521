import { connect } from "react-redux";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AdminLogin from "../../adminlogin/AdminLogin";

import { routes } from "./routes-list";

const Routes = (props) => {
  const { isLoggedIn } = props;
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/admin/dashboard" } };
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            if (isLoggedIn && from) {
              return <Redirect to={from} />;
            } else {
              return <Redirect to="/admin/login" />;
            }
          }}
        />

        <Route exact path="/admin/login" component={AdminLogin} />

        {routes.map((item, index) => (
          <PrivateRoute
            exact
            key={index}
            isLoggedIn={isLoggedIn}
            path={item.path}
            component={item.component}
          />
        ))}
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default withRouter(connect(mapStateToProps, {})(Routes));
