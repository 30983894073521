import React, { createRef } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import "./repeaterFields.scss";
import AddIcon from "@material-ui/icons/Add";
import { apiProviderApisFeildMappingFormModel } from "./form-constant";
import Form from "../../../../library/common/components/Form";
import ButtonComponent from "../../../../library/common/components/Button";
import ActionMessage from "../../../../library/common/components/ActionMessage";
import { URLS } from "../../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../../library/utilities";
import axiosInstance from "../../../../helpers/Axios";
import RepeaterFieldsApisFieldMapping from "./repeaterFieldsApisFieldMapping";
import { indexOf, values } from "lodash";
import _ from "lodash";

let apiProviderApisFeildMappingFormModelArray = apiProviderApisFeildMappingFormModel;
class CreateApiProviderApisFeildMapping extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      repeaterValues: [],
      totalMappings: 1,
      values: {
        apiIdentifier: "",
        isElement: "",
        apiIdentifierValue: "",
        tableFeild: "",
        valueType: "",
        level: 0,
        isReference: "",
        tableName: "",
        columnName: "",
        sportTypeId: "",
      },
      error: [],
      SelectedTableColumnsArray: [],
      apiIdentifierValues: [],
      apiProviderUpdateId: null,
      allApiProvider: [],
      allSportType: [],
      isOther: [],
      isOtherValue: [],
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const {
      isEditMode,
      dbtable,
      currentApiProviderUpdateData,
      allKeyIdentifire,
      allSportsType,
      apisFeildMapping,
    } = this.props;
    let lastElement =
      apisFeildMapping?.length > 0 ? _.last(apisFeildMapping) : 0;

    if (isEditMode) {
      this.fetchCurrentFieldMapping();
    }
    this.handleShowCheckbox();
    // tables name list
    let TablesArray =
      dbtable && typeof dbtable !== "undefined"
        ? Object.keys(dbtable)?.map((item, index) => item)
        : ["HRRunners", "HRRaces", "RunnerOdds"];
    // fields of selected table
    let TablesColumnsArray = Object.keys(dbtable)?.some(
      (item, index) => currentApiProviderUpdateData?.tableNameToUpdate === item
    )
      ? [
          ...Object.keys(
            dbtable[currentApiProviderUpdateData?.tableNameToUpdate]
          )?.map((item, index) => item),
        ]
      : [];
    this.setState({
      TablesColumnsArray: TablesColumnsArray,
      TablesArray: TablesArray,
    });
    apiProviderApisFeildMappingFormModelArray = apiProviderApisFeildMappingFormModelArray?.map(
      (fieldItem) => {
        if (fieldItem?.field === "tableName") {
          return {
            ...fieldItem,
            type: "dropdown",
            options: [
              ...TablesArray?.map((tablecol, i) => {
                return { id: i, value: tablecol, label: tablecol };
              }),
            ],
          };
        } else if (fieldItem?.field === "tableFeild") {
          return {
            ...fieldItem,
            type: "dropdown",
            options: [
              ...TablesColumnsArray?.map((tablecol, i) => {
                return { id: i, value: tablecol, label: tablecol };
              }),
            ],
          };
        } else if (fieldItem?.field === "apiIdentifier") {
          return {
            ...fieldItem,
            type: "dropdown",
            options: [
              ...allKeyIdentifire?.map((tablecol, i) => {
                return { id: i, value: tablecol.id, label: tablecol.key };
              }),
            ],
          };
        }
        return fieldItem;
      }
    );
    this.setState((prevState) => {
      return {
        repeaterValues: [
          {
            ...prevState.repeaterValues,
            id: apisFeildMapping?.length > 0 ? lastElement?.id + 1 : 0,
            isReference: "false",
            isElement: "true",
            valueType: "String",
            apiIdentifierValue: "",
            apiIdentifier:
              allKeyIdentifire?.length > 0 ? allKeyIdentifire[0].id : "",
            tableFeild:
              TablesColumnsArray?.length > 0 ? TablesColumnsArray[0] : "",
            tableName: "",
            columnName: "",
            insertCheckFlag: "0",
          },
        ],
        values: {
          sportTypeId: allSportsType?.length > 0 ? allSportsType[0].id : "",
          level: 0,
        },
      };
    });
  }

  fetchCurrentFieldMapping = async (id) => {
    const { dbtable } = this.props;
    const { status, data } = await axiosInstance.get(
      URLS.apiFieldMapping + `/${this.props.id}`
    );
    if (status === 200) {
      let valuesTemp = data.result[0];
      let SelectedTableColumnsArray = [];
      if (valuesTemp?.tableName !== "") {
        SelectedTableColumnsArray = Object.keys(dbtable)?.some(
          (item, index) => valuesTemp?.tableName === item
        )
          ? [
              ...Object.keys(dbtable[valuesTemp?.tableName])?.map(
                (item, index) => item
              ),
            ]
          : [];
      }
      this.setState((prevState) => {
        return {
          repeaterValues: [
            {
              id: 0,
              ...data.result[0],
              isReference: data.result[0].isReference === true ? "1" : "0",
              isElement: data.result[0].isElement === true ? "1" : "0",
              insertCheckFlag:
                data.result[0].insertCheckFlag === true ? "1" : "0",
            },
          ],
          values: {
            ...data.result[0],
            isReference: data.result[0].isReference === true ? "1" : "0",
            isElement: data.result[0].isElement === true ? "1" : "0",
          },
          apiProviderUpdateId: data.result[0].apiProviderUpdateId,
          SelectedTableColumnsArray: SelectedTableColumnsArray,
        };
      });
      this.handleShowCheckbox();
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let flag = true;
    let { error, repeaterValues, values } = this.state;
    let { apiProviderUpdateId } = this.props;

    let submitArray = [
      ...repeaterValues?.map((item, index) => {
        let retItem = {
          ...item,
          sportTypeId: values?.sportTypeId,
          level: values?.level,
          apiProviderUpdateId: apiProviderUpdateId,
          //id: this.props.id,
        };
        return retItem;
      }),
    ];

    submitArray.map((obj) => {
      let signleItemerror = {};

      if (obj.level === "") {
        flag = false;
        error.level = "This field is mendatory";
      } else {
        error.level = "";
      }

      if (obj.sportTypeId === "") {
        flag = false;
        error.sportTypeId = "This field is mendatory";
      } else {
        error.sportTypeId = "";
      }

      if (obj.apiIdentifier === "") {
        flag = false;
        signleItemerror.apiIdentifier = "This field is mendatory";
      } else {
        signleItemerror.apiIdentifier = "";
      }

      if (obj.apiIdentifierValue === "") {
        flag = false;
        signleItemerror.apiIdentifierValue = "This field is mendatory";
      } else {
        signleItemerror.apiIdentifierValue = "";
      }

      if (obj.tableFeild === "") {
        flag = false;
        signleItemerror.tableFeild = "This field is mendatory";
      } else {
        signleItemerror.tableFeild = "";
      }

      if (obj.valueType === "") {
        flag = false;
        signleItemerror.valueType = "This field is mendatory";
      } else {
        signleItemerror.valueType = "";
      }

      if (obj.isReference === "") {
        flag = false;
        signleItemerror.isReference = "This field is mendatory";
      } else {
        signleItemerror.isReference = "";
      }

      if (obj.isElement === "") {
        flag = false;
        signleItemerror.isElement = "This field is mendatory";
      } else {
        signleItemerror.isElement = "";
      }

      if (obj.insertCheckFlag === "") {
        flag = false;
        signleItemerror.insertCheckFlag = "This field is mendatory";
      } else {
        signleItemerror.insertCheckFlag = "";
      }

      if (obj.isReference === "true") {
        if (obj.tableName === "") {
          flag = false;
          signleItemerror.tableName = "This field is mendatory";
        } else {
          signleItemerror.tableName = "";
        }
        if (obj.columnName === "") {
          flag = false;
          signleItemerror.columnName = "This field is mendatory";
        } else {
          signleItemerror.columnName = "";
        }
      }

      error[obj.id] = signleItemerror;
    });

    this.setState({ error });
    if (flag === false) {
      this.setState({ isLoading: false });
    }
    return flag;
  };

  handleSave = async () => {
    const { isEditMode, apiProviderUpdateId } = this.props;
    const { values, apiIdentifierValues, repeaterValues } = this.state;
    try {
      // if (this.validate()) {
      const method = isEditMode ? "put" : "post";
      const url = URLS.apiFieldMapping;
      /*isEditMode
        ? `${URLS.apiFieldMapping}/${this.props.id}`
        : URLS.apiFieldMapping;*/

      this.setState({ isLoading: true });

      if (this.validate()) {
        let submitArray = [
          ...repeaterValues?.map((item, index) => {
            delete item.id;
            let retItem = {
              ...item,
              sportTypeId: values?.sportTypeId,
              level: values?.level,
              apiProviderUpdateId: apiProviderUpdateId,
            };
            if (isEditMode) {
              retItem = { ...retItem, id: this.props.id };
            }
            return retItem;
          }),
        ];
        const { status } = await axiosInstance[method](url, submitArray);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllFieldMapping();
          this.setActionMessage(
            true,
            "Success",
            `Api provider Api Field Mapping ${
              isEditMode ? "Edited" : "Created"
            } Successfully`
          );
        }
      }
      // }
      // let identifireCheckbox = {};

      // if (apiIdentifierValues?.length > 0) {
      //   apiIdentifierValues.map((obj, i) => {
      //     if (obj.isChecked === true) {
      //       return (identifireCheckbox[obj.value] = obj.isChecked);
      //     }
      //   });
      // }

      /*
        const { current } = this.formRef;
        const form = current.getFormData();
  
        let allValues = removeErrorFieldsFromValues(form.formData);
  
        allValues["apiProviderUpdateId"] = apiProviderUpdateId;
        allValues["sportTypeId"] = values?.sportTypeId;
        allValues["tableName"] = values?.tableName;
        allValues["columnName"] = values?.columnName;
        allValues["level"] = values?.level;
        // allValues["apiIdentifierValue"] = JSON.stringify(identifireCheckbox);
        if (values?.isReference === "0" || values?.isReference === false) {
          allValues["tableName"] = "";
          allValues["columnName"] = "";
        }
  
        if (this.validate()) {
          const { status } = await axiosInstance[method](url, allValues);
          if (status === 200) {
            this.setState({ isLoading: false });
            this.props.inputModal();
            this.props.fetchAllFieldMapping();
            this.setActionMessage(
              true,
              "Success",
              `Api provider Api Field Mapping ${isEditMode ? "Edited" : "Created"
              } Successfully`
            );
          }
        }*/
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Api provider Api Field Mapping`
      );
    }
  };

  // handleChange = (field, value) => {
  //   this.setState(() => {
  //     return {
  //       values: { ...this.state.values, [field]: value },
  //     };
  //   });
  //   this.setActionMessage(false);
  // };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "apiProviderUpdateId") {
      this.setState({ apiProviderUpdateId: value });
    } else if (name === "tableName") {
      const { dbtable } = this.props;
      if (dbtable && typeof dbtable !== "undefined") {
        let SelectedTableColumnsArray = Object.keys(dbtable)?.some(
          (item, index) => value === item
        )
          ? [...Object.keys(dbtable[value])?.map((item, index) => item)]
          : [];
        this.setState((prevState) => {
          return {
            values: { ...prevState.values, tableName: value },
            SelectedTableColumnsArray: SelectedTableColumnsArray,
          };
        });
      }
    } else if (name === "level") {
      this.setState(
        (prevState) => {
          return {
            values: { ...prevState.values, [name]: value },
          };
        },
        () => this.handleShowCheckbox()
      );
    } else {
      this.setState((prevState) => {
        return { values: { ...prevState.values, [name]: value } };
      });
    }
  };

  handleShowCheckbox = async () => {
    const { level } = this.state.values;

    let { data } = await axiosInstance.get(
      URLS.apiIdentifireValue + `/1/${level}`
    );

    let newValues = data?.keys?.map((obj) => {
      return obj;
    });

    this.setState({ apiIdentifierValues: newValues });
  };

  // handleCheckbox = (e) => {
  //   const { name, checked } = e.target;
  //   console.log("checkbox :", name, checked);
  //   let { apiIdentifierValues } = this.state;
  //   let newArray = apiIdentifierValues;
  //   newArray.map((item) => {
  //     if (item.value === name) {
  //       if (item.isChecked === true) {
  //         item.isChecked = false;
  //       } else {
  //         item.isChecked = true;
  //       }
  //     }
  //   });
  //   if (newArray.length >= 1) this.setState({ apiIdentifierValues: newArray });
  // };

  onAddMapping = () => {
    const { repeaterValues, TablesColumnsArray } = this.state;
    const { allKeyIdentifire } = this.props;
    let newid =
      repeaterValues?.length > 0
        ? repeaterValues[repeaterValues?.length - 1].id + 1
        : 0;
    this.setState({
      repeaterValues: [
        ...repeaterValues,
        {
          id: newid,
          isReference: "false",
          isElement: "true",
          valueType: "String",
          apiIdentifierValue: "",
          apiIdentifier:
            allKeyIdentifire?.length > 0 ? allKeyIdentifire[0].id : "",
          tableFeild:
            TablesColumnsArray?.length > 0 ? TablesColumnsArray[0] : "",
        },
      ],
    });
  };

  handleOtherValueChange = (id, name, value) => {
    let { repeaterValues } = this.state;
    let newrepeaterValues = [
      ...repeaterValues?.map((ritem, rindex) => {
        return ritem?.id === id ? { ...ritem, [name]: value } : ritem;
      }),
    ];

    this.setState({ repeaterValues: newrepeaterValues });
  };

  removeMappingItem = (id) => {
    this.setState((prevState) => {
      return {
        repeaterValues: [
          ...prevState?.repeaterValues?.filter(
            (ritem, rindex) => ritem?.id !== id
          ),
        ],
      };
    });
  };

  setRepeaterInputOnchange = (id, name, value) => {
    const { repeaterValues, isOther } = this.state;
    let { dbtable } = this.props;
    let newrepeaterValues = [
      ...repeaterValues?.map((ritem, rindex) => {
        return ritem?.id === id ? { ...ritem, [name]: value } : ritem;
      }),
    ];

    this.setState({ repeaterValues: newrepeaterValues, error: {} }, () => {
      if (name === "isReference") {
        let tableName = Object.keys(dbtable)[0];
        let columnName = Object.keys(dbtable[tableName]);
        let newArray = this.state.repeaterValues.map((obj) => {
          if (obj?.isReference === "true") {
            return {
              ...obj,
              tableName:
                obj?.tableName !== ""
                  ? obj?.tableName
                  : dbtable !== []
                  ? tableName
                  : "",
              columnName:
                obj?.columnName !== ""
                  ? obj?.columnName
                  : columnName?.length > 0
                  ? columnName[0]
                  : "",
            };
          } else {
            return { ...obj, tableName: "", columnName: "" };
          }
        });
        this.setState({ repeaterValues: newArray });
      }

      if (name === "apiIdentifierValue" && value !== "other") {
        let arrayNew = this.state.isOther;
        if (arrayNew.indexOf(id) !== -1) {
          let indexToDel = arrayNew.indexOf(id);
          arrayNew.splice(indexToDel, 1);
        } else {
          // arrayNew.push(id);
        }
        this.setState({ isOther: arrayNew });
      }

      if (name === "apiIdentifierValue" && value === "other") {
        let arrayNew = this.state.isOther;
        if (arrayNew.indexOf(id) !== -1) {
          // let indexToDel = arrayNew.indexOf(id);
          // arrayNew.splice(indexToDel, 1);
        } else {
          arrayNew.push(id);
        }
        this.setState({ isOther: arrayNew });
      }

      //   let newOther = [
      //     isOther?.map((item, i) => {
      //       return item === name ? { ...item, name } : item;
      //     }),
      //   ];
      //   console.log("is other : ", name);
      //   this.setState({ isOther: newOther });
      // } else {
      //   this.setState({ isOther: false, isOtherValue: "" });
      // }
    });
  };

  render() {
    var {
      repeaterValues,
      values,
      messageBox,
      isLoading,
      SelectedTableColumnsArray,
      apiIdentifierValues,
      TablesColumnsArray,
      error,
    } = this.state;
    var { isEditMode, dbtable, allSportsType, allKeyIdentifire } = this.props;
    // tables name list
    let TablesArray =
      dbtable && typeof dbtable !== "undefined"
        ? Object.keys(dbtable)?.map((item, index) => item)
        : ["HRRunners", "HRRaces", "RunnerOdds"];
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}
              <div>
                <div className="w-50 ">
                  <label>Level*</label>
                  <select
                    className="select-box-manual"
                    name="level"
                    onChange={this.handleSelect}
                    value={values?.level}
                    disabled={isEditMode}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    {/* <option value={3}>3</option> */}
                  </select>
                  {typeof error?.level !== "undefined" && error?.level !== "" && (
                    <p
                      className="errorText"
                      style={{ margin: "-10px 0 0 0", fontSize: "12px" }}
                    >
                      {error.level}
                    </p>
                  )}
                </div>
                <div className="w-50 ">
                  <label>Sport Type*</label>
                  <select
                    className="select-box-manual"
                    name="sportTypeId"
                    onChange={this.handleSelect}
                    value={values?.sportTypeId}
                  >
                    {allSportsType?.map((obj, i) => (
                      <option key={i} value={obj.id}>
                        {obj.sportType}
                      </option>
                    ))}
                  </select>
                  {typeof error?.sportTypeId !== "undefined" &&
                    error?.sportTypeId !== "" && (
                      <p
                        className="errorText"
                        style={{ margin: "-10px 0 0 0", fontSize: "12px" }}
                      >
                        {error.sportTypeId}
                      </p>
                    )}
                </div>
                <div className="repeater-wrap">
                  {isEditMode ? (
                    <div className="repeater-item" key={0}>
                      <RepeaterFieldsApisFieldMapping
                        isEditMode={isEditMode}
                        id={
                          repeaterValues?.length > 0 ? repeaterValues[0].id : 0
                        }
                        apisFeildMapping={this.props.apisFeildMapping}
                        dbtable={this.props.dbtable}
                        TablesColumnsArray={TablesColumnsArray}
                        removeMappingItem={this.removeMappingItem}
                        setRepeaterInputOnchange={this.setRepeaterInputOnchange}
                        handleSelect={this.handleSelect}
                        allSportsType={allSportsType}
                        TablesArray={TablesArray}
                        allKeyIdentifire={allKeyIdentifire}
                        apiIdentifierValueOptions={apiIdentifierValues}
                        values={repeaterValues[0]}
                        error={error}
                        isOther={this.state.isOther}
                        isOtherValue={this.state.isOtherValue}
                        handleOtherValueChange={this.handleOtherValueChange}
                      />
                    </div>
                  ) : (
                    <>
                      {repeaterValues?.map((reperterItem, i) => {
                        return (
                          <div className="repeater-item" key={i}>
                            <RepeaterFieldsApisFieldMapping
                              id={reperterItem?.id}
                              apisFeildMapping={this.props.apisFeildMapping}
                              dbtable={this.props.dbtable}
                              TablesColumnsArray={TablesColumnsArray}
                              removeMappingItem={this.removeMappingItem}
                              setRepeaterInputOnchange={
                                this.setRepeaterInputOnchange
                              }
                              handleSelect={this.handleSelect}
                              allSportsType={allSportsType}
                              TablesArray={TablesArray}
                              allKeyIdentifire={allKeyIdentifire}
                              apiIdentifierValueOptions={apiIdentifierValues}
                              values={reperterItem}
                              repeaterValues={repeaterValues}
                              error={error}
                              isOther={this.state.isOther}
                              isOtherValue={this.state.isOtherValue}
                              handleOtherValueChange={
                                this.handleOtherValueChange
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="repeater-actions">
                        <div className="repeater-addButton">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mt-3 admin-btn-green"
                            startIcon={<AddIcon />}
                            onClick={this.onAddMapping}
                          >
                            Add Mapping
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Cancle"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateApiProviderApisFeildMapping;
