import Aboutus from "../aboutus/aboutus";
import Landing from "../landing/landing";
import Contactus from "../contactus/contactus";
import PrivacyPolicy from "../privacypolicy/privacypolicy";
import Terms from "../terms/terms";
import Register from "../register/register";
import Login from "../login/login";
import VerifyEmail from "../register/verifyEmail";
import ForgotPassword from "../forgotpassword/forgotpassword";
import ResetPassword from "../forgotpassword/resetpassword";
import ResponsibleGamblingPolicy from "../responsiblegamblingpolicy/responsiblegamblingpolicy";
import Membership from "../membership/membership";
import Memorabilia from "../memorabilia/memorabilia";
import Bookmakers from "../bookmakers/bookmakers";
import Partners from "../partners/partners";
import AdminLogin from "../../admin/adminlogin/AdminLogin";
import Blog from "../Blog";
import BlogItem from "../Blog/BlogItem";
import Page from "../Page";
import Faqs from "../faqs/Faqs";
import MembershipRaceSports from "../membership/MembershipRaceSports";
import MembershipLiveStreaming from "../membership/MembershipLiveStreaming";
import MembershipTippingSelection from "../membership/MembershipTippingSelection";
import MembershipBookmakers from "../membership/MembershipBookmakers";
import MembershipMemorabilia from "../membership/MembershipMemorebilia";
import MembershipRewardsRebate from "../membership/MembershipRewardsRebate";
import MembershipPoker from "../membership/MembershipPoker";
import IndividualAboutUs from "../aboutus/individualAboutus";
import CMSRacingAndSports from "../racingandsports/racingandsports";
import TipingAndSelection from "../tipingandselection/tipingandselection";

export const publicRoutes = [
  { path: "/", component: Landing, exact: true, index: 0, name: "Landing" },
  {
    path: "/login",
    component: Login,
    exact: false,
    index: 0,
    name: "Login",
    blankLayout: true,
  },
  {
    path: "/admin/login",
    component: AdminLogin,
    exact: false,
    index: 0,
    name: "Login",
    blankLayout: true,
  },
  {
    path: "/register",
    component: Register,
    exact: false,
    index: 5,
    name: "Register",
    blankLayout: true,
  },
  {
    path: "/verifyemail",
    component: VerifyEmail,
    exact: false,
    index: 12,
    name: "VerifyEnail",
    blankLayout: true,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    exact: false,
    index: 6,
    name: "ForgotPassword",
    blankLayout: true,
  },
  {
    path: "/password/reset/:id/:token",
    component: ResetPassword,
    exact: false,
    index: 6,
    name: "ResetPassword",
    blankLayout: true,
  },
  {
    path: "/aboutus",
    component: Aboutus,
    exact: false,
    index: 1,
    name: "Aboutus",
    normalLayout: true,
  },
  {
    path: "/individualaboutus/:slug",
    component: IndividualAboutUs,
    exact: false,
    index: 14,
    name: "IndividualAboutus",
    normalLayout: true,
  },
  {
    path: "/contactus",
    component: Contactus,
    exact: false,
    index: 2,
    name: "Contactus",
    normalLayout: true,
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy,
    exact: false,
    index: 3,
    name: "Privacy",
    normalLayout: true,
  },
  {
    path: "/terms",
    component: Terms,
    exact: false,
    index: 4,
    name: "Terms",
    normalLayout: true,
  },
  {
    path: "/responsiblegamblingpolicy",
    component: ResponsibleGamblingPolicy,
    exact: false,
    index: 7,
    name: "ResponsibleGamblingPolicy",
    normalLayout: true,
  },
  {
    path: "/membership",
    component: Membership,
    exact: true,
    index: 8,
    name: "Membership",
    normalLayout: true,
  },
  {
    path: "/membership/racingandsports",
    component: MembershipRaceSports,
    exact: true,
    index: 13,
    name: " Racing & Sports Form Guide",
    normalLayout: true,
  },
  {
    path: "/membership/livestreaming",
    component: MembershipLiveStreaming,
    exact: true,
    index: 14,
    name: "Live Sports Streaming",
    normalLayout: true,
  },
  {
    path: "/membership/tippingselection",
    component: MembershipTippingSelection,
    exact: true,
    index: 15,
    name: "Tipping & Selection Partners",
    normalLayout: true,
  },
  {
    path: "/membership/bookmakers",
    component: MembershipBookmakers,
    exact: true,
    index: 16,
    name: "Bookmakers",
    normalLayout: true,
  },
  {
    path: "/membership/memorabilia",
    component: MembershipMemorabilia,
    exact: true,
    index: 17,
    name: "Memorabilia",
    normalLayout: true,
  },
  {
    path: "/membership/rewardsrebate",
    component: MembershipRewardsRebate,
    exact: true,
    index: 18,
    name: "Rewards Rebate Program",
    normalLayout: true,
  },
  {
    path: "/membership/poker",
    component: MembershipPoker,
    exact: true,
    index: 19,
    name: "Poker",
    normalLayout: true,
  },
  {
    path: "/memorabilia",
    component: Memorabilia,
    exact: true,
    index: 20,
    name: "Memorabilia",
    normalLayout: true,
  },
  {
    path: "/bookmakers",
    component: Bookmakers,
    exact: true,
    index: 21,
    name: "Bookmakers",
    normalLayout: true,
  },
  {
    path: "/sports/racingandsports",
    component: CMSRacingAndSports,
    exact: true,
    index: 22,
    name: "Racing & Sports Form Guide",
    normalLayout: true,
  },
  {
    path: "/sports/tippingandselection",
    component: TipingAndSelection,
    exact: true,
    index: 23,
    name: "Tipping & Selection Partners",
    normalLayout: true,
  },
  {
    path: "/blog",
    component: Blog,
    exact: true,
    index: 9,
    name: "Blog",
    normalLayout: true,
  },
  {
    path: "/blog/:slug",
    component: BlogItem,
    exact: true,
    index: 10,
    name: "Blog",
    normalLayout: true,
  },
  {
    path: "/faqs",
    component: Faqs,
    exact: true,
    index: 12,
    name: "Faqs",
    normalLayout: true,
  },
  {
    path: "/page/:slug",
    component: Page,
    exact: true,
    index: 11,
    name: "Page",
    normalLayout: true,
  },

  {
    path: "/partners",
    component: Partners,
    exact: false,
    index: 9,
    name: "Partners",
    normalLayout: true,
  },
];
