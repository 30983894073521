import {Validators} from '../../library/utilities/Validators';

export const loginFormModel = [
    {
        label: '',
        value: '',
        type: 'text',
        placeholder: 'Email',
        field: 'username',
        validators: [
            {check: Validators.required, message: 'Email is mandatory'},
            {check: Validators.email, message: 'Email is invalid'},
        ],
        required: true,
        className: '12',
    },
    {
        label: '',
        value: '',
        type: 'password',
        placeholder: 'Password',
        field: 'password',
        validators: [{check: Validators.required, message: 'password is mandatory'}],
        required: true,
        className: '12',
    },
];
