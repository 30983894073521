import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { eventsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { setValidation } from "../../../helpers/common";

let eventsFormModelArray = eventsFormModel;

class CreateEvents extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        eventName: "",
        description: "",
        comment: "",
      },
      eventsForm: [],
      sportId: null,
      leagueId: null,
      categoryId: null,
      weather: null,
      eventDate: new Date(),
      eventToDate: new Date(),
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentEvents(this.props.id);
    }
    const { sportAll, leagueAll, weatherAll, locationAll } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          sportId: sportAll?.length > 0 ? sportAll[0].id : "",
          leagueId: leagueAll?.length > 0 ? leagueAll[0].id : "",
          locationId: locationAll?.length > 0 ? locationAll[0].id : "",
          weather: weatherAll?.length > 0 ? weatherAll[0].id : "",
        },
      };
    });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      /*if (fieldItem?.field === "categoryId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            { value: "", label: "No Item Selected" },
            { id: 1, value: 1, label: 1 },
          ],
        };
      } else */
      if (fieldItem?.field === "weather") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...weatherAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.weatherType,
              };
            }),
          ],
        };
      } else if (fieldItem?.field === "locationId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...locationAll?.map((tablecol, i) => {
              return { id: i, value: tablecol?.id, label: tablecol?.venueName };
            }),
          ],
        };
      } else if (fieldItem?.field === "sportId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...sportAll?.map((tablecol, i) => {
              return { id: i, value: tablecol?.id, label: tablecol?.sportName };
            }),
          ],
        };
      } else if (fieldItem?.field === "leagueId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...leagueAll?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.leagueName,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
  }

  componentWillUnmount() {
    eventsFormModelArray = eventsFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentEvents = async (id) => {
    const { status, data } = await axiosInstance.get(URLS.events + `/${id}`);
    if (status === 200) {
      this.setState({
        values: data.result,
        sportId: data.result.sportId,
        categoryId: data.result.categoryId,
        weather: data.result.weather,
        eventDate: new Date(data.result.eventDate),
        eventToDate: new Date(data.result.eventToDate),
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let {
      eventName,
      sportId,
      locationId,
      weather,
      categoryId,
      description,
      comment,
    } = this.state.values;
    let flag = true;

    if (
      eventName === "" ||
      sportId === null ||
      locationId === null ||
      weather === null ||
      categoryId === null ||
      description === "" ||
      comment === ""
    ) {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { eventDate, eventToDate } = this.state;
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode ? `${URLS.events}/${this.props.id}` : URLS.events;

      const values = removeErrorFieldsFromValues(form.formData);
      values["eventDate"] = moment(eventDate).format("YYYY-MM-DD HH:mm:ss");
      values["eventToDate"] = moment(eventToDate).format("YYYY-MM-DD HH:mm:ss");

      eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllEvents();
          this.setActionMessage(
            true,
            "Success",
            `Events ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} Events`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    eventsFormModelArray = eventsFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleEventDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventDate: selectedDate });
  };

  handleEventToDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ eventToDate: selectedDate });
  };

  render() {
    var { values, messageBox, isLoading, eventDate, eventToDate } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={eventsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div style={{ display: "flex" }}>
                <div className="input-field" style={{ width: "100%" }}>
                  <label>Event Date</label>
                  <br></br>
                  <DatePicker
                    selected={eventDate}
                    onChange={this.handleEventDateChange}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>
                <div className="input-field" style={{ width: "100%" }}>
                  <label>Event To Date</label>
                  <br></br>
                  <DatePicker
                    selected={eventToDate}
                    onChange={this.handleEventToDateChange}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                  />
                </div>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateEvents;
