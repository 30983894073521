import React from "react";
import {
  Grid,
  FormControl,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import ProviderItemRow from "./ProviderItemRow";

class ImportOdds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchData: {},
      providerList: [],
      sportsTypeList: [],
      sportsList: [],
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    this.fetchAllProviders();
    this.fetchSportsTypes();
    this.fetchSports();
  }

  async fetchAllProviders() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(`${URLS.apiProvider}`);
    if (status === 200) {
      let allProviderList = [
        ...data.result?.map((dataitem) => {
          return {
            ...dataitem,
            label: dataitem?.providerName,
            value: dataitem?.providerName,
          };
        }),
      ];
      this.setState({ providerList: allProviderList, isLoading: false });
    }
  }

  async fetchSportsTypes() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(`${URLS.sportType}`);
    if (status === 200) {
      let allsportsTypeList = [
        ...data.result?.map((dataitem) => {
          return {
            ...dataitem,
            label: dataitem?.sportType,
            value: dataitem?.sportType,
          };
        }),
      ];
      this.setState({ sportsTypeList: allsportsTypeList, isLoading: false });
    }
  }

  async fetchSports() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(`${URLS.sports}`);
    if (status === 200) {
      let allSportsList = [
        ...data.result?.map((dataitem) => {
          return {
            ...dataitem,
            label: dataitem?.sportName,
            value: dataitem?.sportName,
          };
        }),
      ];
      this.setState({ sportsList: allSportsList, isLoading: false });
    }
  }

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  render() {
    var {
      messageBox,
      isLoading,
      providerList,
      sportsTypeList,
      sportsList,
    } = this.state;
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left admin-page-heading">
                    Import Fixtures & Odds{" "}
                  </h3>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && providerList.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && providerList.length > 0 && (
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "350px" }}
                >
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell>Provider Logo</TableCell>
                        <TableCell>Provider</TableCell>
                        <TableCell>SportsType</TableCell>
                        <TableCell>Sports</TableCell>
                        <TableCell>Import</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {providerList?.map((providerItem, i) => (
                        <ProviderItemRow
                          sportsTypeList={sportsTypeList}
                          sportsList={sportsList}
                          item={providerItem}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default ImportOdds;
