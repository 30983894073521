import React, { PureComponent } from "react";
import "./betsoddsslip.scss";
import RaceBetsOdssSlip from "./racebetsoddsslip";
import BetsoddsSlipBookmarkerRow from "./betsoddsslipbookmarkerrow";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import warning_icon from "../../images/oddslip-warning.svg";
import winning_edge from "../../images/winning-edge.svg";
import { ReactSVG } from "react-svg";

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core";
import { EventEmitter } from "../../services/event";
import axiosInstance from "../../helpers/Axios";

class BetsOddsSlip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sportName: "",
      bets: [],
      sportsData: {},
      stakes: null,
      sortBy: "wins",
      sortOrder: false,
    };
    this.raceBetBlock = React.createRef();
    EventEmitter.subscribe("updatebetsList", this.updateList);
    EventEmitter.subscribe("resetbetsList", this.resetList);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match?.params?.sportName !== "") {
      this.setState({ sportName: match?.params?.sportName, stakes: null });
      //this.setCategoryData(match?.params?.sportName);
    }
    this.clearodds();
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    if (prevProps.match?.params?.sportName !== match?.params?.sportName) {
      //this.setCategoryData(match?.params?.sportName);
      this.setState({ sportName: match?.params?.sportName });
    }
    if (prevState.bets !== this.state.bets) {
      this.setState({ stakes: null });
    }
  }

  /*   setCategoryData = async (sportName) => {
    const { status, data } = await axiosInstance.get("/category");
    if (status === 200) {
      let singleItem = data.filter((dataitem) =>
        dataitem.name.includes(sportName)
      );
      this.setState({ sportsData: singleItem[0] });
    }
  }; */

  clearodds = () => {
    this.setState({ bets: [], stakes: null });
  };

  resetList = () => {
    this.setState({
      bets: [],
    });
  };

  updateList = (item) => {
    if (this.raceBetBlock && this.raceBetBlock?.current)
      this.raceBetBlock.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        // inline: "start",
      });

    this.setState({
      bets: [item],
    });
  };
  toggleSorting = (by) => {
    this.setState((prevState) => {
      return { sortBy: by, sortOrder: !prevState.sortOrder };
    });
  };

  handleInputChange = (id, value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      this.setState({
        stakes: {
          ...this.state.stakes,
          [id]: value,
        },
      });
    }
  };

  render() {
    const { sportName, bets, sportsData, stakes, sortBy, sortOrder } =
      this.state;
    const { bookmarks } = this.props;
    let SportName = sportName
      ? sportName
      : bets?.SportName && bets?.SportName !== ""
      ? bets.SportName
      : sportsData?.name ?? "Horse Racing";
    console.log("sport name : ", bets?.length > 0 && bets[0].sportName);
    SportName =
      bets?.length > 0 && bets[0].sportName !== undefined
        ? bets[0].sportName
        : SportName;
    return (
      <>
        <div ref={this.raceBetBlock}></div>
        <Card
          className="sidebar-card"
          variant="outlined"
          style={{ maxWidth: 355 }}
        >
          <CardHeader
            title={`Odds - ${SportName}`}
            subheader={
              bets?.length > 0 ? (
                <>
                  <ReactSVG src={warning_icon} />
                  <span>
                    Some bookmakers may not be currently providing odds. Please
                    check back later
                  </span>
                </>
              ) : (
                ""
              )
            }
          />
          <CardContent className="rm-padding-i">
            {bets.length > 0 ? (
              <>
                <RaceBetsOdssSlip
                  bets={bets}
                  handleInputChange={this.handleInputChange}
                  stakes={this.state.stakes}
                  toggleSorting={this.toggleSorting}
                  sportsData={this.state.sportsData}
                />
                <BetsoddsSlipBookmarkerRow
                  bookmarks={bookmarks}
                  bets={bets}
                  stakes={stakes}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  sportsData={this.state.sportsData}
                />
              </>
            ) : (
              <Typography
                className="color-white bg-alert no-bets-msg"
                variant="body2"
              >
                You have not added any BETS. Select a BET to add!
              </Typography>
            )}
          </CardContent>
          <CardActions className="btn-clear-odds-action">
            <Button
              className="btn-winning-edge"
              onClick={() =>
                window.open(
                  "https://winningedgeinvestments.com?af=AFC6E92E",
                  "_blank"
                )
              }
            >
              Get tips from
              <ReactSVG src={winning_edge} />
            </Button>
            <Button
              variant={bets.length > 0 ? "contained" : "outlined"}
              className="btn-link btn-block btn-clear-odds"
              onClick={this.clearodds}
            >
              Clear Odds Slip
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({ bets }) => {
  return {
    bookmarks: bets.bookmarks,
  };
};

export default withRouter(connect(mapStateToProps, {})(BetsOddsSlip));
