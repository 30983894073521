import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { cmsPageContentFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let cmsPageContentFormModelArray = cmsPageContentFormModel;

class CreateCmsMenu extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        key: "H1",
        placeholder: "Header",
        column: "firstColumn",
        content: "",
        displayOrder: null,
        status: "Draft",
      },
      cmsPageId: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentPageContents();
    }
    this.setState({ cmsPageId: this.props.cmsPageId });
  }

  componentWillUnmount() {
    cmsPageContentFormModelArray = cmsPageContentFormModelArray.map(
      (fieldItem) => {
        return { ...fieldItem, errorMessage: "" };
      }
    );
  }

  fetchCurrentPageContents = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.cmsPageContent + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({ values: data.result });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let {
      key,
      placeholder,
      column,
      content,
      displayOrder,
      status,
    } = this.state.values;
    let { cmsPageId } = this.state;
    let flag = true;

    if (
      key === "" ||
      placeholder === "" ||
      column === "" ||
      content === "" ||
      displayOrder === null ||
      status === "" ||
      cmsPageId === null
    ) {
      flag = false;
      this.setState({ isLoading: false });
    } else {
      flag = true;
    }

    return flag;
  };

  handleSave = async () => {
    const { cmsPageId } = this.state;
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.cmsPageContent}/${this.props.id}`
        : URLS.cmsPageContent;

      const values = removeErrorFieldsFromValues(form.formData);
      values["pageId"] = cmsPageId;

      cmsPageContentFormModelArray = cmsPageContentFormModelArray?.map(
        (fieldItem) => {
          return setValidation(fieldItem, values);
        }
      );

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllCmsPageContents();
          this.setActionMessage(
            true,
            "Success",
            `Cms Page Content ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Cms Page Content`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    cmsPageContentFormModelArray = cmsPageContentFormModelArray?.map(
      (fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      }
    );
    this.setActionMessage(false);
  };
  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={cmsPageContentFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateCmsMenu;
