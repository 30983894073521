import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import { Loader } from "../../library/common/components";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import CreateCmsMenu from "./CreateCmsMenu";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";

class CmsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsMenu: [],
      allPage: [],
      isInputModalOpen: false,
      idToSend: null,
      isModalOpen: false,
      isLoading: false,
      itemToDelete: null,
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.fetchAllCmsMenu();
  }

  async fetchAllCmsMenu() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(URLS.cmsMenu);
    if (status === 200) {
      this.setState({ cmsMenu: data.result, isLoading: false });
      this.fetchAllPage();
    }
  }

  async fetchAllPage() {
    const { status, data } = await axiosInstance.get(URLS.cmsPage);
    if (status === 200) {
      this.setState({ allPage: data.result });
    }
  }

  getPage = (id) => {
    let { allPage } = this.state;
    let page = allPage
      .filter((obj) => obj.id == id)
      .map((object) => object.pageName);
    return page;
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      itemToDelete: null,
    });
  };

  toggleInputModal = () => {
    this.setState({
      isInputModalOpen: !this.state.isInputModalOpen,
    });
  };

  inputModal = (id, type) => () => {
    this.setState({ isInputModalOpen: true });
    if (type === "edit") {
      this.setState({ idToSend: id, isEditMode: true });
    } else {
      this.setState({ isEditMode: false });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  setItemToDelete = (id) => () => {
    this.setState({ itemToDelete: id, isModalOpen: true });
  };

  afterChangeRefresh = () => {
    this.fetchAllCmsMenu();
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `${URLS.cmsMenu}/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState({ itemToDelete: null, isModalOpen: false }, () => {
          this.fetchAllCmsMenu();
        });
        this.setActionMessage(
          true,
          "Success",
          "Cms Menu Deleted Successfully!"
        );
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occured while deleting.");
    }
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, cmsMenu } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < cmsMenu.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    var {
      cmsMenu,
      isLoading,
      isModalOpen,
      messageBox,
      isInputModalOpen,
      isEditMode,
      rowPerPage,
      currentPage,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = cmsMenu;

    if (cmsMenu?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = cmsMenu.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(cmsMenu.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left">CMS Menu</h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="addButton admin-btn-green"
                    onClick={this.inputModal(null, "create")}
                    color="primary"
                    value="Add New"
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && cmsMenu.length === 0 && <p>No Data Available</p>}
              {!isLoading && cmsMenu.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className="listTable api-provider-listTable"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Menu Text</TableCell>
                          <TableCell>Display Order</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Page</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((page, i) => (
                          <TableRow key={i}>
                            <TableCell>{page.id}</TableCell>
                            <TableCell>{page.menuText}</TableCell>
                            <TableCell>{page.displayOrder}</TableCell>
                            <TableCell>{page.status}</TableCell>
                            <TableCell>{this.getPage(page.pageId)}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={this.inputModal(page.id, "edit")}
                                color="primary"
                                className="mr10 cursor iconBtn admin-btn-green"
                              />
                              <DeleteOutlineIcon
                                onClick={this.setItemToDelete(page.id)}
                                color="secondary"
                                className="mr10 cursor iconBtn admin-btn-orange"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="tablePagination">
                    <button
                      className={
                        cmsMenu.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={cmsMenu.length / rowPerPage > 1 ? false : true}
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={cmsMenu.length / rowPerPage > 1 ? false : true}
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size="small"
                    />
                    <button
                      className={
                        cmsMenu.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={cmsMenu.length / rowPerPage > 1 ? false : true}
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>

            <ShowModal
              isModalOpen={isModalOpen}
              onClose={this.toggleModal}
              Content="Are you sure you want to delete?"
              onOkayLabel="Yes"
              onOkay={this.deleteItem}
              onCancel={this.toggleModal}
            />

            <Modal
              className="modal modal-input"
              open={isInputModalOpen}
              onClose={this.toggleInputModal}
            >
              <div
                className={"paper modal-show-scroll"}
                style={{ position: "relative" }}
              >
                <h3 className="text-center">
                  {!isEditMode ? "Create New CMS Menu" : "Edit CMS Menu"}
                </h3>
                <CancelIcon
                  className="admin-close-icon"
                  onClick={this.toggleInputModal}
                />
                <CreateCmsMenu
                  inputModal={this.toggleInputModal}
                  id={this.state.idToSend}
                  isEditMode={isEditMode}
                  fetchAllCmsMenu={this.afterChangeRefresh}
                />
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CmsMenu;
