import React from "react";
import { Grid, Divider, Chip } from "@material-ui/core";
import { EventEmitter } from "../../../../services/event";
import { ReactSVG } from "react-svg";
import { FaChevronCircleRight } from "react-icons/fa";
import hourse_img_small from "../../../../images/hourse_img_small.svg";
import greyhoundsIcon from "../../../../images/menu_icons/Greyhounds.svg";
import harnessIcon from "../../../../images/menu_icons/HarnesRacing.svg";
import moment from "moment";
import Countdown from "react-countdown";
import { Loader } from "../../../../library/common/components";

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSeeRace: "" };
  }

  componentDidMount = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  navigateRacing = (race) => {
    EventEmitter.dispatch("show_to_race", race);
  };

  showRunners = (race) => {
    if (race !== "") {
      let selectedRace =
        race?.sportId == 1
          ? "Horse Racing"
          : race?.sportId == 2
          ? "Harness Racing"
          : "Greyhounds Racing";

      let data_to_pass = {
        raceTrackClicked: true,
        full_race: race,
        selectedRaceTrack: race?.id,
        singleRaceData: [],
        title: selectedRace,
      };
      EventEmitter.dispatch("three_race_clicked", data_to_pass);
    }
  };

  renderer = ({ hours, minutes, seconds }) => {
    return (
      <Chip
        className={"allsports_racing_timer m10 "}
        style={{ cursor: "pointer" }}
        size="small"
        label={
          <span>
            {minutes <= 5 ? "" : hours > 0 ? hours + "h" : "0h"}{" "}
            {minutes > 0 ? minutes + "m" : "0m"}{" "}
            {hours == 0 && minutes <= 5
              ? seconds > 0
                ? seconds + "s"
                : "0s"
              : ""}
          </span>
        }
      />
    );
  };
  render() {
    let {
      horseRaceData,
      harnessRaceData,
      greyhoundRaceData,
      selectedCountry,
      selectedLocation,
      isThreeRaceLoading,
    } = this.props;
    let { isSeeRace } = this.state;
    horseRaceData = horseRaceData?.filter((obj) => obj?.raceNumber != 0);
    harnessRaceData = harnessRaceData?.filter((obj) => obj?.raceNumber != 0);
    greyhoundRaceData = greyhoundRaceData?.filter(
      (obj) => obj?.raceNumber != 0
    );
    return (
      <>
        {isSeeRace !== "" ? (
          this.showRunners(isSeeRace)
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <div className="allsports_body_outer">
                <div className="allsports_body_head">
                  <p>Horse</p>
                  <p
                    className={
                      !isThreeRaceLoading || horseRaceData?.length > 0
                        ? ""
                        : "disabled_text"
                    }
                    onClick={
                      !isThreeRaceLoading || horseRaceData?.length > 0
                        ? () => {
                            this.navigateRacing("Horse Racing");
                          }
                        : () => {}
                    }
                  >
                    See All Horse Racing &nbsp;
                    <FaChevronCircleRight style={{ fontSize: 16 }} />
                  </p>
                </div>
                <div className="allsports_boxing_inner">
                  {isThreeRaceLoading && (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  )}
                  {!isThreeRaceLoading &&
                    (horseRaceData?.length > 0 ? (
                      horseRaceData?.map((obj, i) => (
                        <>
                          <div
                            className="allsport_inner_main"
                            key={i}
                            onClick={() => {
                              this.setState({ isSeeRace: obj });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="allsport_inner_match m10 allsport_inner_race_name">
                              <ReactSVG src={hourse_img_small} />
                              <span
                                style={{ paddingRight: 10 }}
                                className="allsports_racing_timer race_number_width"
                              >{`R${obj?.raceNumber}`}</span>
                              {obj?.event?.eventName}
                            </p>
                            <Countdown
                              date={moment.utc(obj?.startTimeDate)}
                              renderer={this.renderer}
                              onComplete={() =>
                                setTimeout(
                                  () =>
                                    this.props.fetchThreeRacing(
                                      "1,2,3",
                                      selectedCountry,
                                      selectedLocation,
                                      false
                                    ),
                                  2000
                                )
                              }
                              key={moment.utc(obj?.startTimeDate)}
                            />
                          </div>
                          <Divider className="allsports_half_hr" />
                        </>
                      ))
                    ) : (
                      <div className="allsport-loader-center">
                        <p>No Race Available</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="allsports_body_outer">
                <div className="allsports_body_head">
                  <p>Greyhounds</p>
                  <p
                    className={
                      !isThreeRaceLoading || greyhoundRaceData?.length > 0
                        ? ""
                        : "disabled_text"
                    }
                    onClick={
                      !isThreeRaceLoading || greyhoundRaceData?.length > 0
                        ? () => {
                            this.navigateRacing("Greyhounds Racing");
                          }
                        : () => {}
                    }
                  >
                    See All Greyhounds Racing &nbsp;
                    <FaChevronCircleRight style={{ fontSize: 16 }} />
                  </p>
                </div>
                <div className="allsports_boxing_inner">
                  {isThreeRaceLoading && (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  )}
                  {!isThreeRaceLoading &&
                    (greyhoundRaceData?.length > 0 ? (
                      greyhoundRaceData?.map((obj, i) => (
                        <>
                          <div
                            className="allsport_inner_main"
                            key={i}
                            onClick={() => {
                              this.setState({ isSeeRace: obj });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="allsport_inner_match m10 allsport_inner_race_name">
                              <ReactSVG src={greyhoundsIcon} />
                              <span
                                style={{ paddingRight: 10 }}
                                className="allsports_racing_timer race_number_width"
                              >{`R${obj?.raceNumber}`}</span>
                              {obj?.event?.eventName}
                            </p>
                            <Countdown
                              date={moment.utc(obj?.startTimeDate)}
                              renderer={this.renderer}
                              onComplete={() =>
                                setTimeout(
                                  () =>
                                    this.props.fetchThreeRacing(
                                      "1,2,3",
                                      selectedCountry,
                                      selectedLocation,
                                      false
                                    ),
                                  2000
                                )
                              }
                              key={moment.utc(obj?.startTimeDate)}
                            />
                          </div>
                          <Divider className="allsports_half_hr" />
                        </>
                      ))
                    ) : (
                      <div className="allsport-loader-center">
                        <p>No Race Available</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="allsports_body_outer">
                <div className="allsports_body_head">
                  <p>Harness</p>
                  <p
                    className={
                      !isThreeRaceLoading || harnessRaceData?.length > 0
                        ? ""
                        : "disabled_text"
                    }
                    onClick={
                      !isThreeRaceLoading || harnessRaceData?.length > 0
                        ? () => {
                            this.navigateRacing("Harness Racing");
                          }
                        : () => {}
                    }
                  >
                    See All Harness Racing &nbsp;
                    <FaChevronCircleRight style={{ fontSize: 16 }} />
                  </p>
                </div>
                <div className="allsports_boxing_inner">
                  {isThreeRaceLoading && (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  )}
                  {!isThreeRaceLoading &&
                    (harnessRaceData?.length > 0 ? (
                      harnessRaceData?.map((obj, i) => (
                        <>
                          <div
                            className="allsport_inner_main"
                            key={i}
                            onClick={() => {
                              this.setState({ isSeeRace: obj });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p className="allsport_inner_match m10 allsport_inner_race_name">
                              <ReactSVG src={harnessIcon} />
                              <span
                                style={{ paddingRight: 10 }}
                                className="allsports_racing_timer race_number_width"
                              >{`R${obj?.raceNumber}`}</span>
                              {obj?.event?.eventName}
                            </p>
                            <Countdown
                              date={moment.utc(obj?.startTimeDate)}
                              renderer={this.renderer}
                              onComplete={() =>
                                setTimeout(
                                  () =>
                                    this.props.fetchThreeRacing(
                                      "1,2,3",
                                      selectedCountry,
                                      selectedLocation,
                                      false
                                    ),
                                  2000
                                )
                              }
                              key={moment.utc(obj?.startTimeDate)}
                            />
                          </div>
                          <Divider className="allsports_half_hr" />
                        </>
                      ))
                    ) : (
                      <div className="allsport-loader-center">
                        <p>No Race Available</p>
                      </div>
                    ))}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
export default AllSportsHome;
