/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
import HttpClient from './http-client';
import CommonService from './common';
import { authHandler } from '../store/common/actions/common.actions';
import StorageService from './storage';
import { STORAGE_ENGINES } from '../helpers/constants';
import { config } from '../helpers/config';

const BASE_URL = config.baseUrl;

export default class AuthService {
  constructor() {
    this.httpClient = new HttpClient();
    this.commonService = new CommonService();
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
    this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
    this.store = this.commonService.store;
  }

  async login(payload, staySignedIn) {
    try {
      const response = await this.httpClient.loginpost(
        `${BASE_URL}/user/login`,
        payload,
      );
      if (staySignedIn) {
        this.localStorage.set('auth', response.access_token);
      } else {
        this.sessionStorage.set('auth', response.access_token);
      }
      this.store.dispatch(authHandler(response));
      return response;
    } catch (err) {
      throw err;
    }
  }
}
