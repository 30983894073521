import React, { PureComponent } from "react";

import MainMenuBar from "../../mainmenubar/mainmenubar";
import HeaderTopBar from "../../headertopbar/headertopbar";
import HeaderLoginBar from "../../headerloginbar/headerloginbar";
import FooterTopBar from "../../footertopbar/footertopbar";
import FooterBottom from "../../footerbottom/footerbottom";
import "./layout.scss";

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showMenu: true };
  }

  toggleMenu = (flag) => {
    this.setState({ showMenu: flag });
  };

  render() {
    const { children } = this.props;
    const { showMenu } = this.state;
    return (
      <>
        <HeaderTopBar {...this.props} />
        <MainMenuBar {...this.props} />
        <HeaderLoginBar {...this.props} />

        <div className="content">
          <div className="SideBar" open={showMenu} />
          <div>
            <div>{children}</div>
            <div className="Footer" />
          </div>
        </div>
        <FooterTopBar {...this.props} />
        <FooterBottom {...this.props} />
      </>
    );
  }
}

export default Layout;
