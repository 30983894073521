export const categoryModel = [
  {
    label: "Sub Category Name",
    value: "",
    type: "text",
    placeholder: "",
    field: "name",
    validators: [],
    required: false,
    className: "6",
    extraProps: {},
  },
  {
    label: "Category",
    value: "",
    type: "dropdown",
    placeholder: "Position",
    field: "catId",
    validators: [],
    required: false,
    className: "6",
    options: [{ value: "", label: "Select Category" }],
  },
  {
    label: "Description",
    value: "",
    type: "editor",
    placeholder: "",
    field: "description",
    validators: [],
    required: false,
    className: "12",
    extraProps: {},
  },
];
