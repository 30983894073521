import React, { Component } from "react";
import { connect } from "react-redux";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import axiosInstance from "../../helpers/Axios";
import Loader from "../../library/common/components/Loader";
import RaceTabs from "./raceTabs";
import dropdownarrow from "../../images/dropdownarrow.svg";
import RaceTabPanels from "./raceTabPanels";
import { setSelecteSport } from "../../store/common/actions/sports.actions";
import "./racelist.scss";
import SportsMenu from "../sportlist/SportsMenu";

class RaceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
      SelectedEvent: "",
      racesDetails: [],
      runnersDetail: [],
      selectedRace: Number(props.match.params.raceNumber),
      isRunnerLoading: false,
    };
  }

  selectDropdownArrow() {
    return (
      <ReactSVG className='MuiSvgIcon-root dropdownarrow' src={dropdownarrow} />
    );
  }

  componentDidMount() {
    const { match } = this.props;
    const { SelectedEvent, eventList } = this.state;
    let SelectedEventTemp = "";
    if (match?.params?.meeting !== "") {
      SelectedEventTemp = match?.params?.meeting;
    } else if (SelectedEvent === "" && eventList?.length > 0) {
      SelectedEventTemp = eventList[0].id;
    }

    this.setState({ SelectedEvent: SelectedEventTemp });

    this.getMeetingsData();
    this.getRaceRunners();
    if (match.params?.id !== "") {
      this.props.setSelecteSport(match.params?.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { selectedRace } = this.state;
    if (prevProps.match.params?.sportName !== match?.params?.sportName) {
      this.getMeetingsData(this.state.SelectedEvent);
    }
    if (prevProps.match.params?.meeting !== match?.params?.meeting) {
      this.setState({ SelectedEvent: match?.params?.meeting });
      this.onMeetingChange(match?.params?.meeting);
    }
    if (prevState.selectedRace !== selectedRace) {
      this.getRaceRunners(this.state.selectedRace);
    }
  }

  getRaceRunners = async () => {
    const { selectedRace } = this.state;
    this.setState({ isRunnerLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `/events/runner/${selectedRace}`
      );
      if (status === 200) {
        this.setState({ runnersDetail: data.result, isRunnerLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getMeetingsData = async () => {
    const { match } = this.props;
    let SelectedEvent = match?.params?.meeting;
    let sportsId = match.params?.id;
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `/events/event/${sportsId}`
      );
      if (status === 200 && data) {
        this.setState(
          {
            eventList: data?.result,
            SelectedEvent:
              SelectedEvent !== "" ? SelectedEvent : data?.result[0].eventName,
            isLoading: false,
          },
          () => this.onMeetingChange(this.state.SelectedEvent)
        );
      } else {
        console.log("err", data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  getEventId = (SelectedEvent) => {
    const { eventList } = this.state;
    let event = eventList?.filter((obj) => obj.eventName === SelectedEvent);
    let eventId = event?.length > 0 ? event[0]?.id : "";
    return eventId;
  };

  handleChangeEvent = (event) => {
    const { value } = event.target;
    this.setState({ SelectedEvent: value });
    this.onMeetingChange(value);
  };

  onMeetingChange = async (EventName) => {
    const { match } = this.props;
    const { SelectedEvent } = this.state;
    let eventId = this.getEventId(EventName);

    let sportName = match.params?.sportName;
    let EventNameParam = EventName !== "" ? EventName : SelectedEvent;
    this.setState({ isLoading: true });
    try {
      let raceUrl = `/events/race/${eventId}`;
      // raceUrl +=
      //   typeof sportName !== "undefined" && sportName !== ""
      //     ? "sportName=" + sportName
      //     : "sportName=";
      // raceUrl +=
      //   EventNameParam !== ""
      //     ? "&meetingName=" + EventNameParam
      //     : "&meetingName=";

      const { status, data } = await axiosInstance.get(raceUrl);
      if (status === 200 && data) {
        // let racesCategories =
        //   data.categories?.length > 0 ? data.categories[0] : false;
        // let RacesList = racesCategories?.races
        //   ? racesCategories?.races[0]?.Race?.sort(function (a, b) {
        //       return a?.RaceNumber - b?.RaceNumber;
        //     })
        //   : [];

        let races = data.result;
        let selectedRace = "";
        if (
          races?.length > 0 &&
          races?.some((r) => Number(r.id) === Number(match.params.raceNumber))
        ) {
          selectedRace = Number(match.params.raceNumber);
        } else if (races?.length > 0) {
          selectedRace = races[0].id;
        }
        this.setState({
          isLoading: false,
          racesDetails: races,
          selectedRace: selectedRace,
        });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (err) {
      this.setState({ isLoading: false });
      console.log(err);
    }
  };

  handleRaceChange = (event, newValue) => {
    this.setState({ selectedRace: Number(newValue), selectedRaceType: 1 });
  };

  render() {
    const {
      isLoading,
      isRunnerLoading,
      SelectedEvent,
      eventList,
      racesDetails,
      selectedRace,
      runnersDetail,
    } = this.state;

    let runnersDetails = runnersDetail.sort(
      (a, b) => a.runnerNumber - b.runnerNumber
    );
    return (
      <>
        <SportsMenu />
        <div className='sportlist-wrap racelist-wrap'>
          <div className='activeSportTitle activeSportTitle-name'>
            {/* <Link className="backarrow" to={{  pathname: "/sports/" + this.props.selected_sport_cat_id}}><ArrowBackIosIcon /></Link> */}
            <FormControl>
              <Select
                value={SelectedEvent}
                onChange={this.handleChangeEvent}
                displayEmpty
                IconComponent={this.selectDropdownArrow}
              >
                {eventList.map((item, i) => (
                  <MenuItem key={"meeting" + i} value={item.eventName}>
                    {item.eventName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {isLoading ? (
            <div className='racelist-loader'>
              <Loader />
            </div>
          ) : (
            <RaceTabs
              SelectedEvent={SelectedEvent}
              selectedRace={selectedRace}
              handleRaceChange={this.handleRaceChange}
              racesDetails={racesDetails}
              match={this.props.match}
              isLoading={isLoading}
            />
          )}
          {!isLoading && (
            <RaceTabPanels
              selectedRace={selectedRace}
              handleRaceChange={this.handleRaceChange}
              racesDetails={racesDetails}
              runnersDetail={runnersDetails}
              isRunnerLoading={isRunnerLoading}
              match={this.props.match}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ sports }) => {
  return {
    selected_sport: sports.selected_sport,
    selected_sport_cat_id: sports.selected_sport_cat_id,
  };
};

export default connect(mapStateToProps, { setSelecteSport })(RaceDetails);
