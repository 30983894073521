import { Validators } from "../../../library/utilities/Validators";

export const playersFormModel = [
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
  {
    label: "Players Type*",
    value: "",
    type: "dropdown",
    placeholder: "Players Type",
    field: "playertypeId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [],
  },
];
