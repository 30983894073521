import React from "react";
import "../forgotpassword/forgotpassword.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import loginbg from "../../images/forgotbg.png";
import logo from "../../images/logo.svg";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      email: "",
      verification_code: "",
      resendemail: "",
      error: {},
      isLoading: false,
      isResendOtp: false,
    };
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, error: { ...prevState.error, [name]: "" } };
    });
  };

  validate = () => {
    var { email, verification_code } = this.state;
    var flage = true;
    var { error } = this.state;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === "") {
      error.email = "Email is required";
      flage = false;
    } else if (!regexp.test(email)) {
      error.email = "Invalid Email";
      flage = false;
    } else {
      error.email = "";
    }

    if (verification_code === "") {
      error.verification_code = "Verification Code is required";
      flage = false;
    }
    this.setState({ error });
    return flage;
  };

  validateResendCode = () => {
    var { resendemail, error } = this.state;
    var flage = true;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (resendemail === "") {
      error.resendemail = "Email is required";
      flage = false;
    } else if (!regexp.test(resendemail)) {
      error.resendemail = "Invalid Email";
      flage = false;
    } else {
      error.resendemail = "";
    }

    this.setState({ error });
    return flage;
  };
  submit() {
    let { email, verification_code } = this.state;
    let params = email + "/" + verification_code;
    if (this.validate()) {
      axiosInstance
        .get(URLS.verifyEmail + params)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({
              message: "your email verify successfully",
              email: "",
              verification_code: "",
            });
          }
        })
        .catch((err) => {
          if (err.hasOwnProperty("response") && err.response.status === 403) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  verification_code: err.response.data.message,
                },
              };
            });
          } else if (
            err.hasOwnProperty("response") &&
            err.response.data.message == "Invalid Code"
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  verification_code: err.response.data.message,
                },
              };
            });
          } else if (
            err.hasOwnProperty("response") &&
            err.response.data.message == "Invalid Email"
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  email: err.response.data.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  email: "There is something wrong, try again",
                },
              };
            });
          }
        });
    }
  }

  resendCode() {
    let { resendemail } = this.state;
    if (this.validateResendCode()) {
      axiosInstance
        .get(URLS.sendCode + resendemail)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({
              //message: "Email verification code send successfully",
              isResendOtp: false,
            });
          }
        })
        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  resendemail: err.response.data.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  resendemail: "There is something wrong, try again",
                },
              };
            });
          }
        });
    }
  }

  render() {
    const {
      isLoading,
      email,
      verification_code,
      resendemail,
      error,
      message,
      isResendOtp,
    } = this.state;
    return (
      <>
        <div className='page-content page-bg'>
          <Card className={"sign-page-root"}>
            <div className={"sign-page-details"}>
              <CardContent className={"sign-page-content"}>
                <Container>
                  <form
                    className='form login-form verifyemail-form'
                    noValidate
                    autoComplete='off'
                  >
                    <Grid
                      container
                      spacing={2}
                      justify='flex-start'
                      alignItems='flex-start'
                    >
                      <Grid item sm={12} xs={12}>
                        <Link to='/' className='logo'>
                          <img src={logo} alt='Motzza' />
                        </Link>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <Typography className='text-left'>
                          {isResendOtp
                            ? "Enter your email for get verification code."
                            : "Enter your email and verification code to verify your email."}
                        </Typography>
                      </Grid>
                      {isResendOtp ? (
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.resendemail !== "undefined" &&
                              error?.resendemail !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Email" }}
                              id='resendemail'
                              name='resendemail'
                              type='text'
                              value={resendemail}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof error.resendemail !== "undefined" &&
                          error.resendemail !== "" ? (
                            <FormHelperText id='component-helper-text-resendemail'>
                              <span
                                className='error color-danger'
                                style={{ marginTop: 0 }}
                              >
                                {error.resendemail}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                          {typeof message !== "undefined" && message !== "" && (
                            <FormHelperText
                              className='helper-text-successfuly'
                              id='component-helper-text-password'
                            >
                              <span className='color-green'>{message}</span>
                            </FormHelperText>
                          )}
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            item
                            className='half-size-input'
                            sm={12}
                            xs={12}
                          >
                            <FormControl
                              className={
                                typeof error?.email !== "undefined" &&
                                error?.email !== ""
                                  ? "invalid"
                                  : ""
                              }
                            >
                              <Input
                                className={" rounded-input"}
                                inputProps={{ placeholder: "Email" }}
                                id='email'
                                name='email'
                                type='text'
                                value={email}
                                onChange={this.handleChange}
                                disableUnderline
                              />
                            </FormControl>
                            {typeof error.email !== "undefined" &&
                            error.email !== "" ? (
                              <FormHelperText id='component-helper-text-email'>
                                <span className='error color-danger'>
                                  {error.email}
                                </span>
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid
                            item
                            className='half-size-input reset-password'
                            sm={12}
                            xs={12}
                          >
                            <FormControl
                              className={
                                typeof error?.verification_code !==
                                  "undefined" && error?.verification_code !== ""
                                  ? "invalid"
                                  : ""
                              }
                            >
                              <Input
                                className={" rounded-input"}
                                inputProps={{
                                  placeholder: "Verification Code",
                                }}
                                id='verification_code'
                                name='verification_code'
                                type='text'
                                value={verification_code}
                                onChange={this.handleChange}
                                disableUnderline
                              />
                              {typeof error.verification_code !== "undefined" &&
                              error.verification_code !== "" ? (
                                <FormHelperText id='component-helper-text-verification_code'>
                                  <span
                                    className='error color-danger'
                                    style={{ marginTop: 0 }}
                                  >
                                    {error.verification_code}
                                  </span>
                                </FormHelperText>
                              ) : (
                                ""
                              )}
                              {typeof message !== "undefined" &&
                                message !== "" && (
                                  <FormHelperText
                                    className='helper-text-successfuly'
                                    id='component-helper-text-password'
                                  >
                                    <span className='color-green'>
                                      {message}
                                    </span>
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid item sm={12} xs={12} className='register-button'>
                        <Button
                          type='button'
                          onClick={
                            isResendOtp
                              ? this.resendCode.bind(this)
                              : this.submit.bind(this)
                          }
                          className='btn btn-warning'
                          variant='contained'
                        >
                          {isLoading ? "Loading.." : "Submit"}
                        </Button>
                        <p
                          className='resend-link'
                          onClick={() =>
                            this.setState({
                              isResendOtp: !this.state.isResendOtp,
                              message: "",
                              email: "",
                              verification_code: "",
                              resendemail: "",
                            })
                          }
                        >
                          {isResendOtp
                            ? "Already have verifycode"
                            : "Resend Verifycode"}
                        </p>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <p className='signup'>
                          <Link to='/login'>Login</Link>
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </CardContent>
            </div>
            <CardMedia className={"sign-page-cover"} image={loginbg}>
              <img src={loginbg} alt='' />
            </CardMedia>
          </Card>
        </div>
      </>
    );
  }
}
export default VerifyEmail;
