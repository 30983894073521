import React, { Component } from "react";
import { AppBar, Toolbar, Tabs, Tab } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./racelist.scss";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

class RaceTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const { match, racesDetails } = this.props;
    // let RacesList = racesDetails?.races
    //   ? racesDetails?.races[0]?.Race?.sort(function (a, b) {
    //       return a?.RaceNumber - b?.RaceNumber;
    //     })
    //   : [];
    // if (match?.params?.raceNumber !== "") {
    //   if (
    //     RacesList?.length > 0 &&
    //     RacesList?.some((r) => r.raceNumber === match.params.raceNumber)
    //   ) {
    //     this.props.handleRaceChange("", match?.params?.raceNumber);
    //   } else if (RacesList?.length > 0) {
    //     this.props.handleRaceChange("", RacesList[0].RaceNumber);
    //   }
    // }
  }

  componentDidUpdate(prevProps) {}

  handleRaceChangeTab = (event, Races) => {
    const { racesDetails, selectedRace } = this.props;
    // let RacesList = racesDetails?.races
    //   ? racesDetails?.races[0]?.Race?.sort(function (a, b) {
    //       return a?.RaceNumber - b?.RaceNumber;
    //     })
    //   : [];
    if (event === "prev") {
      let tempselectedRace =
        selectedRace === Number(racesDetails[0]?.id)
          ? Number(racesDetails[racesDetails?.length - 1]?.id)
          : selectedRace - 1;
      this.props.handleRaceChange(event, tempselectedRace);
    } else if (event === "next") {
      let tempselectedRace =
        selectedRace === Races?.length
          ? Number(racesDetails[0]?.id)
          : selectedRace + 1;
      this.props.handleRaceChange(event, tempselectedRace);
    }
  };

  render() {
    const { racesDetails, selectedRace, isLoading } = this.props;
    // let RacesList = racesDetails?.races
    //   ? racesDetails?.races[0]?.Race?.sort(function (a, b) {
    //       return a?.RaceNumber - b?.RaceNumber;
    //     })
    //   : [];
    return (
      <>
        {racesDetails?.length > 0 && !isLoading ? (
          <AppBar
            className='racetabs race-number-tab'
            position='static'
            color='default'
          >
            <Toolbar>
              <Tabs
                value={selectedRace}
                onChange={this.props.handleRaceChange}
                variant='scrollable'
                scrollButtons='on'
                textColor='primary'
                aria-label='scrollable force tabs example'
              >
                {racesDetails?.map((item, i) => (
                  <Tab
                    key={"racenutab" + i}
                    value={Number(item.id)}
                    label={"Race " + Number(item.raceNumber)}
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
              <div className='race-tab-button'>
                <button
                  disabled={selectedRace == racesDetails[0].id}
                  onClick={() => this.handleRaceChangeTab("prev", racesDetails)}
                >
                  <FontAwesomeIcon color='white' icon={faChevronLeft} />
                </button>
                <button
                  disabled={
                    selectedRace == racesDetails[racesDetails?.length - 1].id
                  }
                  onClick={() => this.handleRaceChangeTab("next", racesDetails)}
                >
                  <FontAwesomeIcon color='white' icon={faChevronRight} />
                </button>
              </div>
            </Toolbar>
          </AppBar>
        ) : (
          <p>Abdn</p>
        )}
      </>
    );
  }
}

export default RaceTabs;
