import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  AppBar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Checkbox,
} from "@material-ui/core";
import { EventEmitter } from "../../services/event";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BetsSidebarPopup from "../betssidebardrawer/betssidebarpopup";

import { connect } from "react-redux";
import { logoutUser } from "../../library/common/actions";
import {
  fetchRacings,
  fetchUpcomingRaces,
} from "../../store/common/actions/common.actions";
import { setSelecteSportCatId } from "../../store/common/actions/sports.actions";
import ShowModal from "../common/ShowModal/ShowModal";
import UpcommingCarousel from "./UpcommingCarousel";
import SearchIcon from "@material-ui/icons/Search";
import SidebarMenu from "../sidebarmenu/sidebarmenu";
import { ReactSVG } from "react-svg";

import Hourse from "../../images/filter1.svg";
import Greys from "../../images/filter2.svg";
import Harnes from "../../images/filter3.svg";

import blank_header_top_bar from "../../images/blank_header_banner.png";
import Unchecked from "../../images/checkboxUnchekedRound.svg";
import Checked from "../../images/checkboxChekedRound.svg";

import "./headertopbar.scss";

class HeaderTopBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      races: [],
      searchrace: "",
      filtermenu: "none",
      filterbg: "#00833e",
      filter: "Horse Racing",
      checkedFilter: "Horse Racing",
      filterRaceType: [
        {
          id: 1,
          value: "Horse Racing",
          isChecked: true,
          icon: (
            <span>
              <ReactSVG src={Hourse} />
            </span>
          ),
        },
        {
          id: 3,
          value: "Greyhound Racing",
          isChecked: true,
          icon: (
            <span>
              <ReactSVG src={Greys} />
            </span>
          ),
        },
        {
          id: 2,
          value: "Harness Racing",
          isChecked: true,
          icon: (
            <span>
              <ReactSVG src={Harnes} />
            </span>
          ),
        },
      ],
      filterCountry: [
        {
          id: 1,
          value: "Aus/NZ",
          isChecked: true,
        },
        {
          id: 2,
          value: "intl",
          isChecked: true,
        },
      ],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.setSelecteSportCatId(match?.params?.id);
    if (this.props.isLoggedIn && this.props.racings.length === 0) {
      // this.props.fetchRacings(match?.params?.id);
    }
    // if (!window.location.href.includes("admin")) {
    //   this.props.fetchUpcomingRaces(match?.params?.id);
    // }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.props.isLoggedIn &&
      this.props.racings.length === 0
    ) {
      // this.props.fetchRacings(match?.params?.id);
    }
    if (match?.params?.id !== prevProps.match?.params?.id) {
      this.props.setSelecteSportCatId(match?.params?.id);
      EventEmitter.dispatch("resetbetsList");
      if (this.props.isLoggedIn) {
        // this.props.fetchRacings(match?.params?.id);
      }
    }
  }

  logout = () => {
    this.props.logoutUser();
    this.props.history.push("/");
  };

  handleFiltermenu = () => {
    this.setState({
      filtermenu: this.state.filtermenu === "none" ? "block" : "none",
      filterbg: this.state.filterbg === "#00833e" ? "#6abf4b" : "#00833e",
    });
  };
  handleFiltermenuClose = () => {
    this.setState({ filtermenu: "none", filterbg: "#00833e" });
  };
  handleFilter = (e) => {
    this.setState({
      filter: this.state.filter === e.target.value ? "" : e.target.value,
      checkedFilter:
        this.state.checkedFilter === e.target.value ? "" : e.target.value,
    });
  };

  handleChangeTypeFilter = (event) => {
    const { value, checked } = event.target;
    let { filterRaceType } = this.state;
    filterRaceType = filterRaceType?.map((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return item;
    });
    let allremoved = filterRaceType.filter((item) => item.isChecked);
    if (allremoved.length >= 1)
      this.setState({ filterRaceType: filterRaceType });
  };

  handleChangeCountryFilter = (event) => {
    const { value, checked } = event.target;
    let { filterCountry } = this.state;
    filterCountry = filterCountry?.map((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return item;
    });
    let allremoved = filterCountry.filter((item) => item.isChecked);
    if (allremoved.length >= 1) this.setState({ filterCountry: filterCountry });
  };

  handleSearchRace = (event) => {
    this.setState({ searchrace: event.target.value });
  };
  render() {
    const { isLoggedIn } = this.props;
    return (
      <>
        <AppBar position='static' className='bg-green top-bar ' elevation={0}>
          <Container className='blank-header-bar'>
            <img src={blank_header_top_bar} alt='' />
          </Container>
          <Container className='header-bar'>
            {isLoggedIn && <SidebarMenu />}
            {/*** desktop header */}
            <div className='NextoJump-wrap hide-xs hide-sm-tab'>
              <div className='NextoJump-Item NextoJump-Title'>
                <span>Next to Jump</span>
              </div>
              <ClickAwayListener onClickAway={this.handleFiltermenuClose}>
                <div
                  className='NextoJump-Item NextoJump-Filteritem'
                  style={{ background: this.state.filterbg }}
                >
                  <p className='innerTitle' onClick={this.handleFiltermenu}>
                    Filter
                  </p>
                  <div
                    style={{ display: this.state.filtermenu }}
                    className='NextoJump-Filteritemlist'
                  >
                    <ul className='NextoJump-Filteritemlist-ul'>
                      {this.state.filterRaceType?.length > 0 &&
                        this.state.filterRaceType?.map((filter, i) => (
                          <li key={("racetype", i)}>
                            <label>
                              <Checkbox
                                icon={<ReactSVG src={Unchecked} />}
                                checkedIcon={<ReactSVG src={Checked} />}
                                name='filter'
                                value={filter?.value}
                                onChange={this.handleChangeTypeFilter}
                                checked={filter?.isChecked}
                              />
                              {filter?.icon}
                            </label>
                          </li>
                        ))}
                    </ul>
                    <ul>
                      {this.state.filterCountry?.length > 0 &&
                        this.state.filterCountry?.map((countryItem, i) => (
                          <li key={"filtercountry" + i}>
                            <label>
                              <Checkbox
                                icon={<ReactSVG src={Unchecked} />}
                                checkedIcon={<ReactSVG src={Checked} />}
                                name='filtercountry'
                                value={countryItem?.value}
                                onChange={this.handleChangeCountryFilter}
                                checked={countryItem?.isChecked}
                              />
                              {countryItem?.value}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </ClickAwayListener>
              <div className='NextoJump-Item NextoJump-Search'>
                <input
                  className='NexttoJump-Searchinput'
                  placeholder='Search'
                  onChange={this.handleSearchRace}
                />
                <SearchIcon />
              </div>

              <UpcommingCarousel
                filter={this.state.filterRaceType}
                country={this.state.filterCountry}
                search={this.state.searchrace}
              />

              {isLoggedIn && (
                <div className='NextoJump-Item text-right right-menu'>
                  {!isLoggedIn ? (
                    <>
                      {/* <ListItem className="text-right">
                      <ListItemText className="cursor-pointer">
                        Secured using...
                      </ListItemText>
                    </ListItem> */}
                      {/*<LoginForm {...this.props} />*/}
                    </>
                  ) : (
                    <>
                      <List component='ul' className='float-right'>
                        <ListItem className='text-right'>
                          <ListItemText className='cursor-pointer account-btn mr-5'>
                            <Link to='/userdetail'>Account</Link>
                          </ListItemText>
                          {/* <ListItemIcon>
                            <IoIosLock fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText
                            className="cursor-pointer"
                            onClick={this.logout}
                          >
                            Logout
                          </ListItemText> */}
                        </ListItem>
                      </List>
                      <div className='BetsSidebarPopup-wrap'>
                        {isLoggedIn && <BetsSidebarPopup />}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            {/*** mobile header */}
            <Grid
              className='hide-xl hide-lg hide-md hide-lg-tab header-mobile MuiGrid-spacing-xs-0'
              container
              spacing={1}
              alignItems='center'
            >
              <Grid item xs={isLoggedIn ? 4 : 4}>
                <div className='NextoJump-Item NextoJump-Searchmain'>
                  <input
                    className='NexttoJump-Searchinput'
                    placeholder='Search'
                  />
                  <SearchIcon />
                </div>
              </Grid>

              {/* <Grid item xs={10}>
                <div className="NextoJump-wrap ">
                  {/* <UpcommingCarousel /> 
                </div>
              </Grid> */}
              {isLoggedIn ? (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={6} className='header-right-mobile'>
                    <SidebarMenu />
                    {/* <p>{user && user.username}</p> */}
                    {/* <div className="account account-header">
                      <Button
                        // className="submit-btn bg-green"
                        className="btn"
                        variant="contained"
                      >
                        <Link to="/login">Account</Link>
                      </Button>
                    </div>
                    <div className="signout signout-header">
                      <Button
                        className="btn"
                        onClick={this.logout}
                        variant="contained"
                      >
                        Sign Out
                      </Button>
                    </div> */}
                    {/* <p
                      className="cursor-pointer float-right"
                      style={{ margin: 0 }}
                      onClick={this.logout}
                    >
                      <ExitToAppIcon />
                    </p> */}
                  </Grid>
                </>
              ) : (
                <div className='height'></div>
              )}
            </Grid>
          </Container>
        </AppBar>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer, common, sports }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    racings: common.racings,
    user: authReducer.user,
    upcomingRaces: common.upcomingRaces,
    selected_sport_cat_id: sports.selected_sport_cat_id,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    fetchRacings,
    fetchUpcomingRaces,
    setSelecteSportCatId,
  })(HeaderTopBar)
);
