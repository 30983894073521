import React from "react";
import { Grid, Paper } from "@material-ui/core";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";

class CreatePlayers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      participantsId: null,
      participantsTypeId: null,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const { allEvents, allPlayers, participantsTypeId } = this.props;
    if (this.props.isEditMode) {
      this.fetchCurrentParticipants(this.props.id);
    }
    this.setState({
      participantsTypeId: participantsTypeId,
      eventId: allEvents.length > 0 ? allEvents[0].id : null,
      participantsId: allPlayers.length > 0 ? allPlayers[0].id : null,
    });
  }

  fetchCurrentParticipants = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.participants + `/${id}`
    );
    if (status === 200) {
      this.setState({
        eventId: data.result.eventId,
        participantsId: data.result.participantsId,
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { eventId, participantsId, error } = this.state;
    let flag = true;

    if (eventId === null) {
      flag = false;
      error.eventId = "This field is mandatory";
      this.setState({ isLoading: false });
    } else {
      flag = true;
      error.eventId = "";
    }
    if (participantsId === null) {
      flag = false;
      error.participantsId = "This field is mandatory";
      this.setState({ isLoading: false });
    } else {
      flag = true;
      error.participantsId = "";
    }

    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { participantsTypeId, eventId, participantsId } = this.state;
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.participants}/${this.props.id}`
        : URLS.participants;

      const values = {};
      values["participantsTypeId"] = participantsTypeId;
      values["eventId"] = eventId;
      values["participantsId"] = participantsId;

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllParticipants();
          this.setActionMessage(
            true,
            "Success",
            `Participants ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Participants`
      );
    }
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "eventId") {
      this.setState({
        eventId: value,
        error: { ...this.state.error, ["eventId"]: "" },
      });
    } else {
      this.setState({
        participantsId: value,
        error: { ...this.state.error, ["participantsId"]: "" },
      });
    }
    this.setActionMessage(false);
  };

  render() {
    var { messageBox, isLoading, eventId, participantsId, error } = this.state;
    var { isEditMode, allEvents, allPlayers } = this.props;
    return (
      <>
        <Grid container className='page-content adminLogin text-left'>
          <Grid item xs={12}>
            <Paper className='pageWrapper api-provider'>
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div>
                <label>Event*</label>
                <select
                  className='select-box-manual'
                  name='eventId'
                  onChange={this.handleSelect}
                  value={eventId}
                >
                  {allEvents?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.eventName}
                    </option>
                  ))}
                </select>
                {typeof error.eventId !== "undefined" &&
                  error.eventId !== "" && (
                    <p style={{ color: "red", margin: "-18px 0 0 0" }}>
                      {error.eventId}
                    </p>
                  )}
                <label>Participant*</label>
                <select
                  className='select-box-manual'
                  name='participantsId'
                  onChange={this.handleSelect}
                  value={participantsId}
                >
                  {allPlayers?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.name}
                    </option>
                  ))}
                </select>
                {typeof error.participantsId !== "undefined" &&
                  error.participantsId !== "" && (
                    <p style={{ color: "red", margin: "-18px 0 0 0" }}>
                      {error.participantsId}
                    </p>
                  )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className='mt-3 admin-btn-green'
                        onClick={this.handleSave}
                        color='primary'
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className='mt-3 admin-btn-orange'
                        onClick={this.handleSave}
                        color='secondary'
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className='mr-lr-30'
                      value='Back'
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreatePlayers;
