import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Visibility from "@material-ui/icons/Visibility";

import loginbg from "../../images/loginbg.png";
import loginMBG from "../../images/loginMBG.png";
import logo from "../../images/logo.svg";
import "./login.scss";
import AuthService from "../../services/auth";
import { STORAGE_ENGINES, PAGEMETASVALUES } from "../../helpers/constants";
import StorageService from "../../services/storage";
import { connect } from "react-redux";
import {
  getAuth,
  logoutUser,
  setAuthentication,
} from "../../library/common/actions";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EventEmitter } from "../../services/event";

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#00833e",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logindata: {
        username: "",
        password: "",
        // terms: false,
        // age: false,
        remember_me: false,
        isLoading: false,
        showPassword: false,
      },
      error: {},
    };
    this.AuthService = new AuthService();
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
    this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
  }

  componentDidMount() {
    PAGEMETASVALUES?.login &&
      EventEmitter.dispatch("setPageMeta", [PAGEMETASVALUES?.login]);
    this.props.getAuth();
    let remember_me = this.localStorage.get("remember_me");
    let logindata = this.localStorage.get("logindata");
    if (remember_me && logindata?.username !== "") {
      this.setState((prevState) => {
        return {
          logindata: {
            ...prevState.logindata,
            username: logindata?.username,
            password: logindata?.password,
            remember_me: logindata?.remember_me,
          },
        };
      });
    }
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return {
        logindata: { ...prevState.logindata, [name]: value },
        error: { ...prevState.error, [name]: "" },
      };
    });
  };

  handleChackbox = (e) => {
    const { checked, name } = e.target;

    this.setState((prevState) => {
      return {
        logindata: { ...prevState.logindata, [name]: checked ? true : false },
        error: { ...prevState.error, [name]: "" },
      };
    });
  };

  validate() {
    var { username, password /*terms, age*/ } = this.state.logindata;
    var { error } = this.state;
    var flag = true;
    if (username === "") {
      error.username = "Please complete this field";
      flag = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
      error.username = "invalid email id";
      flag = false;
    } else {
      error.username = "";
    }
    if (password === "") {
      error.password = "Please complete this field";
      flag = false;
    } else if (password.length < 6) {
      error.password = "Password Must be 6 character";
      flag = false;
    } else {
      error.password = "";
    }
    // if (terms === "" || Boolean(terms) === false) {
    //   error.terms = "Terms and condition is required";
    //   flag = false;
    // } else {
    //   error.terms = "";
    // }
    // if (age === "" || Boolean(age) === false) {
    //   error.age = "Confirm your age required";
    //   flag = false;
    // } else {
    //   error.age = "";
    // }
    this.setState({ error });
    return flag;
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  async onSubmit() {
    const { logindata } = this.state;
    if (this.validate()) {
      // const res = await this.AuthService.login(logindata, remember_me);
      this.setState({ isLoading: true });
      let jwt = require("jsonwebtoken");
      const config = {
        auth: {
          username: logindata.username,
          password: logindata.password,
        },
      };

      axiosInstance
        .post(URLS.login, logindata, config)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.props.setAuthentication(data);
            let user_obj = jwt.decode(data.access_token);
            if (logindata?.remember_me) {
              this.localStorage.set("remember_me", logindata?.remember_me);
              this.localStorage.set("logindata", logindata);
            } else {
              this.localStorage.remove("remember_me");
              this.localStorage.remove("logindata");
            }
            if (logindata?.remember_me) {
              this.localStorage.set("user", user_obj);
              this.sessionStorage.set("user", user_obj);
            } else {
              this.sessionStorage.set("user", user_obj);
            }
            this.props.history.push("/");
          }
        })

        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err?.response?.status === 401 || err?.response?.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  password: err?.response?.data?.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  username: "Something went wrong. Please try again",
                },
              };
            });
          }
          this.setState({ isLoading: false });
        });
    }
  }

  loggedRedirect = () => {
    if (this.props.isLoggedIn) {
      return <Redirect to={"/"} />;
    }
  };

  render() {
    const { isLoading, error } = this.state;
    return (
      <>
        {this.loggedRedirect()}
        <div className="page-content page-bg ">
          <Card className={"sign-page-root login-bg"}>
            <div className={"sign-page-details"}>
              <CardContent className={"sign-page-content signin-page-content"}>
                <Container>
                  <div className={"sign-page-mbg"}>
                    <form
                      className="form login-form"
                      noValidate
                      autoComplete="off"
                    >
                      <Grid
                        container
                        spacing={2}
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item sm={12} xs={12}>
                          <Link to="/" className="logo">
                            <img src={logo} alt="Motzza" />
                          </Link>
                        </Grid>
                        <Grid item sm={12} xs={12} className="input-grid">
                          {isLoading && <CircularProgress />}
                          <FormControl
                            className={
                              typeof error?.username !== "undefined" &&
                              error?.username !== ""
                                ? "invalid"
                                : "aa"
                            }
                          >
                            {/* <InputLabel shrink htmlFor="email">Email Address</InputLabel> */}
                            <Input
                              className={
                                this.props.inputClass +
                                " rounded-input " +
                                (typeof error?.username !== "undefined" &&
                                error?.username !== ""
                                  ? "invalid"
                                  : "")
                              }
                              inputProps={{ placeholder: "Email Address" }}
                              id="username"
                              name="username"
                              value={this.state.logindata.username}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof this.state.error.username !== "undefined" &&
                          this.state.error.username !== "" ? (
                            <FormHelperText id="component-helper-text-username">
                              <span className="error color-danger text-field-error">
                                {this.state.error.username}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item sm={12} xs={12} className="input-grid">
                          <FormControl
                            className={
                              typeof error?.password !== "undefined" &&
                              error?.password !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="password">Password</InputLabel> */}
                            <Input
                              className={
                                this.props.inputClass +
                                " rounded-input " +
                                (error?.password !== "undefined" &&
                                error?.password !== ""
                                  ? "invalid"
                                  : "")
                              }
                              inputProps={{ placeholder: "Password" }}
                              id="password"
                              name="password"
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              value={this.state.logindata.password}
                              onChange={this.handleChange}
                              disableUnderline
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    //onMouseDown={handleMouseDownPassword}
                                  >
                                    {this.state.showPassword ? (
                                      <Visibility className="visiblity" />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {typeof this.state.error.password !== "undefined" &&
                          this.state.error.password !== "" ? (
                            <FormHelperText id="component-helper-text-password">
                              <span className="error color-danger text-field-error">
                                {this.state.error.password}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {/* <Grid
                          item
                          sm={12}
                          xs={12}
                          className="login-text checkbox-grid terms-grid login-checkbox"
                        >
                          <FormControlLabel
                            value="true"
                            name="terms"
                            control={
                              <GreenCheckbox
                                required
                                color="primary"
                                checked={this.state.terms}
                                onChange={this.handleChackbox}
                              />
                            }
                            label={
                              <>
                                {" "}
                                I have read and accept the{" "}
                                <Link to="/terms" className="underline">
                                  Terms & Conditions
                                </Link>
                              </>
                            }
                            labelPlacement="end"
                            className="terms"
                          />
                          {typeof this.state.error.terms !== "undefined" &&
                          this.state.error.terms !== "" ? (
                            <FormHelperText id="component-helper-text-terms">
                              <span className="error color-danger text-check-error">
                                {this.state.error.terms}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className="login-text checkbox-grid login-checkbox"
                        >
                          <FormControlLabel
                            value="true"
                            name="age"
                            control={
                              <GreenCheckbox
                                required
                                color="primary"
                                checked={this.state.age}
                                onChange={this.handleChackbox}
                              />
                            }
                            label="I am 18 years old or over"
                            labelPlacement="end"
                            className="age"
                          />
                          {typeof this.state.error.age !== "undefined" &&
                          this.state.error.age !== "" ? (
                            <FormHelperText id="component-helper-text-age">
                              <span className="error color-danger text-check-error">
                                {this.state.error.age}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid> */}
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className="login-text remember-grid"
                        >
                          <FormControlLabel
                            value="true"
                            name="remember_me"
                            control={
                              <GreenCheckbox
                                required
                                color="primary"
                                checked={this.state?.logindata?.remember_me}
                                onChange={this.handleChackbox}
                              />
                            }
                            label="Remember me"
                            labelPlacement="end"
                            className="checkbox-wrap"
                          />
                        </Grid>

                        <Grid item sm={12} xs={12} className="register-button">
                          <Button
                            onClick={this.onSubmit.bind(this)}
                            // className="submit-btn bg-green"
                            className="btn btn-success login_btn"
                            variant="contained"
                          >
                            LOGIN
                          </Button>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <p className="signup">
                            Not a member?{" "}
                            <Link className="signup-text" to="/register">
                              Sign Up
                            </Link>
                          </p>
                          <Link
                            className="underline-forgot"
                            to="/forgotpassword"
                          >
                            Forgot Password
                          </Link>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Container>
              </CardContent>
            </div>
            <div className="login-image-mobile">
              <img src={loginMBG} alt="" />
            </div>
            <CardMedia
              className={"sign-page-cover hide-sm hide-xs hide-bg"}
              image={loginbg}
            >
              <img src={loginbg} alt="" />
            </CardMedia>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

Login.propTypes = {
  auth: PropTypes.object,
};

Login.defaultProps = {
  auth: {},
};
export default withRouter(
  connect(mapStateToProps, { getAuth, setAuthentication, logoutUser })(Login)
);
