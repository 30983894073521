import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import {
  AppBar,
  Container,
  Grid,
  Button,
  SwipeableDrawer,
} from "@material-ui/core";
import { FaTimes } from "react-icons/fa";

import LoginForm from "../loginform/loginform";
import { logoutUser } from "../../library/common/actions";
import logo from "../../images/logo.svg";
import { connect } from "react-redux";

class FooterTopBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  logout = () => {
    this.props.logoutUser();
    this.props.history.push("/");
  };
  render() {
    return (
      <>
        <AppBar
          position="static"
          className="footer-top-bar appbarpad"
          elevation={0}
        >
          <Container>
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid
                item
                lg={6}
                md={5}
                sm={6}
                xs={5}
                className="text-left footer-logo-wrap"
              >
                <Link to="/">
                  <img src={logo} className="App-logo-main" alt="logo" />
                </Link>
              </Grid>
              <Grid item lg={6} md={7} sm={6} xs={7}>
                <div className="login-buttons">
                  <LoginForm />
                  {/* {!this.state.user ? (
                    <>
                      <LoginForm />
                    </>
                  ) : (
                    ""
                  )} */}
                </div>
                {/* {this.props.login ? (
                  <>
                    {" "}
                    <div className="hide-xl hide-lg hide-md hide-sm login-buttons">
                      <div className="account">
                        <Button
                          // className="submit-btn bg-green"
                          className="btn"
                          variant="contained"
                        >
                          <Link to="/login">Account</Link>
                        </Button>
                      </div>
                      <div className="signout">
                        <Button
                          className="btn"
                          variant="contained"
                          onClick={this.logout}
                        >
                          Sign Out
                        </Button>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  <div className="hide-xl hide-lg hide-md hide-sm login-buttons">
                    <LoginForm />
                  </div>
                )} */}
              </Grid>
            </Grid>
          </Container>
        </AppBar>
      </>
    );
  }
}
const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    user: authReducer.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { logoutUser })(FooterTopBar)
);
