import React, { PureComponent } from "react";
import { Chip } from "@material-ui/core";
import Countdown from "react-countdown";

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return (
      <div className="time-counter">
        <span>
          {"0m"} {"0s"}
        </span>
      </div>
    );
  } else {
    // Render a countdown
    return (
      <div className="time-counter">
        <span>
          {hours > 0 ? hours + "h" : ""} {minutes + "m"} {seconds + "s"}
          {/* {hours > 0 ? hours * 60 + minutes + "m" : minutes + "m"}{" "}
          {seconds + "s"} */}
        </span>
      </div>
    );
  }
};

const chiprenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return (
      <Chip
        className={(minutes < 5 ? "bg-orange" : "") + " sqare-chip "}
        size="small"
        label={
          <span>
            {"0m"} {"0s"}
          </span>
        }
      />
    );
  } else {
    // Render a countdown
    return (
      <Chip
        className={(minutes < 5 ? "bg-orange" : "") + " sqare-chip "}
        size="small"
        label={
          <span>
            {hours > 0 ? hours + "h" : ""} {minutes > 0 ? minutes + "m" : ""}{" "}
            {seconds > 0 ? seconds + "s" : ""}
          </span>
        }
      />
    );
  }
};

class TimeCounter extends PureComponent {
  render() {
    const { chip, time } = this.props;
    return (
      <>
        {Boolean(chip) === true ? (
          <Countdown
            date={time}
            renderer={chiprenderer}
            onComplete={() => {
              this.props.onCompleteTime && this.props.onCompleteTime();
            }}
          />
        ) : (
          <Countdown
            date={time}
            renderer={renderer}
            onComplete={() => {
              this.props.onCompleteTime && this.props.onCompleteTime();
            }}
          />
        )}
      </>
    );
  }
}

export default TimeCounter;
