import React, { Component } from "react";
import { Tabs, Tab } from "@material-ui/core";
import "./tablist.scss";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

class TabList extends Component {
  render() {
    const {
      tabs,
      selectedTab,
      handleChange,
      itemClasses,
      containerClasses,
      isRugby = false,
    } = this.props;
    return (
      <>
        <div className={"tablistContainer " + containerClasses}>
          <Tabs
            className={"tablistWrap"}
            value={selectedTab && selectedTab.id}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {tabs.map((item, i) => (
              <Tab
                key={i}
                className={"tablistItem " + itemClasses}
                value={item.id}
                label={isRugby ? item.leagueName : item.MeetingName}
                icon={item.icon}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </div>
      </>
    );
  }
}

export default TabList;
