import React, { PureComponent } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Grid, AppBar } from "@material-ui/core";

import "./betssidebardrawer.scss";
import BetsSidebar from "../betssidebar/betssidebar";
import BetsOddsSlip from "../betsoddsslip/betsoddsslip";

import RugbySidebar from "../rugbysidebar/rugby.sidebar";
import logo from "../../images/logo.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { EventEmitter } from "../../services/event";

class BetsSidebarDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    EventEmitter.subscribe("draweropen", this.toggleMenu);
  }

  toggleMenu() {
    this.setState((prevstate) => {
      return { showMenu: !prevstate.showMenu };
    });
  }

  render() {
    const { sidebar, type } = this.props;

    return (
      <>
        <div
          className={
            "BetsSidebarDrawer " + (this.state.showMenu ? "active" : "")
          }
        >
          <AppBar
            position="static"
            className="bets-top-bar bets-top-header"
            elevation={0}
          >
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={2}>
                <Button className="MenuTopBar-button" onClick={this.toggleMenu}>
                  <FaBars />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Link to="/">
                  <img src={logo} className="App-logo-main" alt="logo" />
                </Link>
              </Grid>
              <Grid item xs={6} className="text-right">
                <Button
                  className="MenuTopBar-button"
                  variant="contained"
                  color="secondary"
                  onClick={this.toggleMenu}
                >
                  <FaTimes />
                </Button>
              </Grid>
            </Grid>
          </AppBar>
          {sidebar === "rugby" ? (
            <RugbySidebar key="rugbysidebar" type={type} />
          ) : (
            /* <BetsSidebar key="sportidebar" /> */
            <BetsOddsSlip key="sportidebar" />
          )}
        </div>
        <Button
          variant="contained"
          size="small"
          className="BetsSidebar-button btn btn-warning color-white"
          onClick={this.toggleMenu}
        >
          Odds
        </Button>
      </>
    );
  }
}

export default BetsSidebarDrawer;
