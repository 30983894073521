const items = [
  { label: "Pages", value: "/admin/dashboard", isDropdown: false },
  { label: "Blogs", value: "/admin/blog", isDropdown: false },
  { label: "Users", value: "/admin/users", isDropdown: false },
  {
    label: "CMS pages",
    value: "/admin/cmspages",
    isDropdown: true,
    subMenu: [
      {
        label: "CMS pages",
        value: "/admin/cmspages",
      },
      {
        label: "CMS Menu",
        value: "/admin/cmsmenu",
      },
    ],
  },
  {
    label: "AdPlaceHolder",
    value: "",
    isDropdown: true,
    subMenu: [
      {
        label: "AdPlaceHolder",
        value: "/admin/adplaceholder",
      },
      {
        label: "Banners",
        value: "/admin/banners",
      },
    ],
  },
  // {
  //   label: "Ad Management",
  //   value: "/admin/ads",
  //   isDropdown: true,
  //   subMenu: [
  //     {
  //       label: "Ad Management",
  //       value: "/admin/ads",
  //     },
  //     {
  //       label: "Ad Reports",
  //       value: "/admin/ad-reports",
  //     },
  //     {
  //       label: "AdPlaceHolder",
  //       value: "/admin/adplaceholder",
  //       isDropdown: false,
  //     },
  //   ],
  // },
  // {
  //   label: "Media",
  //   value: "/admin/media",
  //   isDropdown: false,
  // },
  /* {
    label: "Motzza Category",
    value: "/admin/category",
    isDropdown: true,
    subMenu: [
      {
        label: "Category",
        value: "/admin/category",
      },
      {
        label: "Sub Category",
        value: "/admin/sub-category",
      },
      {
        label: "Affiliate Mapping",
        value: "/admin/affiliate",
      },
      {
        label: "GenerationWeb MeetingMapping",
        value: "/admin/gwmeetingmapping",
        isDropdown: false,
      },
    ],
  }, */
  // {
  //   label: "Footer Logo",
  //   value: "/admin/footer-logo",
  //   isDropdown: false,
  // },

  {
    label: "API Provider",
    value: "/admin/apiprovider",
    isDropdown: true,
    subMenu: [
      {
        label: "API Provider",
        value: "/admin/apiprovider",
      },
      {
        label: "API Key Identifire",
        value: "/admin/apikeyidentifire",
      },
      // {
      //   label: "Api Provider Apis Feild Mapping",
      //   value: "/admin/apiprovider/apifieldmapping",
      // },
      {
        label: "Bookkeeper",
        value: "/admin/bookkeeper",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Sports",
    value: "",
    isDropdown: true,
    subMenu: [
      {
        label: "Sports",
        value: "/admin/sports",
      },
      {
        label: "Sport Type",
        value: "/admin/sporttype",
      },
    ],
  },
  {
    label: "Events",
    value: "",
    isDropdown: true,
    subMenu: [
      {
        label: "Events",
        value: "/admin/events",
      },
      {
        label: "Event Option Values",
        value: "/admin/eventoptionvalues",
      },
      {
        label: "Api Event Identifire",
        value: "/admin/apieventidentifire",
      },
      {
        label: "League",
        value: "/admin/league",
      },
    ],
  },
  {
    label: "Markets",
    value: "/admin/markets",
    isDropdown: true,
    subMenu: [
      {
        label: "Markets",
        value: "/admin/markets",
      },
      {
        label: "Markets Values",
        value: "/admin/markets/marketsValues",
      },
      {
        label: "Market Value keys",
        value: "/admin/markets/marketvaluekeys",
      },
    ],
  },
  {
    label: "Players",
    value: "/admin/players",
    isDropdown: true,
    subMenu: [
      {
        label: "Players Type",
        value: "/admin/playerstype",
        isDropdown: true,
      },
      {
        label: "Players",
        value: "/admin/players",
        isDropdown: true,
      },
      {
        label: "Player Option Values",
        value: "/admin/playeroptionvalues",
        isDropdown: false,
      },
      {
        label: "Animal Types",
        value: "/admin/animaltypes",
        isDropdown: false,
      },
    ],
  },
  {
    label: "Locations",
    value: "/admin/locations",
    isDropdown: true,
    subMenu: [
      {
        label: "Locations",
        value: "/admin/locations",
      },
      // {
      //   label: "Addresses",
      //   value: "/admin/addresses",
      // },
      {
        label: "Countries",
        value: "/admin/countries",
      },
      {
        label: "Weather",
        value: "/admin/weather",
      },
    ],
  },
  {
    label: "Distance",
    value: "/admin/distance",
    isDropdown: false,
  },
  // {
  //   label: "Fixtures",
  //   value: "/admin/fixtures",
  //   isDropdown: false,
  // },
  {
    label: "Teams",
    value: "/admin/teams",
    isDropdown: false,
  },
  {
    label: "Participants",
    value: "",
    isDropdown: true,
    subMenu: [
      // {
      //   label: "Participants",
      //   value: "/admin/participants",
      // },
      {
        label: "Participants Type",
        value: "/admin/participantstype",
      },
      {
        label: "Participants Value",
        value: "/admin/participantsvalue",
      },
    ],
  },
  {
    label: "Race Table",
    value: "",
    isDropdown: true,
    subMenu: [
      {
        label: "Race Table",
        value: "/admin/racetable",
      },
      {
        label: "Race Table Option Values",
        value: "/admin/racetableoptionvalues",
      },
      {
        label: "Api Race Identifire",
        value: "/admin/apiraceidentifire",
      },
      {
        label: "Track",
        value: "/admin/track",
      },
    ],
  },
  {
    label: "Racing Participants",
    value: "/admin/racingparticipants",
    isDropdown: true,
    subMenu: [
      {
        label: "Racing Participants",
        value: "/admin/racingparticipants",
      },
      {
        label: "Racing Participants Member",
        value: "/admin/racingparticipantsmember",
      },
      {
        label: "Animal",
        value: "/admin/animal",
      },
      {
        label: "Vehicle",
        value: "/admin/vehicle",
      },
    ],
  },
  {
    label: "Import Fixtures & Odds ",
    value: "/admin/importOdds",
  },
  // {
  //   label: "Import Fixtures Using CSV ",
  //   value: "/admin/importCsv",
  // },
  // {
  //   label: "Categories",
  //   value: "/admin/categories",
  //   isDropdown: false,
  // },
  {
    label: "Contacts Requests",
    value: "/admin/contact",
    isDropdown: false,
  },
  {
    label: "Stats",
    value: "",
    isDropdown: true,
    subMenu: [
      {
        label: "Stats Keys",
        value: "/admin/statskeys",
      },
      {
        label: "Stats",
        value: "/admin/stats",
      },
    ],
  },
];

export default items;
