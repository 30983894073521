import React, { Component } from "react";
import moment from "moment";
import { AppBar, Toolbar, Tabs, Tab, Typography } from "@material-ui/core";
import Loader from "../../library/common/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import TabPanel from "../common/tablists/TabPanel";
import RunnersTable from "./runnersTable";
import "./racelist.scss";
import racing_title_warning from "../../images/racing-title-warning.svg";
import { ReactSVG } from "react-svg";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

class RaceTabPanels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRaceType: 1,
      raceTypes: [
        { id: 1, title: "Win/Place" },
        { id: 2, title: "Quinella" },
        { id: 3, title: "Trifecta" },
        { id: 4, title: "Odds & Evens" },
        { id: 5, title: "Trip" },
        { id: 6, title: "First 4" },
        { id: 7, title: "Exacta" },
        { id: 8, title: "Duet" },
        { id: 9, title: "Bundle Bet" },
        { id: 10, title: "Parlay" },
        { id: 11, title: "R Double" },
        { id: 12, title: "Quaddie" },
        { id: 13, title: "Win" },
      ],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  handleRaceTypesChange = (event, newValue) => {
    this.setState({ selectedRaceType: newValue });
  };

  handleChangeTypeTab = (event, Races) => {
    const { selectedRaceType } = this.state;
    if (event === "prev") {
      let tempselectedRaceType =
        selectedRaceType === 1
          ? Races[Races?.length - 1].id
          : selectedRaceType - 1;
      this.handleRaceTypesChange(event, tempselectedRaceType);
    } else if (event === "next") {
      let tempselectedRaceType =
        selectedRaceType === Races?.length ? Races[0].id : selectedRaceType + 1;
      this.handleRaceTypesChange(event, tempselectedRaceType);
    }
  };

  render() {
    const { raceTypes, selectedRaceType } = this.state;
    const {
      racesDetails,
      selectedRace,
      runnersDetail,
      isRunnerLoading,
    } = this.props;
    let RacesList =
      racesDetails?.length > 0
        ? racesDetails.sort(function (a, b) {
            return a?.raceNumber - b?.raceNumber;
          })
        : [];
    return (
      <>
        {RacesList?.map((item, i) => (
          <TabPanel
            key={"tacetabsedetails" + i}
            value={selectedRace}
            index={Number(item.id)}
          >
            <div className="race-list">
              <div className="race-title-block">
                <Typography className="racing-title-warning hide-xl hide-lg hide-md hide-lg-tab">
                  <ReactSVG src={racing_title_warning} />
                  Some events and fixtures may not currently have any odds
                  available. Please check back later.
                </Typography>
                <Typography variant="h5" className="jump-title">
                  {item.distance} {item.raceName},{" "}
                  {moment.utc(item.startTimeDate).local().format("hh:mm a")}
                </Typography>
                <Typography className="racing-title-warning hide-xs hide-sm-tab">
                  <ReactSVG src={racing_title_warning} />
                  Some events and fixtures may not currently have any odds
                  available. Please check back later.
                </Typography>
              </div>
              <div className="race-list-inner racetype-tabs">
                <hr />
                {raceTypes && (
                  <AppBar position="static" color="default">
                    <Toolbar className="race-type-toolbar">
                      <Tabs
                        value={selectedRaceType}
                        onChange={this.handleRaceTypesChange}
                        variant="scrollable"
                        scrollButtons="off"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                      >
                        {raceTypes.map((itemracetype, i) => (
                          <Tab
                            key={"KRaceTypes" + i}
                            value={itemracetype.id}
                            label={itemracetype.title}
                            {...a11yProps(i)}
                          />
                        ))}
                      </Tabs>
                      <div className="race-tab-button racetype-tab-button">
                        <button
                          disabled={selectedRaceType === raceTypes[0].id}
                          onClick={() =>
                            this.handleChangeTypeTab("prev", raceTypes)
                          }
                        >
                          <FontAwesomeIcon color="white" icon={faChevronLeft} />
                        </button>
                        <button
                          disabled={
                            selectedRaceType ===
                            raceTypes[raceTypes?.length - 1].id
                          }
                          onClick={() =>
                            this.handleChangeTypeTab("next", raceTypes)
                          }
                        >
                          <FontAwesomeIcon
                            color="white"
                            icon={faChevronRight}
                          />
                        </button>
                      </div>
                    </Toolbar>
                  </AppBar>
                )}
                <hr />
              </div>
              {raceTypes &&
                raceTypes.map((itemracetype, i) => (
                  <>
                    <TabPanel
                      key={"KRaceTypesP" + i}
                      value={selectedRaceType}
                      index={itemracetype.id}
                      className={"hide-xs hide-sm-tab"}
                    >
                      <div className="race-list-inner">
                        <RunnersTable
                          raceDate={item}
                          racesDetails={racesDetails}
                          Runners={runnersDetail}
                          match={this.props.match}
                          isRunnerLoading={isRunnerLoading}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel
                      key={"KRaceTypesPM" + i}
                      value={selectedRaceType}
                      index={itemracetype.id}
                      className={"hide-xl hide-lg hide-md hide-lg-tab"}
                    >
                      <RunnersTable
                        raceDate={item}
                        racesDetails={racesDetails}
                        Runners={runnersDetail}
                        mobileView={true}
                        match={this.props.match}
                        isRunnerLoading={isRunnerLoading}
                      />
                    </TabPanel>
                  </>
                ))}
            </div>
          </TabPanel>
        ))}
      </>
    );
  }
}

export default RaceTabPanels;
