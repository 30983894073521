import React, { Component, createRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";

import TextEditor from "../../library/common/components/textEditor/TextEditor";
import ActionMessage from "../../library/common/components/ActionMessage";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { AddNewModel } from "./constants";
import Form from "../../library/common/components/Form";
import MetaTag from "./Frame/MetaTag";
import ButtonComponent from "../../library/common/components/Button";
import {
  fetchErrorFields,
  slugify,
  removeErrorFieldsFromValues,
} from "../../library/utilities";
import { Loader } from "../../library/common/components";
import { EditRowPage } from "../../library/common/actions";
import Templates from "./Templates";

class AddNew extends Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      formModel: AddNewModel,
      isLoading: false,
      content: "",
      metaList: [],
      formObject: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      values: {
        slug: "",
      },
      template: null,
      templateData: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editData !== this.props.editData) {
      this.setEditData(this.props.editData);
    }
  }

  componentDidMount() {
    const { EditRowPage, editData } = this.props;
    EditRowPage(this.props.match.params.id);
  }

  setEditData = (data) => {
    const { editData } = this.props;
    const formData = {
      id: editData.id,
      pagename: data.pagename,
      slug: data.slug,
    };
    this.setState({
      formObject: formData,
      metaList: data.tags,
      values: editData,
      template: editData.template,
      templateData:
        editData.template === "about" ? editData.members : editData.banners,
    });
    this.updateContent(editData.content);
  };

  updateContent = (value) => {
    this.setState({ content: value });
  };

  handleSaveRows = async (e) => {
    e.preventDefault();
    const { getFormData } = this.formRef.current;
    const values = getFormData();
    const { isFormValid } = values;
    const { content, metaList, template, templateData } = this.state;

    window.scrollTo(0, 0);

    this.setState({ isLoading: true });
    if (metaList && metaList.length > 0 && isFormValid) {
      this.setState({ isLoading: true });
      //this.setActionMessage(true, "", "");
      const data = {
        pagename: values.formData.pagename,
        slug: values.formData.slug,
        content: values.formData.content,
        tags: metaList,
        type: "page",
        template,
      };

      axiosInstance
        .post(URLS.page, data)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.formRef.current.resetForm();
            this.setState({ content: "" });
            this.setActionMessage(
              true,
              "Success",
              "Record created successfully"
            );
            console.log(data);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.hasOwnProperty("response") && err.response.status === 404) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else if (
            err.hasOwnProperty("response") &&
            err.response.status === 500
          ) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else {
            this.setActionMessage(true, "Error", err.response.data.message);
          }
        });
    } else {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `Please fill details for : ${
          metaList && metaList.length < 1 ? "Meta list," : ""
        } ${content === "" ? "Editor text," : ""} ${fetchErrorFields(
          values.formData,
          this.state.formModel
        ).join(", ")}`
      );
    }
  };

  handleUpdate = async (e) => {
    e.preventDefault();
    const { getFormData } = this.formRef.current;
    const values = getFormData();
    const { isFormValid } = values;
    const { metaList, templateData, template } = this.state;
    const { EditRowPage } = this.props;

    window.scrollTo(0, 0);

    this.setState({ isLoading: true });
    if (metaList && metaList.length > 0 && isFormValid) {
      this.setState({ isLoading: true });
      this.setActionMessage(true, "", "");
      const data = {
        pagename: values.formData.pagename,
        slug: values.formData.slug,
        content: values.formData.content,
        tags: metaList,
        type: "page",
        template,
      };

      axiosInstance
        .put(URLS.removeRow(this.props.match.params.id), data)
        .then(({ status }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            console.log("Test");

            this.formRef.current.resetForm();
            this.setState({ content: "" });
            this.setActionMessage(
              true,
              "Success",
              "Record Updated successfully"
            );
            EditRowPage(this.props.match.params.id);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.hasOwnProperty("response") && err.response.status === 404) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else if (
            err.hasOwnProperty("response") &&
            err.response.status === 500
          ) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else {
            this.setActionMessage(true, "Error", err.response.data.message);
          }
        });
    } else {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `Please fill details for : ${
          metaList.length < 1 ? "Meta list," : ""
        } } ${fetchErrorFields(values.formData, this.state.formModel).join(
          ", "
        )}`
      );
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message,
    };
    this.setState({ messageBox: setActionMessage });
  };

  getMetaData = (metaData) => {
    this.setState({ metaList: metaData });
  };
  back = () => {
    this.props.history.push("/admin/dashboard");
  };

  handleFormChange = (field, value) => {
    if (field === "pagename") {
      const slug = slugify(value);
      this.setState({ values: { slug, pagename: value } });
    }

    if (field === "template") {
      this.setState({ template: value });
    }
  };

  handleTemplateData = (value) => {
    this.setState({ templateData: value });
  };

  handleTemplateSuccess = (message) => {
    const { EditRowPage } = this.props;
    EditRowPage(this.props.match.params.id);
    window.scrollTo(0, 0);
    this.setActionMessage(true, "Success", message);
  };

  handleTemplateError = (message) => {
    window.scrollTo(0, 0);
    this.setActionMessage(true, "Error", message);
  };

  render() {
    const { editData } = this.props;
    const {
      messageBox,
      formModel,
      isLoading,
      formObject,
      values,
      metaList,
      template,
      templateData,
    } = this.state;

    return (
      <Grid container className="page-contentWrapper adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper addNew">
            <h3 className="text-left">
              Add New Page {isLoading && <Loader />}
            </h3>
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            {!this.props.match.params.id || formObject.id ? (
              <Form
                values={values}
                model={formModel}
                ref={this.formRef}
                onChange={this.handleFormChange}
              />
            ) : (
              <Loader />
            )}

            {/* <TextEditor
              updateContent={this.updateContent}
              updatedBookingMessageList={editData.content}
            /> */}

            <Templates
              data={templateData}
              template={template}
              pageId={this.props.match.params.id}
              onChange={this.handleTemplateData}
              onSuccess={this.handleTemplateSuccess}
              onError={this.handleTemplateError}
            />

            <div className="btnWrapper">
              <Grid container>
                <MetaTag
                  id={this.props.match.params.id}
                  updatedData={editData}
                  metaLists={metaList}
                  onAdd={this.getMetaData}
                />
              </Grid>
            </div>

            <Grid container>
              <Grid item xs={3}>
                {this.props.match.params.id ? (
                  <ButtonComponent
                    onClick={this.handleUpdate}
                    color="secondary"
                    value="Update"
                    className="admin-btn-orange"
                  />
                ) : (
                  <ButtonComponent
                    onClick={this.handleSaveRows}
                    color="primary"
                    value="Save"
                    className="admin-btn-green"
                  />
                )}

                <ButtonComponent
                  onClick={this.back}
                  className="mr-lr-30"
                  value="Back"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (authReducer) => {
  return {
    editData: authReducer.authReducer.editData,
  };
};

export default connect(mapStateToProps, { EditRowPage })(AddNew);
