import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Loader } from "../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ButtonComponent from "../../library/common/components/Button";
import { ReactSVG } from "react-svg";
import { URLS } from "../../library/common/constants";
import axiosInstance from "../../helpers/Axios";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";

class SportsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      sportsDetails: [],
      isLoading: false,
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.onSubmit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.onSubmit();
    }
  }

  onSubmit = async () => {
    let id = this.props.match.params.id;
    this.setState({ isLoading: true });
    let { selectedDate } = this.state;
    let params = {
      sportId: id,
      //   date: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      //   endDate: moment(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      date: "2021-04-09T00:00:00.000Z",
      endDate: "2021-04-10T00:00:00.000Z",
    };
    try {
      const { status, data } = await axiosInstance.post(
        "private/races",
        params
      );
      if (status === 200) {
        this.setState({ sportsDetails: data.respose, isLoading: false });
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, sportsDetails } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < sportsDetails.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  navigateUrl = (id) => {
    this.props.history.push(`/admin/sportname/runners/${id}`);
  };

  render() {
    let {
      selectedDate,
      sportsDetails,
      isLoading,
      rowPerPage,
      currentPage,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = sportsDetails;

    if (sportsDetails?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = sportsDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(sportsDetails.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className='page-content adminLogin'>
          <Grid item xs={12}>
            <Paper className='pageWrapper'>
              <Grid container direction='row' alignItems='space-around'>
                <Grid item xs={8}>
                  <h3 className='text-left'>Sports Details</h3>
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify='space-around'>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        value={selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className='addButton admin-btn-green'
                    color='primary'
                    value='Submit'
                    onClick={this.onSubmit}
                  />
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && sportsDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && sportsDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className='listTable api-provider-listTable'
                      aria-label='simple table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Event Name</TableCell>
                          <TableCell>Event Date</TableCell>
                          <TableCell>Race Number</TableCell>
                          <TableCell>Race Name</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Weather</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((sportsDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{sportsDetails.race.id}</TableCell>
                            <TableCell>
                              {sportsDetails.event.eventName}
                            </TableCell>
                            <TableCell>
                              {sportsDetails.event.eventDate}
                            </TableCell>
                            <TableCell>
                              {sportsDetails.race.raceNumber}
                            </TableCell>
                            <TableCell>{sportsDetails.race.raceName}</TableCell>
                            <TableCell>
                              {sportsDetails.race.startTimeDate}
                            </TableCell>
                            <TableCell>{sportsDetails.race.distance}</TableCell>
                            <TableCell>{sportsDetails.race.weather}</TableCell>
                            <TableCell>
                              <EditIcon
                                onClick={() =>
                                  this.navigateUrl(sportsDetails.race.id)
                                }
                                color='primary'
                                className='mr10 cursor iconBtn admin-btn-green'
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='tablePagination'>
                    <button
                      className={
                        sportsDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size='small'
                    />
                    <button
                      className={
                        sportsDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        sportsDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default SportsDetails;
