import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextEditor from "../../library/common/components/textEditor/TextEditor";
import ButtonComponent from "../../library/common/components/Button";
import SaveIcon from "@material-ui/icons/Save";
import ActionMessage from "../../library/common/components/ActionMessage";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "qwqwqwq",
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  updateContent = (value) => {
    this.setState({ content: value });
  };

  handleUpdatesAddons = async (e) => {
    e.preventDefault();
    const { content } = this.state;
    const data = {
      pagename: "about",
      slug: "/about",
      content: content,
      tags: [
        {
          title: "ffff",
          description: "gfg",
        },
        {
          title: "ttt",
          description: "hhh",
        },
      ],
    };
    this.setState({ isLoading: true });
    if (content !== "") {
      axiosInstance
        .post(URLS.about, data)
        .then(({ status }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.setActionMessage(
              true,
              "Success",
              "Booking Message Update successfully"
            );
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (err.hasOwnProperty("response") && err.response.status === 404) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else if (
            err.hasOwnProperty("response") &&
            err.response.status === 500
          ) {
            this.setActionMessage(true, "Error", err.response.data.message);
          } else {
            this.setActionMessage(true, "Error", err.response.data.message);
          }
        });
    } else {
      this.setState({ isLoading: false });
      this.setActionMessage(true, "Error", "Data is empty");
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message,
    };
    this.setState({ messageBox: setActionMessage });
  };

  render() {
    const { messageBox } = this.state;
    return (
      <Grid container className='page-content adminLogin'>
        <Grid item xs={12}>
          <Paper className='pageWrapper'>
            <h3>About Us</h3>
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}
            <TextEditor
              updateContent={this.updateContent}
              updatedBookingMessageList='asjakjskaj'
            />
            <div className='btnWrapper'>
              <Grid container>
                <Grid item xs={5}>
                  <ButtonComponent
                    onClick={this.handleUpdatesAddons}
                    startIcon={<SaveIcon />}
                    color='primary'
                    value='Save'
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default AboutUs;
