import React, { Component } from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import { connect } from "react-redux";
import Loader from "../../library/common/components/Loader";

import { setMetaTags } from "../../library/common/actions/MetaActions";
import axiosInstance from "../../helpers/Axios";

class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cmsPageData: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchCMSPageData();
  }

  async fetchCMSPageData() {
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        "/cms/page/detail/aboutus"
      );
      if (status === 200) {
        this.setState({ cmsPageData: data.result, isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { cmsPageData, isLoading } = this.state;
    let headerData = cmsPageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Header"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    let BodyData = cmsPageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Body"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

    return (
      <>
        {/* {this.renderHeaderMeta()} */}
        <Container className='page-banner m55'>
          <Grid
            container
            justify='center'
            className='contact-banner '
            alignItems='flex-start'
          >
            {isLoading && <Loader />}
            <Typography
              variant='h5'
              className='title contact-title mobile-heading text-center color-blue text-upper'
            >
              {cmsPageData?.pageTitle}
            </Typography>

            <div
              variant='body1'
              className='text-center cms_aboutus'
              dangerouslySetInnerHTML={{
                __html:
                  headerData &&
                  headerData?.length > 0 &&
                  headerData[0]?.content,
              }}
            ></div>
          </Grid>
        </Container>

        <Container className='aboutus-members'>
          <div className='text-center section'>
            <Grid container alignContent='center' justify='center'>
              {BodyData &&
                BodyData?.length > 0 &&
                BodyData.map((obj) => (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className='cms_members'
                    dangerouslySetInnerHTML={{
                      __html: obj?.content,
                    }}
                  ></Grid>
                ))}
            </Grid>
          </div>
        </Container>
      </>
    );
  }
}

export default connect(null, { setMetaTags })(Aboutus);
