import { Validators } from "../../../library/utilities/Validators";

export const AddressesFormModel = [
  {
    label: "Address line 1",
    value: "",
    type: "text",
    placeholder: "Address line 1",
    field: "addressline1",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Address line 2",
    value: "",
    type: "text",
    placeholder: "Address line 2",
    field: "addressline2",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "State",
    value: "",
    type: "text",
    placeholder: "State",
    field: "state",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Country",
    value: "",
    type: "text",
    placeholder: "Country",
    field: "country",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Post Code",
    value: "",
    type: "text",
    placeholder: "Post Code",
    field: "postcode",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Hash",
    value: "",
    type: "text",
    placeholder: "Hash",
    field: "hash",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
];
