import { Validators } from "../../library/utilities/Validators";

export const AddNewModel = [
  {
    label: "Page Name",
    value: "",
    type: "text",
    placeholder: "Page Name",
    field: "pagename",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "4",
  },
  {
    label: "Slug",
    value: "",
    type: "text",
    placeholder: "Slug",
    field: "slug",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "4",
    extraProps: {
      disabled: true,
    },
  },
  {
    label: "Template",
    value: "",
    type: "dropdown",
    placeholder: "Template",
    field: "template",
    validators: [],
    required: false,
    className: "4",
    options: [
      { value: "no-template", label: "No Template" },
      { value: "home", label: "Homepage" },
      { value: "about", label: "About" },
      { value: "membership", label: "Membership" },
    ],
  },
  {
    label: "",
    value: "",
    type: "editor",
    placeholder: "",
    field: "content",
    validators: [],
    required: false,
    className: "12",
  },
];
