import React, { PureComponent } from "react";
import "./betsoddsslip.scss";
import BetOddsslipHeaderData from "./betOddsslipHeaderData";

import { Grid, InputLabel, Input } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { sportIcon } from "../../helpers/common";

class RaceBetsOdssSlip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { bets, sportsData, stakes } = this.props;
    let sportType = bets?.length > 0 ? bets[0].sportType[0] : "";
    return (
      <>
        {bets.map((bet, index) => (
          <div key={index} className="bets_bookmark_content">
            <Grid
              container
              spacing={2}
              key={0}
              className="box racing-details text-left"
            >
              <BetOddsslipHeaderData bet={bet} sportsData={sportsData} />
            </Grid>
            <Grid
              container
              spacing={1}
              className="bet-row-item bets-row-item box text-left"
            >
              <Grid item xs={6}>
                <ReactSVG
                  className="horse_img"
                  src={sportIcon(bet?.imageSrc)}
                />
                <p
                  style={{
                    margin: 0,
                    paddingLeft: 40,
                    fontSize: 12,
                  }}
                >
                  {bet.title}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className="stackinput full-width odd-slip-input">
                  <InputLabel htmlFor="stake">Stake $</InputLabel>
                  <Input
                    inputProps={{
                      "aria-label": "stake",
                    }}
                    id="stake"
                    name="stake"
                    type="text"
                    value={
                      stakes && stakes[bet?.id] !== null ? stakes[bet?.id] : ""
                    }
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        this.props.handleInputChange(bet.id, value);
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="bookmarks-list">
              <Grid container className="box main-header text-left">
                <Grid item sm={2} xs={2}></Grid>
                <Grid item sm={4} xs={4} className="title-odds">
                  Odds
                </Grid>
                <Grid item sm={6} xs={6} className="pot-win">
                  Potential Winnings
                </Grid>
              </Grid>
              <Grid container className="box header">
                <Grid item sm={12} xs={12}>
                  <Grid
                    container
                    className="bookmark-item-values text-center"
                    spacing={0}
                  >
                    <Grid
                      item
                      xs={sportType === "racing" ? 3 : 6}
                      // xs={3}
                    >
                      <span
                        className="pointer_cursor"
                        onClick={() => this.props.toggleSorting("wins")}
                      >
                        Win
                      </span>
                    </Grid>
                    {sportType === "racing" && (
                      <Grid item sm={3} xs={3}>
                        <span
                          className="pointer_cursor"
                          onClick={() => this.props.toggleSorting("place")}
                        >
                          Place
                        </span>
                      </Grid>
                    )}
                    {/* {bet?.showLineCol !== false && (
                      <Grid item sm={3} xs={3}>
                        <span className="pointer_cursor" onClick={() => this.props.toggleSorting("place")}>
                          Place
                        </span>
                      </Grid>
                    )} */}
                    <Grid
                      item
                      xs={sportType === "racing" ? 3 : 6}
                      // xs={3}
                    >
                      Win
                    </Grid>
                    {sportType === "racing" && (
                      <Grid item sm={3} xs={3}>
                        Place
                      </Grid>
                    )}
                    {/* {bet?.showLineCol !== false && (
                      <Grid item sm={3} xs={3}>
                        Place
                      </Grid>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      </>
    );
  }
}
export default RaceBetsOdssSlip;
