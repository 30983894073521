import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { apiKeyIdentifireFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let apiKeyIdentifireFormModelArray = apiKeyIdentifireFormModel;

class CreateApiKeyIdentifire extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        key: "",
      },
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentKeyIdentifire();
    }
  }

  componentWillUnmount() {
    apiKeyIdentifireFormModelArray = apiKeyIdentifireFormModelArray.map(
      (fieldItem) => {
        return { ...fieldItem, errorMessage: "" };
      }
    );
  }

  fetchCurrentKeyIdentifire = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.apiKeyIdentifire + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({ values: data.result[0] });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { key } = this.state.values;
    let flag = true;

    if (key === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.apiKeyIdentifire}/${this.props.id}`
        : URLS.apiKeyIdentifire;

      const values = removeErrorFieldsFromValues(form.formData);

      apiKeyIdentifireFormModelArray = apiKeyIdentifireFormModelArray?.map(
        (fieldItem) => {
          return setValidation(fieldItem, values);
        }
      );

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllKeyIdentifire();
          this.setActionMessage(
            true,
            "Success",
            `Api Key Identifire ${
              isEditMode ? "Edited" : "Created"
            } Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Api Key Identifire`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    apiKeyIdentifireFormModelArray = apiKeyIdentifireFormModelArray?.map(
      (fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      }
    );
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={apiKeyIdentifireFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Cancle"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateApiKeyIdentifire;
