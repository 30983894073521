import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { EventEmitter } from "../../../../services/event";
import fighter from "./../../../../images/menu_icons/fighter.svg";

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToAllRace = (race) => {
    EventEmitter.dispatch("show_all_races", race);
  };
  render() {
    return (
      <>
        <Grid container className="hide-xs">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">Main Card</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title">[Weight Class]</p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title">[Round]</p>
                  </div>
                </div>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">Prelim Card</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title">[Weight Class]</p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title">[Round]</p>
                  </div>
                </div>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>

                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">Early Prelim Card</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title">[Weight Class]</p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title">[Round]</p>
                  </div>
                </div>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
                <div className="allsport_inner_main">
                  <div className="mma-weightclass">
                    <p className="mma-title mma-font-400">[Weight Class]</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                    <p className="mma-title">V</p>
                    <p className="allsport_inner_match mma-fightername">
                      <ReactSVG src={fighter} /> [Fighter Name]
                    </p>
                  </div>
                  <div className="mma-method">
                    <p className="mma-title mma-font-400">[Method]</p>
                  </div>
                  <div className="mma-round">
                    <p className="mma-title mma-font-400">[Round]</p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container className="hide-sm hide-md hide-lg hide-xl">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">Main Card</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername mma-font-400">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">Prelim Card</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">Early Prelim Card</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p className="res-fightername">[Fighter Name]</p>
                  <p className="res-black">V</p>
                  <p className="res-fightername">[Fighter Name]</p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Weight Class :
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Weight Class]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Method</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Method]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Round</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Round]
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AllSportsHome;
