import { Validators } from "../../../../library/utilities/Validators";
import { DATA_TYPES } from "../../../../helpers/constants";

export const apiProviderApisFeildMappingFormModel = [
  {
    label: "Api Identifire*",
    value: "",
    type: "dropdown",
    placeholder: "Api Identifire",
    field: "apiIdentifier",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Is Element*",
    value: "",
    type: "dropdown",
    placeholder: "Is Element",
    field: "isElement",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "1", label: "Yes" },
      { id: 2, value: "0", label: "No" },
    ],
  },
  // {
  //   label: "Api Identifire Value",
  //   value: "",
  //   type: "text",
  //   placeholder: "Api Identifire Value",
  //   field: "apiIdentifierValue",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  // },
  {
    label: "Value Type*",
    value: "",
    type: "dropdown",
    placeholder: "Value Type",
    field: "valueType",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
    options: [
      ...DATA_TYPES?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
  {
    label: "Table Field*",
    value: "",
    type: "text",
    placeholder: "Table Field",
    field: "tableFeild",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  // {
  //   label: "Level",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Level",
  //   field: "level",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  //   options: [
  //     { value: "", label: "No level selected" },
  //     { id: 1, value: 0, label: 0 },
  //     { id: 2, value: 1, label: 1 },
  //     { id: 3, value: 2, label: 2 },
  //     { id: 4, value: 3, label: 3 },
  //   ],
  // },
  {
    label: "Is Reference*",
    value: "",
    type: "dropdown",
    placeholder: "Is Reference",
    field: "isReference",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
    options: [
      { id: 1, value: 1, label: "Yes" },
      { id: 2, value: 0, label: "No" },
    ],
  },
  // {
  //   label: "Table Name",
  //   value: "",
  //   type: "text",
  //   placeholder: "Table Name",
  //   field: "tableName",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  // },
  // {
  //   label: "Column Name",
  //   value: "",
  //   type: "text",
  //   placeholder: "Column Name",
  //   field: "columnName",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  // },
];
