import React, { Component } from "react";

export default class TabPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-force-tabpanel-${index}`}
          aria-labelledby={`scrollable-force-tab-${index}`}
          {...other}>
          {value === index && <>{children}</>}
        </div>
      </>
    );
  }
}
