import { Validators } from "../../../library/utilities/Validators";

export const statsKeysFormModel = [
  {
    label: "Sport*",
    value: "",
    type: "dropdown",
    placeholder: "Sport",
    field: "sportId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Name*",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Variation",
    value: "",
    type: "text",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
];
