import React from "react";
import { Typography, Grid } from "@material-ui/core";
import moment from "moment";

class BetOddsslipHeaderData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRacing = (bet) => {
    return (
      <>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography variant="body1">{bet.sportTitle}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Race {bet.raceNumber} {bet.race}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{bet.raceTime}</Typography>
        </Grid>
      </>
    );
  };

  renderTeamSport = (bet) => {
    return (
      <>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography variant="body1">{bet?.leagueName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{bet?.Description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{bet?.teams}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {moment.utc(bet?.eventDate).local().format("DD MMM YYYY hh:mm:ss")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{bet?.Market}</Typography>
        </Grid>
      </>
    );
  };

  // renderCombatSport = (bet) => {
  //   return (
  //     <>
  //       <Grid item xs={12} style={{ position: "relative" }}>
  //         <Typography variant="body1">{bet?.leagueName}</Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">{bet?.Description}</Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           {bet?.TeamA} VS {bet?.TeamB}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">
  //           {moment(bet?.OutcomeAt).format("DD MMM YYYY hh:mm:ss")}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="body1">{bet?.Market}</Typography>
  //       </Grid>
  //     </>
  //   );
  // };

  render() {
    const { bet, sportsData } = this.props;
    let sportType = bet.sportType[0];
    if (sportType === "racing") {
      return this.renderRacing(bet);
    } else if (sportType === "teamsport" || sportType === "combat") {
      return this.renderTeamSport(bet);
    }
    // else if (sportType === "combat") {
    //   return this.renderCombatSport(bet);
    // }
    else {
      return <></>;
    }
  }
}
export default BetOddsslipHeaderData;
