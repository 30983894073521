import { Validators } from "../../../library/utilities/Validators";

export const citiesFormModel = [
  {
    label: "City Name*",
    value: "",
    type: "text",
    placeholder: "City Name",
    field: "cityName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
];
