import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import ActionMessage from "../../library/common/components/ActionMessage";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Loader } from "../../library/common/components";
import { EditRowPage } from "../../library/common/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pagesList: [],
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    this.getPageData();
    this.clearData();
  }

  clearData = () => {
    const { EditRowPage } = this.props;
    EditRowPage({});
  };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message,
    };
    this.setState({ messageBox: setActionMessage }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  getPageData = () => {
    this.setState({ isLoading: true });
    axiosInstance
      .get(URLS.page + "?type=page")
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState({ isLoading: false, pagesList: data });
        }
      })
      .catch((err) => {
        if (
          err.hasOwnProperty("response") &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          this.setActionMessage(true, "Error", err.response.data.message);
        } else {
          this.setState((prevState) => {
            this.setActionMessage(true, "Error", err.response.data.message);
          });
        }
        this.setState({ isLoading: false });
      });
  };

  createNew = () => {
    this.props.history.push("/admin/add");
  };
  deleteRow = (id) => {
    this.setState({ isLoading: true });
    axiosInstance
      .delete(URLS.removeRow(id))
      .then(async ({ status, data }) => {
        if (status === 200) {
          this.setState({ isLoading: false });
          await this.getPageData();
          await this.setActionMessage(
            true,
            "Success",
            "Record Deleted Successfully"
          );
        }
      })
      .catch((err) => {
        if (
          err.hasOwnProperty("response") &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          this.setActionMessage(true, "Error", err.response.data.message);
        } else {
          this.setState((prevState) => {
            this.setActionMessage(true, "Error", err.response.data.message);
          });
        }
        this.setState({ isLoading: false });
      });
  };

  EditRow = async (id) => {
    const { EditRowPage } = this.props;
    await EditRowPage(id);
    await this.props.history.push(`/admin/edit-page/${id}`);
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, pagesList } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < pagesList.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    const {
      messageBox,
      pagesList,
      isLoading,
      rowPerPage,
      currentPage,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = pagesList;

    if (pagesList?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = pagesList.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(pagesList.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}
            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={10}>
                <h3 className="text-left">Pages {isLoading && <Loader />}</h3>
              </Grid>
              <Grid item xs={2}>
                <ButtonComponent
                  className="addButton admin-btn-green"
                  onClick={this.createNew}
                  color="primary"
                  value="Add New"
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className="listTable" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>DID</TableCell>
                    <TableCell>Page Name</TableCell>
                    <TableCell>Slug</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Updated Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageRow.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.pagename}</TableCell>
                      <TableCell>{row.slug}</TableCell>
                      <TableCell>
                        {moment(row.createdAt, "DD-MM-YYYY").format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(row.updatedAt, "DD-MM-YYYY").format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        <EditIcon
                          onClick={() => this.EditRow(row.id)}
                          color="primary"
                          className="mr10 cursor iconBtn admin-btn-green"
                        />
                        <DeleteOutlineIcon
                          onClick={() => this.deleteRow(row.id)}
                          color="secondary"
                          className="cursor iconBtn admin-btn-orange"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {pagesList?.length > 0 && (
                <div className="tablePagination">
                  <button
                    className={
                      pagesList.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={pagesList.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("prev")}
                  >
                    <ReactSVG src={arrowLeft} />
                  </button>
                  <Pagination
                    hideNextButton
                    hidePrevButton
                    disabled={pagesList.length / rowPerPage > 1 ? false : true}
                    page={currentPage}
                    onChange={this.handlePaginationClick}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                  <button
                    className={
                      pagesList.length / rowPerPage > 1
                        ? "btn-navigation"
                        : "btn-navigation-disabled"
                    }
                    disabled={pagesList.length / rowPerPage > 1 ? false : true}
                    onClick={() => this.handlePaginationButtonClick("next")}
                  >
                    <ReactSVG src={arrowRight} />
                  </button>
                </div>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { EditRowPage })(Dashboard);
