import React, { Component, createRef } from "react";
import { CircularProgress } from "@material-ui/core";

import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import FileUploader from "../../../library/common/components/FileUploader";
import { aboutTeamFormConstant } from "./form-constant";
import axiosInstance from "../../../helpers/Axios";
import { URLS } from "../../../library/common/constants/UrlConstants";

export default class About extends Component {
  formRef = createRef();
  state = {
    file: [],
    isLoading: false,
    value: {},
    isEditMode: false,
  };

  handleFileUpload = (file) => {
    this.setState((prevState) => {
      return { ...prevState, file: file };
    });
  };

  addTeamMember = () => {
    const { value, isEditMode, file } = this.state;
    const { current } = this.formRef;
    const values = current.getFormData();

    let url = "/page/team/" + this.props.pageId;
    const method = isEditMode ? "put" : "post";

    this.setState({ isLoading: true });

    const body = new FormData();
    if (isEditMode) {
      url = "/page/team/" + value.id;
      body.append("pageId", this.props.pageId);
    }
    body.append("name", values.formData.name);
    body.append("position", values.formData.position);
    body.append("image", file.length > 0 ? file[0] : null);
    body.append(
      "isExternal",
      values.formData.isExternal ? values.formData.isExternal : false
    );
    body.append("image", file.length > 0 ? file[0] : null);

    axiosInstance[method](url, body, {
      header: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.status === 200) {
        this.props.onSuccess(
          `Team member ${isEditMode ? "edited" : "added"} successfully!`
        );
        this.setState({
          file: [],
          isLoading: false,
          isEditMode: false,
          value: null,
        });
        current.resetForm();
      }
    });
    // .catch(this.setState({ isLoading: false }));
  };

  removeMember = (id) => {
    this.setState({ isLoading: true });

    axiosInstance.delete("/page/team/" + id).then((res) => {
      if (res.status === 200) {
        this.props.onSuccess("Team member deleted successfully!");
        this.setState({ file: [], isLoading: false });
      }
    });
  };

  editMember = (value) => {
    this.setState({ value, isEditMode: true });
    window.scrollTo(0, 300);
  };

  render() {
    const { file, isLoading, value, isEditMode } = this.state;

    return (
      <div className="about-template-container">
        <h4>Add Team Member</h4>

        <div className="add-team">
          <Form
            model={aboutTeamFormConstant()}
            values={value ? value : {}}
            ref={this.formRef}
          />
          <FileUploader accept="image/*" onDrop={this.handleFileUpload} />
          {file?.map((file, index) => (
            <img key={index} src={file.preview} alt="file" />
          ))}
          <br />
          <ButtonComponent
            onClick={this.addTeamMember}
            color="primary"
            value={isEditMode ? "Update Member" : "Add Member"}
          />
        </div>
        {isLoading && <CircularProgress />}
        {this.props?.data?.map((item, index) => (
          <>
            <hr />
            <Form
              model={aboutTeamFormConstant(
                false,
                item,
                this.removeMember,
                this.editMember
              )}
              values={item}
              key={index}
            />
            {item?.fileName !== "" && (
              <img
                width={100}
                src={URLS.uploadPath + item?.fileName}
                alt="Member"
              />
            )}
          </>
        ))}
      </div>
    );
  }
}
