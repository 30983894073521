import React, { PureComponent } from "react";
import { Button } from "@material-ui/core";
import "./betssidebar.scss";

class BetslipBookmarkerRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { wins, place, bet, stakes, bookmark } = this.props;
    if (wins > 0 || place > 0) {
      return (
        <>
          <div className="box bookmark-item" key={bet}>
            <div className="bookmark-logo">
              {bookmark.logo !== "" ? (
                bookmark.link !== "" ? (
                  <div className="innerbox">
                    <img
                      src={bookmark.logo}
                      onClick={() => window.open(bookmark.link, "_blank")}
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <img src={bookmark.logo} alt="" />
                )
              ) : (
                <div className="imagePlaceholder">Logo</div>
              )}
            </div>
            <div className="bookmark-item-values-wrap text-left">
              <div className="bookmark-item-values">
                <div
                  className={"innerbox " + (place === false && "inner-center")}>
                  <div className="biv-item">
                    {bookmark.enabled === true && Number(wins).toFixed(2)}
                  </div>
                  {place !== false && (
                    <div className="biv-item">
                      {bookmark.enabled === true && Number(place).toFixed(2)}
                    </div>
                  )}
                </div>
                {bookmark.enabled === true && (
                  <>
                    {stakes && stakes[bet.id] ? (
                      <div
                        className={
                          "innerbox " + (place === false && "inner-center")
                        }>
                        <div className="biv-item">
                          {bookmark.enabled === true &&
                            Number(wins * stakes[bet.id]).toFixed(2)}
                        </div>
                        {place !== false && (
                          <div className="biv-item">
                            {bookmark.enabled === true &&
                              Number(place * stakes[bet.id]).toFixed(2)}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className={
                          "innerbox " + (place === false && "inner-center")
                        }>
                        <div className="biv-item"></div>
                        {place !== false && <div className="biv-item"></div>}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="betnow-button">
              <Button
                variant="contained"
                className="btn"
                onClick={() => window.open(bookmark.link, "_blank")}
                type="submit"
                component="button">
                Bet Now
              </Button>
            </div>
          </div>
        </>
      );
    } else {
      return "";
    }
  }
}

export default BetslipBookmarkerRow;
