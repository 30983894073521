import { Validators } from "../../../library/utilities/Validators";

export const blogFormModel = [
  {
    label: "",
    value: "",
    type: "text",
    placeholder: "Post Title",
    field: "pagename",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "",
    value: "",
    type: "text",
    placeholder: "Automatically generated slug",
    field: "slug",
    validators: [],
    required: false,
    className: "12",
    extraProps: {
      disabled: true,
    },
  },
  {
    label: "Blog Content",
    value: "",
    type: "editor",
    placeholder: "",
    field: "content",
    validators: [],
    required: false,
    className: "12",
  },
  {
    label: "",
    value: "",
    type: "textarea",
    placeholder: "Excerpt",
    field: "excerpt",
    validators: [],
    required: false,
    className: "12",
  },
];
