import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  Grid,
  Button,
  FormControl,
  Input,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import "./newsletterform.scss";

const url =
  "https://motzza.us10.list-manage.com/subscribe/post?u=0850839b0c4f1049efddeb32b&id=38cef6852a";

class CustomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      terms: false,
      errors: {},
    };
  }
  submit = () => {
    let { email, firstname, lastname, errors, terms } = this.state;
    errors.email = "";
    errors.firstname = "";
    errors.lastname = "";
    var validflag = true;
    console.log("form", email, firstname, lastname);
    if (email === "") {
      errors.email = "Email is required";
      validflag = false;
    } else {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexp.test(email)) {
        errors.email = "Invalid Email";
        validflag = false;
      }
    }
    if (firstname === "") {
      errors.firstname = "Firstname is required";
      validflag = false;
    }
    if (lastname === "") {
      errors.lastname = "Lastname is required";
      validflag = false;
    }
    if (terms === false) {
      errors.terms = "Accept to be email news";
      validflag = false;
    }
    this.setState((prevState) => {
      return { errors: { ...prevState.errors, errors } };
    });

    validflag &&
      this.props.onValidated({
        MERGE1: firstname,
        MERGE2: lastname,
        EMAIL: email,
      });
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, errors: { ...prevState.errors, [name]: "" } };
    });
  };
  handleChackbox = (e) => {
    let isSelected = e.currentTarget.checked;
    this.setState((prevState) => {
      return { terms: isSelected, errors: { ...prevState.errors, terms: "" } };
    });
  };
  render() {
    let { errors } = this.state;
    return (
      <>
        <form className="newsletter-form" noValidate autoComplete="off">
          <Grid container spacing={3} justify="flex-end" alignItems="flex-end">
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="First Name">
                  First Name
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.firstname}
                  id="firstname"
                  name="firstname"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.firstname && (
                  <span className="error">{errors.firstname}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="Last Name">
                  Last Name
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.lastname}
                  id="lastname"
                  name="lastname"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.lastname && (
                  <span className="error">{errors.lastname}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="Email Address">
                  Email Address
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.email}
                  id="email"
                  name="email"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      required
                      color="primary"
                      icon={<div className="checkboxIcon"></div>}
                      onChange={this.handleChackbox}
                      checkedIcon={
                        <div className="checkboxIcon checkboxIcon-checked"></div>
                      }
                    />
                  }
                  label="Sign me up for the newsletter"
                  labelPlacement="end"
                />
                {errors.terms && <span className="error">{errors.terms}</span>}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <Button
                onClick={this.submit.bind(this)}
                className="submit-btn"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <span className="res_message">
                {this.props.status === "sending" && (
                  <div style={{ color: "blue" }}>sending...</div>
                )}
                {this.props.status === "error" && (
                  <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: this.props.message }}
                  />
                )}
                {this.props.status === "success" && (
                  <div style={{ color: "green" }}>Subscribed !</div>
                )}
              </span>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

class NewsletterForm extends Component {
  render() {
    return (
      <>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
      </>
    );
  }
}

export default NewsletterForm;
