import {
  fetchFromStorage,
  removeFromStorage,
  saveToStorage,
} from "../../utilities";
import { identifiers, storeConstants, URLS } from "../constants";
import axiosInstance from "../../../helpers/Axios";

export const setAuthentication = (token, user) => (dispatch) => {
  saveToStorage(identifiers.token, token);
  dispatch({
    type: storeConstants.SET_AUTHENTICATION,
    payload: { token, user },
  });
};

export const getAuth = () => (dispatch) => {
  const token = fetchFromStorage(identifiers.token);
  const user = fetchFromStorage(identifiers.user);

  if (token || user) {
    dispatch(setAuthentication(token, user));
  }
};

export const logoutUser = () => (dispatch) => {
  removeFromStorage(identifiers.token);
  removeFromStorage("user");
  removeFromStorage("auth");
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("auth");
  dispatch({ type: storeConstants.LOGOUT });
  window.location.href = "/";
};

export const EditRowPage = (id) => (dispatch) => {
  return axiosInstance
    .get(URLS.removeRow(id))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.UPDATE_ROW,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.UPDATE_ROW,
        payload: [],
      });
    });
};
