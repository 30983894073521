import React from "react";
import { Grid } from "@material-ui/core";

const Promotion = () => {
  return (
    <Grid container spacing={15}>
      <Grid item xs={12} style={{ margin: "40px 0 80px 0" }}>
        <h3>Promotions</h3>
        <p>
          Lorem ipsum dolor sit amet. Sed facere inventore sed commodi
          galisumsed animi sit suscipit deleniti. Est veniam similique et minus
          inventore{" "}
          <strong>
            Ut molestiae hic velit natus est molestias vitae hic nisi explicabo
          </strong>{" "}
          et dolor aspernatur.{" "}
        </p>
        <p>
          Et ea debitis officiis quo molestiae aliquam At laudantium voluptatum
          et reiciendis officia id aliquid galisum. Ut enim iusto et omnis
          inventore{" "}
          <strong>
            Qui pariatur aut cumque consequatur et quia quisquam et animi quos
          </strong>{" "}
          nam perspiciatis debitis.{" "}
        </p>
        <p>
          Et numquam omnis est amet animi ea amet accusamus quo placeat
          officiis. Vel minus quisquam aut voluptatum voluptas{" "}
          <em>Non voluptatem hic error molestiae</em> vel dolorem adipisci non
          culpa quod est ipsam deleniti.{" "}
        </p>
        <ul>
          <li>
            Et molestiae veniam At laborum rerum et similique dicta et
            consequatur temporibus.{" "}
          </li>
          <li>
            Et corrupti odio hic praesentium eaque hic enim unde ex quasi iste?{" "}
          </li>
          <li>Ea sint nesciunt aut voluptatibus vero aut eius deserunt. </li>
          <li>Ab veniam ipsa eos voluptatibus ut sint eius. </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default Promotion;
