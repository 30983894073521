import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { racingParticipantsFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let racingParticipantsFormModelArray = racingParticipantsFormModel;

class CreateRacingParticipants extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        particiantIdetifier: "",
        barrierNumber: "",
        // name: "",
        runnerNumber: "",
        // age: null,
        // silkColour: "",
        // jockeyWeight: null,
        // jockeyTotalWeight: null,
        isScratched: "0",
        runnerComments: "",
      },
      raceId: null,
      // genderId: null,
      // colourId: null,
      // JockeyId: null,
      // trainerId: null,
      scratchTime: new Date(),
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    const { isEditMode, id, allRaces } = this.props;
    if (isEditMode) {
      this.fetchCurrentParticipants(id);
    }
    this.setState({ raceId: allRaces?.length > 0 ? allRaces[0].id : null });
  }

  componentWillUnmount() {
    racingParticipantsFormModelArray = racingParticipantsFormModelArray.map(
      (fieldItem) => {
        return { ...fieldItem, errorMessage: "" };
      }
    );
  }

  fetchCurrentParticipants = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.racingParticipants + `/${id}`
    );
    if (status === 200) {
      this.setState({
        values: data.result,
        raceId: data.result.raceId,
        scratchTime: new Date(data.result.scratchTime),
      });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let {
      particiantIdetifier,
      barrierNumber,
      runnerNumber,
      runnerComments,
      isScratched,
    } = this.state.values;
    let flag = true;

    if (
      particiantIdetifier === "" ||
      barrierNumber === "" ||
      runnerNumber === "" ||
      isScratched === "" ||
      runnerComments === ""
    ) {
      flag = false;
      // this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      // this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { raceId, scratchTime } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.racingParticipants}/${this.props.id}`
        : URLS.racingParticipants;

      const values = removeErrorFieldsFromValues(form.formData);
      values["raceId"] = raceId;
      values["scratchTime"] = scratchTime;

      racingParticipantsFormModelArray = racingParticipantsFormModelArray?.map(
        (fieldItem) => {
          return setValidation(fieldItem, values);
        }
      );

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllRacingParticipants();
          this.setActionMessage(
            true,
            "Success",
            `Racing Participants ${
              isEditMode ? "Edited" : "Created"
            } Successfully`
          );
        }
      }
    } catch (err) {
      console.log("error : ", err);
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Racing Participants`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    racingParticipantsFormModelArray = racingParticipantsFormModelArray?.map(
      (fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      }
    );
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "raceId") {
      this.setState({ raceId: value });
    }
    //  else if (name === "genderId") {
    //   this.setState({ genderId: value });
    // } else if (name === "colourId") {
    //   this.setState({ colourId: value });
    // } else if (name === "jockeyId") {
    //   this.setState({ jockeyId: value });
    // } else {
    //   this.setState({ trainerId: value });
    // }
  };

  handleDateChange = (date) => {
    let selectedDate = new Date(date);
    this.setState({ scratchTime: selectedDate });
  };

  render() {
    var { values, messageBox, isLoading, scratchTime } = this.state;
    var { isEditMode, allRaces } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider fixture-paper">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <div>
                <label>Race</label>
                <select
                  className="select-box-manual"
                  name="raceId"
                  onChange={this.handleSelect}
                >
                  {allRaces.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.raceName}
                    </option>
                  ))}
                </select>
              </div>
              <Form
                values={values}
                model={racingParticipantsFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />
              {/* <div>
                <label>Gender</label>
                <select
                  className="select-box-manual"
                  name="genderId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                <label>Colour</label>
                <select
                  className="select-box-manual"
                  name="colourId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                <label>Jockey</label>
                <select
                  className="select-box-manual"
                  name="jockeyId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
                <label>Trainer</label>
                <select
                  className="select-box-manual"
                  name="trainerId"
                  onChange={this.handleSelect}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div> */}
              <div className="input-field">
                <label>Scratch Time</label>
                <br></br>
                <DatePicker
                  selected={scratchTime}
                  onChange={this.handleDateChange}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateRacingParticipants;
