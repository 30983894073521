import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import Select from "react-select/creatable";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";

import "./register.scss";
import loginbg from "../../images/registerPageBG.png";
import bgdesign from "../../images/signupDollerImage.png";
import logo from "../../images/logo.svg";
import { fetchCategory } from "./RegisterActions";
import { connect } from "react-redux";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import Uncheck_square from "../../images/checkbox-square.svg";
import check_square from "../../images/checkbox-square-checked.svg";
import error_checkbox from "../../images/error-checkbox.svg";
import { ReactSVG } from "react-svg";
import { PAGEMETASVALUES } from "../../helpers/constants";
import { EventEmitter } from "../../services/event";

const selectboxStyles = {
  container: (styles) => ({ ...styles, width: "100%", minWidth: "180px" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    // color: "#fff",
    border: "none !important",
    borderRadius: "10px",
    // backgroundColor: "#6ABF4B",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  indicatorsContainer: (styles) => ({
    ...styles,
    color: "#000",
    svg: {
      color: "#656565",
      width: "26px",
      height: "26px",
    },
  }),
  indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
  input: (styles) => ({ ...styles, color: "#000" }),
  placeholder: (styles) => ({ ...styles, fontSize: "22px", color: "#656565" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#000",
    fontSize: "22px",
  }),
  valueContainer: (styles) => ({ ...styles, padding: "5px 16px" }),
};

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#00833e",
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationOptions: [
        { value: "WA", label: "Western Australia" },
        { value: "NT", label: "Northern Territory" },
        { value: "VIC", label: "Victoria" },
        { value: "QLD", label: "Queensland" },
        { value: "NSW", label: "New South Wales" },
        { value: "ACT", label: "Australian Capital Territory" },
        { value: "SA", label: "South Australia" },
        { value: "TAS", label: "Tasmania" },
      ],
      registerData: {
        userfirstname: "",
        userlastname: "",
        password: "",
        repeatPassword: "",
        email: "",
        // phone: "",
        // location: "",
        terms: false,
        age: false,
        showPassword: false,
        showRepeatPassword: false,
        // interest: [],
      },
      error: {},
    };
  }

  async componentDidMount() {
    PAGEMETASVALUES?.register &&
      EventEmitter.dispatch("setPageMeta", [PAGEMETASVALUES?.register]);
    const { fetchCategory } = this.props;
    await fetchCategory();
  }

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return {
        registerData: {
          ...prevState.registerData,
          [name]: value.split(" ").join(""),
        },
        error: { ...prevState.error, [name]: "" },
      };
    });
  };
  /* handleChangePhone = (e) => {
    var re = /^[0-9\b]+$/;
    var name = e.target.name;
    var value = e.target.value;
    if (value === "" || re.test(e.target.value)) {
      this.setState((prevState) => {
        return {
          registerData: {
            ...prevState.registerData,
            [name]: value,
          },
        };
      });
    }
  }; */
  handleChackbox = (e) => {
    let isSelected = e.currentTarget.checked;
    let name = e.target.name;

    this.setState((prevState) => {
      return {
        registerData: { ...prevState.registerData, [name]: isSelected },
        error: { ...prevState.error, [name]: "" },
      };
    });
  };
  /* handleChackboxInterest = (e) => {
    let isSelected = e.currentTarget.checked;

    var value = e.target.value;
    this.state.registerData.interest.push(value);
    this.setState((prevState) => {
      return {
        registerData: {
          ...prevState.registerData,
          interest: this.state.registerData.interest,
        },
      };
    });
  }; */

  /* onChangeLocationSelectbox = (selectedOptions) => {
    this.setState((prevState) => {
      return {
        registerData: {
          ...prevState.registerData,
          location: selectedOptions?.value,
        },
        error: { ...prevState.error, location: "" },
      };
    });
  }; */

  validate() {
    var {
      userfirstname,
      userlastname,
      email,
      password,
      repeatPassword,
      /*  phone,
      location, */
      terms,
      age,
    } = this.state.registerData;
    var { error } = this.state;
    var validflag = true;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === "") {
      error.email = "Please complete this field";
      validflag = false;
    } else if (!regexp.test(email)) {
      error.email = "Invalid Email";
      validflag = false;
    } else {
      error.email = "";
    }

    if (userlastname === "") {
      error.userlastname = "Please complete this field";
      validflag = false;
    } else {
      error.userlastname = "";
    }

    if (userfirstname === "") {
      error.userfirstname = "Please complete this field";
      validflag = false;
    } else {
      error.userfirstname = "";
    }

    /* if (phone === "") {
      error.phone = "Phone Number is required";
      validflag = false;
    } else if (!phone.match(/^[0-9\b]+$/)) {
      error.phone = "Please enter number only";
      validflag = false;
    } else {
      error.phone = "";
    }
    if (location === "") {
      error.location = "Location is required";
      validflag = false;
    } else {
      error.location = "";
    } */

    if (password === "") {
      error.password = "Please complete this field";
      validflag = false;
    } else if (password.length < 6) {
      error.password = "Password Must be 6 character";
      validflag = false;
    } else {
      error.password = "";
    }

    if (repeatPassword === "") {
      error.repeatPassword = "Please complete this field";
      validflag = false;
    } else if (repeatPassword !== "") {
      if (repeatPassword !== password) {
        error.repeatPassword =
          "Password doesn’t match. Please enter the same password twice";
        validflag = false;
      }
    } else {
      error.repeatPassword = "";
    }

    if (terms === "" || Boolean(terms) === false) {
      error.terms = "Please accept Terms & Conditions";
      validflag = false;
    } else {
      error.terms = "";
    }
    if (age === "" || Boolean(age) === false) {
      error.age = "Please confirm you are over 18 years of age";
      validflag = false;
    } else {
      error.age = "";
    }

    this.setState({ error });
    return validflag;
  }
  /*   handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }; */

  sendCode = () => {
    let email = this.state.registerData.email;
    axiosInstance
      .get(URLS.sendCode + email)
      .then(({ status, data }) => {
        if (status === 200) {
          this.props.history.push("/verifyemail");
        }
      })
      .catch((err) => {
        if (
          err.hasOwnProperty("response") &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          this.setState((prevState) => {
            return {
              error: {
                ...prevState.error,
                userfirstname: err.response.data.message,
              },
            };
          });
        } else if (
          err.hasOwnProperty("response") &&
          err.response.data.message === "Username Already Register"
        ) {
          this.setState((prevState) => {
            return {
              error: {
                ...prevState.error,
                email: err.response.data.message,
              },
            };
          });
        } else {
          this.setState((prevState) => {
            return {
              error: {
                ...prevState.error,
                userfirstname: "Failed to send code",
              },
            };
          });
        }
      });
  };

  onSubmit() {
    var {
      userfirstname,
      userlastname,
      email,
      /* location,
      interest, */
      password,
      // phone,
    } = this.state.registerData;
    if (this.validate()) {
      let data = {
        firstName: userfirstname,
        lastName: userlastname,
        fullname: userfirstname + " " + userlastname,
        username: email,
        password: password,
        /*  location: location,
        categories: interest,
        phone: phone, */
      };

      axiosInstance
        .post(URLS.register, data)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.props.history.push("/verifyemail");
            // this.sendCode();
          }
        })

        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  userfirstname: err.response.data.message,
                },
              };
            });
          } else if (
            err.hasOwnProperty("response") &&
            err.response.data.message ===
              "Username/Email already exists, please try sign in"
          ) {
            this.setState((prevState) => {
              return {
                error: {
                  ...prevState.error,
                  email: err.response.data.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                error: { ...prevState.error, userfirstname: "Invalid Login" },
              };
            });
          }
        });
    }
  }
  loggedRedirect = () => {
    if (this.props.isLoggedIn) {
      return <Redirect to={"/"} />;
    }
  };

  render() {
    const { error, showPassword, showRepeatPassword } = this.state;
    var {
      userfirstname,
      userlastname,
      email,
      password,
      terms,
      age,
      repeatPassword,
      // phone,
      // location,
    } = this.state.registerData;
    // const { allCategory } = this.props;
    return (
      <>
        {this.loggedRedirect()}
        <div className='page-content page-bg'>
          <Card className={"sign-page-root register register-bg"}>
            <div className={"sign-page-details"}>
              <CardContent className={"sign-page-content"}>
                <Container>
                  <div className={"signup-page-mbg"}>
                    <form
                      className='form register-form login-form'
                      noValidate
                      autoComplete='off'
                    >
                      <Grid
                        container
                        spacing={2}
                        justify='flex-start'
                        alignItems='flex-start'
                      >
                        <Grid item sm={12} xs={12}>
                          <Link to='/' className='logo'>
                            <img src={logo} alt='Motzza' />
                          </Link>
                        </Grid>
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.userfirstname !== "undefined" &&
                              error?.userfirstname !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="name">Name</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "First Name" }}
                              id='userfirstname'
                              name='userfirstname'
                              value={userfirstname}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof this.state.error.userfirstname !==
                            "undefined" &&
                          this.state.error.userfirstname !== "" ? (
                            <FormHelperText id='component-helper-text-userfirstname'>
                              <span className='error color-danger'>
                                {this.state.error.userfirstname}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.userlastname !== "undefined" &&
                              error?.userlastname !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="name">Name</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Last Name" }}
                              id='userlastname'
                              name='userlastname'
                              value={userlastname}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof error.userlastname !== "undefined" &&
                          error.userlastname !== "" ? (
                            <FormHelperText id='component-helper-text-userlastname'>
                              <span className='error color-danger'>
                                {error.userlastname}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.email !== "undefined" &&
                              error?.email !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="email">Email Address</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Email Address" }}
                              id='email'
                              name='email'
                              value={email}
                              onChange={this.handleChange}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof error.email !== "undefined" &&
                          error.email !== "" ? (
                            <FormHelperText id='component-helper-text-email'>
                              <span className='error color-danger'>
                                {error.email}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {/* <Grid item className="half-size-input" sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.phone !== "undefined" &&
                              error?.phone !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            <InputLabel shrink htmlFor="phone">Phone Number</InputLabel>
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Phone Number" }}
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={this.handleChangePhone}
                              disableUnderline
                            />
                          </FormControl>
                          {typeof error.phone !== "undefined" &&
                          error.phone !== "" ? (
                            <FormHelperText id="component-helper-text-phone">
                              <span className="error color-danger">
                                {error.phone}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid> */}
                        {/* <Grid
                          item
                          className="half-size-input select-grid"
                          sm={12}
                          xs={12}
                        >
                          <FormControl
                            className={
                              typeof error?.location !== "undefined" &&
                              error?.location !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            <Select
                              menuPosition={"absolute"}
                              menuPlacement={"auto"}
                              placeholder="Location"
                              name="location"
                              id="location"
                              defaultValue={location}
                              options={this.state.locationOptions}
                              styles={selectboxStyles}
                              onChange={this.onChangeLocationSelectbox}
                            />
                          </FormControl>
                          {typeof error.location !== "undefined" &&
                          error.location !== "" ? (
                            <FormHelperText id="component-helper-text-location">
                              <span className="error color-danger">
                                {error.location}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid> */}
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.password !== "undefined" &&
                              error?.password !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="password">Password</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Password" }}
                              id='password'
                              name='password'
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={this.handleChange}
                              disableUnderline
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                      this.setState({
                                        showPassword: !this.state.showPassword,
                                      })
                                    }
                                    //onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility className='visiblity' />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {typeof error.password !== "undefined" &&
                          error.password !== "" ? (
                            <FormHelperText id='component-helper-text-password'>
                              <span className='error color-danger'>
                                {error.password}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item className='half-size-input' sm={12} xs={12}>
                          <FormControl
                            className={
                              typeof error?.repeatPassword !== "undefined" &&
                              error?.repeatPassword !== ""
                                ? "invalid"
                                : ""
                            }
                          >
                            {/* <InputLabel shrink htmlFor="password">Password</InputLabel> */}
                            <Input
                              className={" rounded-input"}
                              inputProps={{ placeholder: "Repeat Password" }}
                              id='repeatPassword'
                              name='repeatPassword'
                              type={showRepeatPassword ? "text" : "password"}
                              value={repeatPassword}
                              onChange={this.handleChange}
                              disableUnderline
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                      this.setState({
                                        showRepeatPassword: !this.state
                                          .showRepeatPassword,
                                      })
                                    }
                                  >
                                    {showRepeatPassword ? (
                                      <Visibility className='visiblity' />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {typeof error.repeatPassword !== "undefined" &&
                          error.repeatPassword !== "" ? (
                            <FormHelperText id='component-helper-text-repeatPassword'>
                              <span className='error color-danger'>
                                {error.repeatPassword}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          className='half-size-input checkbox-grid checkbox-icon-small terms-grid login-checkbox'
                          sm={12}
                          xs={12}
                        >
                          <FormControlLabel
                            value='true'
                            name='terms'
                            control={
                              <GreenCheckbox
                                required
                                color='primary'
                                checked={terms}
                                onChange={this.handleChackbox}
                                icon={
                                  typeof error?.terms !== "undefined" &&
                                  error?.terms !== "" ? (
                                    <ReactSVG src={error_checkbox} />
                                  ) : (
                                    <ReactSVG src={Uncheck_square} />
                                  )
                                }
                                checkedIcon={<ReactSVG src={check_square} />}
                              />
                            }
                            label={
                              <>
                                {" "}
                                I have read and accept the{" "}
                                <Link to='/terms' className='underline'>
                                  Terms & Conditions
                                </Link>
                              </>
                            }
                            labelPlacement='end'
                            className={
                              typeof error?.terms !== "undefined" &&
                              error?.terms !== ""
                                ? "terms register-terms error_lable_checkbox"
                                : "terms register-terms"
                            }
                          />
                          {typeof error.terms !== "undefined" &&
                          error.terms !== "" &&
                          error.terms ? (
                            <FormHelperText id='component-helper-text-terms'>
                              <span className='error color-danger text-check-error'>
                                {error.terms}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          className='login-text checkbox-grid checkbox-icon-small'
                        >
                          <FormControlLabel
                            value='true'
                            name='age'
                            control={
                              <GreenCheckbox
                                required
                                color='primary'
                                checked={age}
                                onChange={this.handleChackbox}
                                icon={
                                  typeof error?.age !== "undefined" &&
                                  error?.age !== "" ? (
                                    <ReactSVG src={error_checkbox} />
                                  ) : (
                                    <ReactSVG src={Uncheck_square} />
                                  )
                                }
                                checkedIcon={<ReactSVG src={check_square} />}
                              />
                            }
                            label='I am 18 years old or over'
                            labelPlacement='end'
                            className={
                              typeof error?.age !== "undefined" &&
                              error?.age !== ""
                                ? "age error_lable_checkbox"
                                : "age"
                            }
                          />
                          {typeof error.age !== "undefined" &&
                          error.age !== "" ? (
                            <FormHelperText id='component-helper-text-age'>
                              <span className='error color-danger text-check-error'>
                                {error.age}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {/* <Grid item sm={12} xs={12} className="checkbox-groups">
                          <div className="checkbox-groups-interest">
                            <Typography variant="h5">Your Interests</Typography>
                            <Grid container>
                              {allCategory &&
                                allCategory.map((item, i) => (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    key={i}
                                    className="intrest-options"
                                  >
                                    <FormControlLabel
                                      value={item.id}
                                      name="interest[]"
                                      control={
                                        <Checkbox
                                          required
                                          color="primary"
                                          icon={
                                            <ReactSVG src={Uncheck_square} />
                                          }
                                          checkedIcon={
                                            <ReactSVG src={check_square} />
                                          }
                                          onChange={this.handleChackboxInterest}
                                        />
                                      }
                                      label={item.name}
                                      labelPlacement="end"
                                    />
                                  </Grid>
                                ))}
                            </Grid>
                          </div>
                        </Grid> */}
                        <Grid item sm={12} xs={12} className='register-button'>
                          <Button
                            onClick={this.onSubmit.bind(this)}
                            // className="submit-btn bg-dark-orange"
                            className='btn btn-warning'
                            variant='contained'
                          >
                            SIGN UP
                          </Button>
                        </Grid>
                        <Grid item sm={12} className='already-member'>
                          <p className='signup'>
                            Already a member?{" "}
                            <Link to='/login' className='login'>
                              Login
                            </Link>
                          </p>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                  <div className='mobilebg-design'>
                    <img src={bgdesign} alt='bgdesign' />
                  </div>
                </Container>
              </CardContent>
            </div>
            <CardMedia
              className={"sign-page-cover hide-sm hide-xs hide-bg"}
              image={loginbg}
            >
              <img src={loginbg} alt='' />
            </CardMedia>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer /* registerReducer */ }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    /* allCategory: registerReducer.category, */
  };
};

export default connect(mapStateToProps, { fetchCategory })(Register);
