import { Validators } from "../../../library/utilities/Validators";

export const marketsFormModel = [
  // {
  //   label: "Market",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Market Id",
  //   field: "marketId",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "12",
  //   options: [
  //     { value: "", label: "No market Selected" },
  //     { id: 1, value: "1", label: "1" },
  //     { id: 2, value: "2", label: "2" },
  //     { id: 3, value: "3", label: "3" },
  //   ],
  // },
  {
    label: "Key*",
    value: "",
    type: "dropdown",
    placeholder: "Key",
    field: "key",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { id: 1, value: "1", label: "1" },
      { id: 2, value: "2", label: "2" },
      { id: 3, value: "3", label: "3" },
    ],
  },
  {
    label: "Value*",
    value: "",
    type: "text",
    placeholder: "Value",
    field: "value",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "BookKeeper",
    value: "",
    type: "dropdown",
    placeholder: "BookKeeper",
    field: "bookKeepersId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
];
