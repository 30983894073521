import React from "react";
import "./faqs.scss";
import {
  Grid,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  AppBar,
  Box,
  Collapse,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      value: 0,
      expand: null,
      accordian: null,
    };
  }
  componentDidMount() {
    this.setState({ faqs: this.FAQs });
  }
  handleChange = (e, newValue) => {
    this.setState({ value: newValue, expand: null });
  };
  handleCollapse = (i) => {
    this.setState({ expand: this.state.expand === i ? null : i });
  };
  handleAccordian = (i) => {
    this.setState({
      accordian: this.state.accordian === i ? null : i,
      expand: null,
    });
  };

  FAQs = [
    {
      id: 1,
      label: "Mottza FAQs",
      content: [
        {
          id: 1,
          title: "Can I bet through motzza from outside Australia?",
          body: (
            <>
              You can use every motzza feature, but you may not be able to
              access every betting website affiliated with us. Accessing
              affiliated website will depend on every country/state regulation
              around the betting industry.
            </>
          ),
        },
        {
          id: 2,
          title: "Can I use a bookmaker that isn’t on motzza?",
          body: (
            <>
              If a bookmaker is not affiliated with us, you won’t be able to
              place a bet with this bookmaker through motzza. However, we are
              always adding new bookmakers to motzza, so your bookmaker may be
              available through motzza in future. Please send us the details of
              your preferred bookmaker and we will contact these bookmakers to
              sign up with motzza.
            </>
          ),
        },
        {
          id: 3,
          title: "What do I do if I can’t access a bookmaker?",
          body: (
            <>
              If you are unable to click through to a betting website via
              motzza, please submit a request via our contact form.
              <p>
                Contact form:{" "}
                <a href="https://motzza.com.au/contactus">
                  https://motzza.com.au/contactus
                </a>
              </p>
            </>
          ),
        },
        {
          id: 4,
          title: "I haven’t received my bet winnings",
          body: (
            <>
              Bookmakers can take up to 36 hours to credit their winning bets.
              If you’ve waited longer than 36 hours and haven’t received your
              payment, please submit your details via our contact form and our
              team will investigate. It’s always best to contact the bookmaker
              in the first instance.
              <p>Please provide your:</p>
              Username
              <br />
              Email address
              <br />
              Date and time of the bet
              <br />
              Bet details (match and bet)
              <br />
              <p>
                Contact form:{" "}
                <a href="https://motzza.com.au/contactus">
                  https://motzza.com.au/contactus
                </a>
              </p>
            </>
          ),
        },
        {
          id: 5,
          title: "I have a dispute about a bet",
          body: (
            <>
              If your dispute involves a bet placed with a bookmaker through
              motzza, please always contact your bookmaker first. motzza simply
              connects you to betting websites.
            </>
          ),
        },
        {
          id: 5,
          title: "A sport I’m looking for isn’t on motzza",
          body: (
            <>
              If a particular sport, league or match you are looking for isn’t
              on motzza, please submit a request via our contact form and we
              will endeavour to bring you the best odds for your preferred
              sport.
              <p>
                Contact form:{" "}
                <a href="https://motzza.com.au/contactus">
                  https://motzza.com.au/contactus
                </a>
              </p>
            </>
          ),
        },
        {
          id: 5,
          title: "Is it safe to log into bookmakers?",
          body: (
            <>
              Yes. We partner with bookmakers to provide all our customers with
              the safest login method and experience possible. All bookmaker
              login forms are completely secure and safe to use. Login takes
              places through a direct connection with the bookmaker using HTTPS
              encryption. We will continue to provide you seamless integration
              as the technology continues to improve with our affiliate
              partners.
            </>
          ),
        },
        {
          id: 5,
          title: "What is motzza?",
          body: (
            <>
              motzza is the biggest and most trusted odds comparison platform in
              Australia. We get all our odds from reputable and verified
              bookmakers so you can make informed decisions around all sporting
              events.
            </>
          ),
        },
        {
          id: 5,
          title: "Why do I need to sign up to motzza?",
          body: (
            <>
              You need to sign up to motzza to access our odds comparison
              system. motzza membership gives you access to exclusive member
              benefits, including live sports streaming, form guides and our
              rewards rebate program. motzza also offers you discounted pricing
              on vast array of sporting memorabilia and discounted rates to
              Australia’s largest tipping agency. Overall, by becoming a member,
              you can have a seamless and secure experience with all sporting
              events.{" "}
            </>
          ),
        },
        {
          id: 5,
          title: "How do I log into my bookmaker?",
          body: (
            <>
              From one of our odds comparison pages, tap on the bookmaker you
              would like to access. You’ll then be taken to a new screen that
              will prompt you to log in and start betting.
            </>
          ),
        },
        {
          id: 5,
          title: "Who gets my money?",
          body: (
            <>
              Your bets will always be placed with bookmakers via motzza. motzza
              doesn’t take any money that you place with a bookmaker; we simply
              connect you to your betting account and give you the best odds
              comparison on sporting events for you to make an informed
              decision. Freedom of choice is what we promote.
            </>
          ),
        },
        {
          id: 5,
          title: "How do I track my bets?",
          body: (
            <>
              Once you have placed a bet with a bookmaker through motzza, you
              can track how it is going in your bookmaker’s account. You’ll be
              able to view all your closed and open bets with a particular
              bookmaker on that bookmaker’s website.
            </>
          ),
        },
      ],
    },
    /*{
      id: 2,
      label: "Lorem",
      content: [
        { id: 1, title: "lorem ipsum title 1", body: "lorem ipsum body" },
        { id: 2, title: "lorem ipsum title 1", body: "lorem ipsum body" },
      ],
    },
    {
      id: 3,
      label: "Lorem",
      content: [
        { id: 1, title: "lorem ipsum title 2", body: "lorem ipsum body" },
        { id: 2, title: "lorem ipsum title 2", body: "lorem ipsum body" },
      ],
    },
    {
      id: 4,
      label: "Lorem",
      content: [
        { id: 1, title: "lorem ipsum title 3", body: "lorem ipsum body" },
        { id: 2, title: "lorem ipsum title 3", body: "lorem ipsum body" },
      ],
    },*/
  ];

  render() {
    const { value, faqs, expand, accordian } = this.state;
    return (
      <>
        <Container className="page-banner">
          <div className="faqs-wrapper">
            <Grid
              container
              justify="center"
              className="faqs-banner "
              alignItems="flex-start"
            >
              <Grid item xs={12} className="faqs-wrapp">
                <Typography
                  variant="h5"
                  className="title faqs-title mobile-heading text-center color-blue text-upper"
                >
                  FAQs
                </Typography>
                {/* <Typography
                  variant="body1"
                  className="text-center faqs-subtitle"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography> */}
                <div className="hide-xs">
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={this.handleChange}
                      aria-label="simple tabs example"
                      style={{ display: "none" }}
                    >
                      {faqs?.length > 0 &&
                        faqs?.map((facq, i) => (
                          <Tab key={i} label={facq.label} {...a11yProps(i)} />
                        ))}
                      <Tab></Tab>
                    </Tabs>
                  </AppBar>
                  {faqs?.length > 0 &&
                    faqs?.map((facq, i) => (
                      <TabPanel value={value} index={i}>
                        {facq.content.map((cont, i) => (
                          <>
                            <Typography
                              onClick={() => this.handleCollapse(i)}
                              className={
                                expand === i
                                  ? "faqs-inner-selectedtitle"
                                  : "faqs-inner-title"
                              }
                            >
                              {expand === i ? <RemoveIcon /> : <AddIcon />}
                              {cont.title}
                            </Typography>
                            <Collapse
                              in={expand === i}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Typography className="faqs-inner-content">
                                {cont.body}
                              </Typography>
                            </Collapse>
                          </>
                        ))}
                      </TabPanel>
                    ))}
                </div>
                <div className="hide-sm hide-md hide-lg hide-xl faqs-accordian">
                  {faqs?.length > 0 &&
                    faqs.map((faqs, i) => (
                      <>
                        <div className="mobile-faqs-accordian">
                          {faqs.content.map((cont, i) => (
                            <>
                              <Typography
                                onClick={() => this.handleCollapse(i)}
                                className={
                                  expand === i
                                    ? "faqs-inner-selectedtitle"
                                    : "faqs-inner-title"
                                }
                              >
                                {expand === i ? <RemoveIcon /> : <AddIcon />}
                                {cont.title}
                              </Typography>
                              <Collapse
                                in={expand === i}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Typography className="faqs-inner-content">
                                  {cont.body}
                                </Typography>
                              </Collapse>
                            </>
                          ))}
                        </div>
                        {/* <Accordion
                          expanded={accordian === i}
                          onChange={() => this.handleAccordian(i)}
                        >
                          <AccordionSummary id="panel1a-header">
                            <Typography className="accordian-head">
                              {faqs.label}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {faqs.content.map((cont, i) => (
                              <>
                                <Typography
                                  onClick={() => this.handleCollapse(i)}
                                  className={
                                    expand === i
                                      ? "faqs-inner-selectedtitle"
                                      : "faqs-inner-title"
                                  }
                                >
                                  {expand === i ? <RemoveIcon /> : <AddIcon />}
                                  {cont.title}
                                </Typography>
                                <Collapse
                                  in={expand === i}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Typography className="faqs-inner-content">
                                    {cont.body}
                                  </Typography>
                                </Collapse>
                              </>
                            ))}
                          </AccordionDetails>
                        </Accordion> */}
                      </>
                    ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </>
    );
  }
}
export default Faqs;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="faqs-inner" p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
