import React from "react";
import {
  Grid,
  Select,
  FormControl,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { EventEmitter } from "../../../services/event";
import AllOrganisation from "./AllOrganisations";
import AllEvents from "./AllEvent";
import SingleEvent from "./SingleEvent";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./mma.scss";

const sportsList = [
  { name: "All Organisations" },
  { name: "[organisation 1]" },
  { name: "[organisation 2]" },
];

const event = [
  { id: 1, name: "All Events" },
  { id: 2, name: "[Event 1]" },
  { id: 3, name: "[Event 2]" },
];

class AllSportsAllRacing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrganisation: "",
      selectedEvent: "",
      eventSelected: false,
    };
    EventEmitter.subscribe("show_all_mmaEvents", this.ShowAllMMAEvents);
  }

  componentDidMount = () => {
    this.setState({
      selectedOrganisation: sportsList[0].name,
      selectedEvent: event[0].name,
      eventSelected: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      selectedOrganisation: e.target.value,
      eventSelected: false,
    });
  };

  handleEvents = (e) => {
    this.setState({ selectedEvent: e.target.value, eventSelected: true });
  };

  ShowAllMMAEvents = (events) => {
    this.setState({
      eventSelected: true,
    });
  };

  selectedRacing = (fight) => {
    switch (fight) {
      case "All Organisations":
        return <AllOrganisation />;
      case "[organisation 1]":
        return "";
      case "[organisation 2]":
        return "";
      default:
        return "";
    }
  };

  selectedEventControl = (event) => {
    switch (event) {
      case "All Events":
        return <AllEvents />;
      case "[Event 1]":
        return <SingleEvent />;
      case "[Event 2]":
        return <SingleEvent />;
      default:
        return "";
    }
  };

  render() {
    let { selectedOrganisation, selectedEvent, eventSelected } = this.state;
    return (
      <>
        <Grid
          container
          className="allsports_head allmma_head hide-xs"
          style={{
            marginTop: 10,
            marginBottom: 9,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sm={8}
            xs={12}
            className="allsports_head_left"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                className="sports_select racing-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedOrganisation}
                onChange={this.handleChange}
              >
                {sportsList.map((obj, i) => (
                  <MenuItem key={i} value={obj.name}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedEvent}
                className="select-country"
                onChange={this.handleEvents}
              >
                {event.map((obj, i) => (
                  <MenuItem key={i} value={obj.name}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            sm={4}
            xs={12}
            className="allsports_head_right hide-sm hide-md hide-lg hide-xl"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedEvent}
                className="select-country"
                onChange={this.handleEvents}
              >
                {event.map((obj, i) => (
                  <MenuItem key={i} value={obj.name}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          className="allsports_head allmma_head hide-sm hide-md hide-lg hide-xl"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sm={6}
            xs={12}
            className="allsports_head_left"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControl>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                className="sports_select racing-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedOrganisation}
                onChange={this.handleChange}
              >
                {sportsList.map((obj, i) => (
                  <MenuItem key={i} value={obj.name}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={KeyboardArrowDownIcon}
                value={selectedEvent}
                className="select-country"
                onChange={this.handleEvents}
              >
                {event.map((obj, i) => (
                  <MenuItem key={i} value={obj.name}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider className="allsports_hr" />
        {eventSelected
          ? this.selectedEventControl(selectedEvent)
          : this.selectedRacing(selectedOrganisation)}
      </>
    );
  }
}
export default AllSportsAllRacing;
