import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/bookmarkers.png";

import bet365 from "../../images/footer_icons/bet365.png";
import betstar from "../../images/footer_icons/betstar.png";
import bookmaker from "../../images/footer_icons/bookmaker.png";
import ladbrokes from "../../images/footer_icons/ladbrokes.png";
import neds from "../../images/footer_icons/neds.png";
import playup from "../../images/footer_icons/playup.png";
import VICBet from "../../images/footer_icons/VICBet.png";
import palmerbet from "../../images/footer_icons/palmerbet.png";

class MembershipBookmakers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookmakers: [
        { src: bet365, alt: "bet365" },
        { src: betstar, alt: "betstar" },
        { src: bookmaker, alt: "bookmaker" },
        { src: ladbrokes, alt: "ladbrokes" },
        { src: neds, alt: "neds" },
        { src: playup, alt: "playup" },
        { src: VICBet, alt: "VICBet" },
        { src: palmerbet, alt: "palmerbet" },
      ],
    };
  }
  render() {
    let { bookmakers } = this.state;
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Bookmakers
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                There are many bookmakers out there, making it challenging to
                understand which one is the most suitable for your needs. Here
                at motzza, we compare and analyse a range of bookmakers,
                allowing you to access all the tools and information you need in
                the one place.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                We’ve partnered with some of the biggest bookmakers in the
                country, so that you can find the wagering operator that best
                suits your sporting needs. Whether that be live racing vision,
                top odds for particular sports or simply a sleek user-friendly
                experience, motzza has what you need to get started.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                There are many online bookmaker options available for our motzza
                registered members. Here is a list of the reputable online
                bookmakers that we have partnered with to help inform your
                sporting decisions:
              </Typography>
              <ul className="membership-bookmarker-logo">
                {bookmakers?.map((obj, i) => (
                  <li key={i}>
                    <img src={obj.src} alt={obj.alt} />
                  </li>
                ))}
              </ul>
              <Typography variant="body1" className="afiliate-title-content">
                If you are looking to sign up with a new bookmaker, or you are
                completely new to this industry, motzza is a great place to
                start. We compare odds from our top bookmakers to simplify your
                decision-making process and help you find the best odds quickly
                and easily. You can also access our affiliates anywhere, any
                time and on any device.
              </Typography>
              <Typography variant="body1" className="afiliate-signup-content">
                As a motzza member, you can quickly view the offers, offers,
                racing sign up discounts and rewards programs being offered by
                each individual bookmaker.{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  Sign up now
                </span>{" "}
                and get access to our bookmaker affiliates.
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipBookmakers;
