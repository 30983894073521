import React, { Component } from "react";
import { Grid, Container } from "@material-ui/core";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { Loader } from "../../library/common/components";
import { EventEmitter } from "../../services/event";

class Page extends Component {
  state = {
    pageContent: null,
    isLoading: false,
  };

  async componentDidMount() {
    await this.fetchPageDetail();
  }

  async fetchPageDetail() {
    this.setState({ isLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        URLS.page + "/" + this.props.match.params.slug
      );
      if (status === 200) {
        this.setState({ pageContent: data, isLoading: false });
        document.title = data.pagename;
        EventEmitter.dispatch("setPageMeta", data.tags);
      }
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { pageContent, isLoading } = this.state;

    return (
      <Container className="page-banner">
        <Grid container>
          {isLoading && !pageContent && <Loader />}

          {pageContent && (
            <Grid xs={12} className="text-left">
              <h1>{pageContent.pagename}</h1>
              <div dangerouslySetInnerHTML={{ __html: pageContent.content }} />
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }
}

export default Page;
