/* All Common services should be written here */
import {
    loader,
    authHandler,
    clearAuth,
  } from '../store/common/actions/common.actions';
  import StorageService from './storage';
  import { STORAGE_ENGINES } from '../helpers/constants';
  import { isObjectEmpty } from '../helpers/common';

  export default class CommonService {
    constructor() {
      this.storageService = new StorageService(STORAGE_ENGINES.SESSION);
      this.localStorage = new StorageService(STORAGE_ENGINES.LOCAL);
      this.store = window.store;
      if (window.store) {
        this.store = window.store;
      } else {
        this.error = new Error('Store instance not initialized');
        throw this.error;
      }
    }

    async initSession() {
      const session = this.storageService.get('auth');
      const persistSession = this.localStorage.get('auth');
      const sessionData = persistSession || session;
      if (!isObjectEmpty(sessionData)) {
        console.log(sessionData);
        window.store.dispatch(authHandler(sessionData));
        return Promise.resolve(true);
      }
      this.error = 'Session not initialized';
      return Promise.reject(this.error);
    }

    clearSession() {
      console.log(this.store);
      this.store.dispatch(clearAuth(null));
      this.storageService.clearAll();
      this.localStorage.clearAll();
    }

    logout() {
      this.clearSession();
    }

    loader(action) {
      const validActions = [true, false];
      if (!validActions.includes(action)) {
        this.error.message = `Invalid parameter passed in loader function, valid parameters are ${validActions}`;
        throw this.error;
      }
      this.store.dispatch(loader(action));
    }

    /* Common headers like token should be added here */
    getHeaders() {
      const state = this.store.getState();
      const token = (state.common) && (state.common.auth) && state.common.auth.access_token;
      return {
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imdob3JpdmlwdWw5N0BnbWFpbC5jb20iLCJpZCI6Mywicm9sZSI6Im1lbWJlciIsImlhdCI6MTU5NTMyNjcwNiwiZXhwIjoxNTk1MzI3MDA2fQ.j6rrplgJUXMZLz7ETp9CwC2V4m30iUpJ-crULZwbrOw`,
      };
    }
  }
