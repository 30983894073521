import { Validators } from "../../../library/utilities/Validators";

export const eventsFormModel = [
  {
    label: "Sport*",
    value: "",
    type: "dropdown",
    placeholder: "Sport",
    field: "sportId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "League*",
    value: "",
    type: "dropdown",
    placeholder: "League",
    field: "leagueId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  // {
  //   label: "Api Event Id*",
  //   value: "",
  //   type: "text",
  //   placeholder: "Api Event Id",
  //   field: "apiEventId",
  //   validators: [{ check: Validators.required }],
  //   required: true,
  //   className: "6",
  // },
  {
    label: "Event Name*",
    value: "",
    type: "text",
    placeholder: "Event Name",
    field: "eventName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Description*",
    value: "",
    type: "text",
    placeholder: "Description",
    field: "description",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  // {
  //   label: "Category",
  //   value: "",
  //   type: "dropdown",
  //   placeholder: "Category",
  //   field: "categoryId",
  //   validators: [
  //     { check: Validators.required},
  //   ],
  //   required: true,
  //   className: "6",
  //   options: [
  //     { value: "", label: "No Category Selected" },

  //   ],
  // },
  {
    label: "Location*",
    value: "",
    type: "dropdown",
    placeholder: "Location",
    field: "locationId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Weather*",
    value: "",
    type: "dropdown",
    placeholder: "Weather",
    field: "weather",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Comment*",
    value: "",
    type: "text",
    placeholder: "Comment",
    field: "comment",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
];
