import React from "react";
import { Grid, Paper } from "@material-ui/core";

class ImportCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Grid container className='page-content adminLogin'>
          <Grid item xs={12}>
            <Paper className='pageWrapper'>
              <Grid container direction='row' alignItems='space-around'>
                <Grid item xs={10}>
                  <h3 className='text-left'>Import Fixture Using CSV</h3>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default ImportCSV;
