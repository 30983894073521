import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

// export default class VariationsInput extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       inputValue: "",
//       value: props.value ? props.value : [],
//     };
//     console.log("props values", this.props.value);
//   }

//   handleChange = (value, actionMeta) => {
//     // console.group("Value Changed");
//     // console.log(value);
//     // console.log(`action: ${actionMeta.action}`);
//     // console.groupEnd();
//     this.props.onChange(value, this.props.field);
//     this.setState({ value });
//   };
//   handleInputChange = (inputValue) => {
//     this.setState({ inputValue });
//   };
//   handleKeyDown = (event) => {
//     const { inputValue, value } = this.state;
//     if (!inputValue) return;
//     switch (event.key) {
//       case "Enter":
//       case "Tab":
//         console.group("Value Added");
//         console.log(value, inputValue);
//         console.groupEnd();
//         this.props.onChange(
//           [...value, createOption(inputValue)],
//           this.props.field
//         );
//         this.setState({
//           inputValue: "",
//           value: [...value, createOption(inputValue)],
//         });

//         event.preventDefault();
//     }
//   };
//   render() {
//     const { inputValue, value } = this.state;
//     return (
//       <div className="input-field">
//         <CreatableSelect
//           components={components}
//           inputValue={inputValue}
//           isClearable
//           isMulti
//           menuIsOpen={false}
//           onChange={this.handleChange}
//           onInputChange={this.handleInputChange}
//           onKeyDown={this.handleKeyDown}
//           placeholder="Type variation and press enter..."
//           value={value}
//         />
//       </div>
//     );
//   }
// }

const VariationsInput = (props) => {
  const [selectvalue, setselectvalue] = useState([]);
  const [inputValue, setinputValue] = useState("");
  useEffect(() => {
    setselectvalue(props.value);
  }, [props.value]);
  const handleChange = (value, actionMeta) => {
    // console.group("Value Changed");
    // console.log(value);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
    props.onChange(value, props?.field);
    setselectvalue(value);
  };

  const handleInputChange = (inputValue) => {
    setinputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        // console.group("Value Added");
        // console.log(value, inputValue);
        // console.groupEnd();
        props.onChange([...selectvalue, createOption(inputValue)], props.field);
        setselectvalue([...selectvalue, createOption(inputValue)]);
        setinputValue("");
        event.preventDefault();
    }
  };

  return (
    <div className="input-field">
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type variation and press enter..."
        value={selectvalue}
      />
      <span style={{ fontSize: "13px", opacity: "0.8" }}>
        Type and Press Tab or Enter to add new variation
      </span>
    </div>
  );
};

VariationsInput.propTypes = {
  value: PropTypes.array,
  field: PropTypes.string,
};

VariationsInput.defaultProps = {};

export default VariationsInput;
