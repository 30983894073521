// import { URLS } from 'library/common/constants/UrlConstants';
import { config } from "../../helpers/config";
// import Config from 'library/utilities/Config';
import { URLS } from "../common/constants";

export const errorHandler = (error) => {
  if (error.response) {
    const loginURL = config.baseUrl + URLS.login;

    if (
      error.response.status === 401
      // || error.response.status === 403
    ) {
      // if (error.response.config.url !== loginURL) {
      sessionStorage.clear();
      localStorage.clear();
      // window.location.reload();
      // }
    } else if (error.response.status === 403) {
      let data = error.response?.data;
      if (data?.message === "jwt expired") {
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
      }
    }
  }
};
