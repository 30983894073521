import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/rewards.png";

class MembershipRewardsRebate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Rewards Rebate Program
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                Everyone likes to be rewarded for the hard work they do. That’s
                why motzza wants to reward you for your loyalty to us. Whether
                you’re using motzza to compare odds from leading bookmakers,
                look for sports memorabilia or stream sports live, our rewards
                rebate program is giving it back to you.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                With our motzza rewards rebate program, members can earn an
                impressive number of points simply by signing up and telling
                your friends, family and colleagues about motzza. For each
                member you successfully sign up, you will get motzza Puntin’
                Points added to your account. Once you’ve saved up enough
                points, you can redeem them to access a huge range of exclusive
                rewards.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                Every time you refer a new member, you strengthen the motzza
                community. The more support you give to us, the better we can
                enhance your sports and racing experience. And the better your
                friends’ and family members’ betting decisions will be!
              </Typography>
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                How do I refer a member?
              </p>
              <ul className="affiliate-ul">
                <li>
                  Call, text or email someone who isn’t a motzza member and tell
                  them to sign up today.
                </li>
                <li>
                  Provide your friends with a link to our website so they can
                  check out our benefits and join us at motzza.
                </li>
                <li>
                  Spread the word on Facebook, Instagram, Twitter and LinkedIn.
                  You can tag{" "}
                  <span style={{ color: "#1D9256", fontWeight: "600" }}>
                    @motzzaaus
                  </span>{" "}
                  and link directly to our website in your post.
                </li>
              </ul>
              <Typography variant="body1" className="afiliate-signup-content">
                Start experiencing the rewards rebate program that allows you to
                accrue points and use your points as you like to claim exciting{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  rewards quickly and easily.
                </span>
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipRewardsRebate;
