import { Validators } from "../../../library/utilities/Validators";

export const sportsFormModel = [
  {
    label: "Sport Type*",
    value: "",
    type: "dropdown",
    placeholder: "Sport Type",
    field: "sportTypeId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [],
  },
  {
    label: "Sport Name*",
    value: "",
    type: "text",
    placeholder: "Sport Name",
    field: "sportName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
];
