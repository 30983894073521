import React, { Component } from "react";
import "./login.scss";
import Button from "../../library/common/components/Button";
import { Card, CardContent, Container, Grid } from "@material-ui/core";
import { Link, Redirect, withRouter } from "react-router-dom";
import Form from "../../library/common/components/Form";
import { loginFormModel } from "./constants";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { connect } from "react-redux";
import { getAuth, setAuthentication } from "../../library/common/actions";
import { Loader } from "../../library/common/components";
import { saveToStorage } from "../../library/utilities";
import logo from "../../images/logo.svg";

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loginError: null,
    };
  }

  componentDidMount() {
    this.props.getAuth();
  }

  handleLogin = async (e) => {
    e.preventDefault();
    const values = this.formRef.getFormData();
    const { formData, isFormValid } = values;
    // const { REACT_APP_CLIENT, REACT_APP_SECRET } = process.env;

    if (isFormValid) {
      this.setState({ isLoading: true });
      let jwt = require("jsonwebtoken");
      const config = {
        auth: {
          username: formData.username,
          password: formData.password,
        },
      };
      const data = {
        username: formData.username,
        password: formData.password,
      };
      axiosInstance
        .post(URLS.login, data, config)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: true });
            let user_obj = jwt.decode(data.access_token);
            this.props.setAuthentication(data, user_obj);
            saveToStorage("user", user_obj);
            this.setState({ isLoading: false });
            window.location.href = "/admin/dashboard";
            // this.props.history.push("/admin/dashboard");
            // setTimeout(() => {
            //   window.location.href = "/admin/dashboard";
            // }, 3000);
          }
        })

        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err?.response?.status === 401 || err?.response?.status === 403)
          ) {
            this.setState((prevState) => {
              this.setState({
                isLoading: false,
                loginError: err?.response?.data?.message,
              });
            });
          } else {
            this.setState((prevState) => {
              this.setState({
                isLoading: false,
                loginError: "Something went wrong. Please try again",
              });
            });
          }
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({
        isLoading: false,
        loginError: "Please check email or password",
      });
    }
  };

  loggedRedirect = () => {
    if (this.props.isLoggedIn) {
      return <Redirect to={"/admin/dashboard"} />;
    }
  };

  render() {
    const { loginError, isLoading } = this.state;
    return (
      <>
        {this.loggedRedirect()}
        <div className="page-content adminLogin">
          <Card>
            <div className="loginWrapper">
              <CardContent>
                <Container>
                  <Grid container spacing={3}>
                    <Grid item lg={6} sm={12} xs={4}>
                      <div className="leftSide">
                        <p className="welcomeText">
                          welcome to <br />
                          motzza
                        </p>
                      </div>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={4}>
                      <div className="right-side">
                        <form
                          onSubmit={this.handleLogin}
                          className="text-center"
                        >
                          <div className="mb-30">
                            <Link to="/" className="logo">
                              <img src={logo} alt="Motzza" />
                            </Link>
                          </div>
                          {!!loginError && (
                            <p className="text-left errorText">
                              {" "}
                              {loginError}{" "}
                            </p>
                          )}
                          {isLoading && <Loader />}
                          <Form
                            ref={(el) => {
                              this.formRef = el;
                            }}
                            model={loginFormModel}
                          />{" "}
                          <Button
                            className="loginBtn"
                            type="submit"
                            disabled={isLoading}
                            onClick={this.handleLogin}
                            value={isLoading ? "Loading" : "Login"}
                            color="primary"
                          />
                        </form>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </CardContent>
            </div>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default withRouter(
  connect(mapStateToProps, { getAuth, setAuthentication })(AdminLogin)
);
