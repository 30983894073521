import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  SwipeableDrawer,
  Divider,
} from "@material-ui/core";
import { CATEGORY_MENU_STATUS } from "../../helpers/constants";
import Axios from "axios";
import { FaBars, FaChevronRight, FaTimes } from "react-icons/fa";
import CloseIcon from "@material-ui/icons/Close";
import { getRoute } from "../../helpers/common";

import "./sidebarmenu.scss";
import axiosInstance from "../../helpers/Axios";
import Select from "react-select/creatable";

import csc from "country-state-city-plus";
const colourStyles = {
  container: (styles) => ({ ...styles, width: "100%", minWidth: "180px" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    color: "#fff",
    border: "none",
    backgroundColor: "#6ABF4B",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  input: (styles) => ({ ...styles, color: "#fff" }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles, color: "#fff" }),
};

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
    return item;
  });
  return images;
}

function ListItemLink(props) {
  const { icon, primary, to, menuItem, className } = props;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li className={className}>
      <ListItem button component={renderLink} {...props}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {typeof menuItem.submenu !== "undefined" &&
        menuItem.submenu.length > 0 ? (
          <div className="submenu-indecator">
            <FaChevronRight />
          </div>
        ) : (
          ""
        )}
      </ListItem>
    </li>
  );
}

const images = importAll(
  require.context("../../images/menu_icons/", false, /\.(png|jpe?g|svg)$/)
);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #6ABF4B",
    MuiButtonRoot: {
      Background: "#6ABF4B",
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#6ABF4B",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

class SidebarMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showSubMenu: false,
      activeMenu: "",
      NwsMenu: false,
      subMenu: [],
      options: [
        { value: "WA", label: "Western Australia" },
        { value: "NT", label: "Northern Territory" },
        { value: "VIC", label: "Victoria" },
        { value: "QLD", label: "Queensland" },
        { value: "NSW", label: "New South Wales" },
        { value: "ACT", label: "Australian Capital Territory" },
        { value: "SA", label: "South Australia" },
        { value: "TAS", label: "Tasmania" },
      ],
      mainMenu: [
        {
          id: 1,
          title: "Home",
          link: "/",
          icon: "", //<IoMdHome />,
          submenu: [],
        },
        {
          id: 2,
          title: "All Sports",
          link: "/allsports",
          icon: "",
          subMenu: [],
        },
        { id: 3, title: "Horse Racing", link: "/", icon: "", subMenu: [] },
        {
          id: 4,
          title: "Harness Racing",
          link: "/race/2/Harness Racing",
          icon: "",
          subMenu: [],
        },
        {
          id: 5,
          title: "Greyhound Racing",
          link: "/race/3/Greyhound Racing",
          icon: "",
          subMenu: [],
        },
        {
          id: 6,
          title: "Cricket",
          link: "/sports/Cricket/4",
          icon: "",
          subMenu: [],
        },
        {
          id: 7,
          title: "Boxing",
          link: "/sports/Boxing/6",
          icon: "",
          subMenu: [],
        },
      ],
      address: null,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.closeSubMenu = this.closeSubMenu.bind(this);
    this.toggleMainMenu = this.toggleMainMenu.bind(this);
  }

  componentDidMount() {
    //this.fetchAllCategories();
    this.getCurrentPosition();
  }

  async getCurrentPosition() {
    const countryAUS = csc.getCountryByName("Australia");
    const statesAUS = csc.getStatesOfCountry(countryAUS?.id);

    let options =
      statesAUS.length > 0 &&
      statesAUS.map((item) => {
        return { value: item.name, label: item.name };
      });

    options = this.state.options;
    if (options.length > 0) {
      options.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
      this.setState({ options: options, address: options[0] });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // const API_KEY = "AIzaSyDTgboaoeHZfDq4BNUdHDGFRB2Tr9QGTOQ";
        // const url = `http://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=true&key=${API_KEY}`;
        const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`;
        Axios.get(url)
          .then((res) => {
            this.setState({ address: res.data });

            if (res.data.countryName !== "") {
              // let country = csc.getCountryByName(res.data.countryName);
              // let states = csc.getStatesOfCountry(country?.id);
              // console.log("country states", country, states);

              // options =
              //   states.length > 0 &&
              //   states.map((item) => {
              //     return { value: item.name, label: item.name };
              //   });
              // options = options.sort((a, b) => {
              //   return a.value > b.value;
              // });
              this.setState({
                //options: options,
                address: {
                  value: res.data.principalSubdivision,
                  label: res.data.principalSubdivision,
                },
              });
              if (res.data.countryName !== "Australia") {
                options.push({
                  value: "Outside Australia",
                  label: "Outside Australia",
                });
                this.setState({
                  options: options,
                  address: {
                    value: "Outside Australia",
                    label: "Outside Australia",
                  },
                });
              }
            }
          })
          .catch((err) => console.log(err));
      });
    }

    // https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=37.42159&longitude=-122.0837&localityLanguage=en
  }

  locationCallback(data) {
    console.log(data);
  }

  async fetchAllCategories() {
    try {
      const { status, data } = await axiosInstance.get("/category");
      if (status === 200) {
        const clonedMenus = [...this.state.mainMenu];
        let menu = data.filter(
          (item) => item?.status !== CATEGORY_MENU_STATUS.DEACTIVE
        );
        menu = menu.map((item) => {
          return {
            ...item,
            title: item.name,
            link: getRoute(item.name, item.id, item.sportType),
            icon: "",
          };
        });

        clonedMenus.push(...menu);
        this.setState({ mainMenu: clonedMenus });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleNwsToggle = () => {
    this.setState((prevState) => {
      return { NwsMenu: !prevState.NwsMenu };
    });
  };

  toggleMenu() {
    this.setState((prevstate) => {
      return { showMenu: !prevstate.showMenu };
    });
  }
  closeSubMenu() {
    this.setState({ showSubMenu: false, subMenu: [], activeMenu: "" });
  }
  toggleSubMenu(e, menu) {
    if (menu.title === this.state.activeMenu) {
      this.setState((prevstate) => {
        return { showSubMenu: false, subMenu: [], activeMenu: "" };
      });
    } else {
      this.setState((prevstate) => {
        return {
          showSubMenu: true,
          subMenu: menu.submenu,
          activeMenu: menu.title,
        };
      });
    }
    e.preventDefault();
  }
  toggleMainMenu(e, toggleMainMenu) {
    // e.preventDefault();
    this.setState({ showMenu: false, showSubMenu: false });
  }
  render() {
    const { address } = this.state;
    return (
      <>
        <div className="sidebarMenu">
          <SwipeableDrawer
            anchor={"left"}
            open={this.state.showMenu}
            onClose={this.toggleMenu}
            onOpen={this.toggleMenu}
            swipeAreaWidth={0}
            className="MenuSidebarDrawer"
          >
            <div className="MenuSidebarDrawerClose">
              <CloseIcon onClick={this.toggleMenu} />
            </div>
            <List>
              {this.state.mainMenu.map((menuItem, i) => {
                if (menuItem.id === "divider") {
                  return (
                    <ListItem key={i}>
                      <Divider variant="middle" />
                    </ListItem>
                  );
                } else {
                  var itemprops = {};
                  var classNames = "";
                  if (
                    typeof menuItem.classNames !== "undefined" &&
                    menuItem.classNames !== ""
                  ) {
                    classNames += menuItem.classNames;
                  }
                  if (
                    typeof menuItem.submenu !== "undefined" &&
                    menuItem.submenu.length > 0
                  ) {
                    itemprops = {
                      onClick: (e) => this.toggleSubMenu(e, menuItem),
                    };
                    classNames += " hassub";
                  } else if (menuItem.link !== "") {
                    itemprops.to = menuItem.link;
                  }
                  if (menuItem.title === this.state.activeMenu) {
                    classNames += " active ";
                  }
                  return (
                    <ListItemLink
                      onClick={(e) => this.toggleMainMenu(e, menuItem)}
                      menuItem={menuItem}
                      to={menuItem.link}
                      primary={menuItem.title}
                      icon={menuItem.icon}
                      key={i}
                      {...itemprops}
                      className={classNames}
                    />
                  );
                }
              })}
              <ListItem>
                <Divider variant="middle" />
              </ListItem>
              <ListItem>
                <Select
                  menuPosition={"absolute"}
                  menuPlacement={"auto"}
                  defaultValue={address}
                  options={this.state.options}
                  styles={colourStyles}
                />
              </ListItem>
            </List>
            {this.state.showSubMenu === true ? (
              <div className={"SubMenu-wrap"}>
                <List>
                  <ListItem
                    button
                    className="close-submenu hide-desktop"
                    onClick={this.closeSubMenu}
                  >
                    <ListItemIcon>
                      <FaTimes />
                    </ListItemIcon>
                  </ListItem>
                  {this.state.subMenu.length > 0
                    ? this.state.subMenu.map((menuItem, i) => {
                        var itemprops = {};
                        var classNames = "";
                        if (menuItem.link !== "") {
                          itemprops.to = menuItem.link;
                        }
                        return (
                          <ListItemLink
                            onClick={(e) => this.toggleMainMenu(e, menuItem)}
                            menuItem={menuItem}
                            to={menuItem.link}
                            primary={menuItem.title}
                            icon={menuItem.icon}
                            key={i}
                            {...itemprops}
                            className={classNames}
                          />
                        );
                      })
                    : ""}
                </List>
              </div>
            ) : (
              ""
            )}
          </SwipeableDrawer>
          <Button className="MenuTopBar-button" onClick={this.toggleMenu}>
            <FaBars />
          </Button>
        </div>
      </>
    );
  }
}

export default SidebarMenu;
