import { storeConstants } from "../../../library/common/constants/StoreConstants";
import headerloginbarAds1 from "../../../images/advertise_banners/homepage/1/728x90_BM_generic.jpg";
import headerloginbarAds2 from "../../../images/advertise_banners/homepage/1/09-16-Neds-Level-banner-728x90.jpg";
import headerloginbarAds3 from "../../../images/advertise_banners/homepage/1/728x90_BS_generic.gif";
import headerloginbarAds4 from "../../../images/advertise_banners/homepage/1/0329_BRAND_LadbrokeIt_728x90.jpg";
import headerloginbarAds5 from "../../../images/advertise_banners/homepage/1/racing-728x90.gif";
import headerloginbarAds6 from "../../../images/advertise_banners/homepage/1/PalmerBet_Racing_728x90.gif";

import hometoprightAds1 from "../../../images/advertise_banners/homepage/2/300x600_BS_generic.gif";
import hometoprightAds2 from "../../../images/advertise_banners/homepage/2/09-16-Neds-Level-banner-300x600.jpg";
import hometoprightAds3 from "../../../images/advertise_banners/homepage/2/300x600_BM_generic.jpg";
import hometoprightAds4 from "../../../images/advertise_banners/homepage/2/racing_270x490.jpeg";
import hometoprightAds5 from "../../../images/advertise_banners/homepage/2/0329_BRAND_LadbrokeIt_160x600.jpg";
import hometoprightAds6 from "../../../images/advertise_banners/homepage/2/PalmerBet_Sports_300x600.gif";

import homebottomCenterAds1 from "../../../images/advertise_banners/homepage/3/728x90_BS_generic.gif";
import homebottomCenterAds2 from "../../../images/advertise_banners/homepage/3/09-16-Neds-Level-banner-728x90.jpg";
import homebottomCenterAds3 from "../../../images/advertise_banners/homepage/3/728x90_BM_generic.jpg";
import homebottomCenterAds4 from "../../../images/advertise_banners/homepage/3/racing-728x90.gif";
import homebottomCenterAds5 from "../../../images/advertise_banners/homepage/3/0329_BRAND_LadbrokeIt_728x90.jpg";
import homebottomCenterAds6 from "../../../images/advertise_banners/homepage/3/PalmerBet_Racing_728x90.gif";

import homebottomrightAds1 from "../../../images/advertise_banners/homepage/5/09-16-Neds-Level-banner-300x250.jpg";
import homebottomrightAds2 from "../../../images/advertise_banners/homepage/5/300x250_BM_generic.jpg";
import homebottomrightAds3 from "../../../images/advertise_banners/homepage/5/300x250_BS_generic.gif";
import homebottomrightAds4 from "../../../images/advertise_banners/homepage/5/0912_Brand_BY_racing_300x250.jpg";
import homebottomrightAds5 from "../../../images/advertise_banners/homepage/5/PalmerBet_Racing_300x250.gif";
import homebottomrightAds6 from "../../../images/advertise_banners/homepage/5/Screen Shot 2020-10-22 at 12.15.45 pm.png";
import homebottomrightAds7 from "../../../images/advertise_banners/homepage/5/sports-300x250.gif";

import LogedInTopAds1 from "../../../images/advertise_banners/LoggedIn/1/728x90_BS_generic.gif";
import LogedInTopAds2 from "../../../images/advertise_banners/LoggedIn/1/09-16-Neds-Level-banner-970x300.jpg";
import LogedInTopAds3 from "../../../images/advertise_banners/LoggedIn/1/728x90_BM_generic.jpg";
import LogedInTopAds4 from "../../../images/advertise_banners/LoggedIn/1/racing_1068x300.gif";
import LogedInTopAds5 from "../../../images/advertise_banners/LoggedIn/1/0329_BRAND_LadbrokeIt_728x90.jpg";
import LogedInTopAds6 from "../../../images/advertise_banners/LoggedIn/1/PalmerBet_Racing_970x250.gif";

import LogedInBottomAds1 from "../../../images/advertise_banners/LoggedIn/2/728x90_BS_generic.gif";
import LogedInBottomAds2 from "../../../images/advertise_banners/LoggedIn/2/09-16-Neds-Level-banner-970x300.jpg";
import LogedInBottomAds3 from "../../../images/advertise_banners/LoggedIn/2/728x90_BM_generic.jpg";
import LogedInBottomAds4 from "../../../images/advertise_banners/LoggedIn/2/racing_1068x300.gif";
import LogedInBottomAds5 from "../../../images/advertise_banners/LoggedIn/2/0329_BRAND_LadbrokeIt_970x300.jpg";
import LogedInBottomAds6 from "../../../images/advertise_banners/LoggedIn/1/PalmerBet_Racing_970x250.gif";

const Urls = {
  payups:
    "https://record.affiliates.playup.com/_Y_Ng50hdX2Zhg6WO2I1rgWNd7ZgqdRLk/1/",
  Neds:
    "https://record.nedsaffiliates.com.au/_dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk/1/",
  Ladbrokes:
    "https://record.wageringaffiliates.com.au/_rKStVKr_NJ-itX36G815rmNd7ZgqdRLk/1/",
  Bookmaker:
    "https://record.wageringaffiliates.com.au/_aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk/1/",
  BetStar:
    "https://record.wageringaffiliates.com.au/_vB8fbcQfs2keR0indqtVW2Nd7ZgqdRLk/1/",
  Bet365: "https://www.bet365.com/olp/open-account?affiliate=365_00967140",
  PalmerBet:
    "https://record.palmerbet.com/_aK97AHmof7-WLGrCaiaBQGNd7ZgqdRLk/1/",
};
const initialState = {
  allAds: [],
  headerloginbarAds: [
    //Home 1
    {
      id: 1,
      url: Urls.Bookmaker,
      image: headerloginbarAds1,
    },
    {
      id: 2,
      url: Urls.Neds,
      image: headerloginbarAds2,
    },
    {
      id: 3,
      url: Urls.BetStar,
      image: headerloginbarAds3,
    },
    {
      id: 4,
      url: Urls.Ladbrokes,
      image: headerloginbarAds4,
    },
    {
      id: 5,
      url: Urls.payups,
      image: headerloginbarAds5,
    },
    {
      id: 6,
      url: Urls.PalmerBet,
      image: headerloginbarAds6,
    },
  ],

  hometoprightAds: [
    //Home 2
    {
      id: 1,
      url: Urls.BetStar,
      image: hometoprightAds1,
    },
    {
      id: 2,
      url: Urls.Neds,
      image: hometoprightAds2,
    },
    {
      id: 3,
      url: Urls.Bookmaker,
      image: hometoprightAds3,
    },
    {
      id: 4,
      url: Urls.payups,
      image: hometoprightAds4,
    },
    {
      id: 5,
      url: Urls.Ladbrokes,
      image: hometoprightAds5,
    },
    {
      id: 6,
      url: Urls.PalmerBet,
      image: hometoprightAds6,
    },
  ],

  homebottomCenterAds: [
    // Home 3
    {
      id: 1,
      url: Urls.BetStar,
      image: homebottomCenterAds1,
    },
    {
      id: 2,
      url: Urls.Neds,
      image: homebottomCenterAds2,
    },
    {
      id: 3,
      url: Urls.Bookmaker,
      image: homebottomCenterAds3,
    },
    {
      id: 4,
      url: Urls.payups,
      image: homebottomCenterAds4,
    },
    {
      id: 5,
      url: Urls.Ladbrokes,
      image: homebottomCenterAds5,
    },
    {
      id: 6,
      url: Urls.PalmerBet,
      image: homebottomCenterAds6,
    },
  ],

  homebottomrightAds: [
    {
      // Home 4
      id: 1,
      url: Urls.Neds,
      image: homebottomrightAds1,
    },
    {
      id: 2,
      url: Urls.Bookmaker,
      image: homebottomrightAds2,
    },
    {
      id: 3,
      url: Urls.BetStar,
      image: homebottomrightAds3,
    },
    {
      id: 4,
      url: Urls.Ladbrokes,
      image: homebottomrightAds4,
    },
    {
      id: 5,
      url: Urls.PalmerBet,
      image: homebottomrightAds5,
    },
    {
      id: 6,
      url: Urls.Bet365,
      image: homebottomrightAds6,
    },
    {
      id: 7,
      url: Urls.payups,
      image: homebottomrightAds7,
    },
  ],

  LogedInTopAds: [
    // Login 1
    {
      id: 1,
      url: Urls.BetStar,
      image: LogedInTopAds1,
    },
    {
      id: 2,
      url: Urls.Neds,
      image: LogedInTopAds2,
    },
    {
      id: 3,
      url: Urls.Bookmaker,
      image: LogedInTopAds3,
    },
    {
      id: 4,
      url: Urls.payups,
      image: LogedInTopAds4,
    },
    {
      id: 5,
      url: Urls.Ladbrokes,
      image: LogedInTopAds5,
    },
    {
      id: 6,
      url: Urls.PalmerBet,
      image: LogedInTopAds6,
    },
  ],

  LogedInBottomAds: [
    // Login 2
    {
      id: 1,
      url: Urls.BetStar,
      image: LogedInBottomAds1,
    },
    {
      id: 2,
      url: Urls.Neds,
      image: LogedInBottomAds2,
    },
    {
      id: 3,
      url: Urls.Bookmaker,
      image: LogedInBottomAds3,
    },
    {
      id: 4,
      url: Urls.payups,
      image: LogedInBottomAds4,
    },
    {
      id: 5,
      url: Urls.Ladbrokes,
      image: LogedInBottomAds5,
    },
    {
      id: 6,
      url: Urls.PalmerBet,
      image: LogedInBottomAds6,
    },
  ],
};

const AdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.FETCH_ADS:
      return {
        ...state,
        allAds: action.payload,
      };
    case "SETACTIVEADID":
      return {
        ...state,
        [action.payload?.type]: action.payload?.id,
      };
    default:
      return state;
  }
};

export default AdsReducer;
