import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import BetContent from "./BetContent";
import BetContentCricket from './BetContentCricket';
import { EventEmitter } from "../../services/event";
import "./styles.scss";

class RugbySidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bets: [],
      stakes: null,
      
      
    };
    this.updateList = this.updateList.bind(this);
    EventEmitter.subscribe("updatebetsList", this.updateList);
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.type !== this.props.type){
      this.clearodds();
    }
  }

  updateList(item) {
    // const clonedBets = cloneDeep(this.state.bets);
    // const index = clonedBets.findIndex((bet) => bet.id === item.id);

    // if (index === -1) {
    // clonedBets.push(item);
    // }

    this.setState({ bets: [item] });
  }

  handleInputChange = (id) => (event) => {
    const { value } = event.target;
    this.setState({
      stakes: {
        ...this.state.stakes,
        [id]: value,
      },
    });
  };

  handleDelete = (index) => () => {
    const clonedBets = [...this.state.bets];
    clonedBets.splice(index, 1);
    this.setState({ bets: clonedBets });
  };

  handleAllBetChange = (event) => {
    const { value } = event.target;
    const stakes = {};

    this.state.bets.forEach((item) => {
      stakes[item.id] = value;
    });

    this.setState({ stakes });
  };

  stakeChange(e) {
    this.setState({ stake: e.target.value });
  }
  clearodds = () => {
    this.setState({ stake: "", bets: [] });
  };

  render() {
    const { bets } = this.state;

    return (
      <>
        <Card className="sidebar-card" variant="outlined">
          <CardHeader title={`Odds - ${this.props.type}`} />
          <CardContent className="rm-padding-i">
            {bets.length > 0 ? (
              bets.map((bet, index) =>
              bet?.categoriesName === 'Cricket' ?
              <BetContentCricket
                    handleDelete={this.handleDelete}
                    index={index}
                    bet={bet}
                    ShowWinsOnly={bet?.ShowWinsOnly}
                  />
              :
                  <BetContent
                    handleDelete={this.handleDelete}
                    index={index}
                    bet={bet}
                    ShowWinsOnly={bet?.ShowWinsOnly}
                  />
              )
            ) : (
              <Typography
                className="color-white bg-alert no-bets-msg"
                variant="body2">
                Click compare to see the best odds.
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant={bets.length > 0 ? "contained" : "outlined"}
              className="btn-link btn-block btn-clear-odds"
              onClick={this.clearodds}>
              Clear Odds
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}

export default RugbySidebar;
