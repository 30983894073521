import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonComponent from "../../library/common/components/Button";

class Addresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addresses: [], isModalOpen: false };
  }

  componentDidMount() {
    this.setState({ addresses: this.AddressesList });
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  createNew = () => {
    this.props.history.push("/admin/add-addresses");
  };

  navigateToEdit = (id) => () => {
    this.props.history.push(`/admin/edit-addresses/${id}`);
  };

  AddressesList = [
    {
      id: 1,
      addressLine1: "L.H Road",
      addressLine2: "Varachha",
      state: "Gujrat",
      country: "India",
      postCode: 395010,
      hash: "hash",
    },
    {
      id: 2,
      addressLine1: "Megneto",
      addressLine2: "Range park",
      state: "London",
      country: "England",
      postCode: 459728,
      hash: "hash",
    },
    {
      id: 3,
      addressLine1: "36 Woerdens Road",
      addressLine2: "BRANXTON",
      state: "New South Wales",
      country: "Australia",
      postCode: 2335,
      hash: "hash",
    },
    {
      id: 4,
      addressLine1: "947 County Line Road",
      addressLine2: "ATLAS",
      state: "Michigan",
      country: "Newzealand",
      postCode: 48411,
      hash: "hash",
    },
    {
      id: 5,
      addressLine1: "Honey Pot Lane",
      addressLine2: "Lincolnshire",
      state: "North Witham",
      country: "United Kingdom",
      postCode: 856010,
      hash: "hash",
    },
    {
      id: 6,
      addressLine1: "South O'Neil Avenue",
      addressLine2: "Nevada, Eureka County",
      state: "Eureka",
      country: "United States of America",
      postCode: 798125,
      hash: "hash",
    },
    {
      id: 7,
      addressLine1: "Rodrick Plains",
      addressLine2: "Norve street",
      state: "Abu Dhabi Emirate",
      country: "United Arab Emirates",
      postCode: 453176,
      hash: "hash",
    },
  ];

  render() {
    var { addresses, isModalOpen } = this.state;
    return (
      <>
        <Grid container className="page-content adminLogin">
          <Grid item xs={12}>
            <Paper className="pageWrapper">
              <Grid container direction="row" alignItems="space-around">
                <Grid item xs={10}>
                  <h3 className="text-left">Addresses</h3>
                </Grid>
                <Grid item xs={2}>
                  <ButtonComponent
                    className="addButton admin-btn-green"
                    onClick={this.createNew}
                    color="primary"
                    value="Add New"
                  />
                </Grid>
              </Grid>
              {addresses.length === 0 && <p>No Data Available</p>}
              {addresses.length > 0 && (
                <TableContainer component={Paper}>
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell>Address line 1</TableCell>
                        <TableCell>Address line 2</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Post Code</TableCell>
                        <TableCell>hash</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addresses?.map((add, i) => (
                        <TableRow key={i}>
                          <TableCell>{add.id}</TableCell>
                          <TableCell>{add.addressLine1}</TableCell>
                          <TableCell>{add.addressLine2}</TableCell>
                          <TableCell>{add.state}</TableCell>
                          <TableCell>{add.country}</TableCell>
                          <TableCell>{add.postCode}</TableCell>
                          <TableCell>{add.hash}</TableCell>
                          <TableCell>
                            <EditIcon
                              onClick={this.navigateToEdit(add.id)}
                              color="primary"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                            <DeleteOutlineIcon
                              onClick={this.toggleModal}
                              color="secondary"
                              className="cursor iconBtn admin-btn-orange"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>

            <Modal
              className="modal"
              open={isModalOpen}
              onClose={this.toggleModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={"paper"}>
                Are you sure you want to delete?
                <br /> <br />
                <Button color="primary">Yes</Button>
                <Button color="secondary" onClick={this.toggleModal}>
                  Cancel
                </Button>
              </div>
            </Modal>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Addresses;
