import { Validators } from "../../../library/utilities/Validators";
import { PARTICIPANT_VALUE_TYPES } from "../../../helpers/constants";

export const participantsValueFormModel = [
  {
    label: "Event*",
    value: "",
    type: "dropdown",
    placeholder: "Event",
    field: "eventFeatureId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [],
  },
  {
    label: "Key*",
    value: "",
    type: "text",
    placeholder: "Key",
    field: "key",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Value*",
    value: "",
    type: "text",
    placeholder: "Value",
    field: "value",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Value Type*",
    value: "",
    type: "dropdown",
    placeholder: "Value Type",
    field: "valueType",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    options: [
      ...PARTICIPANT_VALUE_TYPES?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
    required: true,
    className: "12",
  },
];
