import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { bannersFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import FileUploader from "../../../library/common/components/FileUploader";
import { config } from "../../../helpers/config";
import { setValidation } from "../../../helpers/common";

let bannersFormModelArray = bannersFormModel;

class CreateBanners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        pageId: "",
        url: "",
      },
      logo: [],
      bannersForm: [],
      isEditMode: false,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      //this.fetchCurrentBanner();
    }
    const { allCmsPage } = this.props;
    this.setState((prevState) => {
      return {
        values: {
          ...prevState.values,
          pageId: allCmsPage?.length > 0 ? allCmsPage[0].id : "",
        },
      };
    });
    bannersFormModelArray = bannersFormModelArray?.map((fieldItem) => {
      if (fieldItem?.field === "pageId") {
        return {
          ...fieldItem,
          type: "dropdown",
          options: [
            ...allCmsPage?.map((tablecol, i) => {
              return {
                id: i,
                value: tablecol?.id,
                label: tablecol?.pageName,
              };
            }),
          ],
        };
      }
      return fieldItem;
    });
    this.setState({ bannersForm: bannersFormModelArray });
  }

  componentWillUnmount() {
    bannersFormModelArray = bannersFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  // fetchCurrentBanner = async (id) => {
  //   const { status, data } = await axiosInstance.get(
  //     URLS.banners + `/${id}`
  //   );
  //   if (status === 200) {
  //     this.setState({
  //       values: data.result[0],
  //       apiProviderId: data.result[0].apiProviderId,
  //     });
  //     let variationArray = JSON.parse("[" + data.result[0].variation + "]");
  //     if (variationArray.length > 0) {
  //       this.setState(() => {
  //         return {
  //           values: {
  //             ...this.state.values,
  //             ["variation"]: variationArray[0],
  //           },
  //         };
  //       });
  //     }
  //   }
  // };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { pageId, url } = this.state.values;
    let flag = true;

    if (pageId === "" || url === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      this.setActionMessage(false);
    }

    return flag;
  };

  setMedia = async (files) => {
    const formData = new FormData();
    formData.append("image", files ? files : null);
    if (files !== undefined) {
      const { status, data } = await axiosInstance.post(URLS.media, formData, {
        header: { "Content-Type": "multipart/form-data" },
      });
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    }
    return false;
  };

  // handleSave = async () => {
  //   const { logo, values } = this.state;
  //   const { isEditMode } = this.props;
  //   this.setState({ isLoading: true });

  //   try {
  //     const { current } = this.formRef;
  //     const form = current.getFormData();

  //     const method = isEditMode ? "put" : "post";
  //     const url = isEditMode
  //       ? `${URLS.banners}/${this.props.id}`
  //       : URLS.banners;

  //     const values = removeErrorFieldsFromValues(form.formData);
  // bannersFormModelArray = bannersFormModelArray?.map((fieldItem) => {
  //   return setValidation(fieldItem, values);
  // });
  //     if (this.validate()) {
  //       if (logo.length > 0) {
  //         let fileData = await this.setMedia(logo[0]);
  //         if (fileData) {
  //           values["image"] = fileData?.image?.filePath;
  //         }
  //       }

  //       const { status } = await axiosInstance[method](url, values);
  //       if (status === 200) {
  //         this.setState({ isLoading: false });
  //         this.props.inputModal();
  //         //this.props.fetchAllBookkeeper();
  //         this.setActionMessage(
  //           true,
  //           "Success",
  //           `Banners ${isEditMode ? "Edited" : "Created"} Successfully`
  //         );
  //       }
  //     }
  //   } catch (err) {
  //     this.setState({ isLoading: false });
  //     this.setActionMessage(
  //       true,
  //       "Error",
  //       `An error occured while ${isEditMode ? "editing" : "creating"} Banners`
  //     );
  //   }
  // };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    bannersFormModelArray = bannersFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleFileUpload = (name, files) => {
    this.setState({ [name]: files });
  };

  render() {
    const { values, messageBox, isLoading, logo, bannersForm } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={bannersFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div className="blog-file-upload">
                <h6>File Name</h6>
                <FileUploader
                  onDrop={(logo) => this.handleFileUpload("logo", logo)}
                />
                <div className="logocontainer">
                  {logo?.length > 0
                    ? logo.map((file, index) => (
                        <img
                          className="auto-width"
                          key={index}
                          src={file.preview}
                          alt="file"
                        />
                      ))
                    : values?.logo &&
                      values?.logo !== "" && (
                        <img
                          className="auto-width"
                          src={config.baseUrl + values?.logo}
                          alt="file"
                        />
                      )}
                </div>
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateBanners;
