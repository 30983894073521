import React from "react";
import PropTypes from "prop-types";

import Homepage from "./Homepage";
import About from "./About";
import Membership from "./Membership";
import "./templatesStyles.scss";

const Templates = ({
  template,
  data,
  pageId,
  onChange,
  onSuccess,
  onError,
}) => {
  if (template === "home") {
    return (
      <Homepage
        onChange={onChange}
        data={data}
        pageId={pageId}
        onSuccess={onSuccess}
        onError={onError}
      />
    );
  }

  if (template === "about") {
    return (
      <About
        onChange={onChange}
        data={data}
        pageId={pageId}
        onSuccess={onSuccess}
        onError={onError}
      />
    );
  }

  if (template === "membership") {
    return (
      <Membership
        onChange={onChange}
        data={data}
        pageId={pageId}
        onSuccess={onSuccess}
        onError={onError}
      />
    );
  }

  return null;
};

Templates.propTypes = {
  template: PropTypes.string,
  onChange: PropTypes.func,
};

Templates.defaultProps = {
  template: null,
  onChange: () => {},
};

export default Templates;
