import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axiosInstance from "../../helpers/Axios";
import RacingBetsSidebar from "./Racing/RacingBetsSidebar";
import CombatSportsSidebar from "./Combat Sports/combatsportsidebar";
import TeamSportHeadtoHeadSidebar from "./Team Sports/head to head/TeamSportHeadtoHeadSidebar";
import TeamSportOtherMarketSidebar from "./Team Sports/OtherMarket/TeamSportsOtherMarketSidebar";
import MotorRacingSidebar from "./Motor Racing/motorracingsidebar";
import { EventEmitter } from "../../services/event";
import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";

import "./betssidebar.scss";

class BetsSidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sportName: "",
      bets: [],
      sportsData: {},
      // {id:1,sportTitle: 'Healsville',race: 'Race 6',raceType:'Win/Place',image:hourse_img_small,horsename:'Horse Name'},
    };
    this.raceBetBlock = React.createRef();
    EventEmitter.subscribe("updatebetsList", this.updateList);
    EventEmitter.subscribe("resetbetsList", this.resetList);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match?.params?.sportName !== "") {
      this.setState({ sportName: match?.params?.sportName });
      //this.setCategoryData(match?.params?.sportName);
    }

    this.clearodds();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match?.params?.sportName !== match?.params?.sportName) {
      //this.setCategoryData(match?.params?.sportName);
      this.setState({ sportName: match?.params?.sportName });
    }
  }

  setCategoryData = async (sportName) => {
    const { status, data } = await axiosInstance.get("/category");
    if (status === 200) {
      let singleItem = data.filter((dataitem) =>
        dataitem.name.includes(sportName)
      );
      this.setState({ sportsData: singleItem[0] });
    }
  };

  resetList = () => {
    this.setState({
      bets: [],
    });
  };

  updateList = (item) => {
    if (this.raceBetBlock && this.raceBetBlock?.current)
      this.raceBetBlock.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        // inline: "start",
      });
    this.setState({
      bets: [item],
      // sportsData: item?.sportsData,
    });
  };

  handleDelete = (index) => () => {
    const clonedBets = [...this.state.bets];
    clonedBets.splice(index, 1);
    this.setState({ bets: clonedBets });
  };

  clearodds = () => {
    this.setState({ bets: [] });
  };

  handleSidebars = () => {
    const { bets, sportsData } = this.state;
    let { bookmarks } = this.props;
    let sportType = sportsData?.sportType;

    if (sportType === "combat") {
      if (bets[0].ShowOtherSportMarket === true) {
        return (
          <>
            <TeamSportOtherMarketSidebar bets={bets} bookmarks={bookmarks} />
          </>
        );
      }
      return <CombatSportsSidebar bets={bets} bookmarks={bookmarks} />;
    } else if (sportType === "teamsport") {
      if (bets[0].ShowOtherSportMarket === true) {
        return (
          <TeamSportOtherMarketSidebar bets={bets} bookmarks={bookmarks} />
        );
      }
      return <TeamSportHeadtoHeadSidebar bets={bets} bookmarks={bookmarks} />;
    } else if (sportType === "otherracing") {
      return <MotorRacingSidebar bets={bets} bookmarks={bookmarks} />;
    } else {
      return <RacingBetsSidebar bets={bets} bookmarks={bookmarks} />;
    }
  };

  render() {
    const { bets, sportName, sportsData } = this.state;
    let SportName = sportName
      ? sportName
      : bets?.SportName && bets?.SportName !== ""
      ? bets.SportName
      : sportsData?.name ?? "Horse Racing";
    return (
      <>
        <div ref={this.raceBetBlock}></div>
        <Card className="sidebar-card" variant="outlined">
          <CardHeader title={`Odds - ${SportName}`} />
          <CardContent className="rm-padding-i">
            {bets.length > 0 ? (
              this.handleSidebars()
            ) : (
              <Typography
                className="color-white bg-alert no-bets-msg"
                variant="body2"
              >
                You have not added any BETS. Select a BET to add!
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant={bets.length > 0 ? "contained" : "outlined"}
              className="btn-link btn-block btn-clear-odds"
              onClick={this.clearodds}
            >
              Clear Odds Slip
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}
const mapStateToProps = ({ common, bets }) => {
  return {
    bookmarks: bets.bookmarks,
  };
};
export default withRouter(connect(mapStateToProps, {})(BetsSidebar));
