import React from "react";
import "./allSports.scss";
import {
  Container,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Divider,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ReactSVG } from "react-svg";

import d_americal_footboll from "../../images/all_sports_dropdown/american_football.svg";
import d_australian_rules from "../../images/all_sports_dropdown/australian_rules.svg";
import d_basketball from "../../images/all_sports_dropdown/basketball.svg";
import d_boxing from "../../images/all_sports_dropdown/boxing.svg";
import d_cricket from "../../images/all_sports_dropdown/cricket.svg";
import d_motor_racing from "../../images/all_sports_dropdown/motor_racing.svg";
import d_mma from "../../images/all_sports_dropdown/mma.svg";
import d_racing from "../../images/all_sports_dropdown/racing.svg";
import d_rugbyleague from "../../images/all_sports_dropdown/rugby_league.svg";
import d_soccer from "../../images/all_sports_dropdown/soccor.svg";
import d_tennis from "../../images/all_sports_dropdown/tennis.svg";

import { EventEmitter } from "../../services/event";
import AllSport from "./AllSport";
import AmericalFootBall from "./AmericanFootball";
import MMA from "./MMA";
import Racing from "./Racing";
import axiosInstance from "../../helpers/Axios";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const styles = (theme) => ({
  dropdownStyle: {
    borderRadius: "5px",
    width: 251,
    height: 430,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderRadius: "10px 10px 0px 0px",
    },
  },
});

const sportsList = [
  { name: "All Sports" },
  { name: "American Football" },
  { name: "Australian rules" },
  { name: "Basketball" },
  { name: "Boxing" },
  { name: "Cricket" },
  { name: "Motor Racing" },
  { name: "Mixed Martial Arts" },
  { name: "Racing" },
  { name: "Rugby League" },
  { name: "Rugby Union" },
  { name: "Soccer" },
  { name: "Tennis" },
];

class AllSports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      selectedDate: "",
      allRacingData: [],
      allThreeRacingData: [],
      horseRaceData: [],
      harnessRaceData: [],
      greyhoundRaceData: [],
      isRaceLoading: false,
      isThreeRaceLoading: false,
    };
    EventEmitter.subscribe("show_all_races", this.ShowAllRace);
    EventEmitter.subscribe("clear_date_filter", this.clearDateFilter);
  }

  componentDidMount = () => {
    this.setState({ selectedOption: sportsList[0].name });
    this.fetchRace("-1", true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  ShowAllRace = (race) => {
    this.setState({ selectedOption: race });
  };

  handleChange = (e) => {
    this.setState({ selectedOption: e.target.value }, () => {
      this.setState({ selectedDate: "" });
      if (this.state.selectedOption === "Racing") {
        this.fetchThreeRacing("1,2,3", "", "", true);
      } else if (this.state.selectedOption === "All Sports") {
        this.fetchRace("-1", true);
      }
    });
  };

  handleDateChange = (date) => {
    let currentDate = moment().format("YYYY-MM-DD");
    let dateFromParams = moment(date).format("YYYY-MM-DD");
    let selectedDate = currentDate == dateFromParams ? "" : date;

    this.setState({ selectedDate: selectedDate }, () =>
      this.fetchThreeRacing("1,2,3", "", "", true)
    );
  };

  clearDateFilter = () => {
    this.setState({ selectedDate: "" });
  };

  renderSwitchIcon = (sports) => {
    switch (sports) {
      case "All Sports":
        return;
      case "American Football":
        return d_americal_footboll;
      case "Australian rules":
        return d_australian_rules;
      case "Basketball":
        return d_basketball;
      case "Boxing":
        return d_boxing;
      case "Cricket":
        return d_cricket;
      case "Motor Racing":
        return d_motor_racing;
      case "Mixed Martial Arts":
        return d_mma;
      case "Racing":
        return d_racing;
      case "Rugby League":
        return d_rugbyleague;
      case "Rugby Union":
        return d_rugbyleague;
      case "Soccer":
        return d_soccer;
      case "Tennis":
        return d_tennis;
      default:
        return;
    }
  };

  fetchRace = async (id, isRaceLoading) => {
    // let { selectedDate } = this.state;
    // let date_to_pass = moment(selectedDate).format("YYYY-MM-DD");
    this.setState({ isRaceLoading: isRaceLoading /* allRacingData: []*/ });
    let API_url = `/events/sportData?limit=4&isRaceSeeAll=${0}&sportId=${id}`;
    // selectedDate === ""
    //   ? `/events/sportData?limit=4&isRaceSeeAll=${0}&sportId=${id}`
    //   : `/events/sportData?startDate=${date_to_pass}&limit=4&isRaceSeeAll=${0}&sportId=${id}`;
    try {
      const { status, data } = await axiosInstance.get(API_url);
      if (status === 200) {
        this.setState({ isRaceLoading: false }, () => {
          this.setState({ allRacingData: data.data.races });
        });
        EventEmitter.dispatch("All_race_data_get");
      }
    } catch (err) {
      console.log(err);
      this.setState({ isRaceLoading: false });
    }
  };

  fetchThreeRacing = async (id, country, state, isRaceLoading) => {
    let { selectedDate } = this.state;
    let date_to_pass = moment(selectedDate).format("YYYY-MM-DD");
    let country_pass = country !== "none" ? country : "";
    let state_pass = state !== "none" ? state : "";
    let currentDate = moment().format("YYYY-MM-DD");
    this.setState({ isThreeRaceLoading: isRaceLoading });
    let API_url =
      selectedDate === ""
        ? `/events/sportData?limit=4&isRaceSeeAll=${1}&sportId=${id}&countryId=${country_pass}&stateId=${state_pass}`
        : `/events/sportData?startDate=${date_to_pass}&limit=4&isRaceSeeAll=${1}&sportId=${id}&countryId=${country_pass}&stateId=${state_pass}`;

    try {
      const { status, data } = await axiosInstance.get(API_url);
      if (status === 200) {
        this.setState({ isThreeRaceLoading: false }, () => {
          this.setState({
            horseRaceData: data.data.races[1],
            harnessRaceData: data.data.races[2],
            greyhoundRaceData: data.data.races[3],
          });
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ isThreeRaceLoading: false });
    }
  };

  renderSwitchComponent = (option) => {
    let {
      allRacingData,
      horseRaceData,
      harnessRaceData,
      greyhoundRaceData,
      selectedDate,
      isRaceLoading,
      isThreeRaceLoading,
    } = this.state;
    switch (option) {
      case "All Sports":
        return (
          <AllSport
            combineRaceData={allRacingData}
            fetchRace={this.fetchRace}
            fetchThreeRacing={this.fetchThreeRacing}
            selectedDate={selectedDate}
            isRaceLoading={isRaceLoading}
            isThreeRaceLoading={isThreeRaceLoading}
            horseRaceData={horseRaceData}
            harnessRaceData={harnessRaceData}
            greyhoundRaceData={greyhoundRaceData}
          />
        );
      case "American Football":
        return <AmericalFootBall />;
      case "Australian rules":
        return "";
      case "Basketball":
        return "";
      case "Boxing":
        return "";
      case "Cricket":
        return "";
      case "Motor Racing":
        return "";
      case "Mixed Martial Arts":
        return <MMA />;
      case "Racing":
        return (
          <Racing
            horseRaceData={horseRaceData}
            harnessRaceData={harnessRaceData}
            greyhoundRaceData={greyhoundRaceData}
            selectedDate={selectedDate}
            isRaceLoading={isRaceLoading}
            fetchRace={this.fetchRace}
            fetchThreeRacing={this.fetchThreeRacing}
            isThreeRaceLoading={isThreeRaceLoading}
          />
        );
      case "Rugby League":
        return "";
      case "Rugby Union":
        return "";
      case "Soccer":
        return "";
      case "Tennis":
        return "";
      default:
        return "foo";
    }
  };

  render() {
    var { selectedOption, selectedDate } = this.state;
    let { classes } = this.props;
    return (
      <>
        <Container className="allsports_container">
          <Grid container className="allsports_head d-flex-center">
            <Grid
              item
              sm={6}
              xs={12}
              className="allsports_head_left allsports_head_left_margin"
            >
              <div>
                <FormControl>
                  <Select
                    labelId="allsports-select-label"
                    id="allsports-select"
                    className="sports_select"
                    style={{
                      marginBottom: 0,
                      fontWeight: 600,
                      fontFamily: "Open Sans",
                      padding: "10px 25.53px",
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedOption}
                    onChange={this.handleChange}
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {sportsList.map((obj, i) => (
                      <MenuItem
                        key={i}
                        value={obj.name}
                        style={{
                          color: "#707070",
                          fontSize: 15,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                        className={
                          selectedOption === obj.name
                            ? "selected_allsportsoption ssms_res"
                            : "normal_allsportsoption ssms_res"
                        }
                      >
                        {obj.name !== "All Sports" && (
                          <ReactSVG
                            className="selected_svg"
                            style={{ width: 30 }}
                            src={this.renderSwitchIcon(obj.name)}
                          />
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} className="hide-sm hide-md hide-lg hide-xl">
              <Divider className="allsports_hr" style={{ width: "100%" }} />
            </Grid>
            <Grid item sm={6} xs={12} className="allsports_head_right">
              {selectedOption !== "All Sports" && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk={true}
                    variant="inline"
                    format="yyyy/MM/dd"
                    id="date-picker-inline"
                    allowKeyboardControl={false}
                    className="allsports_head_right_date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={selectedDate !== "" ? selectedDate : new Date()}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Grid>
          </Grid>
          <Divider
            className={
              selectedOption !== "All Sports"
                ? "allsports_hr"
                : "allsports_hr hide-xs"
            }
          />

          {this.renderSwitchComponent(selectedOption)}
        </Container>
      </>
    );
  }
}

AllSports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AllSports);
