import React from "react";
import { Chip } from "@material-ui/core";
import { useTimer } from "react-timer-hook";
import { EventEmitter } from "../../../../services/event";

const RaceCountdown = ({ expiryTimestamp, onComplete }) => {
  EventEmitter.subscribe("Race_time_restart", (time) => {
    RefreshTime(time);
  });
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => onComplete,
  });
  const RefreshTime = (time) => {
    restart(time);
  };
  return (
    <>
      <Chip
        className={"singlerace-count-chip"}
        style={{
          backgroundColor: "#fff",
          fontSize: 12,
          fontFamily: "Open Sans",
          color: "#000",
          fontWeight: 600,
          cursor: "pointer",
        }}
        size="small"
        label={
          <span>
            {hours > 0 ? hours + "h" : "0h"}{" "}
            {minutes > 0 ? minutes + "m" : "0m"}{" "}
            {seconds > 0 ? seconds + "s" : "0s"}
          </span>
        }
      />
    </>
  );
};
export default RaceCountdown;
