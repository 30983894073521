import { Validators } from "../../../library/utilities/Validators";

export const raceTableFormModel = [
  {
    label: "Event*",
    value: "",
    type: "dropdown",
    placeholder: "Event",
    field: "eventId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  // {
  //   label: "Race Id*",
  //   value: "",
  //   type: "text",
  //   placeholder: "Race Id",
  //   field: "raceId",
  //   validators: [
  //     { check: Validators.required },
  //     { check: Validators.number, message: "Enter Number Only" },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  {
    label: "Race Name*",
    value: "",
    type: "text",
    placeholder: "Race Name",
    field: "raceName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Description*",
    value: "",
    type: "text",
    placeholder: "Description",
    field: "description",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Distance*",
    value: "",
    type: "dropdown",
    placeholder: "Distance",
    field: "distance",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Track*",
    value: "",
    type: "dropdown",
    placeholder: "Track",
    field: "trackId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
    ],
  },
  {
    label: "Sport*",
    value: "",
    type: "dropdown",
    placeholder: "Sport",
    field: "sportId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Comment*",
    value: "",
    type: "text",
    placeholder: "Comment",
    field: "comment",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Weather*",
    value: "",
    type: "dropdown",
    placeholder: "Weather",
    field: "weather",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
];
