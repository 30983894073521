import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import PropTypes from "prop-types";

const CategoryDropdown = ({ data, title, value, field, onChange }) => {
  return (
    <FormControl className="dropdown-items">
      <InputLabel>{title}</InputLabel>
      <Select
        onChange={(event) => onChange(event.target.value, field)}
        value={value}
      >
        <MenuItem>None</MenuItem>
        {data.map((item, index) => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CategoryDropdown.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.string,
  onChange: PropTypes.func,
};

CategoryDropdown.defaultProps = {
  data: [],
  title: "",
  value: "",
  field: "",
  onChange: () => {},
};

export default CategoryDropdown;
