import React from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import "./membership.scss";

import afiliateLeftScratch from "../../images/afiliat-left-scratch.svg";
import afiliateRightScratch from "../../images/afiliat-right-scratch.svg";
import user_image_1 from "../../images/poker.png";

class MembershipPoker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="top-div"></div>
        <Container>
          <Grid
            container
            justify="center"
            className="contact-banner afiliate-banner"
            alignItems="flex-start"
          >
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateLeftScratch}
                alt=""
                className="afiliate-left-image"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6} className="hide-xs">
              <img
                src={afiliateRightScratch}
                alt=""
                className="afiliate-right-image"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="">
              <div className="afiliate-img-wrapper">
                <img src={user_image_1} alt="afiliate1" />
              </div>
              <Typography
                variant="h5"
                className="title afiliate-title text-center text-upper"
              >
                Poker
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="afiliate-title-content">
                We give you the opportunity to find and compare a variety of
                poker services that are available online. As a motzza member,
                you can access information that can help you further your
                understanding of the details and conditions of these poker
                services and their offerings.
              </Typography>
              <Typography variant="body1" className="afiliate-middle-content">
                We partner with and compare some of the top Australian poker
                rooms available online. Get all the information you need before
                you make a decision, such as trustworthiness, reliability,
                ease-of-use, customer support, security and payout speed.
              </Typography>
              <Typography variant="body1" className="afiliate-title-content">
                Trawling through and manually comparing poker sites can take a
                long time. With motzza, we help you to find the services you
                need quickly and easily. Every online poker site listed on
                motzza has been reviewed by our expert team and accredited by
                third parties to ensure that they offer you:
              </Typography>
              <ul className="affiliate-ul">
                <li>
                  Security systems that match those used by top online banks
                </li>
                <li>
                  Fair dealing utilising industry-standard random number
                  generators
                </li>
                <li>
                  Fast and easy deposits and withdrawals in your preferred
                  currency
                </li>
              </ul>
              <Typography variant="body1" className="afiliate-title-content">
                We go the extra mile to make sure every poker affiliate is of
                the highest quality. Now you can make safe and informed gaming
                decisions that are fair, secure and most of all, fun.
              </Typography>
              <Typography variant="body1" className="afiliate-signup-content">
                Start taking advantage of special poker offers, bonuses and
                rewards with motzza.{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  Sign up
                </span>{" "}
                to{" "}
                <span style={{ color: "#1D9256", fontWeight: "600" }}>
                  become a motzza member
                </span>{" "}
                today.
              </Typography>
              <Button
                className="affiliate-signup-button"
                onClick={() => (window.location.href = "/register")}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}
export default MembershipPoker;
