import Dashboard from "../../dashBoard/Dashboard";
import AboutUs from "../../aboutUs/AboutUs";
import AddNew from "../../dashBoard/AddNew";
import Blog from "../../Blog";
import CreateBlog from "../../Blog/CreateBlog";
import Ads from "../../Ads";
import CreateAd from "../../Ads/CreateAd";
import Media from "../../Media";
import FooterLogo from "../../FooterLogo";
import Category from "../../Category";
import CreateCategory from "../../Category/CreateCategory";
import CreateSubCategory from "../../SubCategory/CreateSubCategory";
import SubCategory from "../../SubCategory";
import Affiliate from "../../Affiliate";
import AdReports from "../../AdReports";
import Contact from "../../Contact";
import GWMeetingMapping from "../../GWMeetingMapping";
import Sports from "../../sports";
import CreateSports from "../../sports/createSports";
import SportType from "../../sportType";
import CreateSportType from "../../sportType/createSportType";
import ApiProvider from "../../APIprovider";
import ApiProviderUpdate from "../../APIprovider/APIproviderUpdate";
import ApiProviderApisFeildMapping from "../../APIprovider/apiProviderApisFeildMapping";
import AnimalTypes from "../../animalTypes";
import AnimalColour from "../../animalColour";
import AnimalGender from "../../animalGender";
import Categories from "../../Categories";
import CreateCategories from "../../Categories/CreateCategories";
import Addresses from "../../Addresses";
import CreateAddresses from "../../Addresses/CreateAddresses";
import Users from "../../Users";
import CreateUsers from "../../Users/CreateUsers";
import PlayersType from "../../playersType";
import Players from "../../players";
import Teams from "../../teams";
import TeamPlayers from "../../teamPlayers";
import Country from "../../country";
import States from "../../states";
import Location from "../../location";
import Weather from "../../weather";
import Fixtures from "../../fixtures";
import RacingParticipants from "../../racingParticipants";
import ParticipantsType from "../../participantType";
import Participants from "../../participants";
import participantValue from "../../participantsValue";
import Bookkeeper from "../../bookkeeper";
import AdPlaceHolder from "../../adPlaceHolder";
import AdPlaceHolderPages from "../../adPlaceHolderPage";

import Markets from "../../Markets";
import MarketValues from "../../Markets/MarketValues";
import ImportOdds from "../../importOdds";
import BannerPlaceHolders from "../../bannerPlaceHolders";
import CmsPages from "../../cmsPages";
import Events from "../../events";
import League from "../../league";
import CmsMenu from "../../cmsMenu";
import CmsPageContent from "../../cmsPageContent";

import EventOptionValues from "../../eventOptionValues";
import RaceTable from "../../raceTable";
import RaceTableOptionValues from "../../raceTableOptionValues";
import Distance from "../../distance";
import PlayerOptionValues from "../../playerOptionValues";
import Cities from "../../cities";
import MarketValueKeys from "../../marketValueKeys";

export const routes = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/about", component: AboutUs },
  { path: "/admin/blog", component: Blog },
  { path: "/admin/ads", component: Ads },
  { path: "/admin/media", component: Media },
  { path: "/admin/add", component: AddNew },
  { path: "/admin/add-blog", component: CreateBlog },
  {
    path: "/admin/edit-blog/:id",
    component: CreateBlog,
  },
  {
    path: "/admin/new-ad",
    component: CreateAd,
  },
  {
    path: "/admin/edit-ad/:id",
    component: CreateAd,
  },
  {
    path: "/admin/edit-page/:id",
    component: AddNew,
  },
  {
    path: "/admin/footer-logo",
    component: FooterLogo,
  },
  {
    path: "/admin/category",
    component: Category,
  },
  {
    path: "/admin/add-category",
    component: CreateCategory,
  },
  {
    path: "/admin/edit-category/:id",
    component: CreateCategory,
  },
  {
    path: "/admin/sub-category",
    component: SubCategory,
  },
  {
    path: "/admin/add-sub-category",
    component: CreateSubCategory,
  },
  {
    path: "/admin/edit-sub-category/:id",
    component: CreateSubCategory,
  },
  {
    path: "/admin/affiliate",
    component: Affiliate,
  },
  {
    path: "/admin/ad-reports",
    component: AdReports,
  },
  {
    path: "/admin/contact",
    component: Contact,
  },
  {
    path: "/admin/gwmeetingmapping",
    component: GWMeetingMapping,
  },
  {
    path: "/admin/sports",
    component: Sports,
  },
  {
    path: "/admin/sporttype",
    component: SportType,
  },
  {
    path: "/admin/apiprovider",
    component: ApiProvider,
  },
  {
    path: "/admin/apiprovider/apifieldmapping/:providerid/:id",
    component: ApiProviderApisFeildMapping,
  },
  {
    path: "/admin/apiprovider/apiproviderupdate/:id",
    component: ApiProviderUpdate,
  },
  {
    path: "/admin/animaltypes",
    component: AnimalTypes,
  },
  {
    path: "/admin/animalcolour/:id",
    component: AnimalColour,
  },
  {
    path: "/admin/animalgender/:id",
    component: AnimalGender,
  },
  {
    path: "/admin/categories",
    component: Categories,
  },
  {
    path: "/admin/add-categories",
    component: CreateCategories,
  },
  {
    path: "/admin/edit-categories/:id",
    component: CreateCategories,
  },
  {
    path: "/admin/addresses",
    component: Addresses,
  },
  {
    path: "/admin/add-addresses",
    component: CreateAddresses,
  },
  {
    path: "/admin/edit-addresses/:id",
    component: CreateAddresses,
  },
  {
    path: "/admin/users",
    component: Users,
  },
  {
    path: "/admin/add-users",
    component: CreateUsers,
  },
  {
    path: "/admin/edit-users/:id",
    component: CreateUsers,
  },
  {
    path: "/admin/playerstype",
    component: PlayersType,
  },
  {
    path: "/admin/players",
    component: Players,
  },
  {
    path: "/admin/playeroptionvalues",
    component: PlayerOptionValues,
  },
  {
    path: "/admin/teams",
    component: Teams,
  },
  {
    path: "/admin/teamplayers/:id",
    component: TeamPlayers,
  },
  {
    path: "/admin/countries",
    component: Country,
  },
  {
    path: "/admin/states/:id",
    component: States,
  },
  {
    path: "/admin/cities/:id",
    component: Cities,
  },
  {
    path: "/admin/locations",
    component: Location,
  },
  {
    path: "/admin/weather",
    component: Weather,
  },
  {
    path: "/admin/fixtures",
    component: Fixtures,
  },
  {
    path: "/admin/racingparticipants",
    component: RacingParticipants,
  },
  {
    path: "/admin/participantstype",
    component: ParticipantsType,
  },
  {
    path: "/admin/participants/:id",
    component: Participants,
  },
  {
    path: "/admin/participantsvalue",
    component: participantValue,
  },
  {
    path: "/admin/bookkeeper",
    component: Bookkeeper,
  },
  {
    path: "/admin/markets",
    component: Markets,
  },
  {
    path: "/admin/markets/marketsValues",
    component: MarketValues,
  },
  {
    path: "/admin/markets/marketvaluekeys",
    component: MarketValueKeys,
  },
  {
    path: "/admin/adplaceholder",
    component: AdPlaceHolder,
  },
  {
    path: "/admin/adplaceholderpage/:id",
    component: AdPlaceHolderPages,
  },
  {
    path: "/admin/importOdds",
    component: ImportOdds,
  },
  {
    path: "/admin/bannerplaceholders/:placeholderid/:id",
    component: BannerPlaceHolders,
  },
  {
    path: "/admin/cmspages",
    component: CmsPages,
  },
  {
    path: "/admin/cmspagecontent/:id",
    component: CmsPageContent,
  },
  {
    path: "/admin/cmsmenu",
    component: CmsMenu,
  },
  {
    path: "/admin/events",
    component: Events,
  },
  {
    path: "/admin/eventoptionvalues",
    component: EventOptionValues,
  },
  {
    path: "/admin/racetable",
    component: RaceTable,
  },
  {
    path: "/admin/racetableoptionvalues",
    component: RaceTableOptionValues,
  },
  {
    path: "/admin/distance",
    component: Distance,
  },
  {
    path: "/admin/league",
    component: League,
  },
];
