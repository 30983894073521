import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { faqs } from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Help = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={15}>
      <Grid item xs={12}>
        <h2>FAQs</h2>
        <List>
          {faqs.map((faq, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={faq.title}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {faq.description}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Help;
