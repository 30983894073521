import React from "react";
import { Grid, Divider } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { EventEmitter } from "./../../../../services/event";
import fighter from "../../../../images/menu_icons/fighter.svg";

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateToAllEvent = (race) => {
    EventEmitter.dispatch("show_all_mmaEvents", race);
  };

  render() {
    return (
      <>
        <Grid container className="hide-xs">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">[Organisation Name]</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">[Organisation 1]</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="card-heading">[Organisation 2]</p>
                <p className="card-heading">[Time]</p>
              </div>

              <div className="allsports_boxing_inner mma-inner">
                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>

                <div className="mma-organisation">
                  <p
                    className="allsport_inner_match mma-title event-green"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    <ReactSVG src={fighter} /> [Event Name]
                  </p>
                  <p className="mma-title">[Date]</p>
                  <p className="mma-title">[Start Time]</p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container className="hide-sm hide-md hide-lg hide-xl">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">[Organisation Name]</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">[Organisation 1]</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>
            </div>

            <div className="allsports_body_outer">
              <div className="allsports_body_head">
                <p className="res-mmacardname">[Organisation 2]</p>
                <p className="res-mmacardname">[Time]</p>
              </div>
              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>

              <div className="allsports_boxing_inner">
                <div className="allsport_inner_heading res-mma">
                  <p
                    className="res-fightername"
                    onClick={() => {
                      this.navigateToAllEvent("All Events");
                    }}
                  >
                    [Event Name]
                  </p>
                </div>
                <Divider className="allsports_inner_hr" />
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">Date :</p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Date]
                  </p>
                </div>
                <div className="allsport_inner_main">
                  <p className="allsport_inner_match res-mma-weight">
                    Start Time
                  </p>
                  <p className="allsport_inner_match res-mma-weight mma-font-400">
                    [Start Time]
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AllSportsHome;
