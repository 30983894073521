import React, { Component } from "react";
import moment from "moment";
import {
  BrowserRouter as Router1,
  HashRouter as Router,
} from "react-router-dom";
import "./app.css";
import { EventEmitter } from "../../services/event";
import { connect } from "react-redux";
import { getAuth } from "../../library/common/actions";
import AppRoutes from "./app.routes";
import { fetchFromStorage } from "../../library/utilities";
import Routes from "../../admin/Module/Routes";
import AppMeta from "../AppMeta";
import { fetchAllAds } from "../../store/common/actions/ads.actions";
import { setMetaTags } from "../../library/common/actions/MetaActions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loginuser: false,
      classList: [],
    };
    this.logout = this.logout.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
    EventEmitter.subscribe("updateAppClassList", this.updateClassList);
    EventEmitter.subscribe("removeAppClassList", this.removeClassList);
    EventEmitter.subscribe("checkLogin", this.checkuserIsLoggedIn);
    EventEmitter.subscribe("setPageMeta", this.setPageMeta);
  }

  componentDidMount() {
    let sessionClockTime = sessionStorage.getItem("sessionClock");
    sessionClockTime =
      sessionClockTime && sessionClockTime !== null
        ? JSON.parse(sessionStorage.getItem("sessionClock"))
        : sessionClockTime;

    if (sessionClockTime === null || sessionClockTime === "undefined") {
      sessionStorage.setItem(
        "sessionClock",
        JSON.stringify({
          sessionStartTime: moment().valueOf(),
        })
      );
      EventEmitter.dispatch("getInitSessionTime");
    }

    // let sessionClock = localStorage.getItem("sessionClock");
    // sessionClock =
    //   sessionClock && typeof sessionClock === "string" && sessionClock !== ""
    //     ? JSON.parse(localStorage.getItem("sessionClock"))
    //     : sessionClock;
    // if (sessionClock?.sessionStartTime && sessionClock?.sessionStartTime > 0) {
    //   localStorage.setItem(
    //     "sessionClock",
    //     JSON.stringify({
    //       ...sessionClock,
    //       lastActionTime: moment().valueOf(),
    //     })
    //   );
    // } else {
    //   localStorage.setItem(
    //     "sessionClock",
    //     JSON.stringify({
    //       ...sessionClock,
    //       sessionStartTime: moment().valueOf(),
    //     })
    //   );
    // }
    this.checkuserIsLoggedIn();
    this.props.getAuth();
    this.props.fetchAllAds();
    EventEmitter.dispatch("getInitialUpcommingRace");
    // try {
    //   setInterval(async () => {
    //     await EventEmitter.dispatch("getInitialUpcommingRace");
    //   }, 60000);
    // } catch (e) {
    //   console.log(e);
    // }
  }

  checkuserIsLoggedIn() {
    let { classList } = this.state;
    classList.push("App");
    this.setState({ classList: classList });

    var loginuser = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : sessionStorage.getItem("user");
    if (localStorage.getItem("user") || sessionStorage.getItem("user")) {
      this.setState({
        login: true,
        loginuser: loginuser,
      });
    } else {
      this.setState({
        loginuser: false,
      });
      // console.log("logout");
      // this.logout();
    }
  }
  updateClassList = (classString) => {
    let { classList } = this.state;
    classList.push(classString);
    this.setState({ classList: classList });
  };
  removeClassList = (classString) => {
    let { classList } = this.state;
    var index = classList.indexOf(classString);
    if (index !== -1) {
      classList.splice(index, 1);
      this.setState({ classList: classList });
    }
  };

  checkLogin(data) {
    if (
      Boolean(data.terms) === true &&
      data.username === "admin@mail.com" &&
      data.password === "123456"
    ) {
      localStorage.setItem("loginuser", JSON.stringify(data.username));
      this.setState({ login: true });
      setTimeout(function () {
        this.logout();
      }, 1000 * 60 * 60);
    } else if (data.username === "admin" && data.password === "123456") {
      localStorage.setItem("loginuser", JSON.stringify(data.username));
      this.setState({ login: true });
      setTimeout(function () {
        this.logout();
      }, 1000 * 60 * 60);
    } else {
      this.setState({ login: false });
    }
  }

  logout() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("auth");
    EventEmitter.dispatch("removeAppClassList", "loggedin");
    this.setState({ login: false, loginuser: "" });
  }

  setPageMeta = (tags) => {
    if (tags) this.props.setMetaTags(tags);
  };

  render() {
    let user = fetchFromStorage("user");
    var { classList, loginuser } = this.state;
    var { isLoggedIn, tags } = this.props;

    if (isLoggedIn) classList.push("loggedin");
    else {
      var index = classList.indexOf("loggedin");
      if (index !== -1) {
        classList.splice(index, 1);
      }
    }

    const uniqueClass = [];
    classList.map((cl) => {
      if (uniqueClass.indexOf(cl) === -1) {
        uniqueClass.push(cl);
      }
      return cl;
    });

    return (
      <div className={uniqueClass.join(" ")}>
        {tags.map((tag, index) => (
          <AppMeta
            title={tag.title}
            description={tag.description}
            key={index}
          />
        ))}

        <Router1>
          <AppRoutes
            loginuser={
              loginuser
                ? typeof loginuser === "string"
                  ? JSON.parse(loginuser)
                  : loginuser
                : false
            }
            isLoggedIn={isLoggedIn}
            login={isLoggedIn}
            checkLogin={this.checkLogin}
            logout={this.logout}
            {...this.props}
          />
        </Router1>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, metaReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    tags: metaReducer.tags,
  };
};

export default connect(mapStateToProps, { getAuth, fetchAllAds, setMetaTags })(
  App
);
