import React from "react";
import { TextField, Grid } from "@material-ui/core";

const Feedback = () => {
  return (
    <Grid container spacing={15}>
      <Grid item xs={12} md={6} style={{ margin: "0 auto" }}>
        <h3>We need your feedback to improve your experience</h3>
        <form style={{ margin: "30px 0 60px 0" }}>
          <TextField
            id="standard-basic"
            label="Subject"
            // variant="outlined"
            fullWidth
          />
          <br />
          <br />
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            fullWidth
            rows={4}
            // variant="outlined"
          />
          <button className="submit-button">Submit</button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Feedback;
