import moment from "moment";

import { storeConstants } from "../../../library/common/constants/StoreConstants";
import { URLS } from "../../../library/common/constants/UrlConstants";
import axiosInstance from "../../../helpers/Axios";

export const fetchAllAds = () => async (dispatch) => {
  try {
    const { status, data } = await axiosInstance.get(URLS.ads);
    if (status === 200) {
      const activeAds = data.filter((ad) => {
        const currentDate = moment();
        const startDate = moment(ad.startDate);
        const endDate = moment(ad.endDate);

        if (
          currentDate.isBetween(startDate, endDate) &&
          currentDate.isSameOrAfter(startDate) &&
          currentDate.isSameOrBefore(endDate)
        ) {
          return ad;
        }
      });

      dispatch({
        type: storeConstants.FETCH_ADS,
        payload: activeAds,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setActiveAdsId = (type,id) => (dispatch) => {
  dispatch({ type: "SETACTIVEADID",
  payload: {type:type,id: id}, });
};