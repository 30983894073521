//betsidebar-
import playup from "../../../images/bookmarks_logo/playup-transparent.png";
import ladbrokes from "../../../images/bookmarks_logo/ladbrokes.jpg";
import neds from "../../../images/bookmarks_logo/neds.jpg";
import VICBet from "../../../images/bookmarks_logo/VICBet.PNG";
import bookmaker from "../../../images/bookmarks_logo/bookmaker.jpg";
import bet365 from "../../../images/bookmarks_logo/bet365.jpeg";
import betstar from "../../../images/bookmarks_logo/betstar.jpg";
import palmerBet from "../../../images/footer_icons/palmerbet.png";

export default function BetsReducer(
  state = {
    bookmarks: [
      {
        id: 1,
        key: "PUP", //"playup",
        logo: playup,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://record.affiliates.playup.com/_Y_Ng50hdX2Zhg6WO2I1rgWNd7ZgqdRLk/1/",
      },
      {
        id: 2,
        key: "VBT",
        logo: VICBet,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://www.vicbet.com",
      },
      {
        id: 3,
        key: "Neds",
        logo: neds,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://record.nedsaffiliates.com.au/_dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk/1/",
      },
      {
        id: 4,
        key: "Ladbrokes",
        logo: ladbrokes,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://record.wageringaffiliates.com.au/_rKStVKr_NJ-itX36G815rmNd7ZgqdRLk/1/",
      },
      {
        id: 5,
        key: "Bookmaker",
        logo: bookmaker,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://record.wageringaffiliates.com.au/_aoh4_X4Dx1XlD9UB4eAeYmNd7ZgqdRLk/1/",
      },
      {
        id: 6,
        key: "BetStar",
        logo: betstar,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://record.wageringaffiliates.com.au/_vB8fbcQfs2keR0indqtVW2Nd7ZgqdRLk/1/",
      },
      {
        id: 8,
        key: "Bet365",
        logo: bet365,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://www.bet365.com/olp/open-account?affiliate=365_00967140",
      },
      {
        id: 9,
        key: "PalmerBet",
        logo: palmerBet,
        wins: 0,
        place: 0,
        enabled: true,
        link: "https://www.palmerbet.com/createaccount/?aff=BJCiiODXq9jReThDaTY5c2Nd7ZgqdRLk",
      },
    ],
  },
  action
) {
  switch (action.type) {
    case "SET_BOOKMARKERS":
      return Object.assign({}, state, { bookmarks: action.payload });

    default:
      return state;
  }
}
