import { Validators } from "../../../library/utilities/Validators";

export const adFormModel = [
  {
    label: "Ad Title",
    value: "",
    type: "text",
    placeholder: "",
    field: "title",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Position",
    value: "",
    type: "dropdown",
    placeholder: "Position",
    field: "position",
    validators: [],
    required: false,
    className: "6",
    options: [
      { value: "", label: "Select Position" },
      { value: "top-left-banner", label: "Top Left Banner" },
      { value: "top-right-banner", label: "Top Right Banner" },
      { value: "bottom-center-banner", label: "Bottom Center Banner" },
      { value: "bottom-right-banner", label: "Bottom Right Banner" },
    ],
  },
  {
    label: "Start Date",
    value: "",
    type: "date",
    placeholder: "Start Date",
    field: "startDate",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "4",
  },
  {
    label: "End Date",
    value: "",
    type: "date",
    placeholder: "End Date",
    field: "endDate",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "4",
  },
  {
    label: "Ad Fallback URL",
    value: "",
    type: "text",
    placeholder: "",
    field: "url",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "4",
  },
];
