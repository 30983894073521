import { Validators } from "../../../library/utilities/Validators";
import { CMS_MENU_STATUS } from "../../../helpers/constants";

export const cmsMenuFormModel = [
  {
    label: "Menu Text*",
    value: "",
    type: "text",
    placeholder: "Menu Text",
    field: "menuText",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Display Order*",
    value: "",
    type: "text",
    placeholder: "Display Order",
    field: "displayOrder",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Please enter number only" },
    ],
    required: true,
    className: "6",
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [
      ...CMS_MENU_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
