import React, { Component } from "react";
import moment from "moment";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
} from "@material-ui/core";
import axiosInstance from "../../helpers/Axios";
import { ReactSVG } from "react-svg";
import Loader from "../../library/common/components/Loader";
import { raceIcon } from "../../helpers/common";
import TimeCounter from "../timecounter/timecounter";
import ShowModal from "../common/ShowModal/ShowModal";
import "./sportlist.scss";
import { URLS } from "../../library/common/constants/UrlConstants";

class UpcomingRaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSyncModalOpen: false,
      upcomingRaces: [],
      isLoading: false,
      date: "",
      allSports: [],
      currentDay: "upcoming",
      daysArray: [
        { id: "upcoming", label: "Next To Jump" },
        // { id: "today", label: "Today" },
        // { id: "tomorrow", label: "Tomorrow" },
      ],
    };
  }

  async componentDidMount() {
    if (!window.location.href.includes("admin")) {
      this.fetchAllSports();
      this.getUpcommingRace();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.sportName !== prevProps.sportName) {
      this.getUpcommingRace();
    }
  }

  async fetchAllSports() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sports);
      if (status === 200) {
        this.setState({ allSports: data.result });
      }
    } catch (err) {}
  }

  // getSportsId = (sportName) => {
  //   let { allSports } = this.state;
  //   console.log("sport id", allSports);
  //   let sportId = allSports
  //     .filter((obj) => obj.sportName === sportName)
  //     .map((object) => object.id);
  //   return sportId;
  // };

  async getUpcommingRace() {
    const { date } = this.state;
    const { sportName, sportId } = this.props;
    let sportname = sportName ? sportName : "Horse Racing";
    let id = sportId !== undefined ? sportId : "1";

    let raceUrl = `/events/upcoming?sportId=${id}`;
    // if (typeof sportname !== "undefined" && sportname !== "") {
    //   raceUrl += "sportName=" + sportname;
    // } else {
    //   raceUrl += "sportName=";
    // }
    // raceUrl += "&MeetingState=";
    // if (typeof date !== "undefined" && date !== "") {
    //   raceUrl += "&date=" + date;
    // } else {
    //   raceUrl += "&date=";
    // }

    this.setState({ isLoading: true });
    await axiosInstance
      .get(raceUrl)
      .then((res) => {
        this.setState({ isLoading: false, upcomingRaces: res.data.result });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false, upcomingRaces: [] });
      });
  }

  handleRaceNavigation = (item) => () => {
    const { history, sportId, sportName } = this.props;
    let id = sportId !== undefined ? sportId : "1";
    let sport = sportName ? sportName : "Horse Racing";

    history.push(`/race/${id}/${sport}/${item?.event?.eventName}/${item?.id}`, {
      item: item,
    });
  };

  toggleSyncModal = () => {
    this.setState((prevState) => {
      return { isSyncModalOpen: !prevState.isSyncModalOpen };
    });
  };

  handleTicketColor = (j) => {
    if (j < 8) {
      return "ticket-green";
    } else if (j > 7 && j < 16) {
      return "ticket-light-green";
    } else {
      return "ticket-orange";
    }
  };

  removeComplatedUpcommingRace = (item) => {
    this.setState((prevState) => {
      return {
        upcomingRaces: prevState.upcomingRaces?.filter(
          (raceitem) => raceitem !== item
        ),
      };
    });
  };

  setDate = (event, day) => {
    let date = "";
    if (day === "upcoming") {
      date = moment().format("DD-MM-yyyy");
    } else if (day === "today") {
      date = moment().format("DD-MM-yyyy");
    } else if (day === "tomorrow") {
      date = moment().add(1, "days").format("DD-MM-yyyy");
    }
    this.setState({ currentDay: day, date: date }, () =>
      this.getUpcommingRace()
    );
  };

  render() {
    const { upcomingRaces, isLoading, currentDay, daysArray } = this.state;
    const { sportName } = this.props;
    let sport = sportName ? sportName : "Horse Racing";
    let upcomingRacesSlides = upcomingRaces;
    // upcomingRacesSlides = upcomingRaces.filter(
    //   (item) => new Date(item.startTimeDate) > new Date()
    // );

    return (
      <>
        <ShowModal
          isModalOpen={this.state.isSyncModalOpen}
          Content={"Please wait data is syncing"}
          onModalClose={this.toggleSyncModal}
          onOkay={this.toggleSyncModal}
        />
        <div className='jump-title'>
          <Tabs
            onChange={this.setDate}
            value={currentDay}
            variant='scrollable'
            className='hideIndecator textTransformauto'
            scrollButtons='off'
          >
            {daysArray?.length > 0 &&
              daysArray?.map((day, index) => (
                <Tab
                  disableFocusRipple
                  disableRipple
                  key={day?.id}
                  value={day?.id}
                  component='h5'
                  // className={currentDay === day?.id ? " active " : ""}
                  label={day?.label}
                ></Tab>
              ))}
          </Tabs>
        </div>
        {isLoading ? (
          <div className='racelist-loader'>
            <Loader />
          </div>
        ) : (
          <Grid container spacing={2}>
            {upcomingRacesSlides?.length > 0 ? (
              upcomingRacesSlides.map((raceitem, j) => (
                <Grid
                  key={j}
                  md={3}
                  sm={6}
                  xs={6}
                  item
                  className='race-item uppcoming-race-item'
                  onClick={this.handleRaceNavigation(raceitem)}
                >
                  <Card
                    className={
                      this.handleTicketColor(j) + " card-root MuiPaper-root"
                    }
                  >
                    <div
                      className={
                        "card-cover MuiCardMedia-root card-cover MuiCardMedia-media MuiCardMedia-img"
                      }
                    >
                      <ReactSVG src={raceIcon(sport)} />
                    </div>
                    <div
                      className={"card-details"}
                      style={{ textAlign: "right" }}
                    >
                      <CardContent className={"card-content"}>
                        <Typography component='h4' variant='h4'>
                          <TimeCounter
                            chip={false}
                            time={moment.utc(raceitem?.startTimeDate)}
                            onCompleteTime={() =>
                              this.removeComplatedUpcommingRace(raceitem)
                            }
                          />
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className='meeting-name'
                        >
                          {raceitem?.event?.eventName}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className='meeting-number'
                        >
                          Race {raceitem?.raceNumber}
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              ))
            ) : (
              <p class='text-center'>No Race Found</p>
            )}
          </Grid>
        )}
      </>
    );
  }
}

export default UpcomingRaces;
