import { Validators } from "../../../library/utilities/Validators";

export const aboutTeamFormConstant = (
  editable = true,
  member,
  onRemove,
  onEdit
) => {
  return [
    {
      label: "Name",
      value: "",
      type: "text",
      placeholder: "",
      field: "name",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "6" : "4",
      extraProps: {
        disabled: !editable,
      },
    },
    {
      label: "Designation",
      value: "",
      type: "text",
      placeholder: "",
      field: "position",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "6" : "4",
      extraProps: {
        disabled: !editable,
      },
    },
    {
      label: "Is External Link",
      value: "",
      type: "dropdown",
      placeholder: "",
      field: "isExternal",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "3" : "3",
      extraProps: {
        disabled: !editable,
        rows: 4,
      },
      options: [
        { id: "no", label: "No", value: false },
        { id: "yes", label: "Yes", value: true },
      ],
    },
    {
      label: "Page Link",
      value: "",
      type: "text",
      placeholder: "https://www.example.com",
      field: "url",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        { check: Validators.url, message: "Invalid Url" },
      ],
      required: true,
      className: editable ? "9" : "3",
      extraProps: {
        disabled: !editable,
      },
    },
    !editable && {
      type: "button",
      value: "Edit",
      color: "primary",
      className: "1",
      onClick: () => onEdit(member),
    },
    !editable && {
      type: "button",
      value: "Remove",
      color: "secondary",
      className: "1",
      onClick: () => onRemove(member.id),
    },
  ];
};

export const membershipFormConstant = (
  editable = true,
  member,
  onRemove,
  onEdit
) => {
  return [
    {
      label: "Title",
      value: "",
      type: "text",
      placeholder: "",
      field: "title",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "12" : "4",
      extraProps: {
        disabled: !editable,
      },
    },
    {
      label: "Description",
      value: "",
      type: "textarea",
      placeholder: "",
      field: "description",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "12" : "6",
      extraProps: {
        disabled: !editable,
        rows: 4,
      },
    },
    {
      label: "Is External Link",
      value: "",
      type: "dropdown",
      placeholder: "",
      field: "isExternal",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      required: true,
      className: editable ? "3" : "3",
      extraProps: {
        disabled: !editable,
        rows: 4,
      },
      options: [
        { id: "no", label: "No", value: false },
        { id: "yes", label: "Yes", value: true },
      ],
    },
    {
      label: "Page Link",
      value: "",
      type: "text",
      placeholder: "https://www.example.com",
      field: "url",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
        { check: Validators.url, message: "Invalid Url" },
      ],
      required: true,
      className: editable ? "9" : "3",
      extraProps: {
        disabled: !editable,
        rows: 4,
      },
    },
    !editable && {
      type: "button",
      value: "Edit",
      color: "primary",
      className: "1",
      onClick: () => onEdit(member),
    },
    !editable && {
      type: "button",
      value: "Remove",
      color: "secondary",
      className: "1",
      onClick: () => onRemove(member.id),
    },
  ];
};
