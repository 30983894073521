import React, { Component } from "react";
import { Typography, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import "./sports.rugby.scss";
import TabList from "../../common/tablists/tablist";
import { EventEmitter } from "../../../services/event";

export default class RugbyTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubType: { id: 1, title: "All Markets" },
      raceSubTypes: [
        { id: 1, League: "All Markets" },
        // { id: 2, League: "Same Game Multi" },
      ],
      toggleMarkets: [],
    };
  }

  toggleMarketDisplay = (id) => () => {
    const clonedToggleMarkets = [...this.state.toggleMarkets];
    const index = clonedToggleMarkets.indexOf(id);

    if (index === -1) {
      clonedToggleMarkets.push(id);
    } else {
      clonedToggleMarkets.splice(index, 1);
    }

    this.setState({ toggleMarkets: clonedToggleMarkets });
  };

  handleSetBet = (itemToSend) => () => {
    EventEmitter.dispatch("draweropen");
    EventEmitter.dispatch("updatebetsList", itemToSend);
  };

  showCompareButton = (outcomeItem, leagueName, marketName) => {
    const item = this.props.MarketDataItem;
    let itemData = {};
    if (item.Market?.length > 0) {
      let itemDatatemp = [];
      itemDatatemp = item.Market?.filter(
        (marketitem) => marketitem?.MarketTypeID === null
      );
      itemData = itemDatatemp?.length > 0 ? itemDatatemp[0] : {};
    }

    const itemToSend = { ...item, ...outcomeItem };
    itemToSend.leagueName = leagueName;
    itemToSend.Market = marketName;
    itemToSend.sportsData = item?.sportsData;
    itemToSend.teams = `${item.TeamA} vs ${item.TeamB}`;
    itemToSend.ShowOtherSportMarket = true;
    itemToSend.itemData = { WinPrices: itemToSend?.WinPrice };
    itemToSend.imageSrc = item.SportName;
    itemToSend.title = outcomeItem.Team;
    let buttonDisabled = "false";
    if (itemToSend?.WinPrice?.length > 0) {
      let bestOdds = itemToSend?.WinPrice?.sort(function (a, b) {
        return Number(b.FixedWin) - Number(a.FixedWin);
      });
      bestOdds = bestOdds[0];
      if (!Number(bestOdds?.team?.WinPrice) > 0) {
        buttonDisabled = "true";
      }
    } else {
      buttonDisabled = "true";
    }

    return (
      <button
        disabled={buttonDisabled == "true"}
        className="cursor compare-button"
        onClick={this.handleSetBet(itemToSend)}
      >
        Compare
      </button>
    );
  };

  render() {
    const { selectedSubType, raceSubTypes, toggleMarkets } = this.state;
    const item = this.props.MarketDataItem;
    return (
      <>
        <div className="team-head title-with-border">
          <KeyboardBackspaceIcon
            className="cursor"
            onClick={() => this.props.hideMarketData()}
          />
          <Typography
            variant="h5"
            className="sub-title"
            style={{ marginLeft: 10 }}
          >
            {item.TeamA} vs {item.TeamB}
          </Typography>
        </div>

        {item && item.Market && item.Market.length > 0 && (
          <>
            <TabList
              isRugby
              handleChange={this.handleChange}
              tabs={raceSubTypes}
              selectedTab={selectedSubType}
            />
          </>
        )}

        <div className="race-details">
          <Grid container spacing={5}>
            {item && item?.Market && item?.Market?.length > 0 ? (
              item?.Market?.map((market, i) => {
                // const OutcomeTotal = market?.Outcome.reduce((totalCalories, outcomeSingle) => totalCalories + outcomeSingle.Win, 0);
                return (
                  market?.Outcome?.length > 0 && (
                    <Grid key={i} item xs={12} md={6}>
                      <div
                        className={`market-item ${
                          toggleMarkets.indexOf(market.id) !== -1
                            ? "active"
                            : "inactive"
                        }`}
                        key={market.id}
                        onClick={this.toggleMarketDisplay(market.id)}
                        role="button"
                        tabIndex="-1"
                      >
                        <span className="icon">
                          {toggleMarkets.indexOf(market.id) !== -1 ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </span>
                        {market.Market}
                      </div>

                      {toggleMarkets.indexOf(market.id) !== -1 && (
                        <div className="market-data">
                          <hr />

                          {market.Outcome.map((outcome) => (
                            <div className="outcome-item" key={outcome.id}>
                              <p>{outcome.Team}</p>{" "}
                              <p>{Number(outcome.Win).toFixed(2)}</p>
                              {this.showCompareButton(
                                { ...outcome, ShowWinsOnly: true },
                                item.leagueName,
                                market.Market
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Grid>
                  )
                );
              })
            ) : (
              <p style={{ marginLeft: 30 }}>No Markets Available</p>
            )}

            {/* {item.Market.length > 0 && (
              <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                  <div className="market-item active">
                    <div className="market-header">
                      <p>Stats & Insights</p>
                      <div className="right-icon-with-text">
                        Preview{" "}
                        <span className="icon">
                          <KeyboardArrowDownIcon />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="market-data">
                    <hr />
                    <p className="season-result-title">2020 Season Results</p>

                    <div className="teams">
                      <h3>Team 1</h3>
                      <h3>Team 2</h3>
                    </div>
                    <div className="team-table">
                      <table>
                        <tbody>
                          <tr>
                            <td>Team 1</td>
                            <td>R3</td>
                            <td align="right">6-34</td>
                            <td>L</td>
                          </tr>
                          <tr>
                            <td>Team 2</td>
                            <td>R2</td>
                            <td align="right">22-18</td>
                            <td>W</td>
                          </tr>
                          <tr>
                            <td>Team 3</td>
                            <td>R1</td>
                            <td align="right">21-28</td>
                            <td>W</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="market-item active">Last 5 Head to Head</div>

                  <div className="market-data">
                    <div className="head-to-head">
                      <p className="stadium-name">
                        Fri 17 May 2019 - Stadium Name
                      </p>
                      <div className="team-table">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>H1</th>
                              <th>H2</th>
                              <th>FT</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Team 1</td>
                              <td>8</td>
                              <td>7</td>
                              <td>15</td>
                              <td>W</td>
                            </tr>
                            <tr>
                              <td>Team 2</td>
                              <td>8</td>
                              <td>2</td>
                              <td>10</td>
                              <td>L</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="head-to-head">
                      <p className="stadium-name">
                        Thu 4 Apr 2019 - Stadium Name
                      </p>
                      <div className="team-table">
                        <table>
                          <thead>
                            <tr>
                              <td></td>
                              <td>H1</td>
                              <td>H2</td>
                              <td>FT</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Team 1</td>
                              <td>8</td>
                              <td>7</td>
                              <td>15</td>
                              <td>W</td>
                            </tr>
                            <tr>
                              <td>Team 2</td>
                              <td>8</td>
                              <td>2</td>
                              <td>10</td>
                              <td>L</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )} */}
          </Grid>
        </div>
      </>
    );
  }
}
