import React, { Component } from "react";
import UpcomingRaces from "./UpcomingRaces";
import SportsMenu from "./SportsMenu";
import "./sportlist.scss";

class SportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sportName: "",
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    if (match.params?.sportName && match.params?.sportName !== "") {
      this.setState({ sportName: match.params?.sportName });
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      match.params?.sportName &&
      match.params?.sportName !== prevProps?.match.params?.sportName
    ) {
      this.setState({ sportName: match.params?.sportName });
    }
  }

  render() {
    const { sportName } = this.state;
    return (
      <>
        <SportsMenu />
        <div className="race-list">
          <UpcomingRaces
            sportId={this.props?.match?.params?.id}
            sportName={sportName}
            history={this.props.history}
          />
        </div>
      </>
    );
  }
}

export default SportList;
