import React, { createRef } from "react";
import { animalColourFormModel } from "./form-constant";
import { Grid, Paper } from "@material-ui/core";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let animalColourFormModelArray = animalColourFormModel;

class CreateAnimalColour extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: "",
        variation: "",
        animaltype: "",
      },
      animalTypeId: null,
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentAnimalColour(this.props.id);
    }
    this.setState({ animalTypeId: this.props.animalTypeId });
  }

  componentWillUnmount() {
    animalColourFormModelArray = animalColourFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentAnimalColour = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.animalColour + `/${id}`
    );
    if (status === 200) {
      this.setState({ values: data.result });
      let variationArray = JSON.parse("[" + data.result.variation + "]");
      if (variationArray.length > 0) {
        this.setState(() => {
          return {
            values: {
              ...this.state.values,
              ["variation"]: variationArray[0],
            },
          };
        });
      }
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { name } = this.state.values;
    let flag = true;

    if (name === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { animalTypeId } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.animalColour}/${this.props.id}`
        : URLS.animalColour;

      const values = removeErrorFieldsFromValues(form.formData);
      values["animalTypeId"] = animalTypeId;

      animalColourFormModelArray = animalColourFormModelArray?.map(
        (fieldItem) => {
          return setValidation(fieldItem, values);
        }
      );

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllAnimalColour();
          this.setActionMessage(
            true,
            "Success",
            `Animal Colour ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${
          isEditMode ? "editing" : "creating"
        } Animal Colour`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    animalColourFormModelArray = animalColourFormModelArray?.map(
      (fieldItem) => {
        if (field === fieldItem?.field) {
          return setValidation(fieldItem, values);
        } else {
          return fieldItem;
        }
      }
    );
    this.setActionMessage(false);
  };

  render() {
    var { values, messageBox, isLoading } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={animalColourFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateAnimalColour;
