import React, { PureComponent } from "react";
import { Button, Typography, Input, Grid, InputLabel } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { ReactSVG } from "react-svg";
import { raceIcon } from "../../../helpers/common";
import "../betssidebar.scss";
import BetslipBookmarkerRow from "../BetslipBookmarkerRow";

class RacingBetsSidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "",
      sortOrder: false,
      stakes: null,
    };
  }

  componentDidMount() {}

  handleInputChange = (id, value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      this.setState({
        stakes: {
          ...this.state.stakes,
          [id]: value,
        },
      });
    }
  };

  handleAllBetChange = (event) => {
    const { value } = event.target;
    const stakes = {};

    this.props.bets.forEach((item) => {
      stakes[item.id] = value;
    });

    this.setState({ stakes });
  };

  showBookmarkRow = (bookmark, bet) => {
    const { stakes } = this.state;
    let RunnerOdd = bet?.RunnerOdds
      ? bet?.RunnerOdds?.filter((RO) => RO.Key === bookmark?.key)
      : [];
    if (bookmark?.key === "generationWeb") {
      RunnerOdd = bet?.RunnerOdds
        ? bet?.RunnerOdds?.filter((RO) => RO.Key === "PUP")
        : [];
    } else if (bookmark?.key === "VICBet") {
      RunnerOdd = bet?.RunnerOdds
        ? bet?.RunnerOdds?.filter((RO) => RO.Key === "VBT")
        : [];
    }
    let wins =
      RunnerOdd?.length > 0
        ? Number(RunnerOdd[0].FixedWin).toFixed(2)
        : Number(bookmark?.wins).toFixed(2);
    let place =
      RunnerOdd?.length > 0
        ? Number(RunnerOdd[0].FixedPlace).toFixed(2)
        : Number(bookmark?.place).toFixed(2);
    if (RunnerOdd?.length > 0) {
      return (
        <BetslipBookmarkerRow
          bet={bet}
          bookmark={bookmark}
          wins={wins}
          place={place}
          stakes={stakes}
        />
      );
    }
  };

  toggleSorting = (by) => {
    this.setState((prevState) => {
      return { sortBy: by, sortOrder: !prevState.sortOrder };
    });
  };

  renderBetContent = (bet, index) => {
    const { stakes, sortBy, sortOrder } = this.state;
    const { bookmarks } = this.props;
    let filtered_bookmarks = bookmarks;
    if (bookmarks?.length > 0) {
      filtered_bookmarks = bookmarks?.map((bItem, i) => {
        let RunnerOdd = bet?.RunnerOdds
          ? bet?.RunnerOdds?.filter((RO) => RO.Key === bItem?.key)
          : [];
        let wins =
          RunnerOdd?.length > 0
            ? Number(RunnerOdd[0].FixedWin).toFixed(2)
            : Number(bItem?.wins).toFixed(2);
        let place =
          RunnerOdd?.length > 0
            ? Number(RunnerOdd[0].FixedPlace).toFixed(2)
            : Number(bItem?.place).toFixed(2);

        return { ...bItem, wins: wins, place: place };
      });
    }

    if (sortBy !== "") {
      if (sortBy === "wins") {
        filtered_bookmarks = filtered_bookmarks.sort((a, b) =>
          (sortOrder ? a.wins > b.wins : a.wins < b.wins) ? 1 : -1
        );
      } else if (sortBy === "place") {
        filtered_bookmarks = filtered_bookmarks.sort((a, b) =>
          (sortOrder ? a.place > b.place : a.place < b.place) ? 1 : -1
        );
      } else {
      }
    }

    return (
      <div
        className="bets_bookmark_content"
        key={"bets_bookmark_content" + index}>
        <Grid
          container
          spacing={2}
          key={0}
          className="box racing-details text-left">
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography variant="body1">{bet.sportTitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Race {bet.raceNumber} {bet.race}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{bet.raceTime}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="bet-row-item box text-left">
          <Grid item xs={6}>
            <ReactSVG className="horse_img" src={raceIcon(bet?.SportName)} />
            <p
              style={{
                margin: 0,
                paddingLeft: 40,
                marginTop: 5,
                fontSize: 12,
              }}>
              {bet.RunnerNumber} {bet.OutcomeName} ({bet.Barrier})
            </p>
          </Grid>
          <Grid item lg={6} md={5} sm={5} xs={5}>
            <div className="stackinput full-width">
              <InputLabel htmlFor="stake">Stake $</InputLabel>
              <Input
                inputProps={{
                  "aria-label": "stake",
                }}
                id="stake"
                name="stake"
                type="text"
                value={
                  stakes && stakes[bet?.id] !== null ? stakes[bet?.id] : ""
                }
                onChange={(e) => {
                  const { value } = e.target;
                  const re = /^[0-9\b]+$/;
                  if (value === "" || re.test(value)) {
                    this.handleInputChange(bet.id, value);
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div className="bookmarks-list">
          <Grid container className="box main-header text-left">
            <Grid item sm={4} xs={3}></Grid>
            <Grid item sm={2} xs={2} className="title-odds">
              Odds
            </Grid>
            {stakes && stakes[bet.id] ? (
              <>
                <Grid item sm={6} xs={6} className="text-right potential-win">
                  Potential Winnings
                </Grid>
              </>
            ) : (
              <Grid item sm={6} xs={6} className="text-right"></Grid>
            )}
          </Grid>
          <Grid container className="box text-left header">
            <Grid item sm={3} xs={3}>
              Bookmaker
            </Grid>
            <Grid item sm={9} xs={8}>
              <Grid container className="bookmark-item-values" spacing={0}>
                <Grid item sm={3} xs={3}>
                  <span onClick={() => this.toggleSorting("wins")}>
                    Win
                    <span>
                      <ImportExportIcon />
                    </span>
                  </span>
                </Grid>
                <Grid item sm={3} xs={3} className="place-odds">
                  <span onClick={() => this.toggleSorting("place")}>
                    Place
                    <span>
                      <ImportExportIcon />
                    </span>
                  </span>
                </Grid>
                {stakes && stakes[bet.id] ? (
                  <>
                    <Grid item sm={2} xs={3} className="win-stake">
                      Win
                    </Grid>
                    <Grid item sm={2} xs={3} className="place-stake">
                      Place
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {filtered_bookmarks.map((bookmark, index) => (
            <>{this.showBookmarkRow(bookmark, bet)}</>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { bets } = this.props;
    return <>{bets.map((bet, index) => this.renderBetContent(bet, index))}</>;
  }
}

export default RacingBetsSidebar;
