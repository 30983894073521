import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  FormControl,
  Input,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import "./newsletterform.scss";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";

class BannerSignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      terms: false,
      age: false,
      errors: {},
    };
  }
  submit = () => {
    let {
      email,
      firstname,
      lastname,
      errors,
      password,
      terms,
      age,
    } = this.state;
    errors.email = "";
    errors.firstname = "";
    errors.lastname = "";
    errors.password = "";
    errors.terms = "";
    errors.age = "";

    var validflag = true;
    if (email === "") {
      errors.email = "Email is required";
      validflag = false;
    } else {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regexp.test(email)) {
        errors.email = "Invalid Email";
        validflag = false;
      }
    }
    if (firstname === "") {
      errors.firstname = "Firstname is required";
      validflag = false;
    }
    if (lastname === "") {
      errors.lastname = "Lastname is required";
      validflag = false;
    }
    if (terms === false) {
      errors.terms = "Terms is required";
      validflag = false;
    }
    if (age === false) {
      errors.age = "Confirm your age is required";
      validflag = false;
    }
    if (password === "") {
      errors.password = "Password is required";
      validflag = false;
    } else if (password.length < 6) {
      errors.password = "Password Must be 6 character";
      validflag = false;
    } else {
      errors.password = "";
    }
    this.setState((prevState) => {
      return { errors: { ...prevState.errors, errors } };
    });

    if (validflag) {
      let data = {
        firstName: firstname,
        lastName: lastname,
        fullname: firstname + " " + lastname,
        username: email,
        password: password,
        location: "",
      };

      axiosInstance
        .post(URLS.register, data)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false });
            this.props.history.push("/login");
          }
        })

        .catch((err) => {
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return {
                errors: {
                  ...prevState.errors,
                  firstname: err.response.data.message,
                },
              };
            });
          } else if (
            err.hasOwnProperty("response") &&
            err.response.data.message === "Username Already Register"
          ) {
            this.setState((prevState) => {
              return {
                errors: {
                  ...prevState.errors,
                  email: err.response.data.message,
                },
              };
            });
          } else {
            this.setState((prevState) => {
              return {
                errors: { ...prevState.errors, firstname: "Invalid Login" },
              };
            });
          }
        });
    }
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, errors: { ...prevState.errors, [name]: "" } };
    });
  };
  handleChackbox = (e) => {
    var name = e.target.name;
    let isSelected = e.currentTarget.checked;
    this.setState((prevState) => {
      return {
        [name]: isSelected,
        errors: { ...prevState.errors, [name]: "" },
      };
    });
  };
  render() {
    let { errors } = this.state;
    return (
      <>
        <form className="newsletter-form" noValidate autoComplete="off">
          <Grid container spacing={3} justify="flex-end" alignItems="flex-end">
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="First Name">
                  First Name
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.firstname}
                  id="firstname"
                  name="firstname"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.firstname && (
                  <span className="error">{errors.firstname}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="Last Name">
                  Last Name
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.lastname}
                  id="lastname"
                  name="lastname"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.lastname && (
                  <span className="error">{errors.lastname}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="Email Address">
                  Email Address
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  value={this.state.email}
                  id="email"
                  name="email"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <InputLabel shrink htmlFor="Password">
                  Password
                </InputLabel>
                <Input
                  className={" rounded-input"}
                  inputProps={{}}
                  type="password"
                  value={this.state.password}
                  id="password"
                  name="password"
                  disableUnderline
                  onChange={this.handleChange}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      required
                      color="primary"
                      name="terms"
                      icon={<div className="checkboxIcon"></div>}
                      onChange={this.handleChackbox}
                      checkedIcon={
                        <div className="checkboxIcon checkboxIcon-checked"></div>
                      }
                    />
                  }
                  label={
                    <>
                      {" "}
                      I have read and accept the{" "}
                      <Link to="/terms" className="underline">
                        Terms & Conditions
                      </Link>
                    </>
                  }
                  labelPlacement="end"
                />
                {errors.terms && <span className="error">{errors.terms}</span>}
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12} className="text-left">
              <FormControl>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      required
                      color="primary"
                      name="age"
                      icon={<div className="checkboxIcon"></div>}
                      onChange={this.handleChackbox}
                      checkedIcon={
                        <div className="checkboxIcon checkboxIcon-checked"></div>
                      }
                    />
                  }
                  label="I am 18 years old or over"
                  labelPlacement="end"
                />
                {errors.age && <span className="error">{errors.age}</span>}
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12} className="text-left">
              <Button
                onClick={this.submit.bind(this)}
                className="submit-btn"
                variant="contained"
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default BannerSignupForm;
