import React, { PureComponent } from "react";
import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import StorageService from "../../services/storage";
import { STORAGE_ENGINES } from "../../helpers/constants";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants/UrlConstants";
import LogoLists from "../logolists/logolists";
import { config } from "../../helpers/config";
import "./sidebarlogos.scss";

import advt_banner_right_side from "../../images/advertise_banners/09-16-Neds-Level-banner-300x600.jpg";

import LogoImagePayup from "../../images/bookmarks_logo/playup-transparent.png";
import ladbrokes from "../../images/bookmarks_logo/ladbrokes.png";
import neds from "../../images/bookmarks_logo/neds.png";
import VICBet from "../../images/bookmarks_logo/VICBet.PNG";

class SidebarLogos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      logoList: [],
      bottomRightAd: null,
      homebottomrightAdsItem: [],
      logoList_temp: [
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "url", content: LogoImagePayup },
        { type: "string", content: "Logo" },
      ],
    };
    this.sessionStorage = new StorageService(STORAGE_ENGINES.SESSION);
  }

  // setAd() {
  //   const bottomRightAd = this.props.ads.find(
  //     (ad) => ad.position === "bottom-right-banner"
  //   );

  //   this.setState({ bottomRightAd });

  //   if (bottomRightAd) {
  //     //this.increaseAdLoads(bottomRightAd.id);
  //   }
  // }

  componentDidMount() {
    // this.setAd();
    this.setAdvtBanners();
  }

  componentDidUpdate(prevProps) {
    // if (this.props.ads !== prevProps.ads) {
    //   this.setAd();
    // }
  }

  setAdvtBanners = () => {
    const { homebottomrightAds } = this.props;
    const { homebottomrightAdsItem } = this.state;
    let id = 0,
      activehomebottomrightAds = this.sessionStorage.get(
        "activehomebottomrightAds"
      );

    let arrayTemp = [];
    let id_array = [];
    for (let i = 1; i <= 6; i++) {
      let id = Math.floor(Math.random() * homebottomrightAds.length);
      if (!id_array.includes(id) && id_array.length < 3) {
        id_array.push(id);
      }
    }
    id_array.map((element) => {
      arrayTemp.push(homebottomrightAds[element]);
    });

    // if (homebottomrightAds.length > 1) {
    //   while (id === Number(activehomebottomrightAds)) {
    //     id = Math.floor(Math.random() * homebottomrightAds.length);
    //   }
    // }
    this.sessionStorage.set("activehomebottomrightAds", String(id));

    this.setState({
      homebottomrightAdsItem: arrayTemp,
    });
  };

  async increaseAdLoads(id) {
    const { status, data } = await axiosInstance.post(URLS.ads + "/load", {
      ids: [id],
    });
    if (status === 200) {
      console.log(data);
    }
  }

  handleAdClick = (item) => async () => {
    await axiosInstance.get(URLS.ads + "/click/" + item.id);

    window.open(item.url, "_blank");
  };

  render() {
    const { homebottomrightAdsItem } = this.state;
    return (
      <>
        {typeof this.state.logoList !== "undefined" &&
        this.state.logoList.length > 0 ? (
          <Card className='sidebar-card' variant='outlined'>
            <CardHeader title='Our Affiliate Banners' />
            <CardContent>
              <LogoLists logos={this.state.logoList} />
            </CardContent>
          </Card>
        ) : (
          <>
            <Grid container>
              {homebottomrightAdsItem?.length > 0 ? (
                homebottomrightAdsItem?.map((obj, i) => (
                  <Grid item xs={12}>
                    <a
                      href={obj?.url}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img className='ad-img' src={obj?.image} alt='' />
                    </a>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <a
                    href='https://record.nedsaffiliates.com.au/_dE8b100VOW_BkgpoV0de5mNd7ZgqdRLk/1/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ cursor: "pointer" }}
                  >
                    <img src={advt_banner_right_side} alt='' />
                  </a>
                </Grid>
              )}
            </Grid>
          </>
          // <span
          //   onClick={this.handleAdClick(bottomRightAd)}
          //   style={{ cursor: "pointer" }}>
          //   <img
          //     src={
          //       bottomRightAd
          //         ? config.baseUrl + "/" + bottomRightAd.filePath
          //         : advt_banner_right_side
          //     }
          //     alt="add logo here"
          //   />
          // </span>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ adsReducer }) => {
  return {
    ads: adsReducer.allAds,
    homebottomrightAds: adsReducer?.homebottomrightAds,
  };
};

export default connect(mapStateToProps)(SidebarLogos);
