import React, { Component } from "react";
import { Typography, Input, Grid, InputLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import moment from "moment";
import { ReactSVG } from "react-svg";

import LogoImagePayup from "../../images/bookmarks_logo/playup-transparent.png";
import playup from "../../images/bookmarks_logo/playup-transparent.png";
import ladbrokes from "../../images/bookmarks_logo/ladbrokes.png";
import neds from "../../images/bookmarks_logo/neds.jpg";
import VICBet from "../../images/bookmarks_logo/VICBet.PNG";
import bookmaker from "../../images/bookmarks_logo/bookmaker.jpg";
import bet365 from "../../images/bookmarks_logo/bet365.jpeg";
import betstar from "../../images/bookmarks_logo/betstar.jpg";

import "./styles.scss";

class BetContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bets: [],
      stakes: null,
      bookmarks: [
        { id: 1, logo: playup, wins: 16, places: 4.4 , enabled: true},
        { id: 2, logo: ladbrokes, wins: 16, places: 2.4 , enabled: false},
        { id: 3, logo: neds, wins: 16, places: 2.0, enabled: false },
        { id: 4, logo: VICBet, wins: 16, places: 2.0, enabled: false },
        { id: 5, logo: bookmaker, wins: 16, places: 2.0, enabled: false },
        { id: 6, logo: bet365, wins: 16, places: 2.0, enabled: false },
        { id: 7, logo: betstar, wins: 16, places: 2.0, enabled: false },
      ],
    };
  }

  handleInputChange = (id) => (event) => {
    const { value } = event.target;
    this.setState({
      stakes: {
        ...this.state.stakes,
        [id]: value,
      },
    });
  };
  

  handleAllBetChange = (event) => {
    const { value } = event.target;
    const stakes = {};

    this.state.bets.forEach((item) => {
      stakes[item.id] = value;
    });

    this.setState({ stakes });
  };

  renderBetHeaders = (bet, index) => {
    return (
      <>
        {/* <DeleteIcon
          className="delete-icon"
          onClick={this.props.handleDelete(index)}
        /> */}
        <Typography fontSize={15} style={{ margin: 0 }} variant="body1">
          {bet.leagueName}
        </Typography>
        <Typography fontSize={15} style={{ margin: 0 }}>
          {bet.teams}
        </Typography>
        <Typography
          fontSize={15}
          style={{ margin: "10px 0 0 0" }}
          className="text-left">
          {moment(bet.OutcomeAt).format("DD MMM YYYY hh:mm:ss")}
        </Typography>
      </>
    );
  };

  renderMarketTitle = (bet) => {
    const { ShowWinsOnly } = this.props;
    if (!bet?.showLineCol || (ShowWinsOnly && ShowWinsOnly === true)) {
      return (
        <Typography fontSize={12} style={{ margin: 0 }} className="text-left">
          {bet.Market}
        </Typography>
      );
    } else {
      return (
        bet?.showLineCol && <Typography fontSize={12} style={{ margin: 0 }} className="text-left">
          Line{" "}
          {bet?.ShowTeam === "TeamB"
            ? bet?.itemData?.BLine
            : bet?.itemData?.ALine}
        </Typography>
      );
    }
  };

  renderBookmarkGrid = (bet, bookmark, index) => {
    const { stakes } = this.state;
    const { ShowWinsOnly } = this.props;
    if (!bet?.showLineCol || (ShowWinsOnly && ShowWinsOnly === true)) {
      return (<>
        <div className="box bookmark-item">
          <div className="bookmark-logo"> {bookmark.logo !== "" ? (
              <img src={bookmark.logo} alt="" />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </div>
          <div className="bookmark-item-values-wrap text-left">
            <div className="bookmark-item-values">
              <div className="innerbox">
                <div className="biv-item">
                  {bookmark.enabled === true && <>
                  {Number(bet?.Win) >= 0
                    ? Number(bet?.Win).toFixed(2)
                    : bookmark.wins.toFixed(2)} </>}
                </div>
                <div className="biv-item"></div>
              </div>
              {bookmark.enabled === true && <>{stakes && stakes[bet.id] ? (
                <div className="innerbox">
                  <div className="biv-item">
                    {Number(bet?.Win) >= 0
                      ? (bet?.Win * stakes[bet.id]).toFixed(2)
                      : (bookmark.wins * stakes[bet.id]).toFixed(2)}
                  </div>
                  <div className="biv-item"></div>
                </div>
              ) : (
                <div className="innerbox">
                  <div className="biv-item"></div>
                  <div className="biv-item"></div>
                </div>
              )}</>}
            </div>
          </div>
        </div>
        {/* <Grid
          container
          spacing={0}
          className="box bookmark-item "
          alignItems="center">
          <Grid item sm={4} xs={4}>
            {bookmark.logo !== "" ? (
              <img src={bookmark.logo} alt="" />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </Grid>
          <Grid item sm={8} xs={8} className="text-left">
            <Grid container className="bookmark-item-values"  alignItems="stretch" spacing={1}>
              <Grid item sm={3} xs={3}>
              {bookmark.enabled === true && <>
                {bet?.Win
                  ? Number(bet?.Win).toFixed(2)
                  : bookmark.wins.toFixed(2)} </>}
              </Grid>
              <Grid item sm={3} xs={3}></Grid>
              {bookmark.enabled === true && <>{stakes && stakes[bet.id] ? (
                <>
                  <Grid item sm={3} xs={3}>
                    {bet?.Win
                      ? (bet?.Win * stakes[bet.id]).toFixed(2)
                      : (bookmark.wins * stakes[bet.id]).toFixed(2)}
                  </Grid>
                  <Grid item sm={3} xs={3}></Grid>
                </>
              ) : (
                <>
                  <Grid item sm={3} xs={3}></Grid>
                  <Grid item sm={3} xs={3}></Grid>
                </>
              )}</>}
            </Grid>
          </Grid>
        </Grid> */}
      </>);
    } else {
      let headtohead =
        bet?.ShowTeam === "TeamB" ? bet?.itemData?.BWin : bet?.itemData?.AWin;
      let line =
        bet?.ShowTeam === "TeamB"
          ? bet?.itemData?.BLineDiv
          : bet?.itemData?.ALineDiv;
      return (<>
      <div className="box bookmark-item">
          <div className="bookmark-logo"> 
            {bookmark.logo !== "" ? (
              <img src={bookmark.logo} alt="" />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </div>
          <div className="bookmark-item-values-wrap text-left">
            <div className="bookmark-item-values">
              <div className="innerbox">
                <div className="biv-item">
                {bookmark.enabled === true && <>{headtohead
                  ? Number(headtohead).toFixed(2)
                  : bookmark.wins.toFixed(2)}</>}
                </div>
                <div className="biv-item">
                  {bookmark.enabled === true && <>{line ? Number(line).toFixed(2) : bookmark.places.toFixed(2)}</>}
                </div>
              </div>
              {bookmark.enabled === true && <>{stakes && stakes[bet.id] ? (
                <div className="innerbox">
                  <div className="biv-item">
                  {bookmark.enabled === true &&<>{headtohead
                      ? (Number(headtohead) * stakes[bet.id]).toFixed(2)
                      : (Number(bookmark.wins) * stakes[bet.id]).toFixed(2)}</>}
                  </div>
                  <div className="biv-item">
                  {bookmark.enabled === true &&<>{line
                      ? (line * stakes[bet.id]).toFixed(2)
                      : (bookmark.places * stakes[bet.id]).toFixed(2)}</>}
                  </div>
                </div>
              ) : (
                <div className="innerbox">
                  <div className="biv-item"></div>
                  <div className="biv-item"></div>
                </div>
              )}</>}
            </div>
          </div>
        </div>
        {/* <Grid
          container
          spacing={0}
          className="box bookmark-item"
          alignItems="stretch">
          <Grid item sm={4} xs={4}>
            {bookmark.logo !== "" ? (
              <img src={bookmark.logo} alt="" />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </Grid>
          <Grid item sm={8} xs={8} className=" text-left">
            <Grid container className="bookmark-item-values"  alignItems="stretch" spacing={1}>
              <Grid item sm={3} xs={3}>
              {bookmark.enabled === true && <>{headtohead
                  ? Number(headtohead).toFixed(2)
                  : bookmark.wins.toFixed(2)}</>}
              </Grid>
              <Grid item sm={3} xs={3}>
                {bookmark.enabled === true && <>{line ? Number(line).toFixed(2) : bookmark.places.toFixed(2)}</>}
              </Grid>
              {stakes && stakes[bet.id] ? (
                <>
                  <Grid item sm={3} xs={3}>
                  {bookmark.enabled === true &&<>{headtohead
                      ? (Number(headtohead) * stakes[bet.id]).toFixed(2)
                      : (Number(bookmark.wins) * stakes[bet.id]).toFixed(2)}</>}
                  </Grid>
                  <Grid item sm={3} xs={3}>
                  {bookmark.enabled === true &&<>{line
                      ? (line * stakes[bet.id]).toFixed(2)
                      : (bookmark.places * stakes[bet.id]).toFixed(2)}</>}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sm={3} xs={3}></Grid>
                  <Grid item sm={3} xs={3}></Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid> */}
      </>);
    }
  };

  renderStackInputGrid = (bet) => {
    const { stakes } = this.state;
    return (
      <>
        <Grid
          container
          spacing={1}
          className="bet-row-item box text-left"
          alignItem="center">
          <Grid item lg={6} md={7} sm={7} xs={7}>
            <ReactSVG className="horse_img" src={bet.image} />
          </Grid>
          <Grid item lg={6} md={5} sm={5} xs={5}>
            <div className="stackinput full-width">
              <InputLabel htmlFor="stake">Stake $</InputLabel>
              <Input
                inputProps={{ "aria-label": "stake" }}
                id="stake"
                name="stake"
                type="number"
                value={stakes && stakes[bet.id] && stakes[bet.id]}
                onChange={this.handleInputChange(bet.id)}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { stakes, bookmarks } = this.state;
    const { bet, index, ShowWinsOnly } = this.props;

    return ShowWinsOnly && ShowWinsOnly === true ? (
      <div className="bets_bookmark_content">
        <Grid container spacing={2} key={0} className="box text-left">
          <Grid item xs={12} style={{ position: "relative" }}>
            {this.renderBetHeaders(bet, index)}

            {this.renderMarketTitle(bet)}

            <div className="team-name">
              <span>
                <SportsFootballIcon />
              </span>{" "}
              {bet.Team}
            </div>
          </Grid>
        </Grid>
        {this.renderStackInputGrid(bet)}
        <div className="bookmarks-list">
          <Grid container className="box main-header text-left">
            <Grid item sm={4} xs={4}></Grid>
            <Grid item sm={2} xs={2}>
              Odds
            </Grid>
            {stakes && stakes[bet.id] ? (
              <>
                <Grid item sm={6} xs={6} className="text-right">
                  Potential Winnings
                </Grid>
              </>
            ) : (
              <Grid item sm={6} xs={6} className="text-right"></Grid>
            )}
          </Grid>
          <Grid container className="box text-left header">
            <Grid item sm={4} xs={4}>
              Bookmark
            </Grid>
            <Grid item sm={8} xs={8}>
              <Grid container className="bookmark-item-values" spacing={2}>
                <Grid item sm={3} xs={3}>
                  Win
                </Grid>
                <Grid item sm={3} xs={3}></Grid>
                {stakes && stakes[bet.id] ? (
                  <>
                    <Grid item sm={3} xs={3}>
                      Win
                    </Grid>
                    <Grid item sm={3} xs={3}></Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {bookmarks.map((bookmark, index) =>
            this.renderBookmarkGrid(bet, bookmark, index)
          )}
        </div>
      </div>
    ) : (
      <>
        <div className="bets_bookmark_content">
          <Grid container spacing={2} key={0} className="box text-left">
            <Grid item xs={12} style={{ position: "relative" }}>
              {this.renderBetHeaders(bet, index)}

              {this.renderMarketTitle(bet)}

              <div className="team-name">
                <span>
                  <SportsFootballIcon />
                </span>{" "}
                {bet.Team}
              </div>
            </Grid>
          </Grid>
          {this.renderStackInputGrid(bet)}
          <div className="bookmarks-list">
            <Grid container className="box main-header text-left">
              <Grid item sm={4} xs={4}></Grid>
              <Grid item sm={2} xs={2}>
                Odds
              </Grid>
              {stakes && stakes[bet.id] ? (
                <>
                  <Grid item sm={6} xs={6} className="text-right">
                    Potential Winnings
                  </Grid>
                </>
              ) : (
                <Grid item sm={6} xs={6} className="text-right"></Grid>
              )}
            </Grid>
            <Grid container className="box text-left header">
              <Grid item sm={4} xs={4}>
                Bookmark
              </Grid>
              <Grid item sm={8} xs={8}>
                <Grid container className="bookmark-item-values" spacing={2}>
                  <Grid item sm={3} xs={3}>
                    <span className="headtoheadtitle">{"H <> H"}</span>
                  </Grid>
                  <Grid item sm={3} xs={3}>
                    Line
                  </Grid>
                  {stakes && stakes[bet.id] ? (
                    <>
                      <Grid item sm={3} xs={3}>
                        <span className="headtoheadtitle">{"H <> H"}</span>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        Line
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            {bookmarks.map((bookmark, index) =>
              this.renderBookmarkGrid(bet, bookmark, index)
            )}
          </div>
        </div>
      </>
    );
  }
}

export default BetContent;
