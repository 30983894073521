import React, { Component } from "react";

import { Redirect, withRouter } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { AppBar, Tabs, Tab, Toolbar } from "@material-ui/core";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants/UrlConstants";
import { CATEGORY_MENU_STATUS } from "../../helpers/constants";
import { getRoute } from "../../helpers/common";

import "./sportlist.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import hourse_img_small from "../../images/hourse_img_small.svg";
import greys from "../../images/sport_icons/Greyhound.svg";
import darts from "../../images/sport_icons/darts.svg";
import esports from "../../images/sport_icons/esports.svg";
import harnes from "../../images/sport_icons/Harness.svg";
import rugby from "../../images/sport_icons/rugbyleague.svg";

import icehockey from "../../images/sport_icons/icehockey.svg";
import mma from "../../images/sport_icons/mma.svg";
import soccer from "../../images/sport_icons/soccer.svg";
import tennis from "../../images/sport_icons/tennis.svg";
import volleyball from "../../images/sport_icons/volleyball.svg";
import baseball from "../../images/sport_icons/baseball.svg";
import basketball from "../../images/sport_icons/basketball.svg";
import afl from "../../images/sport_icons/americanFootBall.svg";
import boxing from "../../images/menu_icons/Boxing.svg";
import cricket from "../../images/menu_icons/Cricket.svg";
import { indexOf } from "lodash";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const icons = [
  { name: "Horse Racing", icon: hourse_img_small },
  { name: "Harness Racing", icon: harnes },
  { name: "Greyhound Racing", icon: greys },
  { name: "Cricket", icon: cricket },
  { name: "Mixed Martial Arts", icon: mma },
  { name: "Boxing", icon: boxing },
  { name: "Tennis", icon: tennis },
  { name: "Soccer", icon: soccer },
  { name: "Baseball", icon: baseball },
  { name: "Basketball", icon: basketball },
  { name: "Australian Rules", icon: rugby },
  { name: "Rugby League", icon: rugby },
];

class SportsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectto: "",
      selectedSport: 1,
      sportName: "",
      defaultSport: 7,
      sliderSports: [],
      allSportsType: [],
      // sliderItems: [
      //   {
      //     id: 1,
      //     title: "Horses",
      //     icon: <ReactSVG src={hourse_img_small} />,
      //     redirectto: "",
      //     enabled: true,
      //     slug: "Horse Racing",
      //   },
      //   {
      //     id: 2,
      //     title: "Harness",
      //     icon: <ReactSVG src={harnes} />,
      //     enabled: true,
      //     redirectto: "",
      //     slug: "Harness Racing",
      //   },
      //   {
      //     id: 3,
      //     title: "Greys",
      //     icon: <ReactSVG src={greys} />,
      //     slug: "Greyhound Racing",
      //     enabled: true,
      //     redirectto: "",
      //   },
      //   {
      //     id: 4,
      //     title: "Rugby League",
      //     icon: <ReactSVG src={rugby} />,
      //     enabled: false,
      //     redirectto: "/sports/rugby",
      //     slug: "Rugby League",
      //   },
      //   {
      //     id: 5,
      //     title: "Australian Rules",
      //     icon: <ReactSVG src={afl} />,
      //     enabled: false,
      //     redirectto: "/sports/australian",
      //     slug: "Australian Rules",
      //   },
      //   {
      //     id: 6,
      //     title: "ESports",
      //     icon: <ReactSVG src={esports} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 7,
      //     title: "Darts",
      //     icon: <ReactSVG src={darts} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 8,
      //     title: "Ice Hockey",
      //     icon: <ReactSVG src={icehockey} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 9,
      //     title: "MMA",
      //     icon: <ReactSVG src={mma} />,
      //     enabled: true,
      //     slug: "Mixed Martial Arts",
      //   },
      //   {
      //     id: 10,
      //     title: "Soccer",
      //     icon: <ReactSVG src={soccer} />,
      //     enabled: true,
      //     slug: "Soccer",
      //   },
      //   {
      //     id: 11,
      //     title: "Tennis",
      //     icon: <ReactSVG src={tennis} />,
      //     enabled: true,
      //     slug: "Tennis",
      //   },
      //   {
      //     id: 12,
      //     title: "Volleyball",
      //     icon: <ReactSVG src={volleyball} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 13,
      //     title: "Basketball",
      //     icon: <ReactSVG src={basketball} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 14,
      //     title: "Baseball",
      //     icon: <ReactSVG src={baseball} />,
      //     enabled: false,
      //   },
      //   {
      //     id: 15,
      //     title: "Boxing",
      //     icon: <ReactSVG src={boxing} />,
      //     enabled: true,
      //     slug: "Boxing",
      //   },
      //   {
      //     id: 16,
      //     title: "Cricket",
      //     icon: <ReactSVG src={cricket} />,
      //     enabled: true,
      //     slug: "Cricket",
      //   },
      // ],
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { sliderSports } = this.state;
    await this.fetchAllSports();
    await this.fetchAllSportsType();

    let sliderSportsSort = this.state.sliderSports.sort(function (a, b) {
      if (a.sportName < b.sportName) {
        return -1;
      }
      if (a.sportName > b.sportName) {
        return 1;
      }
      return 0;
    });
    this.setState({ sliderSports: sliderSportsSort });

    if (match.params?.sportName && match.params?.sportName !== "") {
      this.setState({ sportName: match.params?.sportName });

      sliderSportsSort.map((element, index) => {
        if (element.sportName === match.params?.sportName) {
          this.setState({
            defaultSport: index,
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { sliderSports } = this.state;
    if (
      match.params?.sportName &&
      match.params?.sportName !== prevProps?.match.params?.sportName
    ) {
      this.setState({ sportName: match.params?.sportName });

      sliderSports.map((element, index) => {
        if (element.sportName === match.params?.sportName) {
          this.setState({
            defaultSport: index,
          });
        }
      });
    }
  }

  async fetchAllSports() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sports);
      if (status === 200) {
        this.setState({ sliderSports: data.result });
      }
    } catch (err) {}
  }

  async fetchAllSportsType() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sportType);
      if (status === 200) {
        this.setState({ allSportsType: data.result });
      }
    } catch (err) {}
  }

  getSportType = (id) => {
    let { allSportsType } = this.state;
    let type_to_return = "";
    allSportsType.map((element) => {
      if (element.id == id) {
        type_to_return = element.sportType;
      }
    });
    return type_to_return;
  };

  handleChange = (event, newValue) => {
    let item = this.state.sliderSports[newValue];
    let sportType = this.getSportType(item?.sportTypeId);
    let redirectlink = getRoute(item.sportName, item.id, sportType);
    this.setState({
      redirectto: redirectlink,
      defaultSport: newValue,
    });
  };

  handleChangeSportTab = (event, selectedSport, Races) => {
    let NewValue = -1;
    let data_length = Races.length;
    if (event === "prev") {
      NewValue = selectedSport === 0 ? data_length - 1 : selectedSport - 1;
    } else if (event === "next") {
      NewValue = selectedSport === data_length - 1 ? 0 : selectedSport + 1;
    }
    this.handleChange(event, NewValue);
  };

  getIcon = (name) => {
    let icon = icons.filter((obj) => obj.name === name);
    return <ReactSVG src={icon?.length > 0 && icon[0].icon} />;
  };
  render() {
    const {
      redirectto,
      //sliderItems,
      sliderSports,
      defaultSport,
    } = this.state;
    return (
      <>
        {redirectto !== "" && <Redirect to={redirectto} />}
        <div className="sportlist-wrap sportslist-slider-main">
          {/* <AppBar position="static" color="default">
            <Toolbar>
              <Tabs
                value={selectedSport}
                //onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                {sliderItems.map((item, i) => (
                  <Tab
                    key={i}
                    value={item.id}
                    label={item.title}
                    icon={item.icon}
                    className={
                      "sportMenuItem " +
                      (item?.enabled === true ? "active" : "disabled")
                    }
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
              <div className="race-tab-button">
                <button
                // onClick={() =>
                //   this.handleChangeSportTab(
                //     "prev",
                //     selectedSport,
                //     sliderItems
                //   )
                // }
                >
                  <FontAwesomeIcon color="white" icon={faChevronLeft} />
                </button>
                <button
                // onClick={() =>
                //   this.handleChangeSportTab(
                //     "next",
                //     selectedSport,
                //     sliderItems
                //   )
                // }
                >
                  <FontAwesomeIcon color="white" icon={faChevronRight} />
                </button>
              </div>
            </Toolbar>
          </AppBar> */}

          {/* for 2.0 */}
          <AppBar position="static" color="default">
            <Toolbar>
              <Tabs
                value={defaultSport}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                textColor="primary"
                aria-label="scrollable force tabs example"
              >
                {sliderSports.map((item, i) => (
                  <Tab
                    key={i}
                    value={i}
                    label={item.sportName}
                    icon={this.getIcon(item.sportName)}
                    className={"sportMenuItem "}
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
              <div className="race-tab-button">
                <button
                  onClick={() =>
                    this.handleChangeSportTab(
                      "prev",
                      defaultSport,
                      sliderSports
                    )
                  }
                >
                  <FontAwesomeIcon color="white" icon={faChevronLeft} />
                </button>
                <button
                  onClick={() =>
                    this.handleChangeSportTab(
                      "next",
                      defaultSport,
                      sliderSports
                    )
                  }
                >
                  <FontAwesomeIcon color="white" icon={faChevronRight} />
                </button>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </>
    );
  }
}

export default withRouter(SportsMenu);
