import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Loader } from "../../../library/common/components";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import { URLS } from "../../../library/common/constants";
import axiosInstance from "../../../helpers/Axios";
import moment from "moment";

class RunnersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runnersDetails: [],
      isLoading: false,
      currentPage: 1,
      rowPerPage: 20,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.fetchAllRunners(id);
  }

  fetchAllRunners = async (id) => {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get(`/events/runner/${id}`);
    if (status === 200) {
      this.setState({ runnersDetails: data.result, isLoading: false });
    }
  };

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.match !== this.props.match) {
  //       this.fetchAllSports();
  //     }
  //   }

  //   onSubmit = async () => {
  //     let { sportId, selectedDate } = this.state;
  //     let params = {
  //       sportId: sportId,
  //       //   date: moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       //   endDate: moment(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  //       date: "2021-04-09T00:00:00.000Z",
  //       endDate: "2021-04-10T00:00:00.000Z",
  //     };
  //     const { status, data } = await axiosInstance.post("private/races", params);
  //     if (status === 200) {
  //       this.setState({ runnersDetails: data.respose });
  //     }
  //   };

  //   getSportId = () => {
  //     let { sports } = this.state;
  //     let sportName = this.props.match.params.name;
  //     let obj = sports
  //       .filter((obj) => obj.sportName == sportName)
  //       .map((object) => object.id);
  //     return obj;
  //   };

  //   handleDateChange = (date) => {
  //     this.setState({ selectedDate: date });
  //   };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, runnersDetails } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < runnersDetails.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  render() {
    let { runnersDetails, isLoading, rowPerPage, currentPage } = this.state;
    const pageNumbers = [];
    let currentPageRow = runnersDetails;

    if (runnersDetails?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = runnersDetails.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(runnersDetails.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <>
        <Grid container className='page-content adminLogin'>
          <Grid item xs={12}>
            <Paper className='pageWrapper'>
              <Grid container direction='row' alignItems='space-around'>
                <Grid item xs={8}>
                  <h3 className='text-left'>Runners Details</h3>
                </Grid>
              </Grid>
              {isLoading && <Loader />}
              {!isLoading && runnersDetails.length === 0 && (
                <p>No Data Available</p>
              )}
              {!isLoading && runnersDetails.length > 0 && (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      className='listTable api-provider-listTable'
                      aria-label='simple table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>DID</TableCell>
                          <TableCell>Animal Name</TableCell>
                          <TableCell>Jockey Weight</TableCell>
                          <TableCell>Player Name</TableCell>
                          <TableCell>Player Weight</TableCell>
                          <TableCell>Barrier Number</TableCell>
                          <TableCell>Runner Number</TableCell>
                          {/* <TableCell>Weather</TableCell> */}
                          {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageRow?.map((runnersDetails, i) => (
                          <TableRow key={i}>
                            <TableCell>{runnersDetails.id}</TableCell>
                            <TableCell>{runnersDetails.animal.name}</TableCell>
                            <TableCell>{runnersDetails.JockeyWeight}</TableCell>
                            <TableCell>
                              {runnersDetails.players.length > 0
                                ? runnersDetails.players[0].name
                                : ""}
                            </TableCell>
                            <TableCell>
                              {runnersDetails.players.length > 0
                                ? runnersDetails.players[0].weight
                                : ""}
                            </TableCell>
                            <TableCell>
                              {runnersDetails.barrierNumber}
                            </TableCell>
                            <TableCell>{runnersDetails.runnerNumber}</TableCell>
                            {/* <TableCell>
                              <EditIcon
                                onClick={() =>
                                  this.navigateUrl(runnersDetails.race.id)
                                }
                                color='primary'
                                className='mr10 cursor iconBtn admin-btn-green'
                              />
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='tablePagination'>
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("prev")}
                    >
                      <ReactSVG src={arrowLeft} />
                    </button>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      page={currentPage}
                      onChange={this.handlePaginationClick}
                      count={pageNumbers[pageNumbers?.length - 1]}
                      siblingCount={2}
                      boundaryCount={1}
                      size='small'
                    />
                    <button
                      className={
                        runnersDetails.length / rowPerPage > 1
                          ? "btn-navigation"
                          : "btn-navigation-disabled"
                      }
                      disabled={
                        runnersDetails.length / rowPerPage > 1 ? false : true
                      }
                      onClick={() => this.handlePaginationButtonClick("next")}
                    >
                      <ReactSVG src={arrowRight} />
                    </button>
                  </div>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default RunnersDetails;
