import React, { Component } from "react";
import { Typography, Container } from "@material-ui/core";
import { EventEmitter } from "../../services/event";
import Loader from "../../library/common/components/Loader";

import axiosInstance from "../../helpers/Axios";
import AppMeta from "../AppMeta";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.fetchPageData();
    EventEmitter.dispatch("updateAppClassList", "blank_header_top_bar");
  }

  componentWillUnmount() {
    EventEmitter.dispatch("removeAppClassList", "blank_header_top_bar");
  }

  async fetchPageData() {
    this.setState({ isLoading: true });
    const { status, data } = await axiosInstance.get("/cms/page/detail/terms");
    if (status === 200) {
      this.setState({ pageData: data.result, isLoading: false });
    }
  }

  renderHeaderMeta = () => {
    const { pageData } = this.state;
    if (pageData && pageData.hasOwnProperty("tags")) {
      pageData.tags.map((tag, index) => (
        <AppMeta title={tag.title} description={tag.description} key={index} />
      ));
    }
  };

  render() {
    const { pageData, isLoading } = this.state;
    let bodyData = pageData?.CMSPageContents?.filter(
      (obj) => obj.placeholder === "Body"
    ).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    return (
      <>
        {this.renderHeaderMeta()}
        <Container>
          <div className='text-left regular-contents terms-main'>
            {isLoading && (
              <div className='text-center'>
                <Loader />
              </div>
            )}
            <Typography
              variant='h6'
              className='cap-title color-green text-center'
            >
              {pageData?.pageTitle}
            </Typography>
            <Typography
              variant='body1'
              dangerouslySetInnerHTML={{
                __html: bodyData && bodyData?.length > 0 && bodyData[0].content,
              }}
            ></Typography>
          </div>
        </Container>
      </>
    );
  }
}

export default Terms;
