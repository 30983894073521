import { Validators } from "../../../library/utilities/Validators";
import { SAVE_STATUS } from "../../../helpers/constants";

export const adPlaceHolderFormModel = [
  {
    label: "AdPlaceHolder Name*",
    value: "",
    type: "text",
    placeholder: "AdPlaceHolder Name",
    field: "placeHolderName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    className: "12",
    options: [
      ...SAVE_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
