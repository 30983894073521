import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SideMenu from "react-sidemenu";
import "react-sidemenu/dist/side-menu.css";
import items from "./menuItems";
import "./sidebarmenu.scss";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

class Sidebar extends Component {
  state = {
    isDropdownOpen: "",
    isSubDropdownOpen: "",
  };
  handleClick = (value) => {
    this.props.history.replace(value);
  };

  handleDropdown = (label) => {
    if (this.state.isDropdownOpen == label) {
      this.setState({ isDropdownOpen: "" });
    } else {
      this.setState({ isDropdownOpen: label });
    }
  };

  handleSubDropdown = (label) => {
    if (this.state.isSubDropdownOpen == label) {
      this.setState({ isSubDropdownOpen: "" });
    } else {
      this.setState({ isSubDropdownOpen: label });
    }
  };

  render() {
    return (
      <div className='sidebar'>
        <ul>
          {items.map((item, index) => {
            var is_expaned = this.state.isDropdownOpen == item.label;
            let currentitem = item.subMenu?.filter(
              (item) => item.value === this.props.location.pathname
            );
            if (currentitem?.length > 0) is_expaned = true;
            if (item.isDropdown) {
              if (item.label === "Sports") {
                return (
                  <>
                    <li
                      button
                      key={index}
                      onClick={() => this.handleDropdown(item.label)}
                      className='menu-main'
                    >
                      <a>
                        {item.label}
                        {is_expaned ? <ExpandLess /> : <ExpandMore />}
                      </a>
                    </li>
                    <Collapse in={is_expaned} timeout='auto' unmountOnExit>
                      <li className='menu-sub'>
                        {item.subMenu.map((menu, i) => (
                          <NavLink key={i} to={menu.value}>
                            {menu.label}
                          </NavLink>
                        ))}
                      </li>
                      <li
                        button
                        className='menu-main'
                        onClick={() => this.handleSubDropdown("Racing")}
                      >
                        <a>
                          Racing{" "}
                          {this.state.isSubDropdownOpen == "Racing" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </a>
                      </li>
                      <Collapse
                        in={this.state.isSubDropdownOpen == "Racing"}
                        timeout='auto'
                        unmountOnExit
                      >
                        <li className='menu-sub'>
                          <NavLink to='/admin/racing/Horse Racing/1'>
                            Horse Racing
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/racing/Harness Racing/2'>
                            Harness Racing
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/racing/Greyhound Racing/3'>
                            Greyhound Racing
                          </NavLink>
                        </li>
                      </Collapse>
                      <li
                        button
                        className='menu-main'
                        onClick={() => this.handleSubDropdown("Team Sport")}
                      >
                        <a>
                          Team Sport{" "}
                          {this.state.isSubDropdownOpen == "Team Sport" ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </a>
                      </li>
                      <Collapse
                        in={this.state.isSubDropdownOpen == "Team Sport"}
                        timeout='auto'
                        unmountOnExit
                      >
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Cricket/4'>Cricket</NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Mixed Martial Arts/5'>
                            Mixed Martial Arts
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Boxing/6'>Boxing</NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Tennis/7'>Tennis</NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Soccer/8'>Soccer</NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Australian Rules/9'>
                            Australian Rules
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Baseball/10'>
                            Baseball
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Basketball/11'>
                            Basketball
                          </NavLink>
                        </li>
                        <li className='menu-sub'>
                          <NavLink to='/admin/sport/Rugby League/12'>
                            Rugby League
                          </NavLink>
                        </li>
                      </Collapse>
                    </Collapse>
                  </>
                );
              } else {
                return (
                  <>
                    <li
                      button
                      key={index}
                      onClick={() => this.handleDropdown(item.label)}
                      className='menu-main'
                    >
                      <a>
                        {item.label}
                        {is_expaned ? <ExpandLess /> : <ExpandMore />}
                      </a>
                    </li>
                    <Collapse in={is_expaned} timeout='auto' unmountOnExit>
                      <li className='menu-sub'>
                        {item.subMenu.map((menu, i) => (
                          <NavLink key={i} to={menu.value}>
                            {menu.label}
                          </NavLink>
                        ))}
                      </li>
                    </Collapse>
                  </>
                );
              }
            } else {
              return (
                <li>
                  <NavLink to={item.value}>{item.label}</NavLink>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(Sidebar);
