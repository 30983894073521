import React from "react";
import {
  Button,
  Collapse,
  Grid,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { EventEmitter } from "../../../../services/event";
import fighter from "./../../../../images/menu_icons/fighter.svg";

import { ReactComponent as Bet365 } from "./../../../../images/bookmarks_logo/small/bet365.svg";
import { ReactComponent as BetStar } from "./../../../../images/bookmarks_logo/small/betstar.svg";
import { ReactComponent as BookMakers } from "./../../../../images/bookmarks_logo/small/bookmaker.svg";
import { ReactComponent as LadBrokes } from "./../../../../images/bookmarks_logo/small/ladbrokes.svg";
import { ReactComponent as Neds } from "./../../../../images/bookmarks_logo/small/neds.svg";
import { ReactComponent as PlayUp } from "./../../../../images/bookmarks_logo/small/playup.svg";
import { ReactComponent as VICBet } from "./../../../../images/bookmarks_logo/small/VICBet.svg";

import ResBetIcon from "./../../../../images/res_bookmaker/BetIcon.svg";
import RNedsIcon from "./../../../../images/res_bookmaker/neds.svg";
import ResBet365Icon from "./../../../../images/res_bookmaker/bet365.svg";
import ResBetStarIcon from "./../../../../images/res_bookmaker/betstar.svg";
import ResRedCIcon from "./../../../../images/res_bookmaker/redC.svg";
import ResBlackBIcon from "./../../../../images/res_bookmaker/blackB.svg";
import ResVICBetIcon from "./../../../../images/res_bookmaker/VICBet.svg";

const oddsArray = [
  { id: 1, name: "Bookmaker Odds" },
  { id: 2, name: "Wining odds" },
];

const winningOddsArray = [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }];

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedodds: "",
      openRaceRow: null,
    };
  }

  // navigateToAllRace = (race) => {
  //   EventEmitter.dispatch("show_all_races", race);
  // };

  componentDidMount = () => {
    this.setState({
      selectedodds: oddsArray[0].name,
    });
  };

  handleOdds = (e) => {
    this.setState({ selectedodds: e.target.value });
  };

  handleRaceRow = (index) => {
    console.log("index : ", index);
    let { openRaceRow } = this.state;
    if (openRaceRow == index) {
      this.setState({ openRaceRow: null });
    } else {
      this.setState({ openRaceRow: index });
    }
  };

  render() {
    let { selectedodds, openRaceRow } = this.state;
    return (
      <>
        <Grid container className="hide-xs">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head mma-odd-select">
                <p className="card-heading mma-head-title mma-single-title">
                  <ReactSVG src={fighter} /> &nbsp;Fighter Name &nbsp;V&nbsp;
                  Fighter Name
                </p>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedodds}
                    className="select-country mma-odd-select"
                    onChange={this.handleOdds}
                  >
                    {oddsArray.map((obj, i) => (
                      <MenuItem key={i} value={obj.name}>
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Divider className="allsports_inner_hr" />

              {selectedodds === "Bookmaker Odds" ? (
                <div className="allsports_boxing_inner mma-compare-inner">
                  <div className="allsport_inner_main mma-line-p4">
                    <div>
                      <p className="mma-title mma-fighterdetails mma-font-400 ">
                        <ReactSVG src={fighter} /> &nbsp;[Fighter Name]
                      </p>
                    </div>
                    <div>
                      <p className="mma-title mma-font-400">
                        Best Odds: [Number]
                      </p>
                    </div>
                    <div className="mma-fighter-compare-btn">
                      <Button variant="contained" onClick={() => {}}>
                        Compare
                      </Button>
                    </div>
                  </div>

                  <div className="allsport_inner_main mma-line-p4">
                    <div>
                      <p className="mma-title mma-fighterdetails mma-font-400 ">
                        <ReactSVG src={fighter} /> &nbsp;[Fighter Name]
                      </p>
                    </div>
                    <div>
                      <p className="mma-title mma-font-400">
                        Best Odds: [Number]
                      </p>
                    </div>
                    <div className="mma-fighter-compare-btn">
                      <Button variant="contained" onClick={() => {}}>
                        Compare
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mma-wining-oddscontainer">
                    <div className="vcap-box">
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Venue</p>
                        <p className="vcap-green">[Venue]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">City</p>
                        <p className="vcap-green">[City]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Attend</p>
                        <p className="vcap-green">[Attend]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Prize</p>
                        <p className="vcap-green">[Amount]</p>
                      </div>
                    </div>

                    <div className="mma-winingodds-table">
                      <TableContainer>
                        <Table
                          className="mma-odds-bigtable"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="wining-fighter-details"></TableCell>
                              <TableCell
                                align="center"
                                className="mma-best-at-open"
                              >
                                Best at Open
                              </TableCell>
                              <TableCell align="center" className="mma-changes">
                                Changes
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-current-best"
                              >
                                Current Best
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <Bet365 className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <BetStar className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <BookMakers className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <LadBrokes className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <Neds className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <PlayUp className="wining-bookmaker" />
                                </div>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="mma-bookmakercell"
                              >
                                <div className="bookmaker-whitebox">
                                  <VICBet className="wining-bookmaker" />
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            component={Paper}
                            className="mma-wining-tbody"
                          >
                            {winningOddsArray.map((item, index) => {
                              return (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <div className="mmaw-container">
                                      <div className="mma-blckIcon">
                                        <ReactSVG src={fighter} />
                                      </div>
                                      <div>
                                        <div>
                                          <p className="wining-fightername">
                                            [Fighter Name]
                                          </p>
                                        </div>
                                        <div className="wining-desc">
                                          <div className="wining-weight-method">
                                            <p className="wining-weight">
                                              [Weight]
                                            </p>
                                            <p>[Method]</p>
                                          </div>
                                          <div className="mma-wining-round">
                                            <p>[Round]</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <p className="best-at-open-content">
                                      [Odd]
                                    </p>
                                  </TableCell>
                                  <TableCell align="center">
                                    <p className="sport-graph-content">
                                      [Graph]
                                    </p>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="winning-best-content"
                                  >
                                    <div className="best-content-icon">
                                      <PlayUp />
                                    </div>
                                    <p>[Odd]</p>
                                  </TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                  <TableCell align="center"></TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container className="hide-sm hide-md hide-lg hide-xl">
          <Grid item xs={12}>
            <div className="allsports_body_outer">
              <div className="allsports_body_head mma-odd-select">
                <p className="card-heading mma-head-title mma-single-title">
                  <ReactSVG src={fighter} /> &nbsp;Fighter Name &nbsp;V&nbsp;
                  Fighter Name
                </p>
              </div>
              <Divider className="allsports_inner_hr" />
              <div className="mma-right-side">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedodds}
                    onChange={this.handleOdds}
                  >
                    {oddsArray.map((obj, i) => (
                      <MenuItem key={i} value={obj.name}>
                        {obj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {selectedodds === "Bookmaker Odds" ? (
                <div className="allsports_boxing_inner">
                  <div className="allsport_inner_heading res-mma mma-flex">
                    <div>
                      <p className="mma-title mma-fighterdetails">
                        <ReactSVG src={fighter} /> &nbsp;[Fighter Name]
                      </p>
                      <p className="mma-title">Best Odds: [Number]</p>
                    </div>
                    <div>
                      <Button variant="contained" className="res-mma-cmpbtn">
                        Compare
                      </Button>
                    </div>
                  </div>
                  <div className="allsport_inner_heading res-mma mma-flex">
                    <div>
                      <p className="mma-title mma-fighterdetails">
                        <ReactSVG src={fighter} /> &nbsp;[Fighter Name]
                      </p>
                      <p className="mma-title">Best Odds: [Number]</p>
                    </div>
                    <div>
                      <Button variant="contained" className="res-mma-cmpbtn">
                        Compare
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Divider className="allsports_inner_hr" />

                  <div className="mma-wining-oddscontainer mma-mt-8">
                    <div className="vcap-box">
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Venue</p>
                        <p className="vcap-green">[Venue]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">City</p>
                        <p className="vcap-green">[City]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Attend</p>
                        <p className="vcap-green">[Attend]</p>
                      </div>
                      <div className="mma-white-boxes">
                        <p className="vcap-black">Prize</p>
                        <p className="vcap-green">[Amount]</p>
                      </div>
                    </div>
                  </div>

                  <div className="res-mma-bao-cng">
                    <div className="res-mma-efrom"></div>
                    <div className="res-mma-bao">Best at Open</div>
                    <div className="res-mma-cng">Changes</div>
                  </div>

                  <div className="rtrack-details-mv">
                    <div className="race-track-data-table-mv">
                      {winningOddsArray.map((race, index) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "0.5px solid #464545",
                                paddingLeft: 4,
                                paddingRight: 4,
                              }}
                            >
                              <div className="rthname res-wods-nb">
                                <div className="rdt-mv-icon">
                                  <p className="mma-title mma-res-fighticon">
                                    <ReactSVG src={fighter} />
                                  </p>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="rth-horsename">
                                      [Fighter Name]
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="rth-weightname">
                                      [Weight]
                                    </span>
                                    <span className="rth-jockeyname">
                                      [Method]
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span className="rth-weightname">
                                      [Round]
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="odds-dropdown"
                                  onClick={() => this.handleRaceRow(index)}
                                  style={{ paddingLeft: 25 }}
                                >
                                  <div className="res-sodds-img">
                                    <img src={ResBetIcon} />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      paddingBottom: 10,
                                    }}
                                  >
                                    <ArrowForwardIosIcon className="res-odd-arrow" />
                                  </div>
                                </div>
                              </div>

                              <div className="res-best-at-open">
                                <p>[Odd]</p>
                              </div>
                              <div className="res-winingodd-graph">
                                <p>[Graph]</p>
                              </div>
                            </div>
                            <div>
                              <Collapse
                                in={openRaceRow == index}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div className="single_race_open_main">
                                  <div className="res-odds-drpdwnbookmaker">
                                    <div className="odd-dpdwn-opt">
                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={RNedsIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResBetIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResBet365Icon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResBetStarIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResRedCIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResBlackBIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>

                                      <div>
                                        <div className="res-odd-opt">
                                          <img
                                            src={ResVICBetIcon}
                                            className="res-odopt"
                                          />
                                        </div>
                                        <p className="bookmaker-option-value">
                                          [Odds]
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AllSportsHome;
