import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import ButtonComponent from "../../library/common/components/Button";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants";
import { config } from "../../helpers/config";
import { Loader } from "../../library/common/components";
import Select from "react-select";

const selectboxStyles = {
  container: (styles) => ({ ...styles, width: "100%", minWidth: "180px" }),
  control: (styles) => ({
    ...styles,
    width: "100%",
    // color: "#fff",
    borderRadius: "10px",
    borderColor: "#cccccc",
    boxShadow: "none",
    // backgroundColor: "#6ABF4B",

    "&:hover": {
      // border: "1px solid #cccccc",
      borderColor: "#cccccc",
      boxShadow: "none",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#000",
    };
  },
  indicatorContainer: (styles) => ({ ...styles, padding: "0 2px" }),
  input: (styles) => ({ ...styles, color: "#000" }),
  placeholder: (styles) => ({ ...styles, fontSize: "14px", color: "#656565" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#000",
    fontSize: "14px",
  }),
  valueContainer: (styles) => ({ ...styles, padding: "5px 16px" }),
};

class ProviderItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: {
        sports: "",
        sportsType: "",
      },
      isLoadingFixtures: false,
      isLoadingOdds: false,
    };
  }

  componentDidMount() {
    const { sportsList } = this.props;
    this.setState({ sportsList: sportsList });
  }

  async fetchSports() {
    const { searchData } = this.state;
    this.setState({ sportsList: [] });
    let selectedSportsTypes = searchData?.sportsType
      ? searchData.sportsType?.map((st) => st?.id)
      : [];
    const { status, data } = await axiosInstance.post(`${URLS.sports}/type`, [
      ...selectedSportsTypes,
    ]);
    if (status === 200) {
      let allSportsList = [
        ...data.result?.map((dataitem) => {
          return {
            ...dataitem,
            label: dataitem?.sportName,
            value: dataitem?.sportName,
          };
        }),
      ];
      this.setState({ sportsList: allSportsList });
    }
  }

  onChangeSelectbox = (name, selectedOptions) => {
    this.setState(
      (prevState) => {
        return {
          searchData: {
            ...prevState.searchData,
            [name]: selectedOptions,
          },
        };
      },
      () => {
        if (name === "sportsType") {
          this.fetchSports();
        }
      }
    );
  };

  handleImportOdds = async (id) => {
    let { searchData } = this.state;
    let data = {};
    data["sportId"] = searchData.sports[0].id;
    data["providerId"] = id;
    this.setState({ isLoadingOdds: true });
    try {
      let { response } = await axiosInstance.post(URLS.importOdds, data);
      if (response) {
        this.setState({ isLoadingOdds: false });
      }
    } catch (err) {
      this.setState({ isLoadingOdds: false });
    }
  };

  handleImportFixtures = async (id) => {
    let { searchData } = this.state;
    let data = {};
    data["sportId"] = searchData.sports[0].id;
    data["providerId"] = id;
    this.setState({ isLoadingFixtures: true });
    try {
      let { response } = await axiosInstance.post(URLS.importFixtures, data);
      if (response) {
        this.setState({ isLoadingFixtures: false });
      }
    } catch (err) {}
    this.setState({ isLoadingFixtures: false });
  };

  handleImportBoth = async (id) => {
    this.handleImportFixtures(id);
    this.handleImportOdds(id);
  };

  render() {
    var { sportsTypeList, item } = this.props;
    var {
      sportsList,
      isLoadingFixtures,
      isLoadingOdds,
      searchData,
    } = this.state;
    return (
      <TableRow key={item?.id}>
        <TableCell>{item.id}</TableCell>
        <TableCell style={{ maxWidth: "100px", minWidth: "100px" }}>
          <div className="logocontainer">
            {item.logo ? (
              <img src={config.baseUrl + item.logo} alt={item.providerName} />
            ) : (
              <div className="imagePlaceholder">Logo</div>
            )}
          </div>
        </TableCell>
        <TableCell style={{ maxWidth: "100px", minWidth: "100px" }}>
          {item.providerName}
        </TableCell>
        <TableCell style={{ maxWidth: "180px", minWidth: "180px" }}>
          <Select
            placeholder="Sports Type"
            name="sportsType"
            id="sportsType"
            isMulti
            styles={selectboxStyles}
            defaultValue={searchData?.sportsType}
            options={sportsTypeList}
            onChange={(selectedOptions) =>
              this.onChangeSelectbox("sportsType", selectedOptions)
            }
          />
        </TableCell>
        <TableCell style={{ maxWidth: "180px", minWidth: "180px" }}>
          <Select
            placeholder="Sports"
            name="sports"
            id="sports"
            isMulti
            styles={selectboxStyles}
            defaultValue={searchData?.sports}
            options={sportsList}
            onChange={(selectedOptions) =>
              this.onChangeSelectbox("sports", selectedOptions)
            }
          />
        </TableCell>
        <TableCell>
          <ButtonComponent
            className="mt-3 admin-btn-green"
            onClick={() => this.handleImportFixtures(item?.id)}
            color="primary"
            value={!isLoadingFixtures ? "Fixtures" : "Loading..."}
            disabled={
              isLoadingFixtures ||
              searchData?.sportsType === "" ||
              searchData?.sportsType === null ||
              searchData?.sportsType === "undefined" ||
              searchData?.sports === "" ||
              searchData?.sports === null ||
              searchData?.sports === "undefined"
            }
          />{" "}
          <ButtonComponent
            className="mt-3 admin-btn-green"
            onClick={() => this.handleImportOdds(item?.id)}
            color="primary"
            value={!isLoadingOdds ? "Odds" : "Loading..."}
            disabled={
              isLoadingOdds ||
              searchData?.sportsType === "" ||
              searchData?.sportsType === null ||
              searchData?.sportsType === "undefined" ||
              searchData?.sports === "" ||
              searchData?.sports === null ||
              searchData?.sports === "undefined"
            }
          />{" "}
          <ButtonComponent
            className="mt-3 admin-btn-green"
            onClick={() => this.handleImportBoth(item?.id)}
            color="primary"
            value={!isLoadingFixtures || !isLoadingOdds ? "Both" : "Loading..."}
            disabled={
              (isLoadingFixtures && isLoadingOdds) ||
              searchData?.sportsType === "" ||
              searchData?.sportsType === null ||
              searchData?.sportsType === "undefined" ||
              searchData?.sports === "" ||
              searchData?.sports === null ||
              searchData?.sports === "undefined"
            }
          />
        </TableCell>
      </TableRow>
    );
  }
}
export default ProviderItemRow;
