import React, { PureComponent } from "react";
import moment from "moment";
import { EventEmitter } from "../../services/event";

class WatchClock extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tempSecondCount: 0,
      sessionClock: false,
    };
    EventEmitter.subscribe("getInitSessionTime", this.getSessionTime);
  }

  componentDidMount() {
    this.getSessionTime();
  }

  getSessionTime = () => {
    let _this = this;
    let sessionClock = sessionStorage.getItem("sessionClock");
    sessionClock =
      sessionClock && typeof sessionClock === "string" && sessionClock !== ""
        ? JSON.parse(sessionStorage.getItem("sessionClock"))
        : sessionClock;
    this.setState({ sessionClock: sessionClock });
    setInterval(function () {
      _this.setState((prevState) => {
        return {
          tempSecondCount: !prevState.tempSecondCount,
        };
      });
    }, 1000);
  };

  render() {
    const { tempSecondCount } = this.state;
    var from = this.state?.sessionClock?.sessionStartTime;
    var now = moment().valueOf();
    let sessionDuration = moment.duration(moment(now).diff(from));
    return (
      <>
        Watch the Clock <br />
        Session{" "}
        {sessionDuration?._data?.hours > 0 &&
          `${sessionDuration?._data?.hours}h`}{" "}
        {sessionDuration?._data?.minutes}m {sessionDuration?._data?.seconds}s
        <span style={{ display: "none" }}>{tempSecondCount}</span>
      </>
    );
  }
}

export default WatchClock;
