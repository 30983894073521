import React, { Component } from "react";
import moment from "moment";
import { EventEmitter } from "../../services/event";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Button,
} from "@material-ui/core";
import Loader from "../../library/common/components/Loader";
import Pagination from "@material-ui/lab/Pagination";
import { raceIcon } from "../../helpers/common";
import { ReactSVG } from "react-svg";
import "./racelist.scss";
import axiosInstance from "../../helpers/Axios";
import { URLS } from "../../library/common/constants/UrlConstants";
import racing_winning_edge from "../../images/racing-winning-edge.svg";

class RunnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRaceType: 1,
      currentPage: 1,
      recPerPage: 10,
      allSportsType: null,
      allSports: [],
      oddLoading: false,
    };
  }

  componentDidMount() {
    this.fetchAllSportsType();
    this.fetchAllSports();
  }

  componentDidUpdate(prevProps) {}

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  async fetchAllSportsType() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sportType);
      if (status === 200) {
        this.setState({ allSportsType: data.result });
      }
    } catch (err) {}
  }

  async fetchAllSports() {
    try {
      const { status, data } = await axiosInstance.get(URLS.sports);
      if (status === 200) {
        this.setState({ allSports: data.result });
      }
    } catch (err) {}
  }

  getSportTypeId = (name) => {
    let { allSports } = this.state;
    let sportTypeId = allSports
      .filter((obj) => obj.sportName == name)
      .map((object) => object.sportTypeId);
    return sportTypeId;
  };

  getSportType = () => {
    let sportTypeId = this.getSportTypeId(this.props.match.params.sportName);
    let { allSportsType } = this.state;
    let sportType = allSportsType
      .filter((obj) => obj.id == sportTypeId[0])
      .map((object) => object.sportType);
    return sportType;
  };

  fetchRunnerOdds = async (id) => {
    this.setState({ oddLoading: true });
    let odds = [];
    try {
      const { status, data } = await axiosInstance.get(`/events/odd/${id}`);
      if (status === 200) {
        odds = data.result;
        this.setState({ oddLoading: false });
      }
    } catch (err) {}
    return odds;
  };

  handleCompare = async (item) => {
    const { racesDetails, raceDate, match, Runners } = this.props;
    let sportName = this.getSportType(match?.params?.id);
    let RunnerOdds = await this.fetchRunnerOdds(item?.id);

    var emititem = { ...item };
    emititem.SportName = match.params.sportName;
    emititem.sportType = sportName;
    emititem.raceNumber = raceDate?.raceNumber;
    emititem.sportTitle = match.params.meeting;
    emititem.race = raceDate.raceName;
    emititem.raceType = "";
    emititem.allHorses = Runners;
    emititem.RunnerOdds = RunnerOdds;
    emititem.raceTime = moment
      .utc(raceDate.startTimeDate)
      .local()
      .format("DD MMM YYYY hh:mm a");
    emititem.imageSrc = match.params.sportName;
    emititem.title = `${item?.runnerNumber} ${item?.animal?.name} (${
      item.barrierNumber !== "" && item.barrierNumber !== null
        ? item.barrierNumber
        : 0
    })`;
    EventEmitter.dispatch("draweropen");
    EventEmitter.dispatch("updatebetsList", emititem);
  };

  renderItemRow = (row, i, item) => {
    // let bestOdds = row?.RunnerOdds?.sort(function (a, b) {
    //   return Number(b.FixedWin) - Number(a.FixedWin);
    // });
    // bestOdds = bestOdds?.odds;
    // let wins = bestOdds?.Win
    //   ? Number(bestOdds?.Win).toFixed(2)
    //   : Number(0.0).toFixed(2);
    // let place = bestOdds?.Place
    //   ? Number(bestOdds?.Place).toFixed(2)
    //   : Number(0.0).toFixed(2);

    return (
      <TableRow
        key={"runnertable" + row.id}
        className={row.isScratched === 1 ? "disabled" : ""}
      >
        <TableCell className='row-image'>
          <ReactSVG src={raceIcon(this.props.match.params.sportName)} />
        </TableCell>
        <TableCell component='th' scope='row'>
          {`${row.runnerNumber} ${row?.animal?.name} (${
            row.barrierNumber !== "" && row.barrierNumber !== null
              ? row.barrierNumber
              : 0
          })`}
        </TableCell>
        {/* <TableCell>{row.trainer ? row.trainer : "-"}</TableCell> */}
        <TableCell>{row?.players[0]?.name}</TableCell>
        {/* <TableCell>{row.form ? row.form : "-"}</TableCell> */}
        <TableCell>{row?.animal?.JockeyWeight}</TableCell>
        {/* <TableCell>{wins && !isNaN(wins) ? wins : "0.00"}</TableCell>
        <TableCell>{place && !isNaN(place) ? place : "0.00"}</TableCell> */}
        <TableCell>
          {row?.bestOdd !== "undefined" &&
          row?.bestOdd !== null &&
          row?.bestOdd !== {} &&
          row?.bestOdd?.length > 0
            ? row.bestOdd.Win !== null
              ? Number(row.bestOdd.Win).toFixed(2)
              : "0.00"
            : "0.00"}
        </TableCell>
        <TableCell>
          {" "}
          {row?.bestOdd !== "undefined" &&
          row?.bestOdd !== null &&
          row?.bestOdd !== {} &&
          row?.bestOdd?.length > 0
            ? row.bestOdd.NSEPlcce !== null
              ? Number(row.bestOdd.NSEPlcce).toFixed(2)
              : "0.00"
            : "0.00"}
        </TableCell>
        <TableCell>
          <Button
            // disabled={
            //   row.isScratched === 1
            //     ? true
            //     : (!isNaN(wins) && wins > 0) || (!isNaN(place) && place > 0)
            //     ? false
            //     : true
            // }
            disabled={row?.isScratched === 1 ? true : false}
            className={"compare-btn"}
            variant='outlined'
            onClick={() => this.handleCompare(row)}
          >
            {row.isScratched === 1 ? "SCR" : "Compare"}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  renderItemRowMobile = (row, i, item) => {
    // let bestOdds = row?.RunnerOdds?.sort(function (a, b) {
    //   return Number(b.FixedWin) - Number(a.FixedWin);
    // });
    // bestOdds = bestOdds[0];
    // let wins = bestOdds?.FixedWin
    //   ? Number(bestOdds?.FixedWin).toFixed(2)
    //   : Number(0.0).toFixed(2);
    // let place = bestOdds?.FixedPlace
    //   ? Number(bestOdds?.FixedPlace).toFixed(2)
    //   : Number(0.0).toFixed(2);
    return (
      <Grid
        container
        className={
          "racelistmb-main " + (row.isScratched === 1 ? "disabled" : "")
        }
        key={"racelist" + row.id}
      >
        <Grid item xs={2}>
          {/* <ReactSVG src={hourse_img_small} /> */}
          <ReactSVG
            className='horse_img'
            src={raceIcon(this.props.match.params.sportName)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className='racelistmb-name'>{`${row.runnerNumber} ${
            row?.animal?.name
          } (${
            row.barrierNumber !== "" && row.barrierNumber !== null
              ? row.barrierNumber
              : 0
          })`}</Typography>
          <Typography className='racelistmb-jockey'>
            {row?.players[0]?.name}
          </Typography>
          <Typography className='racelistmb-weight'>
            Weight: {row?.animal?.JockeyWeight}
          </Typography>
          <Typography className='racelistmb-bestodds'>Best Odds</Typography>
          <Divider />
          <Typography className='racelistmb-win'>
            {/* <span>Win: {wins && !isNaN(wins) ? wins : "0.00"}</span>
            <span>Place: {place && !isNaN(place) ? place : "0.00"}</span> */}
            <span>
              Win:{" "}
              {row?.bestOdd !== "undefined" &&
              row?.bestOdd !== null &&
              row?.bestOdd !== {}
                ? row.bestOdd.Win !== null
                  ? Number(row.bestOdd.Win).toFixed(2)
                  : "0.00"
                : "0.00"}
            </span>
            <span>
              Place:{" "}
              {row?.bestOdd !== "undefined" &&
              row?.bestOdd !== null &&
              row?.bestOdd !== {}
                ? row.bestOdd.NSEPlcce !== null
                  ? Number(row.bestOdd.NSEPlcce).toFixed(2)
                  : "0.00"
                : "0.00"}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            // disabled={
            //   row.isScratched === 1
            //     ? true
            //     : (!isNaN(wins) && wins > 0) || (!isNaN(place) && place > 0)
            //     ? false
            //     : true
            // }
            disabled={row?.isScratched === 1 ? true : false}
            className='compare-btn'
            variant='outlined'
            onClick={() => this.handleCompare(row)}
          >
            {row.isScratched === 1 ? "SCR" : "Compare"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { mobileView, Runners, isRunnerLoading } = this.props;
    const { currentPage, recPerPage, oddLoading } = this.state;
    let filtredRunners = Runners.filter(
      (obj) => obj.animal !== null && obj.animal !== "undefined"
    );
    //   ? Runners?.sort(function (a, b) {
    //       return a?.RaceNumber - b?.RaceNumber;
    //     })
    //   : [];
    // if (filtredRunners?.length > 0) {
    //   filtredRunners = filtredRunners?.map((itemRunner) => {
    //     let newRunnerOdds = [...itemRunner?.RunnerOdds];
    //     newRunnerOdds = newRunnerOdds?.filter(
    //       (itemOdd) => itemOdd?.Key !== "generationWeb"
    //     );
    //     return {
    //       ...itemRunner,
    //       RunnerOdds: newRunnerOdds,
    //     };
    //   });
    // }
    const pageNumbers = [];
    let currentPageRec = filtredRunners;
    if (mobileView === true) {
      if (filtredRunners?.length > 0) {
        const indexOfLastTodo = currentPage * recPerPage;
        const indexOfFirstTodo = indexOfLastTodo - recPerPage;
        currentPageRec = filtredRunners.slice(
          indexOfFirstTodo,
          indexOfLastTodo
        );

        for (
          let i = 1;
          i <= Math.ceil(filtredRunners.length / recPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
      }
    }
    return (
      <>
        {oddLoading && (
          <div className='racelist-odds-loader'>
            <Loader />
          </div>
        )}
        {isRunnerLoading ? (
          <div className='racelist-loader'>
            <Loader />
          </div>
        ) : mobileView === true ? (
          <>
            {currentPageRec.map((row, i) => (
              <Grid
                key={"runnerlistm" + i}
                container
                className='racelist-mobile'
              >
                <Grid item xs={12} sm={12}>
                  {this.renderItemRowMobile(row, i, filtredRunners)}
                </Grid>
              </Grid>
            ))}
            <div className='racing-winning-edge'>
              <div
                style={{ cursor: "pointer", display: "flex" }}
                onClick={() =>
                  window.open(
                    "https://winningedgeinvestments.com?af=AFC6E92E",
                    "_blank"
                  )
                }
              >
                <div>{"Get tips from"}</div>
                <ReactSVG src={racing_winning_edge} />
              </div>
            </div>
            <div className='runnerPagination'>
              <Pagination
                page={currentPage}
                onChange={this.handlePaginationClick}
                count={pageNumbers[pageNumbers?.length - 1]}
                siblingCount={2}
                boundaryCount={1}
                size='small'
              />
            </div>
          </>
        ) : (
          <TableContainer>
            <Table
              className={"race-table"}
              size='small'
              aria-label='a dense table'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: "20px", padding: "8px" }}
                  ></TableCell>
                  <TableCell></TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell></TableCell>
                  <TableCell colSpan='2'>
                    <Typography
                      className='racelistmb-bestodds'
                      style={{
                        color: "#6abf4b",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      Best Odds
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ width: "20px", padding: "8px" }}
                  ></TableCell>
                  <TableCell>Horse Name</TableCell>
                  {/* <TableCell>Trainer </TableCell> */}
                  <TableCell>Jockey</TableCell>
                  {/* <TableCell>Form</TableCell> */}
                  <TableCell>Weight</TableCell>
                  <TableCell>Win</TableCell>
                  <TableCell>Place</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtredRunners.map((row, i) =>
                  this.renderItemRow(row, i, filtredRunners)
                )}
              </TableBody>
            </Table>
            <div className='racing-winning-edge'>
              <div
                style={{ cursor: "pointer", display: "flex" }}
                onClick={() =>
                  window.open(
                    "https://winningedgeinvestments.com?af=AFC6E92E",
                    "_blank"
                  )
                }
              >
                <div>{"Get tips from"}</div>
                <ReactSVG src={racing_winning_edge} />
              </div>
            </div>
          </TableContainer>
        )}
      </>
    );
  }
}

export default RunnersTable;
