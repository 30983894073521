import { Validators } from "../../../library/utilities/Validators";

export const statsFormModel = [
  {
    label: "Reference Type*",
    value: "",
    type: "dropdown",
    placeholder: "Reference Type",
    field: "referenceType",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
    ],
  },
  {
    label: "Reference Id*",
    value: "",
    type: "text",
    placeholder: "Reference Id",
    field: "referenceId",
    validators: [{ check: Validators.required }, { check: Validators.number }],
    required: true,
    className: "6",
  },
  {
    label: "Stat Key*",
    value: "",
    type: "dropdown",
    placeholder: "Stat Key",
    field: "statkeyId",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [],
  },
  {
    label: "Is Reference*",
    value: "",
    type: "dropdown",
    placeholder: "Is Reference",
    field: "isReference",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
  },
  {
    label: "Table Name*",
    value: "",
    type: "text",
    placeholder: "Table Name",
    field: "tableName",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Value*",
    value: "",
    type: "text",
    placeholder: "Value",
    field: "value",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
  },
  {
    label: "Value Type*",
    value: "",
    type: "dropdown",
    placeholder: "Value Type",
    field: "valueType",
    validators: [{ check: Validators.required }],
    required: true,
    className: "6",
    options: [
      { value: "string", label: "string" },
      { value: "int", label: "int" },
    ],
  },
];
