import { Validators } from "../../../library/utilities/Validators";
import {
  RESPONSE_TYPES,
  DATE_TIMEZONES,
  API_SAVE_STATUS,
} from "../../../helpers/constants";
export const apiProviderFormModel = [
  {
    label: "Provider Name*",
    value: "",
    type: "text",
    placeholder: "Provider Name",
    field: "providerName",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  // {
  //   label: "Api Url",
  //   value: "",
  //   type: "text",
  //   placeholder: "Api Url",
  //   field: "apiURL",
  //   validators: [
  //     { check: Validators.required, message: "This field is mandatory" },
  //   ],
  //   required: true,
  //   className: "6",
  // },
  {
    label: "Api Key Id*",
    value: "",
    type: "text",
    placeholder: "Api Key Id",
    field: "apiKeyId",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Api Access Key*",
    value: "",
    type: "text",
    placeholder: "Api Access Key",
    field: "apiAccessKey",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Display Order*",
    value: "",
    type: "text",
    placeholder: "Display Order",
    field: "displayOrder",
    validators: [
      { check: Validators.required },
      { check: Validators.number, message: "Insert Number Only" },
    ],
    required: true,
    errorMessage: "",
    className: "6",
  },
  {
    label: "Status*",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      ...API_SAVE_STATUS?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
  {
    label: "Response Type*",
    value: "",
    type: "dropdown",
    placeholder: "Response Type",
    field: "responseType",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      ...RESPONSE_TYPES?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
  {
    label: "Date Type*",
    value: "",
    type: "dropdown",
    placeholder: "Date Type",
    field: "dateType",
    validators: [{ check: Validators.required }],
    required: true,
    errorMessage: "",
    className: "6",
    options: [
      ...DATE_TIMEZONES?.map((item, i) => {
        return { id: 1, value: item, label: item };
      }),
    ],
  },
];
