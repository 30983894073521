import React, { createRef } from "react";
import { Grid, Paper } from "@material-ui/core";
import { locationFormModel } from "./form-constant";
import Form from "../../../library/common/components/Form";
import ButtonComponent from "../../../library/common/components/Button";
import ActionMessage from "../../../library/common/components/ActionMessage";
import { URLS } from "../../../library/common/constants";
import { removeErrorFieldsFromValues } from "../../../library/utilities";
import axiosInstance from "../../../helpers/Axios";
import { setValidation } from "../../../helpers/common";

let locationFormModelArray = locationFormModel;

class CreateCountry extends React.Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      values: {
        venueName: "",
      },
      state: null,
      stateAll: [],
      country: null,
      countryAll: [],
      city: null,
      cityAll: [],
      isEditMode: false,
      error: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEditMode) {
      this.fetchCurrentLocation();
    }
    this.fetchAllCountry();
  }

  componentWillUnmount() {
    locationFormModelArray = locationFormModelArray.map((fieldItem) => {
      return { ...fieldItem, errorMessage: "" };
    });
  }

  fetchCurrentLocation = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.location + `/${this.props.id}`
    );
    if (status === 200) {
      this.setState({
        values: data.result,
        country: data.result.countryId,
        state: data.result.stateId,
        city: data.result.cities,
      });
      this.fetchAllState(data.result.countryId);
      this.fetchAllCity(data.result.stateId);
    }
  };

  fetchAllCountry = async () => {
    const { status, data } = await axiosInstance.get(URLS.country);
    if (status === 200) {
      this.setState({ countryAll: data.result });
    }
  };

  fetchAllState = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.state + `/country/${id}`
    );
    if (status === 200) {
      this.setState({ stateAll: data.result });
    }
  };

  fetchAllCity = async (id) => {
    const { status, data } = await axiosInstance.get(
      URLS.cities + `/state/${id}`
    );
    if (status === 200) {
      this.setState({ cityAll: data.result });
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } });
  };

  validate = () => {
    let { venueName } = this.state.values;
    let { state, country, city, error } = this.state;
    let flag = true;

    if (venueName === "") {
      flag = false;
      //this.setActionMessage(true, "Error", "Please Fill Details First");
      this.setState({ isLoading: false });
    } else {
      flag = true;
      //this.setActionMessage(false);
    }

    if (country === null) {
      error.country = "This field id mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.country = "";
    }

    if (state === null) {
      error.state = "This field is mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.state = "";
    }

    if (city === null) {
      error.city = "This field is mandatory";
      flag = false;
      this.setState({ isLoading: false });
    } else {
      error.city = "";
    }

    this.setState({ error });
    return flag;
  };

  handleSave = async () => {
    const { isEditMode } = this.props;
    const { country, state, city } = this.state;
    this.setState({ isLoading: true });
    try {
      const { current } = this.formRef;
      const form = current.getFormData();

      const method = isEditMode ? "put" : "post";
      const url = isEditMode
        ? `${URLS.location}/${this.props.id}`
        : URLS.location;

      const values = removeErrorFieldsFromValues(form.formData);
      values["countryId"] = country;
      values["stateId"] = state;
      values["cities"] = city;
      locationFormModelArray = locationFormModelArray?.map((fieldItem) => {
        return setValidation(fieldItem, values);
      });

      if (this.validate()) {
        const { status } = await axiosInstance[method](url, values);
        if (status === 200) {
          this.setState({ isLoading: false });
          this.props.inputModal();
          this.props.fetchAllLocation();
          this.setActionMessage(
            true,
            "Success",
            `Location ${isEditMode ? "Edited" : "Created"} Successfully`
          );
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      this.setActionMessage(
        true,
        "Error",
        `An error occured while ${isEditMode ? "editing" : "creating"} Location`
      );
    }
  };

  handleChange = (field, value) => {
    let values = { ...this.state.values, [field]: value };
    this.setState({ values: values });
    locationFormModelArray = locationFormModelArray?.map((fieldItem) => {
      if (field === fieldItem?.field) {
        return setValidation(fieldItem, values);
      } else {
        return fieldItem;
      }
    });
    this.setActionMessage(false);
  };

  handleSelect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "state") {
      this.setState(
        { state: value, error: { ...this.state.error, ["state"]: "" } },
        () => this.fetchAllCity(value)
      );
    }
    if (name === "country") {
      this.setState(
        { country: value, error: { ...this.state.error, ["country"]: "" } },
        () => this.fetchAllState(value)
      );
    }
    if (name === "city") {
      this.setState({
        city: Number(value),
        error: { ...this.state.error, ["city"]: "" },
      });
    }
  };

  render() {
    var {
      values,
      messageBox,
      isLoading,
      countryAll,
      stateAll,
      cityAll,
      country,
      state,
      city,
      error,
    } = this.state;
    var { isEditMode } = this.props;
    return (
      <>
        <Grid container className="page-content adminLogin text-left">
          <Grid item xs={12}>
            <Paper className="pageWrapper api-provider">
              {messageBox.display && (
                <ActionMessage
                  message={messageBox.message}
                  type={messageBox.type}
                  styleClass={messageBox.styleClass}
                />
              )}

              <Form
                values={values}
                model={locationFormModelArray}
                ref={this.formRef}
                onChange={this.handleChange}
              />

              <div>
                <label>Country*</label>
                <select
                  className="select-box-manual"
                  name="country"
                  onChange={this.handleSelect}
                  value={country}
                >
                  <option>No Country Selected</option>
                  {countryAll?.map((obj, i) => (
                    <option key={i} value={obj.id}>
                      {obj.country}
                    </option>
                  ))}
                </select>
                {typeof error.country !== "undefined" &&
                  error.country !== "" && (
                    <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                      {error.country}
                    </p>
                  )}
                <label>State*</label>
                <select
                  className="select-box-manual"
                  name="state"
                  onChange={this.handleSelect}
                  value={state}
                >
                  {this.state.country === null ? (
                    <option>Select Country First</option>
                  ) : (
                    <>
                      <option>No State Selected</option>
                      {stateAll?.map((obj, i) => (
                        <option key={i} value={obj.id}>
                          {obj.state}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {typeof error.state !== "undefined" && error.state !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.state}
                  </p>
                )}
                <label>City*</label>
                <select
                  className="select-box-manual"
                  name="city"
                  onChange={this.handleSelect}
                  value={city}
                >
                  {this.state.state === null ? (
                    <option>Select State First</option>
                  ) : (
                    <>
                      <option value={null}>No City Selected</option>
                      {cityAll?.map((obj, i) => (
                        <option key={i} value={obj.id}>
                          {obj.cityName}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {typeof error.city !== "undefined" && error.city !== "" && (
                  <p style={{ color: "red", margin: "-18px 0px 0px" }}>
                    {error.city}
                  </p>
                )}
              </div>

              <Grid container>
                <Grid item xs={3}>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    {!isEditMode ? (
                      <ButtonComponent
                        className="mt-3 admin-btn-green"
                        onClick={this.handleSave}
                        color="primary"
                        value={!isLoading ? "Save" : "Loading..."}
                        disabled={isLoading}
                      />
                    ) : (
                      <ButtonComponent
                        className="mt-3 admin-btn-orange"
                        onClick={this.handleSave}
                        color="secondary"
                        value={!isLoading ? "Update" : "Loading..."}
                        disabled={isLoading}
                      />
                    )}

                    <ButtonComponent
                      onClick={this.props.inputModal}
                      className="mr-lr-30"
                      value="Back"
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default CreateCountry;
