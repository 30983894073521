import React from "react";
import "./individual.scss";
import { Container, Grid, Typography } from "@material-ui/core";
import user from "../../images/user_image.png";
import individual_scratch_left from "../../images/individual_scratch_left.png";
import individual_scratch_right from "../../images/individual_scratch_right.png";
import link from "../../images/about-link.svg";
import linkedin from "../../images/about-linkedin.svg";
import { ReactSVG } from "react-svg";

class IndividualAboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkUrl: "",
      linkedinUrl: "",
    };
  }
  render() {
    const { linkUrl, linkedinUrl } = this.state;
    return (
      <>
        <div className='top-individual-div'></div>
        <div className='individual-main-wrapper'>
          <Container>
            <Grid
              container
              justify='center'
              className='contact-banner afiliate-banner individual-banner'
              alignItems='flex-start'
            >
              <Grid item lg={4} md={6} sm={6} xs={6} className='hide-xs'>
                <img
                  src={individual_scratch_left}
                  alt='left-scratch'
                  className='individual-left-image'
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6} className='hide-xs'>
                <img
                  src={individual_scratch_right}
                  alt='right-scratch'
                  className='individual-right-image'
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className='individual-inner'
              >
                <div>
                  <img src={user} alt='member profile' />
                </div>
                <Typography
                  variant='h5'
                  className='title individual-title text-center'
                >
                  Amjad Khanche
                </Typography>
                <Typography
                  variant='h5'
                  className='text-center individual-subtitle'
                >
                  Executive Director – Technology and Innovation
                </Typography>
                <Typography
                  variant='h5'
                  className='text-center individual-description'
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </Typography>
                <div className='individual-icons'>
                  <a href={linkUrl !== "" ? linkUrl : "/"}>
                    <ReactSVG src={link} />
                  </a>
                  <a href={linkedinUrl !== "" ? linkedinUrl : "/"}>
                    <ReactSVG src={linkedin} />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    );
  }
}
export default IndividualAboutUs;
