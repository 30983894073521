import React from "react";
import "./otherracing.scss";
import { Button, Divider, Grid, Typography, Collapse } from "@material-ui/core";
import TabList from "../../common/tablists/tablist";
import { ReactSVG } from "react-svg";
import cyclingIcon from "../../../images/sport_icons/cycling.svg";
import motorIcon from "../../../images/sport_icons/motorracing.svg";
import entertainmentIcon from "../../../images/sport_icons/starIcon.svg";
import yachtIcon from "../../../images/sport_icons/Yacht Racing.svg";
import politicsIcon from "../../../images/sport_icons/Politics.svg";
import ArrowDropDownCircleRoundedIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { EventEmitter } from "../../../services/event";

export default class OtherRacing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueTabs: [],
      selectedLeagueTab: null,
      leagueSubTabs: [],
      selectedSubLeagueTab: null,
      showRaceNumber: 10,
      races: [],
      raceIcon: null,
      raceName: "",
      expanded: [],
    };
  }
  componentDidMount() {
    this.fetchRaces();
  }
  handleChangeLeauge = (event, newValue) => {
    let { selectedLeagueTab } = this.state;
    this.state.leagueTabs.map((raceItem) => {
      if (raceItem.id === newValue) {
        selectedLeagueTab = raceItem;
      }
      return raceItem;
    });
    this.setState({ selectedLeagueTab });
  };
  handleChangeSubLeauge = (event, newValue) => {
    let { selectedSubLeagueTab } = this.state;
    this.state.leagueSubTabs.map((raceItem) => {
      if (raceItem.id === newValue) {
        selectedSubLeagueTab = raceItem;
      }
      return raceItem;
    });
    this.setState({ selectedSubLeagueTab });
  };

  handleShowAll = () => {
    this.setState({ showRaceNumber: this.cyclingRace.length });
  };
  cyclingRace = [
    { id: 1, name: "Tadej Pogacar" },
    { id: 2, name: "Primoz Roglic" },
    { id: 3, name: "Remco Evenepoel" },
    { id: 4, name: "Egan Bernal" },
    { id: 5, name: "Tom Dumoulin" },
    { id: 6, name: "Geraint Thomas" },
    { id: 7, name: "Richard Carapaz" },
    { id: 8, name: "Thibaut Pinot" },
    { id: 9, name: "Alexander Vlasov" },
    { id: 10, name: "Simon Yates" },
    { id: 11, name: "Emanuel Buchmann" },
    { id: 12, name: "Tao Geoghegan Hart" },
    { id: 13, name: "Chris Froome" },
    { id: 14, name: "Enric Mas Nicolau" },
  ];

  cyclingLeauge = [
    { id: 1, League: "Tour de France" },
    { id: 2, League: "Tour de France" },
  ];
  cyclingLeaugeSelected = { id: 1, League: "Tour de France" };
  cyclingSubLeauge = [
    { id: 1, League: "Tour de France 2021" },
    { id: 2, League: "Tour de France 2021" },
    { id: 3, League: "Tour de France 2021" },
    { id: 4, League: "Tour de France 2021" },
    { id: 5, League: "Tour de France 2021" },
  ];
  cyclingSubLeaugeSelcted = { id: 1, League: "Tour de France 2021" };
  motorRace = [
    { id: 1, name: "To win an F1 Grand Prix in his career" },
    { id: 2, name: "To sign for Ferrari before 2025" },
    { id: 3, name: "To win an F1 race before the end of 2022 season" },
    { id: 4, name: "To win and F1 World Championships by 2030" },
    { id: 5, name: "To break his dads record of 91 Grand Prix wins" },
  ];
  motorLeauge = [
    { id: 1, League: "All motor racing" },
    { id: 2, League: "All motor racing" },
  ];
  motorLeaugeSelected = { id: 1, League: "All motor racing" };
  motorSubLeauge = [
    { id: 1, League: "Mick Schumacher Specials" },
    { id: 2, League: "F1 Abu Dabi Grand Prix" },
  ];
  motorSubLeaugeSelcted = { id: 1, League: "Mick Schumacher Specials" };

  entertainmentList = [
    {
      id: 1,
      type: "Best Actor",
      list: [
        { id: 1, name: "Chadwick Boseman" },
        { id: 2, name: "Anthony Hopkins" },
        { id: 3, name: "George Clooney" },
        { id: 4, name: "Daniel Kaluuya" },
        { id: 5, name: "Delroy Lindo" },
        { id: 6, name: "Eddie Redmayne" },
        { id: 7, name: "Gary Oldman" },
        { id: 8, name: "Robert Pattison" },
      ],
    },
    {
      id: 2,
      type: "Best Actress",
      list: [
        { id: 1, name: "Viola Davis" },
        { id: 2, name: "Jennifer Hudson" },
        { id: 3, name: "Frances McDormand" },
        { id: 4, name: "Vanessa Kirby" },
        { id: 5, name: "Meryl Streep" },
        { id: 6, name: "Kate Winslet" },
        { id: 7, name: "Amy Adams" },
        { id: 8, name: "Glenn Close" },
      ],
    },
    {
      id: 3,
      type: "Best Picture",
      list: [
        { id: 1, name: "Nomadland" },
        { id: 2, name: "Mank" },
        { id: 3, name: "The Trial of the Chicago 7" },
        { id: 4, name: "News of the World" },
        { id: 5, name: "Da 5 Bloods" },
        { id: 6, name: "West Side Story" },
        { id: 7, name: "Raineys Black Bottom" },
        { id: 8, name: "Respect" },
      ],
    },
  ];
  entertainmentLeauge = [
    { id: 1, League: "Featured" },
    { id: 2, League: "Competitions" },
  ];
  entertainmentLeaugeSelected = { id: 1, League: "Featured" };
  entertainmentSubLeauge = [{ id: 1, League: "Academy Awards" }];
  entertainmentSubLeaugeSelcted = { id: 1, League: "Academy Awards" };

  yachtRace = [
    { id: 1, name: "To win an F1 Grand Prix in his career" },
    { id: 2, name: "To sign for Ferrari before 2025" },
    { id: 3, name: "To win an F1 race before the end of 2022 season" },
    { id: 4, name: "To win and F1 World Championships by 2030" },
    { id: 5, name: "To break his dads record of 91 Grand Prix wins" },
  ];
  yachtLeauge = [
    { id: 1, League: "All motor racing" },
    { id: 2, League: "All motor racing" },
  ];
  yachtLeaugeSelected = { id: 1, League: "All motor racing" };
  yachtSubLeauge = [
    { id: 1, League: "Mick Schumacher Specials" },
    { id: 2, League: "F1 Abu Dabi Grand Prix" },
  ];
  yachtSubLeaugeSelcted = { id: 1, League: "Mick Schumacher Specials" };

  politicsList = [
    { id: 1, name: "To win an F1 Grand Prix in his career" },
    { id: 2, name: "To sign for Ferrari before 2025" },
    { id: 3, name: "To win an F1 race before the end of 2022 season" },
    { id: 4, name: "To win and F1 World Championships by 2030" },
    { id: 5, name: "To break his dads record of 91 Grand Prix wins" },
  ];
  politicsLeauge = [
    { id: 1, League: "All motor racing" },
    { id: 2, League: "All motor racing" },
  ];
  politicsLeaugeSelected = { id: 1, League: "All motor racing" };
  politicsSubLeauge = [
    { id: 1, League: "Mick Schumacher Specials" },
    { id: 2, League: "F1 Abu Dabi Grand Prix" },
  ];
  politicsSubLeaugeSelcted = { id: 1, League: "Mick Schumacher Specials" };
  fetchRaces = () => {
    let raceType = this.props.match.params.sportName;
    if (raceType === "cycling") {
      this.setState({
        races: this.cyclingRace,
        raceIcon: cyclingIcon,
        raceName: "Cycling",
        leagueTabs: this.cyclingLeauge,
        selectedLeagueTab: this.cyclingLeaugeSelected,
        leagueSubTabs: this.cyclingSubLeauge,
        selectedSubLeagueTab: this.cyclingSubLeaugeSelcted,
      });
    } else if (raceType === "motor") {
      this.setState({
        races: this.motorRace,
        raceIcon: motorIcon,
        raceName: "Motor Racing",
        leagueTabs: this.motorLeauge,
        selectedLeagueTab: this.motorLeaugeSelected,
        leagueSubTabs: this.motorSubLeauge,
        selectedSubLeagueTab: this.motorSubLeaugeSelcted,
      });
    } else if (raceType === "entertainment") {
      this.setState({
        races: this.entertainmentList,
        raceIcon: entertainmentIcon,
        raceName: "Entertainment",
        leagueTabs: this.entertainmentLeauge,
        selectedLeagueTab: this.entertainmentLeaugeSelected,
        leagueSubTabs: this.entertainmentSubLeauge,
        selectedSubLeagueTab: this.entertainmentSubLeaugeSelcted,
      });
    } else if (raceType === "yacht") {
      this.setState({
        races: this.yachtRace,
        raceIcon: yachtIcon,
        raceName: "Yacht Racing",
        leagueTabs: this.yachtLeauge,
        selectedLeagueTab: this.yachtLeaugeSelected,
        leagueSubTabs: this.yachtSubLeauge,
        selectedSubLeagueTab: this.yachtSubLeaugeSelcted,
      });
    } else if (raceType === "politics") {
      this.setState({
        races: this.politicsList,
        raceIcon: politicsIcon,
        raceName: "Politics",
        leagueTabs: this.politicsLeauge,
        selectedLeagueTab: this.politicsLeaugeSelected,
        leagueSubTabs: this.politicsSubLeauge,
        selectedSubLeagueTab: this.politicsSubLeaugeSelcted,
      });
    }
  };
  handleCollapse = (id) => {
    let expandedCloned = this.state.expanded;
    const index = expandedCloned.indexOf(id);
    if (index === -1) {
      expandedCloned.push(id);
    } else {
      expandedCloned.splice(index, 1);
    }

    this.setState({ expanded: expandedCloned });
  };
  handleCompare = (event) => {
    event.stopPropagation();

    let itemToSend = {};
    itemToSend.itemData = this.state.races;
    itemToSend.name = this.state.raceName;
    itemToSend.leagueTabs = this.state.leagueTabs;
    itemToSend.selectedLeagueTab = this.state.selectedLeagueTab;
    itemToSend.leagueSubTabs = this.state.leagueSubTabs;
    itemToSend.selectedSubLeagueTab = this.state.selectedSubLeagueTab;

    EventEmitter.dispatch("draweropen");
    EventEmitter.dispatch("updatebetsList", itemToSend);
  };

  entertainmentRender = () => {
    return (
      <>
        {this.state.races?.map((data, i) => (
          <div className="cycling-racelist-inner m20" key={i}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  className="ent-heading"
                  onClick={() => this.handleCollapse(data.id)}
                >
                  <IoIosArrowDropupCircle />
                  {data.type}
                </Typography>
              </Grid>
              <Grid item xs={6} className="text-right">
                <Typography className="cycling-inner-head">
                  {"1 Market"}
                </Typography>
              </Grid>
            </Grid>
            <Collapse
              in={this.state.expanded.includes(data.id)}
              timeout="auto"
              unmountOnExit
            >
              <Divider />
              <Typography className="ent-inner-head">
                {"Sunday 25/04/2021 12:00"}
              </Typography>
              <Grid container>
                <Grid item xs={12} className="hide-xs">
                  {data?.list?.map((obj, i) => (
                    <Grid container className="cycling-race-listcol" key={i}>
                      <Grid item xs={8} sm={6}>
                        <Typography className="cycling-race-name">
                          <ReactSVG src={this.state.raceIcon} /> {obj.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={6} className="text-right">
                        <Button
                          className="cycling-compare-button"
                          onClick={this.handleCompare}
                        >
                          Compare
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} className="hide-sm hide-md hide-lg hide-xl">
                  {data?.list
                    ?.slice(0, this.state.showRaceNumber)
                    .map((obj, i) => (
                      <Grid container className="cycling-race-listcol" key={i}>
                        <Grid item xs={8}>
                          <Typography className="cycling-race-name">
                            <ReactSVG src={this.state.raceIcon} /> {obj.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} className="text-right">
                          <Button
                            className="cycling-compare-button"
                            onClick={this.handleCompare}
                          >
                            Compare
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                  {this.state.showRaceNumber < data?.list?.length && (
                    <div className="text-center show-all-wrap">
                      <Divider />
                      <Button
                        className="cycling-showall-button"
                        onClick={this.handleShowAll}
                      >
                        Show All <ArrowDropDownCircleRoundedIcon />
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          </div>
        ))}
      </>
    );
  };
  render() {
    const {
      leagueTabs,
      selectedLeagueTab,
      leagueSubTabs,
      selectedSubLeagueTab,
      races,
      raceIcon,
      raceName,
    } = this.state;
    let raceType = this.props.match.params.sportName;
    // console.log("race name : ", raceType);
    return (
      <>
        <div className="cycling-main">
          <Typography className="cycling-heading">{raceName}</Typography>
          <Button className="odds-fluc-button hide-sm hide-md hide-lg hide-xl">
            Odds fluc
          </Button>
          <Divider className="head-hr" />
          <div className="cycling-leagues-wrap">
            <TabList
              isRugby
              handleChange={this.handleChangeLeauge}
              tabs={leagueTabs}
              selectedTab={selectedLeagueTab}
              itemClasses={"cycling-leagues"}
            />
          </div>
          <div className="cycling-racelist">
            <TabList
              isRugby
              handleChange={this.handleChangeSubLeauge}
              tabs={leagueSubTabs}
              selectedTab={selectedSubLeagueTab}
              itemClasses={"cycling-subleagues"}
            />
            <Divider />
            {raceType !== "entertainment" && (
              <Typography className="cycling-date">{"26/06/2021"}</Typography>
            )}
            {raceType !== "entertainment" ? (
              <>
                <div className="cycling-racelist-inner">
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography className="cycling-inner-head">
                        {"21:00"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                      <Typography className="cycling-inner-head">
                        {"1 Market"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <Grid item xs={12} className="hide-xs">
                      {races?.map((obj, i) => (
                        <Grid
                          container
                          className="cycling-race-listcol"
                          key={i}
                        >
                          <Grid item xs={8} sm={6}>
                            <Typography className="cycling-race-name">
                              <ReactSVG src={raceIcon} /> {obj.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={6} className="text-right">
                            <Button
                              className="cycling-compare-button"
                              onClick={this.handleCompare}
                            >
                              Compare
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="hide-sm hide-md hide-lg hide-xl"
                    >
                      {races
                        ?.slice(0, this.state.showRaceNumber)
                        .map((obj, i) => (
                          <Grid
                            container
                            className="cycling-race-listcol"
                            key={i}
                          >
                            <Grid item xs={8}>
                              <Typography className="cycling-race-name">
                                <ReactSVG src={raceIcon} /> {obj.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} className="text-right">
                              <Button
                                className="cycling-compare-button"
                                onClick={this.handleCompare}
                              >
                                Compare
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      {this.state.showRaceNumber < races.length && (
                        <div className="text-center show-all-wrap">
                          <Divider />
                          <Button
                            className="cycling-showall-button"
                            onClick={this.handleShowAll}
                          >
                            Show All <ArrowDropDownCircleRoundedIcon />
                          </Button>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              this.entertainmentRender()
            )}
          </div>
        </div>
      </>
    );
  }
}
