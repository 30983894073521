import React, { Component } from "react";
import "./forgotpassword.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from "@material-ui/core";
import ApiService from "../../services/ApiService";
import loginbg from "../../images/forgotbg.png";
import logo from "../../images/logo.svg";
import axiosInstance from "../../helpers/Axios";
import { connect } from "react-redux";
import { setAuthentication } from "../../library/common/actions";
import { URLS } from "../../library/common/constants";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      password: "",
      confirmpassword: "",
      errors: {},
      isLoading: false,
      id: "",
      token: "",
    };
    this.ApiService = new ApiService();
  }

  componentDidMount = () => {
    if (
      this.props.match.params?.id !== "" &&
      this.props.match.params?.token !== ""
    ) {
      this.setState({
        id: this.props.match.params.id,
        token: this.props.match.params.token,
      });
    }
  };

  submit = (e) => {
    e.preventDefault();
    let { password, confirmpassword, errors } = this.state;
    errors.email = "";
    var validflag = true;

    if (password === "") {
      errors.password = "Please complete this field";
      validflag = false;
    } else if (password.length < 6) {
      errors.password = "Password Must be 6 character";
      validflag = false;
    } else {
      errors.password = "";
    }

    if (confirmpassword === "") {
      errors.confirmpassword = "Please complete this field";
      validflag = false;
    } else {
      if (password !== confirmpassword) {
        errors.confirmpassword =
          "Password doesn’t match. Please enter the same password twice";
        validflag = false;
      } else {
        errors.confirmpassword = "";
      }
    }
    this.setState((prevState) => {
      return { errors: { ...prevState.errors, errors } };
    });

    const { id, token } = this.state;
    const dataapi = { password: password, token: token };

    if (validflag && id !== "" && token !== "") {
      this.setState({ isLoading: true });
      const { errors } = this.state;
      // const response=this.ApiService.forgotPasword({email:this.state.email})

      axiosInstance
        .put(URLS.resetPassword(id), dataapi)
        .then(({ status, data }) => {
          if (status === 200) {
            this.setState({ isLoading: false, message: data.message });
            return (window.location = "/login");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          if (
            err.hasOwnProperty("response") &&
            (err.response.status === 401 || err.response.status === 403)
          ) {
            this.setState((prevState) => {
              return { errors: { ...prevState.errors, errors } };
            });
          } else {
            if ("jwt expired" === err.response.data.message) {
              errors.confirmpassword =
                "Your link to reset password is expired.";
            } else {
              errors.confirmpassword = err.response.data.message;
            }
            this.setState({ errors });
          }
        });
    }
  };
  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => {
      return { [name]: value, errors: { ...prevState.errors, [name]: "" } };
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        <div className="page-content page-bg">
          <Card className={"sign-page-root"}>
            <div className={"sign-page-details"}>
              <CardContent className={"sign-page-content"}>
                <Container>
                  <form
                    className="form login-form"
                    noValidate
                    autoComplete="off"
                  >
                    <Grid
                      container
                      spacing={2}
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item sm={12} xs={12}>
                        <Link to="/" className="logo">
                          <img src={logo} alt="Motzza" />
                        </Link>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        {isLoading && <CircularProgress />}
                        <Typography className="text-left">
                          Enter your New Password to reset your password.
                        </Typography>
                      </Grid>
                      <Grid item className="half-size-input" sm={12} xs={12}>
                        <FormControl>
                          {/* <InputLabel shrink htmlFor="password">Password</InputLabel> */}
                          <Input
                            className={" rounded-input"}
                            inputProps={{ placeholder: "Password" }}
                            id="password"
                            name="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            disableUnderline
                          />
                          {typeof this.state.errors.password !== "undefined" &&
                          this.state.errors.password !== "" ? (
                            <FormHelperText id="component-helper-text-password">
                              <span className="error color-danger">
                                {this.state.errors.password}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        className="half-size-input reset-password"
                        sm={12}
                        xs={12}
                      >
                        <FormControl>
                          {/* <InputLabel shrink htmlFor="password">Password</InputLabel> */}
                          <Input
                            className={" rounded-input"}
                            inputProps={{ placeholder: "Confirm Password" }}
                            id="confirmpassword"
                            name="confirmpassword"
                            type="password"
                            value={this.state.confirmpassword}
                            onChange={this.handleChange}
                            disableUnderline
                          />
                          {typeof this.state.errors.confirmpassword !==
                            "undefined" &&
                          this.state.errors.confirmpassword !== "" ? (
                            <FormHelperText id="component-helper-text-password">
                              <span className="error color-danger">
                                {this.state.errors.confirmpassword}
                              </span>
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                          {typeof this.state.message !== "undefined" &&
                            this.state.message !== "" && (
                              <FormHelperText
                                className="helper-text-successfuly"
                                id="component-helper-text-password"
                              >
                                <span className="color-green">
                                  {this.state.message}
                                </span>
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item sm={12} xs={12} className="register-button">
                        <Button
                          type="button"
                          onClick={this.submit.bind(this)}
                          //   className="submit-btn bg-orange"
                          className="btn btn-warning btn-reset-password"
                          variant="contained"
                        >
                          RESET
                        </Button>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <p className="signup">
                          <Link to="/login">Login</Link>
                        </p>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </CardContent>
            </div>
            <CardMedia className={"sign-page-cover"} image={loginbg}>
              <img src={loginbg} alt="" />
            </CardMedia>
          </Card>
        </div>
      </>
    );
  }
}

export default connect(null, { setAuthentication })(ResetPassword);
