import { Validators } from "../../../library/utilities/Validators";

export const bookkeeperFormModel = [
  {
    label: "Name",
    value: "",
    type: "text",
    placeholder: "Name",
    field: "name",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
  },
  {
    label: "Variation",
    value: "",
    type: "variations",
    placeholder: "Variation",
    field: "variation",
    required: false,
    className: "12",
  },
  {
    label: "Status",
    value: "",
    type: "dropdown",
    placeholder: "Status",
    field: "status",
    validators: [
      { check: Validators.required, message: "This field is mandatory" },
    ],
    required: true,
    className: "12",
    options: [
      { value: "", label: "No Status Selected" },
      { id: 1, value: "active", label: "active" },
      { id: 2, value: "inactive", label: "inactive" },
      { id: 3, value: "deleted", label: "deleted" },
    ],
  },
];
