import moment from "moment";

import axiosInstance from "../../../helpers/Axios";
import { config } from "../../../helpers/config";

export function loader(action) {
  return {
    type: "LOADER_VIEW",
    payload: action,
  };
}

export function errorHandler(error) {
  return {
    type: "ERROR_HANDLER",
    payload: error,
  };
}

export function authHandler(auth) {
  return {
    type: "AUTH_HANDLER",
    payload: auth,
  };
}

export function clearAuth() {
  return {
    type: "CLEAR_AUTH",
  };
}

export const setRacings = (racings, upcomingRaces) => (dispatch) => {
  dispatch({ type: "SET_RACINGS", payload: { racings, upcomingRaces } });
};

export const removeComplatedUpcommingRace = (upcomingRacesItem) => (
  dispatch
) => {
  dispatch({ type: "REMOVE_UPCOMMING_RACE", payload: upcomingRacesItem });
};

export const fetchRacings = (catName) => async (dispatch) => {
  // const id = config.baseUrl === "https://local.motzza.com.au/api" ? 10 : 15;
  // const allCat = await axiosInstance.get("/category");
  // let catNameParam = catName;
  // let FilteredCat = allCat?.data?.filter(
  //   (cat) => cat.id === (catName ? Number(catName) : id)
  // );
  // if (FilteredCat?.length > 0) {
  //   catNameParam = FilteredCat[0].name;
  // }
  // if (typeof catNameParam !== "undefined" && catNameParam !== "") {
  //   dispatch({ type: "SET_SPORT_LOADING", payload: true });
  //   const { status, data } = await axiosInstance.get(
  //     "/af/category?sportName=" + catNameParam
  //   );
  //   if (status === 200 && data && data.status) {
  //     let horseRaces = [];
  //     if (data?.categories?.length > 0) {
  //       horseRaces = data.categories[0].races;
  //       dispatch({ type: "SET_SPORTDATA", payload: data.categories[0] });
  //       dispatch({ type: "SET_SPORT_LOADING", payload: false });
  //     }
  //     dispatch({ type: "SET_RACINGS", payload: horseRaces });
  //   }
  // }
};

export const fetchUpcomingRaces = (filter, country) => async (dispatch) => {
  let raceUrl = `/events/upcoming?`;
  // let raceUrl = `/af/upcoming_race?`;
  if (typeof filter !== "undefined" && filter !== "") {
    let allremoved = filter.filter((item) => item.isChecked);
    let sportId = allremoved?.map((item) => item.id);

    raceUrl += "sportId=" + sportId.join(",");
  } else {
    raceUrl += "sportId=";
  }
  // if (typeof country !== "undefined" && country !== "") {
  //   let allcountryremoved = country.filter((item) => item.isChecked);
  //   let MeetingState = allcountryremoved?.map((item) => item.value);
  //   raceUrl += "&MeetingState=" + MeetingState.join(",");
  // } else {
  //   raceUrl += "&MeetingState=";
  // }

  axiosInstance
    .get(raceUrl)
    .then((res) => {
      dispatch({ type: "SET_UPCOMING_RACE", payload: res.data.result });
    })
    .catch((err) => console.log(err));

  // const id = config.baseUrl === "https://local.motzza.com.au/api" ? 10 : 15;
  // const allCat = await axiosInstance.get("/category");
  // let catNameParam = catName;
  // let FilteredCat = allCat?.data?.filter(
  //   (cat) => cat.id === (catName ? Number(catName) : id)
  // );
  // if (FilteredCat?.length > 0) {
  //   catNameParam = FilteredCat[0].name;
  // }
  // let raceUrl = `/af/upcoming_race?`;
  // if (typeof catNameParam !== "undefined" && catNameParam !== "") {
  //   raceUrl += "sportName=" + catNameParam;
  // } else {
  //   raceUrl += "sportName=";
  // }
  // raceUrl += "&MeetingState=";
  // axiosInstance
  //   .get(raceUrl)
  //   .then((res) => {
  //     dispatch({ type: "SET_UPCOMING_RACE", payload: res.data.races });
  //   })
  //   .catch((err) => console.log(err));
};
