import React, { Component } from "react";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Button,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import arrowLeft from "../../images/blog_img/paginationArrowLeft.svg";
import arrowRight from "../../images/blog_img/paginationArrowRight.svg";
import { ReactSVG } from "react-svg";
import axiosInstance from "../../helpers/Axios";
import ShowModal from "../../components/common/ShowModal/ShowModal";
import ActionMessage from "../../library/common/components/ActionMessage";

export default class Contact extends Component {
  state = {
    data: [],
    itemToDelete: null,
    isDeleteModalOpen: false,
    message: null,
    isModalOpen: false,
    currentPage: 1,
    rowPerPage: 20,
    messageBox: {
      display: false,
      type: "",
      message: "",
    },
  };

  componentDidMount() {
    this.fetchAllMessages();
  }

  fetchAllMessages = async () => {
    try {
      const { status, data } = await axiosInstance.get("contact");
      if (status === 200) {
        this.setState({ data: data.contacts });
      }
    } catch (err) {}
  };

  viewMessage = (item) => () => {
    this.setState({ message: item, isModalOpen: true, itemToDelete: item?.id });
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: false,
      message: null,
    });
  };

  handlePaginationClick = (event, page) => {
    this.setState({
      currentPage: Number(page),
    });
  };

  handlePaginationButtonClick = (navDirection) => {
    let { currentPage, rowPerPage, data } = this.state;
    if (navDirection === "prev") {
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    } else {
      if (currentPage < data.length / rowPerPage)
        this.setState({ currentPage: currentPage + 1 });
    }
  };

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
  };

  deleteItem = async () => {
    try {
      const { status } = await axiosInstance.delete(
        `contact/${this.state.itemToDelete}`
      );
      if (status === 200) {
        this.setState(
          { itemToDelete: null, isDeleteModalOpen: false, isModalOpen: false },
          () => {
            this.fetchAllMessages();
          }
        );
        this.setActionMessage(true, "Success", "Message Deleted Successfully!");
      }
    } catch (err) {
      this.setActionMessage(true, "Error", "An error occured while deleting.");
    }
  };

  setActionMessage = (display = false, type = "", message = "") => {
    this.setState({ messageBox: { display, type, message } }, () =>
      setTimeout(
        () =>
          this.setState({
            messageBox: { display: false, type: "", message: "" },
          }),
        3000
      )
    );
  };

  render() {
    const {
      isModalOpen,
      message,
      rowPerPage,
      currentPage,
      data,
      isDeleteModalOpen,
      messageBox,
    } = this.state;
    const pageNumbers = [];
    let currentPageRow = data;

    if (data?.length > 0) {
      const indexOfLastTodo = currentPage * rowPerPage;
      const indexOfFirstTodo = indexOfLastTodo - rowPerPage;
      currentPageRow = data.slice(indexOfFirstTodo, indexOfLastTodo);

      for (let i = 1; i <= Math.ceil(data.length / rowPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <Grid container className="page-content adminLogin">
        <Grid item xs={12}>
          <Paper className="pageWrapper">
            {messageBox.display && (
              <ActionMessage
                message={messageBox.message}
                type={messageBox.type}
                styleClass={messageBox.styleClass}
              />
            )}

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={6}>
                <h3 className="text-left">Contact Requests</h3>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="space-around">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table className="listTable" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>DID</TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Additional Details</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentPageRow.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.firstname}</TableCell>
                          <TableCell>{item.lastname}</TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.message}</TableCell>
                          <TableCell>{item.additional_details}</TableCell>
                          <TableCell>
                            <VisibilityIcon
                              onClick={this.viewMessage(item)}
                              color="primary"
                              className="mr10 cursor iconBtn admin-btn-green"
                            />
                            <DeleteOutlineIcon
                              color="secondary"
                              className="cursor iconBtn admin-btn-orange"
                              onClick={() =>
                                this.setState({
                                  itemToDelete: item.id,
                                  isDeleteModalOpen: true,
                                })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {data?.length > 0 && (
                    <div className="tablePagination">
                      <button
                        className={
                          data.length / rowPerPage > 1
                            ? "btn-navigation"
                            : "btn-navigation-disabled"
                        }
                        disabled={data.length / rowPerPage > 1 ? false : true}
                        onClick={() => this.handlePaginationButtonClick("prev")}
                      >
                        <ReactSVG src={arrowLeft} />
                      </button>
                      <Pagination
                        hideNextButton
                        hidePrevButton
                        disabled={data.length / rowPerPage > 1 ? false : true}
                        page={currentPage}
                        onChange={this.handlePaginationClick}
                        count={pageNumbers[pageNumbers?.length - 1]}
                        siblingCount={2}
                        boundaryCount={1}
                        size="small"
                      />
                      <button
                        className={
                          data.length / rowPerPage > 1
                            ? "btn-navigation"
                            : "btn-navigation-disabled"
                        }
                        disabled={data.length / rowPerPage > 1 ? false : true}
                        onClick={() => this.handlePaginationButtonClick("next")}
                      >
                        <ReactSVG src={arrowRight} />
                      </button>
                    </div>
                  )}
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>

          <ShowModal
            isModalOpen={isDeleteModalOpen}
            onClose={this.toggleDeleteModal}
            Content="Are you sure you want to delete?"
            onOkayLabel="Yes"
            onOkay={this.deleteItem}
            onCancel={this.toggleDeleteModal}
          />

          <Modal
            className="modal"
            open={isModalOpen}
            onClose={this.toggleModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {message && (
              <div className={"paper"}>
                <p>
                  {message.firstname} {message.lastname}
                </p>
                <p>{message.email}</p>
                <p>{message.message}</p>
                <p>{message.additional_details}</p>
                <br />
                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button color="secondary" onClick={this.deleteItem}>
                    Delete
                  </Button>
                  <Button color="primary" onClick={this.toggleModal}>
                    Close
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </Grid>
      </Grid>
    );
  }
}
