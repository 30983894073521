import React from "react";
import {
  Grid,
  Divider,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Typography,
  withStyles,
  Collapse,
  Accordion,
  Select,
  MenuItem,
  Paper,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { EventEmitter } from "../../../../services/event";
import HorseIcon from "../../../../images/allsport_racing/runners_table/runner_horse.svg";
import GreyHoundsIcon from "../../../../images/allsport_racing/runners_table/runner_greys.svg";
import HarnessIcon from "../../../../images/allsport_racing/runners_table/runner_harness.svg";
import "./singleRace.scss";
import { ReactSVG } from "react-svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import axiosInstance from "../../../../helpers/Axios";
import moment from "moment";
import { Loader } from "../../../../library/common/components";
import { AFURLS } from "../../../../helpers/constants";

import { ReactComponent as Bet365 } from "./../../../../images/bookmarks_logo/small/bet365.svg";
import { ReactComponent as BetStar } from "./../../../../images/bookmarks_logo/small/betstar.svg";
import { ReactComponent as BookMakers } from "./../../../../images/bookmarks_logo/small/bookmaker.svg";
import { ReactComponent as LadBrokes } from "./../../../../images/bookmarks_logo/small/ladbrokes.svg";
import { ReactComponent as Neds } from "./../../../../images/bookmarks_logo/small/neds.svg";
import { ReactComponent as PlayUp } from "./../../../../images/bookmarks_logo/small/playup.svg";
import { ReactComponent as VICBet } from "./../../../../images/bookmarks_logo/small/VICBet.svg";

import ResBetIcon from "./../../../../images/res_bookmaker/BetIcon.svg";
import RNedsIcon from "./../../../../images/res_bookmaker/neds.svg";
import ResBet365Icon from "./../../../../images/res_bookmaker/bet365.svg";
import ResBetStarIcon from "./../../../../images/res_bookmaker/betstar.svg";
import ResRedCIcon from "./../../../../images/res_bookmaker/redC.svg";
import ResBlackBIcon from "./../../../../images/res_bookmaker/blackB.svg";
import ResVICBetIcon from "./../../../../images/res_bookmaker/VICBet.svg";
import UpDownIcon from "./../../../../images/updown.svg";

import OddsHighChart from "./highChart";
import RaceCountdown from "./raceCountdown";
import TrackListCountdown from "./TrackListCountdown";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "rgb(0 0 0 / 49%)",
    backgroundColor: "transparent",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  body: {
    fontSize: 14,
    color: "#000",
    cursor: "pointer",
    fontWeight: 600,
    fontFamily: "Open Sans",
    borderRight: "0.5px solid #ded7d7",
    borderBottom: "none",
  },
}))(TableCell);

const styles = (theme) => ({
  dropdownStyle: {
    borderRadius: "5px",
    // width: 251,
    // height: 430,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      borderRadius: "10px 10px 0px 0px",
    },
  },
});

const StyledTableRow = withStyles((theme) => ({}))(TableRow);

const oddsArray = [
  { id: 1, name: "Bookmaker Odds" },
  { id: 2, name: "Win Odds" },
];

class AllSportsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raceTrackClicked: false,
      openRaceRow: null,
      selectedodds: "",
      singleRaceData: [],
      runnersDetails: [],
      runnersOdds: [],
      runnersWin: [],
      isLoading: "",
      raceTrackdata: "",
      isRunnerLoading: false,
      isGraphLoading: false,
      oddLoading: false,
      isRunnerOddLoading: false,
      isRunnerCommentLoading: false,
      expandForm: "Expend Form",
      runnersComments: {},
      runnersChartData: [],
      isRunnersResult: false,
      isResultLoading: false,
      commentInnerExpand: "",
      raceDataByRunner: "",
      isRaceFinished: false,
      mobileFluc: "",
      runnerFluc: [],
    };
    EventEmitter.subscribe("hide_raceclicked", this.raceChangingClick);
    EventEmitter.subscribe("check_race_ispast", this.checkSelectedRacePast);
  }

  raceChangingClick = () => {
    this.setState({ raceTrackClicked: false, isRaceFinished: false });
  };

  componentDidMount = () => {
    let { raceTrackClicked, raceTrackdata } = this.props;
    this.setState(
      {
        selectedodds: oddsArray[0].name,
        raceTrackClicked:
          raceTrackClicked !== undefined
            ? raceTrackClicked
            : this.state.raceTrackClicked,
        raceTrackdata:
          raceTrackdata !== undefined
            ? raceTrackdata
            : this.state.raceTrackdata,
      },
      () => {
        if (this.state.raceTrackClicked) {
          this.fetchRaceRunner(this.props.trackNumber);
          this.fetchDirectData();
        } else {
          this.fetchSingleRacing();
        }
      }
    );
    // this.fetchSingleRacing();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    // this.fetchOddsAuto(this.props.trackNumber, this.state.raceTrackdata);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.selectedCountry !== this.props.selectedCountry ||
      prevProps.selectedLocation !== this.props.selectedLocation ||
      prevProps.selectedDate !== this.props.selectedDate ||
      prevProps.title !== this.props.title
    ) {
      if (this.state.raceTrackClicked) {
        this.fetchSingleRacing(true);
      } else {
        this.fetchSingleRacing();
      }
    }
    if (this.state.raceTrackClicked) {
      if (prevProps.trackNumber !== this.props.trackNumber) {
        this.fetchRaceRunner(this.props.trackNumber);
        // this.fetchOddsAuto(this.props.trackNumber, this.state.raceTrackdata);
        this.setState({ selectedodds: "Bookmaker Odds", runnerFluc: [] });
      }
    }
    if (prevProps.raceTrackdata !== this.props.raceTrackdata) {
      this.setState({ raceTrackdata: this.props.raceTrackdata });
    }
    if (prevState.raceTrackClicked !== this.state.raceTrackClicked) {
      window.scrollTo(0, 0);
    }
    if (prevProps.title !== this.props.title) {
      this.setState({ selectedodds: oddsArray[0].name });
    }
  };

  fetchDirectData = async () => {
    const { title, trackNumber } = this.props;
    const { raceTrackdata } = this.state;
    this.setState({ isLoading: "singleRace", singleRaceData: [] });
    let date = moment(new Date()).format("YYYY-MM-DD");
    let id = 1;
    if (title === "Horse Racing") {
      id = 1;
    } else if (title === "Greyhounds Racing") {
      id = 3;
    } else {
      id = 2;
    }
    try {
      const { status, data } = await axiosInstance.get(
        `events/trackList/${id}?todate=${date}&countryId=&stateId=`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? data?.events?.map((obj) => {
                return {
                  ...obj,
                  race: obj?.race?.filter((race) => race?.raceNumber != 0),
                };
              })
            : [];
        this.setState({ singleRaceData: data_pass, isLoading: "" });
        let race_event = data_pass?.filter(
          (obj) => obj?.id === raceTrackdata?.eventId
        );
        let data_to_pass = {
          // raceTrackClicked: true,
          full_race: race_event[0],
          selectedRaceTrack: trackNumber,
          singleRaceData: data_pass,
        };
        EventEmitter.dispatch("racetrack_clicked", data_to_pass);
      }
    } catch (err) {}
  };

  checkSelectedRacePast = (race_data) => {
    if (race_data?.startTimeDate !== null) {
      if (moment(new Date(race_data?.startTimeDate)).isBefore(new Date())) {
        this.fetchRunnersWin(race_data?.id);
        this.setState({
          isRunnersResult: true,
        });
      } else {
        this.setState({
          isRunnersResult: false,
        });
      }
    }
  };

  handleExpandForm = (e) => {
    this.setState({ expandForm: e.target.value });
  };

  fetchSingleRacing = async (isRUnnersList) => {
    const { title, selectedCountry, selectedLocation, selectedDate } =
      this.props;
    this.setState({ isLoading: "singleRace", singleRaceData: [] });
    let date_to_pass =
      selectedDate !== ""
        ? moment(selectedDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD");
    let country_to_pass = selectedCountry === "none" ? "" : selectedCountry;
    let state_to_pass = selectedLocation === "none" ? "" : selectedLocation;
    let id = 1;
    if (title === "Horse Racing") {
      id = 1;
    } else if (title === "Greyhounds Racing") {
      id = 3;
    } else {
      id = 2;
    }
    try {
      const { status, data } = await axiosInstance.get(
        `events/trackList/${id}?todate=${date_to_pass}&countryId=${country_to_pass}&stateId=${state_to_pass}`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? data?.events?.map((obj) => {
                return {
                  ...obj,
                  race: obj?.race?.filter((race) => race?.raceNumber != 0),
                };
              })
            : [];
        this.setState({ singleRaceData: data_pass, isLoading: "" });
        if (isRUnnersList) {
          let race_obj =
            data_pass?.length > 0
              ? data_pass[0]?.race?.length > 0
                ? data_pass[0]?.race[0]
                : ""
              : "";
          if (race_obj !== "") {
            let data_to_pass = {
              raceTrackClicked: true,
              full_race: data_pass[0],
              selectedRaceTrack: race_obj?.id,
              singleRaceData: data_pass,
            };
            EventEmitter.dispatch("racetrack_clicked", data_to_pass);
            this.fetchRaceRunner(race_obj?.id);
            this.setState({ raceTrackdata: race_obj });
            if (race_obj.startTimeDate !== null) {
              return moment(new Date(race_obj?.startTimeDate)).isBefore(
                new Date()
              )
                ? () => {
                    this.fetchRunnersWin(race_obj?.id);
                    this.setState({ isRunnersResult: true });
                  }
                : this.setState({ isRunnersResult: false });
            }
          } else {
            this.setState({
              singleRaceData: [],
              runnersDetails: [],
              runnersOdds: [],
            });
          }
        }
      }
    } catch (err) {}
  };

  RaceIconHandle = (title) => {
    switch (title) {
      case "Horse Racing":
        return <ReactSVG src={HorseIcon} />;
      case "Greyhounds Racing":
        return <ReactSVG src={GreyHoundsIcon} />;
      case "Harness Racing":
        return <ReactSVG src={HarnessIcon} className="single-race-harness" />;
      default:
        return "";
    }
  };

  fetchFlucs = async (id) => {
    // let { runnerFluc } = this.state;
    let params = { runners: [id] };
    const { status, data } = await axiosInstance.post(`events/fluc`, params);
    if (status === 200) {
      this.setState({
        runnerFluc: [...this.state.runnerFluc, data.result[0]],
      });
    }
  };

  fetchRaceRunner = async (race) => {
    this.setState({
      isRunnerLoading: true,
      openRaceRow: null,
    });
    try {
      const { status, data } = await axiosInstance.get(
        `/events/runner/${race}`
      );
      if (status === 200) {
        data.result.map((runner) => {
          this.fetchFlucs(runner.id);
        });
        this.setState({
          runnersDetails: data?.result,

          isRunnerLoading: false,
          raceDataByRunner: data?.race,
        });
        this.fetchRunnersOdds(race);
        this.fetchRunnersGraphData(race);
      }
    } catch (err) {
      console.log(err);
      this.setState({ isRunnerLoading: true });
    }
  };

  fetchRunnersOdds = async (race) => {
    this.setState({ isRunnerOddLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${race}`
      );
      if (status === 200) {
        this.setState({ runnersOdds: data?.result, isRunnerOddLoading: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: "" });
    }
  };

  fetchRunnersGraphData = async (race) => {
    this.setState({ isGraphLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getGraphByrace/${race}`
      );
      if (status === 200) {
        this.setState({
          runnersChartData: data?.result,
          isGraphLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchRunnersWin = async (race) => {
    this.setState({ isResultLoading: true });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getRaceResult/${race}`
      );
      if (status === 200) {
        this.setState({ runnersWin: data?.result, isResultLoading: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ isResultLoading: false });
    }
  };

  selectRaceTrackHandler = async (race, race_obj, full_race) => {
    let { singleRaceData } = this.state;
    let data_to_pass = {
      raceTrackClicked: true,
      full_race: full_race,
      selectedRaceTrack: race,
      singleRaceData: singleRaceData,
    };
    EventEmitter.dispatch("racetrack_clicked", data_to_pass);
    this.setState({
      raceTrackClicked: true,
      raceTrackdata: race_obj,
    });
    // this.fetchRaceRunner(race);
  };

  handleRaceRow = async (index, runnerId, type) => {
    let { openRaceRow } = this.state;
    this.setState({ commentInnerExpand: "", isRunnerCommentLoading: true });
    if (openRaceRow == index) {
      this.setState({ openRaceRow: null });
    } else {
      this.setState({ openRaceRow: index });
      if (type !== "odds") {
        try {
          const { status, data } = await axiosInstance.get(
            `events/runnerExtraInfo/${runnerId}`
          );
          if (status === 200) {
            this.setState({
              runnersComments: data?.RaceParticipantData,
              isRunnerCommentLoading: false,
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  renderSufixes = (num) => {
    switch (num) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
      default:
        return `${num}th`;
    }
  };

  handleOdds = (e) => {
    this.setState({ selectedodds: e.target.value, openRaceRow: null });
  };

  bestOddsRenderer = (id) => {
    switch (id) {
      case 1:
        return (
          <a href={AFURLS?.payups} target="_blank">
            <PlayUp />
          </a>
        );
      case 2:
        return (
          <a href={AFURLS?.vicbet} target="_blank">
            <VICBet />
          </a>
        );
      case 3:
        return (
          <a href={AFURLS?.Neds} target="_blank">
            <Neds />
          </a>
        );
      case 4:
        return (
          <a href={AFURLS?.Ladbrokes} target="_blank">
            <LadBrokes />
          </a>
        );
      case 5:
        return (
          <a href={AFURLS?.Bookmaker} target="_blank">
            <BookMakers />
          </a>
        );
      case 6:
        return (
          <a href={AFURLS?.BetStar} target="_blank">
            <BetStar />
          </a>
        );
      case 8:
        return (
          <a href={AFURLS?.Bet365} target="_blank">
            <Bet365 />
          </a>
        );
      default:
        return (
          <a href={AFURLS?.payups} target="_blank">
            <PlayUp />
          </a>
        );
    }
  };

  renderWinOddsTable = (item, index, view, title) => {
    let { openRaceRow, isRunnersResult, isGraphLoading } = this.state;
    let odds = item?.odds;
    if (odds?.length > 0) {
      odds = odds?.filter((obj) => obj?.bookKeepersId !== null);

      let ref_array = [];
      let odds_clone = [];
      odds.map((element) => {
        if (ref_array.includes(element.bookKeepersId)) {
          let index = ref_array.indexOf(element.bookKeepersId);
          let obj = odds_clone[index];
          if (element.key == 2) {
            obj["NSEPlcce"] = element.value;
          } else {
            obj["Win"] = element.value;
          }
          odds_clone[index] = obj;
        } else {
          ref_array.push(element.bookKeepersId);
          let obj = element;

          if (element.key == 1) {
            obj["NSEPlcce"] = 0;
            obj["Win"] = element.value;
          } else {
            obj["Win"] = 0;
            obj["NSEPlcce"] = element.value;
          }
          odds_clone.push(obj);
        }
      });
      odds = odds_clone;
    }

    let pup = odds?.find((odd) => odd?.bookKeepersId === 1);
    let vbt = odds?.find((odd) => odd?.bookKeepersId === 2);
    let neds = odds?.find((odd) => odd?.bookKeepersId === 3);
    let ladbrokes = odds?.find((odd) => odd?.bookKeepersId === 4);
    let bookmarker = odds?.find((odd) => odd?.bookKeepersId === 5);
    let betstar = odds?.find((odd) => odd?.bookKeepersId === 6);
    let bet365 = odds?.find((odd) => odd?.bookKeepersId === 8);
    // let palmerbet = odds?.find((odd) => odd?.bookKeepersId === 9);

    return view === "desktop" ? (
      <>
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <div className="mmaw-container">
              <div className="mma-blckIcon">{this.RaceIconHandle(title)}</div>
              <div>
                <div>
                  <p className="wining-fightername">
                    {item?.animal?.name} &nbsp;{" "}
                    <span className="race-wining-gate">
                      {!isRunnersResult ? item?.barrierNumber : ""}
                    </span>
                  </p>
                </div>
                <div className="wining-desc">
                  <div className="wining-weight-method">
                    <p>{item?.JockeyWeight}</p>
                    <p>{/* [Final] */}</p>
                  </div>
                  <div className="mma-wining-round">
                    <p>
                      {item?.member?.length > 0 &&
                        item?.member[0]?.player?.name}
                    </p>
                    <p>{/* [Trainer] */}</p>
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell align="center">
            <p className="best-at-open-content">{item?.bestOpenValue}</p>
          </TableCell>
          <TableCell
            className="sport-graph-main"
            style={{
              textAlign: "-webkit-center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isGraphLoading ? (
              <div className="graph-loader-div">
                <Loader />
              </div>
            ) : (
              <p className="sport-graph-content">
                <OddsHighChart data={item?.graphData} />
              </p>
            )}
          </TableCell>
          <TableCell align="center" className="winning-best-content">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="best-content-icon"
                    style={{ maxWidth: 60.75 }}
                  >
                    {this.bestOddsRenderer(item?.bestOdds?.bookKeepersId)}
                  </div>
                </div>
                <p
                  style={{
                    fontWeight: 600,
                    marginBlockStart: 10.34,
                    marginBlockEnd: 0,
                  }}
                >
                  {item?.bestOdds?.value !== undefined
                    ? Number(item?.bestOdds?.value).toFixed(2)
                    : Number(0).toFixed(2)}
                </p>
              </div>
            </div>
          </TableCell>
          <TableCell align="center">
            {bet365 !== undefined
              ? isNaN(Number(bet365?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(bet365?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          <TableCell align="center">
            {betstar !== undefined
              ? isNaN(Number(betstar?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(betstar?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          <TableCell align="center">
            {bookmarker !== undefined
              ? isNaN(Number(bookmarker?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(bookmarker?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          <TableCell align="center">
            {ladbrokes !== undefined
              ? isNaN(Number(ladbrokes?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(ladbrokes?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          <TableCell align="center">
            {neds !== undefined
              ? isNaN(Number(neds?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(neds?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          <TableCell align="center">
            {pup !== undefined
              ? isNaN(Number(pup?.Win).toFixed(2))
                ? Number(0).toFixed(2)
                : Number(pup?.Win).toFixed(2)
              : Number(0).toFixed(2)}
          </TableCell>
          {title === "Horse Racing" && (
            <TableCell align="center">
              {vbt !== undefined
                ? isNaN(Number(vbt?.Win).toFixed(2))
                  ? Number(0).toFixed(2)
                  : Number(vbt?.Win).toFixed(2)
                : Number(0).toFixed(2)}
            </TableCell>
          )}
        </TableRow>
      </>
    ) : (
      <>
        <div
          style={{
            display: "flex",
            height: 67,
            alignItems: "center",
            borderBottom: "0.5px solid #ded7d7",
            paddingLeft: 15,
          }}
        >
          <div className="rthname res-wods-nb">
            <div className="rdt-mv-icon">{this.RaceIconHandle(title)}</div>
            <div style={{ width: 105, fontWeight: 600 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="rth-horsename">{item?.animal?.name}</span>
                <span className="rth-gatename">{item?.barrierNumber}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="rth-weightname">{item?.JockeyWeight}</span>
                <span className="rth-jockeyname">
                  {item?.member?.length > 0 && item?.member[0]?.player?.name}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="rth-weightname">{/* [Final] */}</span>
                <span className="rth-jockeyname">{/* [Trainer] */}</span>
              </div>
            </div>
            <div
              className="odds-dropdown"
              onClick={() => this.handleRaceRow(index, item?.id, "odds")}
            >
              <div className="res-sodds-img">
                <img src={ResBetIcon} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 10,
                }}
              >
                <ArrowForwardIosIcon
                  className={
                    openRaceRow == index
                      ? "res-odd-arrow-rotate"
                      : "res-odd-arrow"
                  }
                />
              </div>
            </div>
          </div>

          <div className="res-best-at-open">
            <p>{item?.bestOpenValue}</p>
          </div>
          <div className="res-winingodd-graph">
            {isGraphLoading ? (
              <div className="graph-loader-div">
                <Loader />
              </div>
            ) : (
              <p className="sport-graph-content">
                <OddsHighChart data={item?.graphData} />
              </p>
            )}
          </div>
        </div>
        <div>
          <Collapse in={openRaceRow == index} timeout="auto" unmountOnExit>
            {/* <div className="single_race_open_main"> */}
            <div className="res-odds-drpdwnbookmaker">
              <div className="odd-dpdwn-opt">
                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.Neds} target="_blank">
                      <img src={RNedsIcon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {neds !== undefined
                      ? isNaN(Number(neds?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(neds?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.payups} target="_blank">
                      <img src={ResBetIcon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {pup !== undefined
                      ? isNaN(Number(pup?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(pup?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.Bet365} target="_blank">
                      <img src={ResBet365Icon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {bet365 !== undefined
                      ? isNaN(Number(bet365?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(bet365?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.BetStar} target="_blank">
                      <img src={ResBetStarIcon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {betstar !== undefined
                      ? isNaN(Number(betstar?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(betstar?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.Ladbrokes} target="_blank">
                      <img src={ResRedCIcon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {ladbrokes !== undefined
                      ? isNaN(Number(ladbrokes?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(ladbrokes?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                <div>
                  <div className="res-odd-opt">
                    <a href={AFURLS?.Bookmaker} target="_blank">
                      <img src={ResBlackBIcon} className="res-odopt" />
                    </a>
                  </div>
                  <p className="bookmaker-option-value">
                    {bookmarker !== undefined
                      ? isNaN(Number(bookmarker?.Win).toFixed(2))
                        ? Number(0).toFixed(2)
                        : Number(bookmarker?.Win).toFixed(2)
                      : Number(0).toFixed(2)}
                  </p>
                </div>

                {title === "Horse Racing" && (
                  <div>
                    <div className="res-odd-opt">
                      <a href={AFURLS?.vicbet} target="_blank">
                        <img src={ResVICBetIcon} className="res-odopt" />
                      </a>
                    </div>
                    <p className="bookmaker-option-value">
                      {vbt !== undefined
                        ? isNaN(Number(vbt?.Win).toFixed(2))
                          ? Number(0).toFixed(2)
                          : Number(vbt?.Win).toFixed(2)
                        : Number(0).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* </div> */}
          </Collapse>
        </div>
      </>
    );
  };

  checkRaceMinCell = (time, type) => {
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(time).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");
    let class_to_pass = "";
    if (type === "desktop") {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 600 && diff_sec > 0
          ? "upcoming_race_cell"
          : diff_sec < 0
          ? "upcoming_race_cell_close"
          : ""
        : "";
    } else {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 600 && diff_sec > 0
          ? "upcoming_race_mobile"
          : ""
        : "";
    }
    return class_to_pass;
  };

  fetchRunnerOdds = async (id) => {
    this.setState({ oddLoading: true });
    let odds = [];
    try {
      const { status, data } = await axiosInstance.get(`/events/odd/${id}`);
      if (status === 200) {
        odds = data.result;
        this.setState({ oddLoading: false });
      }
    } catch (err) {}
    return odds;
  };

  handleCompare = async (item) => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const { title, selected_event } = this.props;
    const { raceTrackdata } = this.state;
    let RunnerOdds = await this.fetchRunnerOdds(item?.id);

    var emititem = { ...item };
    emititem.sportName = title;
    emititem.sportType = ["racing"];
    emititem.raceNumber = raceTrackdata?.raceNumber;
    emititem.sportTitle = selected_event?.eventName;
    emititem.race = raceTrackdata?.raceName;
    emititem.RunnerOdds = RunnerOdds;
    emititem.raceTime = moment
      .utc(raceTrackdata?.startTimeDate)
      .local()
      .format("DD MMM YYYY hh:mm a");
    emititem.imageSrc = title;
    emititem.title = `${item?.runnerNumber} ${item?.animal?.name} (${
      item.barrierNumber !== "" && item.barrierNumber !== null
        ? item.barrierNumber
        : 0
    })`;
    EventEmitter.dispatch("draweropen");
    EventEmitter.dispatch("updatebetsList", emititem);
  };

  handleCommentInnerAccordian = (name) => {
    if (this.state.commentInnerExpand == name) {
      this.setState({ commentInnerExpand: "" });
    } else {
      this.setState({ commentInnerExpand: name });
    }
  };

  // fetchOddsAuto = (trackNumber, raceTrackdata) => {
  //   clearInterval(this.oddsIntervalId);
  //   console.log("start interval", trackNumber);
  //   let time = new Date(raceTrackdata?.startTimeDate);
  //   let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
  //   let end_time_string = moment(raceTrackdata?.startTimeDate).format(
  //     "YYYY/MM/DD HH:mm:ss"
  //   );
  //   let diff = moment(end_time_string).diff(current_time_string, "minute");
  //   if (!moment(time).isBefore(new Date())) {
  //     if (trackNumber !== 0) {
  //       if (diff < 60 && diff > 0) {
  //         this.oddsIntervalId = setInterval(() => {
  //           this.fetchRunnersOdds(trackNumber);
  //         }, 20000);
  //       } else {
  //         this.oddsIntervalId = setInterval(() => {
  //           this.fetchRunnersOdds(trackNumber);
  //         }, 40000);
  //       }
  //     }
  //   }
  // };

  // stopFetchOddsAuto = () => {
  //   console.log("race complete");
  //   clearInterval(this.oddsIntervalId);
  // };

  setMobileFluc = (type) => {
    if (type === this.state.mobileFluc) {
      this.setState({ mobileFluc: "" });
    } else {
      this.setState({ mobileFluc: type });
    }
  };

  runnerResultFromCountdown = (type) => {
    this.setState({ isRunnersResult: type });
  };

  render() {
    let { title, classes } = this.props;
    let {
      raceTrackClicked,
      openRaceRow,
      selectedodds,
      singleRaceData,
      runnersDetails,
      runnersOdds,
      raceTrackdata,
      isLoading,
      expandForm,
      runnersComments,
      isRunnersResult,
      runnersWin,
      isResultLoading,
      runnersChartData,
      commentInnerExpand,
      raceDataByRunner,
      mobileFluc,
      runnerFluc,
      oddLoading,
      isRunnerOddLoading,
      isRunnerCommentLoading,
      isRunnerLoading,
    } = this.state;

    singleRaceData = singleRaceData?.map((obj) => {
      let data = obj?.race;
      for (var i = obj?.race?.length; i < 16; i++) {
        let dummy_obj = { startTimeDate: null };
        data.push(dummy_obj);
      }
      obj["race"] = data;
      return { ...obj };
    });

    let runnersWithOdd = [];

    if (runnersDetails?.length > 0) {
      runnersWithOdd = runnersDetails;
    }

    if (runnersDetails?.length > 0) {
      runnersWithOdd = runnersDetails?.map((obj) => {
        let final_obj = { ...obj };
        if (runnersOdds?.length > 0) {
          let match_obj = runnersOdds?.find((data) => data?.id === obj?.id);
          let odds_obj =
            match_obj?.odds?.length > 0 ? match_obj?.odds[0]?.odd : [];
          if (title !== "Horse Racing") {
            let index = odds_obj.findIndex(function (e) {
              return e.bookKeepersId == 2;
            });
            if (index !== -1) odds_obj.splice(index, 1);
          }
          let best_odds =
            odds_obj?.length > 0
              ? odds_obj.reduce((prev, current) =>
                  +prev.value > +current.value ? prev : current
                )
              : {};
          final_obj = {
            ...final_obj,
            odds: odds_obj,
            bestOdds: best_odds,
            bestOpenValue: match_obj?.bestOpen,
          };
        }

        if (runnersChartData?.length > 0) {
          let match_graph_obj = runnersChartData?.find(
            (data) => data?.id === obj?.id
          );
          let temp_graph_array = [];
          temp_graph_array = match_graph_obj?.odds?.map((obj) => {
            let inner_array = obj;
            inner_array[1] = Number(inner_array[1]);
            return inner_array;
          });
          final_obj = { ...final_obj, graphData: temp_graph_array };
        }
        return final_obj;
      });
    }

    let runnerCommentPera = "";
    let runnersPreviousRun = [];
    let runnerExtraData = [];
    if (runnersComments?.length > 0) {
      runnerCommentPera = runnersComments?.find(
        (obj) => obj?.keyData?.name == "entrant_comment"
      );
      let runnersPreviousRunValue = runnersComments?.find(
        (obj) => obj?.keyData?.name == "previous_runs"
      );
      runnersPreviousRun =
        runnersPreviousRunValue !== undefined
          ? JSON.parse(runnersPreviousRunValue?.value)
          : "";

      runnerExtraData = runnersComments?.filter(
        (obj) =>
          obj?.keyData?.name !== "entrant_comment" &&
          obj?.keyData?.name !== "previous_runs"
      );
    }
    return (
      <>
        {oddLoading && (
          <div
            style={{
              position: "fixed",
              top: "45%",
              left: "48%",
              zIndex: "999",
            }}
          >
            <Loader />
          </div>
        )}
        <Grid container>
          <Grid item xs={12} className="allsports_body_outer_main">
            {!raceTrackClicked ? (
              <div className="spot-body-outer">
                <Typography className="singlerace_main_title" variant="h6">
                  {this.RaceIconHandle(title)}
                  &nbsp;&nbsp;
                  {title === "Horse Racing"
                    ? "Horses"
                    : title === "Greyhounds Racing"
                    ? "Greyhounds"
                    : title === "Harness Racing"
                    ? "Harness"
                    : "Horses"}
                </Typography>
                <Divider className="allsports_hr" />

                <div className="hide-xs">
                  <TableContainer className="racing-table-container">
                    <Table
                      className="racing-data"
                      aria-label="customized table"
                      style={{ minWidth: 700 }}
                    >
                      <TableHead className="racing-table-head">
                        <TableRow>
                          <StyledTableCell className="rtable-hc1"></StyledTableCell>
                          <StyledTableCell>R1</StyledTableCell>
                          <StyledTableCell>R2</StyledTableCell>
                          <StyledTableCell>R3</StyledTableCell>
                          <StyledTableCell>R4</StyledTableCell>
                          <StyledTableCell>R5</StyledTableCell>
                          <StyledTableCell>R6</StyledTableCell>
                          <StyledTableCell>R7</StyledTableCell>
                          <StyledTableCell>R8</StyledTableCell>
                          <StyledTableCell>R9</StyledTableCell>
                          <StyledTableCell>R10</StyledTableCell>
                          <StyledTableCell>R11</StyledTableCell>
                          <StyledTableCell>R12</StyledTableCell>
                          <StyledTableCell>R13</StyledTableCell>
                          <StyledTableCell>R14</StyledTableCell>
                          <StyledTableCell>R15</StyledTableCell>
                          <StyledTableCell>R16</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-odd-even">
                        {isLoading === "singleRace" && (
                          <StyledTableRow
                            style={{ borderBottom: "transparent" }}
                          >
                            <StyledTableCell
                              style={{
                                textAlign: "center",
                              }}
                              colSpan={17}
                            >
                              <Loader />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {isLoading !== "singleRace" &&
                          (singleRaceData?.length > 0 ? (
                            <>
                              {singleRaceData.map((race, index) => {
                                return (
                                  <StyledTableRow
                                    key={index}
                                    className="racing-data-row"
                                  >
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      className="racing-data"
                                    >
                                      <Typography
                                        className="track-title"
                                        variant="subtitle1"
                                      >
                                        {race?.track?.name}
                                      </Typography>
                                      <Typography
                                        className="country-title"
                                        variant="subtitle1"
                                      >
                                        {race?.country?.country}
                                      </Typography>
                                    </StyledTableCell>
                                    {race?.race?.slice(0, 16).map((obj, i) => (
                                      <TrackListCountdown
                                        race={race}
                                        race_obj={obj}
                                        key={i}
                                        expiryTimestamp={new Date(
                                          obj?.startTimeDate
                                        ).getTime()}
                                        checkRaceMinCell={this.checkRaceMinCell}
                                        fetchRunnersWin={this.fetchRunnersWin}
                                        selectRaceTrackHandler={
                                          this.selectRaceTrackHandler
                                        }
                                        runnerResultFromCountdown={
                                          this.runnerResultFromCountdown
                                        }
                                        isMobile={false}
                                      />
                                    ))}
                                  </StyledTableRow>
                                );
                              })}
                              <StyledTableRow
                                style={{
                                  borderBottom: "transparent",
                                  borderBottomLeftRadius: 5,
                                  borderBottomRightRadius: 5,
                                  backgroundColor: "#fff",
                                }}
                              >
                                <StyledTableCell
                                  style={{ height: 27 }}
                                  colSpan={17}
                                ></StyledTableCell>
                              </StyledTableRow>
                            </>
                          ) : (
                            <StyledTableRow
                              style={{ borderBottom: "transparent" }}
                            >
                              <StyledTableCell
                                style={{ textAlign: "center" }}
                                colSpan={17}
                              >
                                <p>No Data Available</p>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div className="hide-sm hide-md hide-lg hide-xl">
                  <div className="single-race-innerbody">
                    {isLoading === "singleRace" && (
                      <div className="d-flex-center">
                        <Loader />
                      </div>
                    )}
                    {isLoading !== "singleRace" &&
                      (singleRaceData?.length > 0 ? (
                        singleRaceData.map((race, index) => {
                          return (
                            <>
                              <div className="allsport_inner_heading single-race-track">
                                <div className="mobileview-track-country">
                                  <Typography
                                    className="title-left track-title"
                                    variant="subtitle1"
                                  >
                                    {race?.track?.name}
                                  </Typography>
                                  <Typography
                                    className="title-left country-title"
                                    variant="subtitle1"
                                  >
                                    {race?.country?.country}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                  }}
                                >
                                  <td
                                    style={{ width: 30, textAlign: "center" }}
                                  >
                                    <Typography
                                      className="mbvwractracknmbr"
                                      onClick={
                                        race?.race[0]?.raceNumber !== undefined
                                          ? () => {
                                              this.selectRaceTrackHandler(
                                                race?.race[0].id,
                                                race?.race[0],
                                                race
                                              );
                                            }
                                          : () => {}
                                      }
                                    >
                                      {race?.race[0]?.raceNumber !== undefined
                                        ? `R${race?.race[0].raceNumber}`
                                        : "-"}
                                    </Typography>
                                  </td>
                                  <TrackListCountdown
                                    race={race}
                                    race_obj={race?.race[0]}
                                    key={""}
                                    expiryTimestamp={new Date(
                                      race?.race[0].startTimeDate
                                    ).getTime()}
                                    checkRaceMinCell={this.checkRaceMinCell}
                                    fetchRunnersWin={this.fetchRunnersWin}
                                    selectRaceTrackHandler={
                                      this.selectRaceTrackHandler
                                    }
                                    runnerResultFromCountdown={
                                      this.runnerResultFromCountdown
                                    }
                                    isMobile={true}
                                  />
                                </div>
                              </div>
                              <Divider className="allsports_half_hr" />
                            </>
                          );
                        })
                      ) : (
                        <div className="d-flex-center">
                          <p>No Data Available</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* race result design */}
                {isRunnersResult && (
                  <>
                    <div className="races-result">
                      <div className="spot-body-outer">
                        <div className="race-result-header hide-xs">
                          <div className="white-title-box">Results</div>
                          <div className="right-race-titleblock">
                            <p className="right-final-title">
                              {`${raceTrackdata?.raceName} Race Result`}
                            </p>
                            <p className="right-final-title">
                              {`${moment
                                .utc(raceTrackdata?.startTimeDate)
                                .local()
                                .format("DD-MM-YYYY")} - ${moment
                                .utc(raceTrackdata?.startTimeDate)
                                .local()
                                .format("hh:mm a")}`}
                            </p>
                          </div>
                        </div>
                        <div className="race-result-header-mobile hide-sm hide-md hide-lg hide-xl">
                          <div className="race-result-top-header">
                            <Typography
                              variant="h6"
                              style={{ display: "flex" }}
                            >
                              {this.RaceIconHandle(title)}
                              {"   "}
                              {title === "Horse Racing"
                                ? "Horses"
                                : title === "Greyhounds Racing"
                                ? "Greyses"
                                : title === "Harness Racing"
                                ? "Harness"
                                : "Horses"}
                            </Typography>

                            <div className="track-details-mv ">
                              <div className="white-title-box">Results</div>
                            </div>
                          </div>
                          <Divider className="allsports_hr" />
                          <div className="right-race-titleblock">
                            <p className="right-final-title">
                              {`${raceTrackdata?.raceName} Race Result`}
                            </p>
                            <p className="right-final-title">
                              {`${moment
                                .utc(raceTrackdata?.startTimeDate)
                                .local()
                                .format("DD-MM-YYYY")} - ${moment
                                .utc(raceTrackdata?.startTimeDate)
                                .local()
                                .format("hh:mm a")}`}
                            </p>
                          </div>
                        </div>
                        <Divider className="allsports_hr" />
                        {isResultLoading && (
                          <div className="race-inner-body rr-mt-20">
                            <div
                              className="rrb-row"
                              style={{
                                justifyContent: "center",
                                padding: 15,
                              }}
                            >
                              <Loader />
                            </div>
                          </div>
                        )}
                        {!isResultLoading &&
                          (runnersWin?.length > 0 ? (
                            runnersWin?.map((obj, i) => (
                              <div className="race-inner-body rr-mt-20" key={i}>
                                <div className="rrb-row">
                                  <div className="race-result-title-column">
                                    <div className="left-icon-position">
                                      <Typography
                                        variant="h6"
                                        style={{ display: "flex" }}
                                      >
                                        {this.RaceIconHandle(title)}
                                      </Typography>
                                      <p className="race-result-position">
                                        {this.renderSufixes(Number(obj?.value))}
                                      </p>
                                    </div>
                                    <div className="rr-right-block">
                                      <p className="rrb-title">
                                        {obj?.animal?.name}
                                      </p>
                                      {/* <p className="rrb-jt-title">[Jockey]</p>
                                    <p className="rrb-jt-title">[Trainer]</p> */}
                                    </div>
                                  </div>

                                  <div className="rrr-container">
                                    <div className="rrr-win-block">
                                      <div>
                                        <p>Win</p>
                                        <p>
                                          {obj?.odds?.Win !== undefined
                                            ? Number(obj?.odds?.Win).toFixed(2)
                                            : Number(0).toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="rrr-place-block">
                                      <div>
                                        <p>Place</p>
                                        <p>
                                          {obj?.odds?.Place !== undefined
                                            ? Number(obj?.odds?.Place).toFixed(
                                                2
                                              )
                                            : Number(0).toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="race-inner-body rr-mt-20">
                              <div
                                className="rrb-row"
                                style={{
                                  justifyContent: "center",
                                  padding: 15,
                                }}
                              >
                                No Data Available
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}

                {isRunnersResult && (
                  <Typography variant="h6" className="race-card-title">
                    Race Card
                  </Typography>
                )}
                <div className="spot-body-outer hide-xs runners-list-outer">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      className="race-refresh-button"
                      onClick={() => this.fetchRunnersOdds(raceTrackdata?.id)}
                      disabled={isRunnerOddLoading ? true : false}
                    >
                      {isRunnerOddLoading ? <Loader /> : "Refresh"}
                    </button>
                  </div>
                  <div className="runners-list-header-main">
                    <Typography
                      variant="h6"
                      className="runners-list-header-title"
                    >
                      {this.RaceIconHandle(title)}
                      &nbsp;&nbsp;
                      {title === "Horse Racing"
                        ? "Horses"
                        : title === "Greyhounds Racing"
                        ? "Greyses"
                        : title === "Harness Racing"
                        ? "Harness"
                        : "Horses"}
                    </Typography>

                    {!isRunnersResult && (
                      <div className="track-details-mv runners-list-header-info">
                        <div>
                          <Typography
                            variant="subtitle1"
                            className="runners-list-info-title"
                          >
                            {`${
                              raceTrackdata?.distance !== null
                                ? raceTrackdata?.distance
                                : ""
                            } ${raceTrackdata?.raceName} ${moment
                              .utc(raceTrackdata?.startTimeDate)
                              .local()
                              .format("hh:mm a")}`}
                          </Typography>
                        </div>
                        <div className="race-length-box">
                          <RaceCountdown
                            expiryTimestamp={new Date(
                              raceTrackdata?.startTimeDate
                            ).getTime()}
                            // onComplete={this.stopFetchOddsAuto}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <Divider className="allsports_hr" />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: 12,
                    }}
                  >
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={selectedodds}
                        className="race-odd-select"
                        onChange={this.handleOdds}
                        style={{ zIndex: 1111 }}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        {oddsArray.map((obj, i) => (
                          <MenuItem
                            style={{
                              color: "#707070",
                              fontSize: 15,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                            className={
                              selectedodds === obj?.name
                                ? "selected_raceoption"
                                : "normal_raceoption"
                            }
                            key={i}
                            value={obj.name}
                          >
                            {obj.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {selectedodds === "Bookmaker Odds" ? (
                    <div>
                      <TableContainer className="race-bookmakersodd">
                        <Table
                          className="racing-data"
                          aria-label="customized table"
                          style={{ minWidth: 700, borderRadius: 5 }}
                        >
                          <TableHead className="racing-track-table-head">
                            <TableRow style={{ fontSize: 12 }}>
                              <StyledTableCell
                                style={{ minWidth: 231, textAlign: "left" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    fontSize: 12,
                                  }}
                                >
                                  <FormControl>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      IconComponent={KeyboardArrowDownIcon}
                                      value={expandForm}
                                      className="race-odd-select"
                                      onChange={this.handleExpandForm}
                                    >
                                      <MenuItem value="Expend Form">
                                        Expend Form
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>Open</StyledTableCell>
                              <StyledTableCell>Fluc 1</StyledTableCell>
                              <StyledTableCell>Fluc 2</StyledTableCell>
                              <StyledTableCell>Win Fixed</StyledTableCell>
                              {/* <StyledTableCell>Place Fixed</StyledTableCell>
                              <StyledTableCell>Place TT+</StyledTableCell>
                              <StyledTableCell>Each Way Fixed</StyledTableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-odd-even">
                            {isRunnerLoading && (
                              <StyledTableRow>
                                <StyledTableCell
                                  style={{ textAlign: "center" }}
                                  colSpan={8}
                                >
                                  <Loader />
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                            {!isRunnerLoading &&
                              (runnersWithOdd?.length > 0 ? (
                                runnersWithOdd.map((race, index) => {
                                  return (
                                    <>
                                      <StyledTableRow
                                        key={index}
                                        className={
                                          openRaceRow === index
                                            ? "racing-track-data-row rctdrbb-none"
                                            : runnersWithOdd?.length - 1 ===
                                              index
                                            ? "racing-track-data-row last-to2ndrow"
                                            : "racing-track-data-row"
                                        }
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          className="racing-data"
                                          onClick={() =>
                                            this.handleRaceRow(
                                              index,
                                              race?.id,
                                              "comments"
                                            )
                                          }
                                        >
                                          <div className="race-details-track">
                                            <div className="race-track-icon">
                                              {this.RaceIconHandle(title)}
                                            </div>
                                            <div style={{ width: "100%" }}>
                                              <div
                                                style={{ textAlign: "left" }}
                                              >
                                                <Typography
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span className="racing-horse-name">
                                                    {race?.animal?.name}
                                                  </span>
                                                  &nbsp;&nbsp;
                                                  <span className="racing-gate">
                                                    {!isRunnersResult
                                                      ? race?.barrierNumber
                                                      : ""}
                                                  </span>
                                                </Typography>
                                              </div>
                                              <div>
                                                <div>
                                                  <Typography
                                                    style={{
                                                      // width: 110,
                                                      display: "flex",
                                                      // justifyContent:
                                                      //   "space-between",
                                                      paddingTop: 2,
                                                    }}
                                                  >
                                                    <span className="racing-weight">
                                                      {race?.JockeyWeight}
                                                    </span>
                                                    <span className="racing-jockey">
                                                      {race?.member?.length >
                                                        0 &&
                                                        race?.member[0]?.player
                                                          ?.name}
                                                    </span>
                                                  </Typography>
                                                </div>
                                                <div>
                                                  <Typography
                                                    style={{
                                                      width: 110,
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      paddingTop: 2,
                                                    }}
                                                  >
                                                    <span className="racing-final">
                                                      {/* [Final] */}
                                                    </span>
                                                    <span className="racing-trainer">
                                                      {/* [Trainer] */}
                                                    </span>
                                                  </Typography>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {race?.bestOpenValue}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {/* {race?.fluc?.fluc1} */}
                                          {runnerFluc.filter(
                                            (data) => data.runnerId === race?.id
                                          ).length > 0 ? (
                                            runnerFluc
                                              .filter(
                                                (data) =>
                                                  data.runnerId === race?.id
                                              )
                                              .map((obj) => {
                                                return Number(
                                                  obj.fluc1
                                                ).toFixed(2);
                                              })
                                          ) : (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <Loader />
                                            </div>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {/* {race?.fluc?.fluc2} */}
                                          {runnerFluc.filter(
                                            (data) => data.runnerId === race?.id
                                          ).length > 0 ? (
                                            runnerFluc
                                              .filter(
                                                (data) =>
                                                  data.runnerId === race?.id
                                              )
                                              .map((obj) => {
                                                return Number(
                                                  obj.fluc2
                                                ).toFixed(2);
                                              })
                                          ) : (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <Loader />
                                            </div>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Button
                                            variant="contained"
                                            className="race-track-compare-btn"
                                            onClick={() =>
                                              this.handleCompare(race)
                                            }
                                            disabled={
                                              race?.isScratched === "true"
                                                ? true
                                                : false
                                            }
                                          >
                                            Compare
                                          </Button>
                                        </StyledTableCell>
                                        {/* <StyledTableCell align='center'>
                                          <Button
                                            variant="contained"
                                            className="race-track-compare-btn"
                                          >
                                            Compare
                                          </Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Button
                                            variant="contained"
                                            className="race-track-compare-btn"
                                          >
                                            Compare
                                          </Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Button
                                            variant="contained"
                                            className="race-track-compare-btn"
                                          >
                                            Compare
                                          </Button>
                                        </StyledTableCell> */}
                                      </StyledTableRow>
                                      {openRaceRow === null ? null : (
                                        <StyledTableRow
                                          style={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <TableCell
                                            colSpan={8}
                                            style={{
                                              padding: 0,
                                              borderBottom: "none",
                                            }}
                                          >
                                            <Collapse
                                              in={openRaceRow == index}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <>
                                                <div className="rcdtlstraiangle"></div>
                                                <div
                                                  className={
                                                    openRaceRow ==
                                                    runnersWithOdd?.length - 1
                                                      ? "single_race_open_main last-row-brdr-btm"
                                                      : "single_race_open_main"
                                                  }
                                                >
                                                  <div
                                                    className="single_race_open_innermain"
                                                    style={{ color: "#000" }}
                                                  >
                                                    {isRunnerCommentLoading && (
                                                      <div className="allsport-loader-center">
                                                        <Loader />
                                                      </div>
                                                    )}
                                                    {!isRunnerCommentLoading &&
                                                      (runnersComments?.length <=
                                                      0 ? (
                                                        <div className="allsport-loader-center">
                                                          <p>
                                                            No Data Available
                                                          </p>
                                                        </div>
                                                      ) : (
                                                        <>
                                                          <Typography
                                                            style={{
                                                              fontWeight: 600,
                                                            }}
                                                            className="single_race_open_heading"
                                                          >
                                                            Comments
                                                          </Typography>
                                                          <Typography
                                                            className="single_race_open_pera"
                                                            style={{
                                                              color: "#000",
                                                            }}
                                                          >
                                                            {runnerCommentPera !==
                                                              "" &&
                                                              JSON.parse(
                                                                runnerCommentPera?.value
                                                              )}
                                                          </Typography>
                                                          <Grid container>
                                                            {runnerExtraData?.length >
                                                              0 &&
                                                              runnerExtraData?.map(
                                                                (obj, i) => {
                                                                  let value =
                                                                    JSON.parse(
                                                                      obj?.value
                                                                    );
                                                                  return (
                                                                    <Grid
                                                                      item
                                                                      xs={2}
                                                                      key={i}
                                                                      className="single-race-open-gridwrap"
                                                                    >
                                                                      <Typography className="single_race_open_pera single-race-open-bold">
                                                                        {
                                                                          obj
                                                                            ?.keyData
                                                                            ?.name
                                                                        }
                                                                      </Typography>
                                                                      <Typography className="single_race_open_pera">
                                                                        {typeof value ===
                                                                        "string"
                                                                          ? value
                                                                          : value?.name}
                                                                      </Typography>
                                                                    </Grid>
                                                                  );
                                                                }
                                                              )}
                                                          </Grid>
                                                          <div>
                                                            <Typography className="single_race_open_pera single-race-open-bold single-race-open-gridwrap">
                                                              Recent Starts:
                                                            </Typography>
                                                            <Grid
                                                              container
                                                              className="single-race-open-gridwrap"
                                                            >
                                                              {runnersPreviousRun?.length >
                                                                0 &&
                                                                runnersPreviousRun?.map(
                                                                  (
                                                                    obj,
                                                                    index
                                                                  ) => {
                                                                    let data_to_show =
                                                                      Object.keys(
                                                                        obj
                                                                      );
                                                                    return (
                                                                      <Grid
                                                                        item
                                                                        xs={6}
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="single_race_open_acordiangrid"
                                                                      >
                                                                        <Accordion
                                                                          expanded={
                                                                            commentInnerExpand ==
                                                                            `comment${index}`
                                                                          }
                                                                          onChange={() =>
                                                                            this.handleCommentInnerAccordian(
                                                                              `comment${index}`
                                                                            )
                                                                          }
                                                                        >
                                                                          <AccordionSummary
                                                                            expandIcon={
                                                                              <ExpandMoreIcon />
                                                                            }
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                              minHeight: 33,
                                                                              maxHeight: 33,
                                                                            }}
                                                                          >
                                                                            <Grid
                                                                              container
                                                                            >
                                                                              <Grid
                                                                                item
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <Typography className="single_race_open_pera">
                                                                                  {
                                                                                    obj[
                                                                                      "number"
                                                                                    ]
                                                                                  }
                                                                                </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                item
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <Typography
                                                                                  style={{
                                                                                    textAlign:
                                                                                      "center",
                                                                                  }}
                                                                                  className="single_race_open_pera"
                                                                                >
                                                                                  {
                                                                                    obj?.date
                                                                                  }
                                                                                </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                item
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <Typography
                                                                                  style={{
                                                                                    textAlign:
                                                                                      "center",
                                                                                  }}
                                                                                  className="single_race_open_pera"
                                                                                >
                                                                                  {
                                                                                    obj?.finish
                                                                                  }
                                                                                </Typography>
                                                                              </Grid>
                                                                              <Grid
                                                                                item
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <Typography
                                                                                  style={{
                                                                                    fontWeight: 600,
                                                                                    textAlign:
                                                                                      "right",
                                                                                  }}
                                                                                  className="single_race_open_pera"
                                                                                >
                                                                                  {
                                                                                    obj?.finish
                                                                                  }
                                                                                </Typography>
                                                                              </Grid>
                                                                            </Grid>
                                                                          </AccordionSummary>
                                                                          <Divider className="runners_comments_hr" />
                                                                          <AccordionDetails className="alsprtsaccdetls">
                                                                            <div className="alsprtsaccdetlstp-raw">
                                                                              {data_to_show?.map(
                                                                                (
                                                                                  item,
                                                                                  i
                                                                                ) => {
                                                                                  let value =
                                                                                    obj[
                                                                                      item
                                                                                    ];
                                                                                  return (
                                                                                    <Typography
                                                                                      style={{
                                                                                        display:
                                                                                          "flex",
                                                                                      }}
                                                                                      className="single_race_open_acordian single-race-open-gridwrap"
                                                                                    >
                                                                                      <div
                                                                                        style={{
                                                                                          fontWeight: 600,
                                                                                        }}
                                                                                      >
                                                                                        {`${item}: `}
                                                                                      </div>
                                                                                      {
                                                                                        value
                                                                                      }
                                                                                    </Typography>
                                                                                  );
                                                                                }
                                                                              )}
                                                                            </div>
                                                                          </AccordionDetails>
                                                                        </Accordion>
                                                                      </Grid>
                                                                    );
                                                                  }
                                                                )}
                                                            </Grid>
                                                          </div>
                                                        </>
                                                      ))}
                                                  </div>
                                                </div>
                                              </>
                                            </Collapse>
                                          </TableCell>
                                        </StyledTableRow>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell
                                    style={{ textAlign: "center" }}
                                    colSpan={8}
                                  >
                                    <p>No Data Available</p>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : (
                    <div>
                      <div
                        className="mma-wining-oddscontainer"
                        style={{
                          marginTop: 0,
                          position: "relative",
                          bottom: 25,
                        }}
                      >
                        <div className="vcap-box">
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Status</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.status === ""
                                ? "-"
                                : raceDataByRunner?.status}
                            </p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Sts</p>
                            <p className="vcap-green">-</p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Class</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.raceClass === ""
                                ? "-"
                                : raceDataByRunner?.raceClass}
                            </p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Prize</p>
                            <p className="vcap-green">-</p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Distance</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.distance === null
                                ? "-"
                                : raceDataByRunner?.distance}
                            </p>
                          </div>
                        </div>

                        <div className="mma-winingodds-table">
                          <TableContainer>
                            <Table
                              className="mma-odds-bigtable"
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className="wining-fighter-details">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        fontSize: 12,
                                        padding: "50px 0px 0px 15px",
                                      }}
                                    >
                                      <FormControl>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          IconComponent={KeyboardArrowDownIcon}
                                          value={expandForm}
                                          className="race-odd-select"
                                          onChange={this.handleExpandForm}
                                        >
                                          <MenuItem value="Expend Form">
                                            Expend Form
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-best-at-open"
                                  >
                                    Best at Open
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-changes"
                                  >
                                    Changes
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-current-best"
                                  >
                                    Current Best
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a href={AFURLS?.Bet365} target="_blank">
                                        <Bet365 className="wining-bookmaker wn-bet365" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a href={AFURLS?.BetStar} target="_blank">
                                        <BetStar className="wining-bookmaker wn-bstr" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a
                                        href={AFURLS?.Bookmaker}
                                        target="_blank"
                                      >
                                        <BookMakers className="wining-bookmaker wn-bkmaker" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a
                                        href={AFURLS?.Ladbrokes}
                                        target="_blank"
                                      >
                                        <LadBrokes className="wining-bookmaker wn-ldbkers" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a href={AFURLS?.Neds} target="_blank">
                                        <Neds className="wining-bookmaker wn-nds" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="mma-bookmakercell"
                                  >
                                    <div className="bookmaker-whitebox">
                                      <a href={AFURLS?.payups} target="_blank">
                                        <PlayUp className="wining-bookmaker wn-plyups" />
                                      </a>
                                    </div>
                                  </TableCell>
                                  {title === "Horse Racing" && (
                                    <TableCell
                                      align="center"
                                      className="mma-bookmakercell"
                                    >
                                      <div className="bookmaker-whitebox">
                                        <a
                                          href={AFURLS?.vicbet}
                                          target="_blank"
                                        >
                                          <VICBet className="wining-bookmaker wn-vicbet" />
                                        </a>
                                      </div>
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody
                                component={Paper}
                                className="mma-wining-tbody"
                              >
                                {isRunnerLoading && (
                                  <TableRow>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      colSpan={
                                        title === "Horse Racing" ? 11 : 10
                                      }
                                    >
                                      <Loader />
                                    </TableCell>
                                  </TableRow>
                                )}
                                {!isRunnerLoading &&
                                  (runnersWithOdd?.length > 0 ? (
                                    runnersWithOdd.map((item, index) =>
                                      this.renderWinOddsTable(
                                        item,
                                        index,
                                        "desktop",
                                        title
                                      )
                                    )
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        style={{ textAlign: "center" }}
                                        colSpan={
                                          title === "Horse Racing" ? 11 : 10
                                        }
                                      >
                                        <p>No Data Available</p>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="rtrack-details-mv hide-sm hide-md hide-lg hide-xl">
                  <div className="spot-body-outer runners-list-outer race-track-mv">
                    <Typography
                      variant="h6"
                      className="runners-list-header-title"
                    >
                      {this.RaceIconHandle(title)}
                      &nbsp;&nbsp;
                      {title === "Horse Racing"
                        ? "Horses"
                        : title === "Greyhounds Racing"
                        ? "Greyses"
                        : title === "Harness Racing"
                        ? "Harness"
                        : "Horses"}
                      {!isRunnersResult && (
                        <button
                          className="race-refresh-button"
                          onClick={() =>
                            this.fetchRunnersOdds(raceTrackdata?.id)
                          }
                          disabled={isRunnerOddLoading ? true : false}
                        >
                          {isRunnerOddLoading ? <Loader /> : "Refresh"}
                        </button>
                      )}
                    </Typography>
                    <Divider className="allsports_hr" />
                    {!isRunnersResult && (
                      <>
                        <div className="runners-list-header-info">
                          <div>
                            <Typography
                              variant="body1"
                              className="runners-list-info-title"
                            >
                              {`${
                                raceTrackdata?.distance !== null
                                  ? raceTrackdata?.distance
                                  : ""
                              } ${raceTrackdata?.raceName} ${moment
                                .utc(raceTrackdata?.startTimeDate)
                                .local()
                                .format("hh:mm a")}`}
                            </Typography>
                          </div>
                          <div className="race-length-box">
                            <RaceCountdown
                              expiryTimestamp={new Date(
                                raceTrackdata?.startTimeDate
                              ).getTime()}
                              // onComplete={this.stopFetchOddsAuto}
                            />
                          </div>
                        </div>
                        <Divider className="allsports_hr" />
                      </>
                    )}

                    {selectedodds === "Bookmaker Odds" ? (
                      <div style={{ display: "inline-flex" }}>
                        <div
                          className={
                            mobileFluc === "open"
                              ? "race-track-box race-track-box-active"
                              : "race-track-box"
                          }
                          onClick={() => this.setMobileFluc("open")}
                        >
                          Open
                        </div>
                        <div
                          className={
                            mobileFluc === "fluc"
                              ? "race-track-box race-track-box-active"
                              : "race-track-box"
                          }
                          onClick={() => this.setMobileFluc("fluc")}
                        >
                          Fluc
                        </div>
                        <div
                          className={
                            mobileFluc === "place"
                              ? "race-track-box race-track-box-active"
                              : "race-track-box"
                          }
                          onClick={() => this.setMobileFluc("place")}
                        >
                          Place TT+
                        </div>
                        <div
                          className={
                            mobileFluc === "ew"
                              ? "race-track-box race-track-box-active"
                              : "race-track-box"
                          }
                          onClick={() => this.setMobileFluc("ew")}
                        >
                          EW
                        </div>
                      </div>
                    ) : (
                      <div className="mma-wining-oddscontainer res-oddscontainer">
                        <div className="vcap-box">
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Status</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.status === ""
                                ? "-"
                                : raceDataByRunner?.status}
                            </p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Sts</p>
                            <p className="vcap-green">-</p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Class</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.raceClass === ""
                                ? "-"
                                : raceDataByRunner?.raceClass}
                            </p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Prize</p>
                            <p className="vcap-green">-</p>
                          </div>
                          <div className="mma-white-boxes">
                            <p className="vcap-black">Distance</p>
                            <p className="vcap-green">
                              {raceDataByRunner?.distance === null
                                ? "-"
                                : raceDataByRunner?.distance}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="bookmaker-odds-mv">
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          IconComponent={KeyboardArrowDownIcon}
                          value={selectedodds}
                          className="race-odd-select"
                          onChange={this.handleOdds}
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          {oddsArray.map((obj, i) => (
                            <MenuItem
                              style={{
                                color: "#707070",
                                fontSize: 15,
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                              }}
                              className={
                                selectedodds === obj?.name
                                  ? "selected_raceoption"
                                  : "normal_raceoption"
                              }
                              key={i}
                              value={obj.name}
                            >
                              {obj.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <Divider className="allsports_hr" />

                    {selectedodds === "Bookmaker Odds" ? (
                      <div className="race-track-table-mv">
                        <div className="track-table-header-mv">
                          <div className="expand-form-mv">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  IconComponent={KeyboardArrowDownIcon}
                                  value={expandForm}
                                  className="race-odd-select"
                                  onChange={this.handleExpandForm}
                                >
                                  <MenuItem value="Expend Form">
                                    Expend Form
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="win-place-mv">
                            Win &nbsp;
                            <ReactSVG src={UpDownIcon} />
                          </div>
                          {/* <div className="win-place-mv">
                            Place &nbsp;
                            <ReactSVG src={UpDownIcon} />
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="race-track-table-mv">
                        <div className="track-table-header-mv">
                          <div className="expand-form-mv res-efrom">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  IconComponent={KeyboardArrowDownIcon}
                                  value={expandForm}
                                  className="race-odd-select"
                                  onChange={this.handleExpandForm}
                                >
                                  <MenuItem value="Expend Form">
                                    Expend Form
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>

                          <div className="win-place-mv res-mao">
                            Best at Open
                          </div>
                          <div className="win-place-mv res-cng">Changes</div>
                        </div>
                      </div>
                    )}

                    {selectedodds === "Bookmaker Odds" ? (
                      <div className="race-track-data-table-mv">
                        {isRunnerLoading && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Loader />
                          </div>
                        )}
                        {!isRunnerLoading &&
                          (runnersWithOdd?.length > 0 ? (
                            runnersWithOdd.map((race, index) => {
                              return (
                                <>
                                  <div
                                    className={
                                      openRaceRow == index
                                        ? "race-track-data-table-mv-raw rsactivebbnone"
                                        : runnersWithOdd?.length - 1 === index
                                        ? "race-track-data-table-mv-raw last-to2nd-mvrow"
                                        : "race-track-data-table-mv-raw"
                                    }
                                  >
                                    <div className="rthname">
                                      <div className="rdt-mv-icon">
                                        {this.RaceIconHandle(title)}
                                      </div>
                                      <div
                                        onClick={() =>
                                          this.handleRaceRow(
                                            index,
                                            race?.id,
                                            "comments"
                                          )
                                        }
                                        style={{ fontWeight: 600 }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span className="rth-horsename">
                                            {race?.animal?.name}
                                          </span>
                                          <span className="rth-gatename">
                                            {race?.barrierNumber}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span className="rth-weightname">
                                            {race?.JockeyWeight}
                                          </span>
                                          <span className="rth-jockeyname">
                                            {race?.member?.length > 0 &&
                                              race?.member[0]?.player?.name}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span className="rth-weightname">
                                            {/* [Final] */}
                                          </span>
                                          <span className="rth-jockeyname">
                                            {/* [Trainer] */}
                                          </span>
                                        </div>
                                        {mobileFluc !== "" && (
                                          <div className="mobile-fluc-detail">
                                            <span className="rth-weightname">
                                              {`${mobileFluc}:  `}
                                            </span>
                                            {mobileFluc == "open" ? (
                                              <span className="rth-weightname">
                                                {race?.bestOpenValue}
                                              </span>
                                            ) : mobileFluc == "fluc" ? (
                                              <>
                                                <span className="rth-weightname">
                                                  {runnerFluc.filter(
                                                    (data) =>
                                                      data.runnerId === race?.id
                                                  ).length > 0 &&
                                                    runnerFluc
                                                      .filter(
                                                        (data) =>
                                                          data.runnerId ===
                                                          race?.id
                                                      )
                                                      .map((obj) => {
                                                        return Number(
                                                          obj.fluc1
                                                        ).toFixed(2);
                                                      })}
                                                </span>
                                                <span className="rth-jockeyname">
                                                  {runnerFluc.filter(
                                                    (data) =>
                                                      data.runnerId === race?.id
                                                  ).length > 0 &&
                                                    runnerFluc
                                                      .filter(
                                                        (data) =>
                                                          data.runnerId ===
                                                          race?.id
                                                      )
                                                      .map((obj) => {
                                                        return Number(
                                                          obj.fluc2
                                                        ).toFixed(2);
                                                      })}
                                                </span>
                                              </>
                                            ) : mobileFluc == "place" ? (
                                              <span className="rth-weightname">
                                                {"-"}
                                              </span>
                                            ) : (
                                              <span className="rth-weightname">
                                                {"-"}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="rth-win">
                                      <Button
                                        variant="contained"
                                        className="rth-compare-btn"
                                        onClick={() => this.handleCompare(race)}
                                        disabled={
                                          race?.isScratched === "true"
                                            ? true
                                            : false
                                        }
                                      >
                                        Compare
                                      </Button>
                                    </div>
                                    {/* <div className='rth-win rth-place'>
                                      <Button
                                        variant="contained"
                                        className="rth-compare-btn"
                                        onClick={() => {}}
                                      >
                                        Compare
                                      </Button>
                                    </div> */}
                                  </div>
                                  {openRaceRow === null ? null : (
                                    <div>
                                      <Collapse
                                        in={openRaceRow == index}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <>
                                          <div className="rcdtlstraiangle"></div>
                                          <div
                                            className={
                                              openRaceRow ==
                                              runnersWithOdd?.length - 1
                                                ? "single_race_open_main last-row-brdr-btm-mv"
                                                : "single_race_open_main"
                                            }
                                          >
                                            <div className="single_race_open_innermain">
                                              {isRunnerCommentLoading && (
                                                <div className="allsport-loader-center">
                                                  <Loader />
                                                </div>
                                              )}
                                              {!isRunnerCommentLoading &&
                                                (runnersComments?.length <=
                                                0 ? (
                                                  <div className="allsport-loader-center">
                                                    <p>No Data Available</p>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <Typography className="single_race_open_heading">
                                                      Comments
                                                    </Typography>
                                                    <Typography className="single_race_open_pera">
                                                      {runnerCommentPera !==
                                                        "" &&
                                                        JSON.parse(
                                                          runnerCommentPera?.value
                                                        )}
                                                    </Typography>
                                                    <Grid container>
                                                      {runnerExtraData?.length >
                                                        0 &&
                                                        runnerExtraData?.map(
                                                          (obj, i) => {
                                                            /* let heading =
                                                        Object.keys(obj);
                                                      heading = heading[0];
                                                      let value =
                                                        Object.values(obj);
                                                      value = value[0]; */
                                                            let value =
                                                              JSON.parse(
                                                                obj?.value
                                                              );
                                                            return (
                                                              <Grid
                                                                item
                                                                xs={3}
                                                                sm={2}
                                                                key={i}
                                                                className="single-race-open-gridwrap"
                                                              >
                                                                <Typography className="single_race_open_pera single-race-open-bold">
                                                                  {
                                                                    obj?.keyData
                                                                      ?.name
                                                                  }
                                                                </Typography>
                                                                <Typography className="single_race_open_pera">
                                                                  {typeof value ===
                                                                  "string"
                                                                    ? value
                                                                    : value?.name}
                                                                </Typography>
                                                              </Grid>
                                                            );
                                                          }
                                                        )}
                                                    </Grid>
                                                    <div>
                                                      <Typography className="single_race_open_pera single-race-open-bold single-race-open-gridwrap">
                                                        Recent Starts:
                                                      </Typography>
                                                      <Grid
                                                        container
                                                        className="single-race-open-gridwrap"
                                                      >
                                                        {runnersPreviousRun?.length >
                                                          0 &&
                                                          runnersPreviousRun?.map(
                                                            (obj, index) => {
                                                              let data_to_show =
                                                                Object.keys(
                                                                  obj
                                                                );
                                                              return (
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={6}
                                                                  key={index}
                                                                  className="single_race_open_acordiangrid"
                                                                >
                                                                  <Accordion
                                                                    expanded={
                                                                      commentInnerExpand ==
                                                                      `comment${index}`
                                                                    }
                                                                    onChange={() =>
                                                                      this.handleCommentInnerAccordian(
                                                                        `comment${index}`
                                                                      )
                                                                    }
                                                                  >
                                                                    <AccordionSummary
                                                                      expandIcon={
                                                                        <ExpandMoreIcon />
                                                                      }
                                                                      aria-controls="panel1a-content"
                                                                      id="panel1a-header"
                                                                      style={{
                                                                        minHeight: 32,
                                                                        maxHeight: 32,
                                                                      }}
                                                                    >
                                                                      <Grid
                                                                        container
                                                                      >
                                                                        <Grid
                                                                          item
                                                                          xs={3}
                                                                        >
                                                                          <Typography className="single_race_open_pera">
                                                                            {
                                                                              obj?.number
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={3}
                                                                        >
                                                                          <Typography
                                                                            style={{
                                                                              textAlign:
                                                                                "center",
                                                                            }}
                                                                            className="single_race_open_pera"
                                                                          >
                                                                            {
                                                                              obj?.date
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={3}
                                                                        >
                                                                          <Typography
                                                                            style={{
                                                                              textAlign:
                                                                                "center",
                                                                            }}
                                                                            className="single_race_open_pera"
                                                                          >
                                                                            {
                                                                              obj?.finish
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                          item
                                                                          xs={3}
                                                                        >
                                                                          <Typography
                                                                            style={{
                                                                              fontWeight: 600,
                                                                              textAlign:
                                                                                "right",
                                                                            }}
                                                                            className="single_race_open_pera"
                                                                          >
                                                                            {
                                                                              obj?.finish
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                      </Grid>
                                                                    </AccordionSummary>
                                                                    <Divider className="runners_comments_hr" />
                                                                    <AccordionDetails>
                                                                      <div
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                        }}
                                                                      >
                                                                        <div className="alsprtsaccdetlstp-raw">
                                                                          {data_to_show?.map(
                                                                            (
                                                                              item,
                                                                              i
                                                                            ) => {
                                                                              let value =
                                                                                obj[
                                                                                  item
                                                                                ];
                                                                              return (
                                                                                <Typography
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                  }}
                                                                                  className="single_race_open_acordian single-race-open-gridwrap"
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      fontWeight: 600,
                                                                                    }}
                                                                                  >
                                                                                    {`${item}: `}
                                                                                    &nbsp;
                                                                                  </div>
                                                                                  {
                                                                                    value
                                                                                  }
                                                                                </Typography>
                                                                              );
                                                                            }
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    </AccordionDetails>
                                                                  </Accordion>
                                                                </Grid>
                                                              );
                                                            }
                                                          )}
                                                      </Grid>
                                                    </div>
                                                  </>
                                                ))}
                                            </div>
                                          </div>
                                        </>
                                      </Collapse>
                                    </div>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Data Available</p>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="race-track-data-table-mv">
                        {isRunnerLoading && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Loader />
                          </div>
                        )}
                        {!isRunnerLoading &&
                          (runnersWithOdd?.length > 0 ? (
                            runnersWithOdd.map((item, index) =>
                              this.renderWinOddsTable(
                                item,
                                index,
                                "mobile",
                                title
                              )
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p>No Data Available</p>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
AllSportsHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AllSportsHome);
